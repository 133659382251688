import React, { useEffect, useState } from 'react';
import { ModalBody } from 'react-bootstrap';
import { Button, Modal } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';

interface RekognitionParent {
    Name: string;
}

interface RekognitionResult {
    Confidence: number;
    Instances: any[];
    Name: string;
    Parents: RekognitionParent[];
};

const apiPath = 'https://jrgrover.com/api/mountain';

const MountainOut: React.FC = () => {
    const [result, setResult] = useState<RekognitionResult|null>();

    const check = async () => {
        const rekognitionResults: RekognitionResult[] = await (await fetch(apiPath)).json();
        console.log(rekognitionResults);
        setResult(rekognitionResults.find(rekognitionResult => {
            return rekognitionResult.Name === "Mountain";
        }) || null);
    }

    useEffect(() => { check(); }, []);

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                textAlign: 'center',
            }}
        >
            {
                result === undefined
                    ? (<h2>Checking if the mountain is out...</h2>)
                    : (
                        <MountainOutResult
                            rekognitionResult={result}
                        />
                    )
            }
        </div>
    )
}

export default MountainOut;

interface MountainOutResultProps {
    rekognitionResult: RekognitionResult|null;
}

const MountainOutResult: React.FC<MountainOutResultProps> = ({ rekognitionResult }) => {
    const [proof, setProof] = useState(false);
    const definitely = rekognitionResult !== null && rekognitionResult.Confidence > 75;
    return (
        <div>
            {rekognitionResult === null
                ? (<h4>Sad boi hours, no mountain :(</h4>)
                : definitely
                    ? (<h3>The Mountain is Out!<br/>🏔️</h3>)
                    : (<h4>Maybe the mountain is out?<br/>?🏔?️</h4>)}
            <Button
                onClick={() => setProof(true)}
            >
                Prove it
            </Button>
            {
                proof && (
                    <Modal
                        show={proof}
                        onHide={() => { setProof(false) }}
                    >
                        <ModalHeader closeButton={true}>
                            <a href="https://cdn.tegna-media.com/king/weather/waterfront.jpg">See?</a>
                        </ModalHeader>
                        <ModalBody>
                            <img
                                style={{ width: '100%' }}
                                src="https://s3.amazonaws.com/jrgrover.com-strava/mountain/rainier.png"
                                alt={`${definitely ? 'definitely' : 'maybe'} a mountain`}
                            />
                        </ModalBody>
                    </Modal>
                )
            }
        </div>
    );
}