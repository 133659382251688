import * as React from 'react';
import * as d3 from 'd3';
import { margin, linearGradientStops } from './constants';
import * as SunCalc from 'suncalc';

export interface LatitudeLongitude {
  latitude: number;
  longitude: number;
}

export interface Data {
  name: string;
  values: [Date, number][]
}

interface Props {
  parentContainer: HTMLElement;
  title: string;
  latitudeLongitude: LatitudeLongitude;
  data: Data[];
  currentTime: Date;
}

export default class Graph extends React.Component<Props> {
  private x = d3.scaleTime();
  private y = d3.scaleLinear();
  private bisectDate = d3.bisector(function(d) { return d; }).left;
  private dateDomain: Date[] = [];

  private xAxis = d3.axisBottom(this.x);
  private yAxis = d3.axisLeft(this.y);

  private xAxisElement: SVGElement|null = null;
  private yAxisElement: SVGElement|null = null;

  private line = d3.line<[Date, number]>()
    .curve(d3.curveLinear)
    .x(d => this.x(d[0]))
    .y(d => this.y(d[1]));

  public constructor(props: Props) {
    super(props);
  }

  public state = {
    width: 0,
    height: 0,
    graphWidth: 0,
    graphHeight: 0,
    focus: false,
    focusDate: 0,
    focusIndex: 0,
    focusPoint: 0,
  };

  private resize = (parentContainer: HTMLElement) => {
    const width = parentContainer.offsetWidth;
    const height = parentContainer.offsetHeight;
    const graphWidth = width - margin.left - margin.right;
    const graphHeight = height - margin.top - margin.bottom;
    // These could move to render???
    this.x.range([0, graphWidth]);
    this.y.range([graphHeight, 0]);
    this.xAxis.scale(this.x);
    this.yAxis.scale(this.y);
    this.setState({ width, height, graphWidth, graphHeight });
    this.updateAxis();
  };

  private handleResize = () => {
    this.resize(this.props.parentContainer);
  }

  private updateAxis = () => {
    d3.select(this.xAxisElement).call(this.xAxis as any);
    d3.select(this.yAxisElement).call(this.yAxis as any);
  }

  private updateData = (data: Data[]) => {
    var ymin = d3.min(
      data,
      d => (d3.min(d.values.map(a => a[1])) || 0) - 5);
    var ymax = d3.max(
      data,
      d => (d3.max(d.values.map(a => a[1])) || 0) + 5);
    this.y.domain(
      [
        ymin || 0,
        ymax || 0,
      ]
    );

    this.dateDomain = data[0].values.map(a => a[0]) || [new Date()];
    const dateExtentTry = d3.extent(this.dateDomain);

    this.x.domain([dateExtentTry[0] || new Date(), dateExtentTry[1] || new Date()]);
  }

  public componentWillMount() {
    this.updateData(this.props.data);
    this.resize(this.props.parentContainer);
    window.addEventListener('resize', this.handleResize);
  }

  public componentDidMount() {
    this.updateAxis();
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  public handleOverlayMouseOver = () => {
    this.setState({ focus: true });
  }

  public handleOverlayMouseOut = () => {
    this.setState({ focus: false });
  }

  public handleOverlayMouseMove = (event: React.MouseEvent<SVGRectElement>) => {
    const { data } = this.props;
    const rect = event.currentTarget.getBoundingClientRect();
    const xPos = event.clientX - rect.left;;
    const x0 = +this.x.invert(xPos);
    let i = this.bisectDate(this.dateDomain, x0, 1);
    const d0 = +this.dateDomain[i - 1];
    const d1 = +this.dateDomain[i];
    const focusDate = ((x0 - d0) > (d1 - x0)) ? d1 : d0;
    const focusIndex = ((x0 - d0) > (d1 - x0)) ? i : i - 1;

    const tempY = this.y(data[0].values[focusIndex][1])
    const dewY = this.y(data[1].values[focusIndex][1])
    const focusPoint = (tempY > dewY) ? dewY : tempY
    
    this.setState({ focusDate, focusIndex, focusPoint });
  }

  public componentWillReceiveProps(nextProps: any){
    this.updateData(nextProps.data);
    this.resize(nextProps.parentContainer);
  }

  public render() {
    const {
      currentTime,
      latitudeLongitude,
      title,
      data,
    } = this.props;
    const {
      width,
      height,
      graphWidth,
      graphHeight,
      focus,
      focusDate,
      focusIndex,
      focusPoint,
    } = this.state;

    const days = d3.range(0,11).map((d, i) => {
      const dateToIncrement = new Date(currentTime)
      const day0 = new Date(dateToIncrement.setDate(dateToIncrement.getDate() + d))
      const day1 = new Date(dateToIncrement.setDate(dateToIncrement.getDate() + 1))
      const sunset = SunCalc.getTimes(day0 , latitudeLongitude.latitude , latitudeLongitude.longitude).sunset
      const sunrise = SunCalc.getTimes(day1 , latitudeLongitude.latitude , latitudeLongitude.longitude).sunrise
      const left = Math.max(0, Math.min(this.x(sunset), graphWidth));
      const right = Math.min(this.x(sunrise), graphWidth);
      const width = (right - left);

      return (
        <rect
          key={i}
          className="day"
          height={graphHeight}
          width={width}
          x={left}
        />
      );
    });

    const stops = linearGradientStops.map((stop, i) => {
      return (
        <stop
          key={i}
          offset={this.y(stop.temp) / this.y.range()[0]}
          stopColor={stop.color}
        />
      );
    });

    const lines = data.map((d, i) => {
      return (
        <g
          key={i}
          className="dataLines"
        >
          <path
            className="line"
            d={this.line(d.values) || undefined}
          />
          {focus && (
            <g
              className="focus"
              transform={`translate(${this.x(focusDate)}, ${this.y(d.values[focusIndex][1])})`}
            >
              <circle
                r={4.5}
                stroke="#FFF"
	              strokeWidth="1.5px"
	              fill="none"
              />
              <text
                y={-14}
                dy=".35em"
              >
                {`${d.name} : ${d.values[focusIndex][1]}`}
              </text>
            </g>
          )}
        </g>
      );
    })

    return (
      <svg
        width={width}
        height={height}
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0%"
            x2="0%"
            y1="0%"
            y2="100%"
          >
            {stops}
          </linearGradient>
        </defs>
        <g
          transform={`translate(${margin.left}, ${margin.top})`}
        >
          <rect
            fill="url(#linear-gradient)"
            className="background"
            width={graphWidth}
            height={graphHeight}
          />
          {days}
          <g
            className="x axis"
            transform={`translate(0, ${graphHeight})`}
            ref={(el) => { this.xAxisElement = el; }}
          />
          <g
            className="y axis"
            ref={(el) => { this.yAxisElement = el; }}
          />
          <line
            className="xLine"
            transform={`translate(${this.x(focusDate)}, ${focusPoint})`}
            style={{ stroke: 'white', strokeDasharray: '3,3' }}
            y1={0}
            y2={graphHeight - focusPoint}
          />
          {lines}
          {focus && (<text
            className="time"
            transform={`translate(${this.x(focusDate)}, ${this.y.range()[0] - 5})`}
          >
            {(new Date(focusDate)).toLocaleString()}
          </text>)}
          <rect
            className="overlay"
            onMouseOver={this.handleOverlayMouseOver}
            onMouseOut={this.handleOverlayMouseOut}
            onMouseMove={this.handleOverlayMouseMove}
            width={graphWidth}
            height={graphHeight}
          />
        </g>
        <text
          textAnchor="middle"
          style={{ fontSize: "16px" }}
          transform={`translate(${margin.left/2}, ${height/2})rotate(-90)`}
        >
          Degrees (Fahrenheit)
        </text>
        <text
          textAnchor="middle"
          style={{ fontSize: '35px' }}
          className="titleText"
          x={width/2}
          y={40 + margin.top}
        >
          {title}
        </text>
      </svg>
    );
  }
};
