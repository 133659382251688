import React, { useState } from 'react';

const GetSlopeAngleMaskPath = (height: number, width: number) => {
    return `M0,${height} S ${width},${height} ${width},0 L${width},${height} Z`;
}

const Width = 400;
const Height = 350;
const SlopeAngleMaskPath = GetSlopeAngleMaskPath(Height, Width);

interface SlopeAngleMaskPreviewProps {
    min: number;
    max: number;
    changeStart: (start: number) => void;
    changeEnd: (end: number) => void;
}

const SlopeAngleMaskPreview: React.FC<SlopeAngleMaskPreviewProps> = ({
    min,
    max,
    changeStart,
    changeEnd,
}) => {
    const start = (min / 90) * Width;
    const end = (max / 90) * Width;
    const maskWidth = end - start;
    const [draggingStart, setDraggingStart] = useState(false);
    const [draggingEnd, setDraggingEnd] = useState(false);
    const [mouseOverStart, setMouseOverStart] = useState(false);
    const [mouseOverEnd, setMouseOverEnd] = useState(false);

    const onDrag = (event: React.MouseEvent<SVGElement, MouseEvent>) => {
        const target = event.currentTarget;
        if (draggingStart) {
            const bbox = (target as SVGElement).getBoundingClientRect();
            const diff = event.clientX - bbox.x;
            const percentInClientWidth = diff / target.clientWidth;
            const changedStart = percentInClientWidth * 90;
            changeStart(
                Math.max(
                    Math.min(
                        max,
                        changedStart
                    ),
                    0
                )
            );
        } else if (draggingEnd) {
            const changedEnd = ((end + event.movementX) / Width) * 90;
            changeEnd(
                Math.min(
                    90,
                    Math.max(
                        min,
                        changedEnd
                    )
                )
            );
        }
    }

    return (
        <svg
            width="100%"
            height={`${Height}px`}
            viewBox={`0 0 ${Width} ${Height + 25}`}
            preserveAspectRatio="none"
            onMouseMove={onDrag}
            style={{
                cursor: (draggingStart || draggingEnd) ? 'col-resize' : undefined
            }}
            onMouseUp={() => {
                setDraggingEnd(false);
                setDraggingStart(false);
                setMouseOverEnd(false);
                setMouseOverStart(false);
            }}
            onMouseLeave={() => {
                setDraggingEnd(false);
                setDraggingStart(false);
                setMouseOverEnd(false);
                setMouseOverStart(false);
            }}
        >
            <clipPath id="mask">
                <rect
                    height="400px"
                    x={`${start}px`}
                    y="0px"
                    width={`${maskWidth}px`}
                />
            </clipPath>
            <pattern
                id="diagonalHatch"
                width="10"
                height="10"
                patternTransform="rotate(45 0 0)"
                patternUnits="userSpaceOnUse"
            >
                <rect
                    x="0"
                    y="0"
                    width="10"
                    height="10"
                    style={{ fill: '#FF8' }}
                />
                <line
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="10"
                    style={{
                        stroke: 'black',
                        strokeWidth: 10,
                     }}
                />
            </pattern>
            <text
                y={15}
                x={start}
                style={{ textAnchor: 'middle' }}
                fill="#DDD"
            >
                {min.toFixed(2)}°
            </text>
            <text
                y={15}
                x={end}
                style={{ textAnchor: 'middle' }}
                fill="#DDD"
            >
                {max.toFixed(2)}°
            </text>
            <text
                y={Height + 15}
                x={0}
                fill="#DDD"
            >
                0°
            </text>
            <text
                y={Height + 15}
                x={Width}
                style={{ textAnchor: 'end' }}
                fill="#DDD"
            >
                90°
            </text>
            <g>
                <path
                    fill="#EEE"
                    stroke="#DDD"
                    d={SlopeAngleMaskPath}
                />
            </g>
            <g>
                <path
                    fill="url(#diagonalHatch)"
                    clipPath="url(#mask)"
                    color="#CCC"
                    stroke="#DDD"
                    d={SlopeAngleMaskPath}
                />
            </g>
            <g>
                <line
                    style={{
                        transition: 'all 200ms linear',
                        cursor: 'col-resize',
                    }}
                    x1={start}
                    x2={start}
                    y1={20}
                    y2={Height}
                    stroke="#CCC"
                    strokeWidth={mouseOverStart ? 4 : 2}
                    onMouseDown={() => setDraggingStart(true) }
                    onMouseUp={() => setDraggingStart(false) }
                    onMouseOver={() => setMouseOverStart(true) }
                    onMouseOut={() => setMouseOverStart(draggingStart || false) }
                />
                <line
                    style={{
                        transition: 'all 200ms linear',
                        cursor: 'col-resize',
                    }}
                    x1={end}
                    x2={end}
                    y1={20}
                    y2={Height}
                    stroke="#CCC"
                    strokeWidth={mouseOverEnd ? 4 : 2}
                    onMouseDown={() => setDraggingEnd(true) }
                    onMouseUp={() => setDraggingEnd(false) }
                    onMouseOver={() => setMouseOverEnd(true) }
                    onMouseOut={() => setMouseOverEnd(draggingEnd || false) }
                />
            </g>
        </svg>
    )
}

export default SlopeAngleMaskPreview;