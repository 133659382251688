import * as React from 'react';
import mapboxgl from 'mapbox-gl';
import { ResortData, SkiActivityData } from '.';
import * as d3 from 'd3';
import { GetGeoJsonFeatureForPath } from 'src/lib/Utils/GeoJSON';
import { GetDefaultHeatmapColor } from '../Running/HeatmapUtils';
import { EmptyGeoJsonFeatureCollection, GetMapboxStyleUrl, HeatmapLayer, MapboxStyle, PathsLayer, PointsLayer } from 'src/lib/Utils/Maps';
import { HeatmapWrapper } from '../Running/MapboxUtils/HeatmapWrapper';
import { LayerToggle } from '../Running/MapboxUtils/LayerToggle';
import { faCircle, faFire, faLocationArrow, faMap, faSquare, faTh } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { Feature } from 'geojson';
import { CreateGeoJSONBoxForBinExtent, GetAcreBinDeduplicateDays, GetGeoJsonBinWithCoordinatesAndColor } from 'src/lib/Utils/Skiing';

export interface SkiingHeatmapWrapperProps  {
    resort: ResortData;
    activities: SkiActivityData[];
    style?: MapboxStyle;
}

const pathColorInterpolation = d3.interpolateHclLong('#08519c', '#f7fbff');
const commonColorInterpolation = d3.interpolateHsl("rgb(255,237,160)", "rgb(128,0,38)");
interface TrackPoint extends Array<number> {
    ele?: number;
    time?: number;
}

export const getTrackPointsFromActivityStreams = (activity: SkiActivityData) => {
    const latlng = activity.data.latlng;
    const altitude = activity.data.altitude;
    const time = activity.data.distance;
    return latlng.map((value, index) => {
        const point: TrackPoint = [value[1], value[0]];
        point.ele = altitude[index];
        point.time = time[index];
        return point
    });
}

const GetPathColor = (number: number) => {
    if (number === 1) {
        return 'rgb(43, 236, 137)';
    }
    return pathColorInterpolation(number);
}

const SkiingHeatmapWrapper: React.FC<SkiingHeatmapWrapperProps> = (props) => {
    return (
        <HeatmapWrapper
            Heatmap={SkiingHeatmap}
            style={MapboxStyle.SKIING}
            {...props}
        />
    );
}

export default SkiingHeatmapWrapper;

interface SkiingHeatmapProps {
    map: mapboxgl.Map;
    cache: Cache;
    resort: ResortData;
    activities: SkiActivityData[];
};

interface PathsAndPoints {
    pathsFeatures: Feature<any, any>[],
    lonlat: TrackPoint[],
};

const SkiingHeatmap: React.FC<SkiingHeatmapProps> = ({ resort, activities, map, cache }) => {
    const [satelliteView, setSatelliteView] = useState(false);
    const [bins] = useState(() => {
        return GetAcreBinDeduplicateDays(activities, resort.bounds);
    });
    const [pathsAndPoints] = useState<PathsAndPoints>(() => {
        return activities.reduce((
            pathsAndPointsAccumulated: PathsAndPoints,
            activity: SkiActivityData,
            index: number) => {
                const trackPoints = getTrackPointsFromActivityStreams(activity);
                pathsAndPointsAccumulated.pathsFeatures.push(
                    GetGeoJsonFeatureForPath({
                        color: GetPathColor((index + 1) / activities.length),
                        coordinates: trackPoints,
                        activityId: activity.id,
                    })
                );
                trackPoints.forEach(point => {
                    pathsAndPointsAccumulated.lonlat.push(point);
                });
                return pathsAndPointsAccumulated;
        }, {
            pathsFeatures: [],
            lonlat: [],
        });
    })

    const AddSourcesAndLayers = useCallback(() => {
        map.getSource('paths') ||
        map.addSource('paths', EmptyGeoJsonFeatureCollection());
        map.getSource('points') ||
            map.addSource('points', EmptyGeoJsonFeatureCollection());
        map.getSource('bins') ||
            map.addSource('bins', EmptyGeoJsonFeatureCollection());
        map.getSource('mesh') ||
            map.addSource('mesh', EmptyGeoJsonFeatureCollection());

        map.getLayer('paths') ||
            map.addLayer(PathsLayer({ visibility: 'none' }));
        const pointsLayer = PointsLayer();
        if (pointsLayer.paint) {
            pointsLayer.paint['circle-color'] = 'rgba(87, 179, 79, 1)';
        }
        map.getLayer('points') ||
            map.addLayer(pointsLayer);
        map.getLayer('heatmap') ||
            map.addLayer(HeatmapLayer('heatmap', GetDefaultHeatmapColor(), 'points'));
        map.getLayer('bins') ||
            map.addLayer({
                id: 'bins',
                type: 'fill',
                source: 'bins',
                paint: {
                    'fill-color': [
                        'get',
                        'color'
                    ],
                    'fill-opacity': [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        12, 0,
                        13, 0.75,
                        24, 0.25
                    ]
                },
                minzoom: 12,
                maxzoom: 24,
            });
        map.getLayer('mesh') ||
            map.addLayer({
                id: 'mesh',
                type: 'line',
                source: 'mesh',
                paint: {
                    'line-color': 'rgba(0,0,0,0.3)',
                    'line-width': [
                        'interpolate',
                        ['exponential', 1],
                        ['zoom'],
                        12, 0.1,
                        24, 3
                    ],
                },
                minzoom: 12,
                maxzoom: 24,
            });
    }, [map]);

    useEffect(() => {
        AddSourcesAndLayers();
    }, [AddSourcesAndLayers]);

    useEffect(() => {
        const mouseEnter = () => { map.getCanvas().style.cursor = 'pointer'; };
        const mouseLeave = () => { map.getCanvas().style.cursor = ''; };
        const pathsClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
            if (e === undefined || e.features === undefined) {
                return;
            }
            var activityId = e.features[0].properties?.activityId;

            if (map) {
                new mapboxgl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(`<a href="https://www.strava.com/activities/${activityId}">${activityId}</a>`)
                    .addTo(map);
            }
        }

        map.on('mouseenter', 'paths', mouseEnter);
        map.on('mouseleave', 'paths', mouseLeave);
        map.on('click', 'paths', pathsClick);

        return () => {
            map.off('mouseenter', 'paths', mouseEnter);
            map.off('mouseleave', 'paths', mouseLeave);
            map.off('click', 'paths', pathsClick);
        }
    }, [map]);

    const loadData = useCallback(() => {
        const { pathsFeatures, lonlat } = pathsAndPoints;

        map.getSource('paths') && (map.getSource('paths') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: pathsFeatures,
        });
        map.getSource('points') && (map.getSource('points') as mapboxgl.GeoJSONSource).setData({
            type: 'Feature',
            geometry: {
                type: 'MultiPoint',
                coordinates: lonlat,
            },
            properties: {},
        });
        map.getSource('bins') && (map.getSource('bins') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: bins.bins.map(bin => {
                return GetGeoJsonBinWithCoordinatesAndColor(
                    CreateGeoJSONBoxForBinExtent(bin.extent),
                    commonColorInterpolation((bin.runs.length - bins.binExtent[0]) / bins.binRange),
                    bin.runs);
            })
        });
        map.getSource('mesh') && (map.getSource('mesh') as mapboxgl.GeoJSONSource).setData({
            type: 'Feature',
            geometry: {
                coordinates: bins.boundaries,
                type: 'MultiLineString',
            },
            properties: {},
        });
    }, [map, bins, pathsAndPoints]);

    useEffect(() => {
        const { bounds } = resort;
        const sw = new mapboxgl.LngLat(bounds.west, bounds.south)
        const ne = new mapboxgl.LngLat(bounds.east, bounds.north)

        map.setCenter([resort.geo_lng, resort.geo_lat]);
        map.fitBounds(new mapboxgl.LngLatBounds(sw, ne));
    }, [map, resort])

    useEffect(() => {
        map.setStyle(GetMapboxStyleUrl(satelliteView ? MapboxStyle.SATELLITE : MapboxStyle.SKIING));
        map.on('idle', async () => {
            AddSourcesAndLayers();
            loadData();
        });
    }, [map, satelliteView, AddSourcesAndLayers, loadData]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    console.log('render')
    return (
        <>
            <div style={{position: 'fixed', right: 0, top: 0, margin: '10px 10px 0px 0px' }}>
                <div>
                    <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
                        <LayerToggle
                            onClick={() => {
                                setSatelliteView(!satelliteView);
                            }}
                            icon={faMap}
                            defaultDisabled={true}
                        />
                        <LayerToggle
                            icon={faLocationArrow}
                            id="paths"
                            map={map}
                        />
                        <LayerToggle
                            icon={faCircle}
                            id="points"
                            map={map}
                        />
                        <LayerToggle
                            icon={faFire}
                            id="heatmap"
                            map={map}
                        />
                        <LayerToggle
                            icon={faSquare}
                            id="bins"
                            map={map}
                        />
                        <LayerToggle
                            icon={faTh}
                            id="mesh"
                            map={map}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}