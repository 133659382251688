import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef } from 'react';
import { GetMapboxStyleUrl, MapboxStyle } from 'src/lib/Utils/Maps';
import { DataViewPropsBase } from './types';

const Tiles: React.FC<DataViewPropsBase> = ({ athleteId }) => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const map = useRef<mapboxgl.Map>();

    useEffect(() => {
        if (mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: GetMapboxStyleUrl(MapboxStyle.DARK),
                center: [0, 0],
                zoom: 1,
                minZoom: 1,
                maxZoom: 18,
            });
            map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
            map.current.addControl(new mapboxgl.GeolocateControl({
                positionOptions: { enableHighAccuracy: true },
                trackUserLocation: true,
                showUserLocation: true,
            }), 'bottom-right');
            map.current.on('load', () => {
                map.current?.addSource('tile', {
                    type: "raster",
                    tiles: [
                        `https://s3.amazonaws.com/jrgrover.com-strava/data/${athleteId}/tiles/{z}/{x}/{y}.png`
                    ],
                    tileSize: 512,
                });
                map.current?.addLayer({
                    id: "tiles",
                    type: "raster",
                    source: 'tile',
                    paint: {
                        "raster-opacity": .85,
                    },
                    minzoom: 0,
                    maxzoom: 19,
                });
            });
            map.current.on('error', e => {
                // Hide those annoying non-error errors
                if (e && e.error.message !== 'Error: Not Found')
                    console.error(e);
            });
        }
        return () => {
            if (map.current) {
                map.current.remove();
            }
        }
    }, [athleteId]);

    return (
        <>
            <div
                style={{ width: '100%', height: '100%' }}
                ref={mapContainer} 
            />
        </>
    );
}

export default Tiles;
