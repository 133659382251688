import { useState, useRef, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import { SONGS } from ".";
import { RouteComponentProps } from "react-router-dom";

const SONG_NAME_TO_COLOR: { [key: string]: string } = Object.keys(SONGS).reduce(
	(a: { [key: string]: string }, c: string) => {
		const { song, color} = SONGS[c];
		a[song] = color;
		return a;
	}, {});
const SONG_NAME_TO_ICON: { [key: string]: string } = Object.keys(SONGS).reduce(
	(a: { [key: string]: string }, c: string) => {
		const { song } = SONGS[c];
		a[song] = c;
		return a;
	}, {});
const TWO_PI = Math.PI * 2;


interface Props extends RouteComponentProps {
	songName: string;
}

const ROTATION_RATE = 0.05;

const MusicPlayer: React.FC<Props> = ({
	match: { params }
}) => {
	const { songName } = params as Props;
	const [audioPlayable, setAudioPlayable] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const audioContextRef = useRef(new AudioContext());
	const analyserRef = useRef<AnalyserNode>(audioContextRef.current.createAnalyser());
	const audioRef = useRef(new Audio());
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const animation = useRef<number>(0);
	const dataArrayRef = useRef<Uint8Array>();
	const averageDataArrayRef = useRef<Uint8Array>();
	const rotationRef = useRef<number>(0);

	const drawBigIcon = (icon: string) => {
		const { current: canvas } = canvasRef;
		if (!canvas) { return }
		const ctx = canvas.getContext('2d');
		if (!ctx) return;
		ctx.save();
		ctx.font = `${document.body.offsetHeight - 400}px Arial`
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillText(icon, canvas.width / 2, canvas.height / 2);
		ctx.restore();
	}

	useEffect(() => {
		audioRef.current.src = `/music/${songName}.mp3`;
		audioRef.current.crossOrigin = 'anonymous';
		audioRef.current.load();
		audioRef.current.loop = true;
		const source = audioContextRef.current.createMediaElementSource(audioRef.current);
		// audioContextRef.current.resume();
		source.connect(audioContextRef.current.destination);
		source.connect(analyserRef.current);
		toast.success('Tap for hype');
		analyserRef.current.fftSize = 64;
		analyserRef.current.smoothingTimeConstant = 1;
		const bufferLength = analyserRef.current.frequencyBinCount;
		dataArrayRef.current = new Uint8Array(bufferLength);
		averageDataArrayRef.current = new Uint8Array(bufferLength);
		analyserRef.current.connect(audioContextRef.current.destination);
		audioContextRef.current.resume().then(() => {
			setAudioPlayable(true)
		});

		const icon = SONG_NAME_TO_ICON[songName];
		const resizeCanvas = () => {
			const { current: canvas } = canvasRef;
			if (!canvas) { return }
			canvas.width = window.innerWidth;
			canvas.height = window.innerHeight;
			drawBigIcon(icon);
		}

		drawBigIcon(icon);
		window.addEventListener('resize', resizeCanvas);
		
		const audio = audioRef.current;
		const animationCurrent = animation.current;
		return (() => {
			window.removeEventListener('resize', resizeCanvas);
			animationCurrent && window.cancelAnimationFrame(animationCurrent);
			audio?.pause();
		})
	}, [songName]);

	const animate = useCallback(() => {
		animation.current = requestAnimationFrame(animate);
		const { current: analyser } = analyserRef;
		const { current: dataArray } = dataArrayRef;
		const { current: averageArray } = averageDataArrayRef;
		const { current: rotation } = rotationRef;
		if (!analyser || !dataArray || !averageArray) {
			console.log('returning early from animation', analyser, dataArray);
			return;
		}
		const icon = SONG_NAME_TO_ICON[songName];
		const { current: canvas } = canvasRef;
		if (!canvas) { return }
		const ctx = canvas.getContext('2d');
		if (!ctx) return;

		analyser.getByteTimeDomainData(dataArray);
		const bufferLength = dataArray.length;
		const raidalIncrement = TWO_PI / bufferLength;
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.font = `50px Arial`;
		ctx.save();
		ctx.translate(canvas.width / 2, canvas.height / 2);
		ctx.rotate(rotation);
		for (var i = 0; i < bufferLength; i++) {
			const currentValue = dataArray[i];
			const updatedAverageValue = (averageArray[i] * 2 + currentValue) / 3;
			averageArray[i] = updatedAverageValue;
			ctx.rotate(-i * raidalIncrement);
			ctx.save();
			// const v = dataArray[i] / 128.0;
			for (var j = 0; j < Math.ceil(updatedAverageValue / 16); j++) {
				const currentRadialDistance = j * 3.5;
				ctx.translate(0, currentRadialDistance);
				ctx.font = `${j*3}px Arial`
				ctx.fillText(icon, 0, 0);
			}
			// const radialDistance = v * 200;
			ctx.restore();
		}
		ctx.restore();
		rotationRef.current += ROTATION_RATE;
		drawBigIcon(icon);
	}, [songName])

	const toggleAudio = () => {
		if (audioContextRef.current.state !== "running") {
			audioContextRef.current.resume().then(() => {
				setAudioPlayable(true);
				// toast.success("loaded audio");
			});
		} else {
			if (audioRef.current.paused) {
				// toast.success(audioContextRef.current.state + " is current state");
				audioRef.current.play();
				console.log(audioRef.current)
				console.log(audioContextRef.current);
				console.log(analyserRef.current);
				animate();
			} else {
				audioRef.current.pause();
				window.cancelAnimationFrame(animation.current);
			}
		}
	}

	return (
		<div
			style={{
				height: '100%',
				width: '100%',
				backgroundColor: audioPlayable ? SONG_NAME_TO_COLOR[songName] : "#CCC",
				overflow: 'hidden',
			}}
			ref={containerRef}
			onClick={toggleAudio}
		>
			<canvas
				style={{ position: 'fixed', top: 0, left: 0 }}
				ref={canvasRef}
				width={window.innerWidth}
				height={window.innerHeight}
			/>
		</div>
	);
}

export default MusicPlayer;