import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PeerHost from './Host';
import PeerClient from './Client';
import SVGCanvas from '../../projects/Drawgression/SVGCanvas';

const PeerJSTest: React.FC = () => {
    const [hostOrClient, setHostOrClient] = useState("");

    return hostOrClient === ''
        ? (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                }}
            >
                <Button
                    onClick={() => setHostOrClient('Host')}
                >
                    Host
                </Button>
                <Button
                    onClick={() => setHostOrClient('Client')}
                >
                    Client
                </Button>
                <SVGCanvas
                    onSend={() => {}}
                />
            </div>
        )
        : hostOrClient === 'Host'
            ? <PeerHost/>
            : <PeerClient/>
}

export default PeerJSTest;