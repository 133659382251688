import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as d3 from 'd3';
import { d3Category10 } from 'src/lib/Utils/Color';
import FullScreenLoading from 'src/components/loading';
import { Col, Container, Row } from 'react-bootstrap';
import SimplyGraph from 'src/lib/d3/SimplyGraph';

const TEMP_URL = "https://jrgrover.com/api/temp"

interface SessionData {
	id: string;
	createdAt: string;
	name: string;
}

interface Reading {
	probe: string;
	value: number;
}

interface DataPoints {
	sessionId: string;
	recordedAt: string;
	readings: Reading[];
}

interface Probe {
	name: string;
	values: [number, Date][];
}

const formatCToF = (t: number) => {
	return (Math.round(((t * 1.8) + 32) * 10) / 10) + " F"
}

const sortEventsIntoProbes = (dataPoints: DataPoints[]) => {
  dataPoints.sort(function(a, b){
    return (a.recordedAt < b.recordedAt) ? -1 : (a.recordedAt > b.recordedAt) ? 1 : 0;
  })
  const parsedData: Probe[] = []
  for (var i = 0; i < dataPoints.length; i++)
  {
    const dataPoint = dataPoints[i];
    const dataPointTime = new Date(dataPoint.recordedAt)
    const readings = dataPoint.readings;

    for (var j = 0; j < readings.length; j++)
    {
      const reading = readings[j];
      const probe = reading.probe;
      const value = reading.value;

      var probeIndex = parsedData.map(function(d){ return d.name}).indexOf(probe)
      if (probeIndex === -1)
      {
        probeIndex = parsedData.length
        const probeObject: Probe = {
          "name": probe,
          "values": []
        }
        parsedData.push(probeObject)
      }

      parsedData[probeIndex].values.push([value, dataPointTime])
    }
  }
  return parsedData
}

const GetSessionAddress = (session: string) => `${TEMP_URL}/session/${session}`;
const GetSessionEventAddress = (session: string) => `${GetSessionAddress(session)}/event`;
const GetSessionEventAddressWithAfter = (session: string, after: Date) => `${GetSessionEventAddress(session)}?after=${after.toISOString()}`;

interface Props extends RouteComponentProps {
	session?: string;
}

const TempReader: React.FC<Props> = ({ match : { params } }) => {
	const { session } = params as Props;
	const [cookName, setCookName] = useState('Smoke Meat Everyday');
	const [probes, setProbes] = useState<Probe[]>([]);
	const [loading, setLoading] = useState(true);
	const [loadingPercent, setLoadingPercent] = useState(10);
	const lastDataTimeRef = useRef<Date>(new Date());
	const colorsRef = useRef<d3.ScaleOrdinal<string,string,never>>(d3.scaleOrdinal(d3Category10));

	const getLatestData = useCallback(async () => {
		if (!session) return;

		const latestData: DataPoints[] = await (await fetch(GetSessionEventAddressWithAfter(session, lastDataTimeRef.current))).json();
		if (latestData.length === 0) return;
		const sortedLatestData = sortEventsIntoProbes(latestData);
		setProbes(existingProbes => {
			const updatedProbes = [...existingProbes];
			for (var i = 0; i < sortedLatestData.length; i++) {
				const latestProbe = sortedLatestData[i];
				let existingProbeIndex = existingProbes.map(probe => probe.name).indexOf(latestProbe.name);
				if (existingProbeIndex === -1) {
					existingProbeIndex = updatedProbes.length;
					updatedProbes.push({
						name: latestProbe.name,
						values: [],
					});
				}
				const updatedValues = existingProbes[existingProbeIndex].values.concat(latestProbe.values);
				updatedValues.sort(function(a, b){
					return (a[1] < b[1]) ? -1 : (a[1] > b[1]) ? 1 : 0;
				});
				// console.log("current data:", existingProbes[existingProbeIndex].values.length, "updated:", updatedValues.length);
				existingProbes[existingProbeIndex].values = updatedValues;
			}
			return updatedProbes;
		});
	}, [session]);

	const initialize = useCallback(async () => {
		if (!session) return;

		setLoadingPercent(20);
		const eventData = await (await fetch(GetSessionEventAddress(session))).json();
		const probes = sortEventsIntoProbes(eventData);
		colorsRef.current.domain(probes.map((p: Probe) => p.name ))
		console.log(probes);
		setProbes(probes);
		setLoadingPercent(50);
		const sessionData: SessionData = await (await fetch(`${TEMP_URL}/session/${session}`)).json();
		setCookName(sessionData.name);
		document.title = sessionData.name + ' - temp.watch';
		setLoading(false);
	  	// setup loop to ask for new data on repeat
	  	var intervalId = setInterval(getLatestData, 12000)
		return () => {
			clearInterval(intervalId);
		}
	}, [session, getLatestData]);

	useEffect(() => {
		if (!session) { return; }
		initialize();
	}, [session, initialize]);

	var newXDomainMaxDate = d3.max(probes, probe => d3.max(probe.values, datum => datum[1])) || new Date();
	lastDataTimeRef.current = newXDomainMaxDate;
	var xDomainMax = new Date(newXDomainMaxDate.getTime() + (.1 * 60 * 60 * 1000));
	var xDomainMinDate = d3.min(probes, probe => d3.min(probe.values, datum => datum[1])) || new Date();
	var xDomainMin = new Date(xDomainMinDate.getTime() - (.1 * 60 * 60 * 1000));
	const xDomain = [xDomainMin,xDomainMax];

	return !session
		? (<b>No session!</b>)
		: (
		<>
			<FullScreenLoading
                isOpen={loading}
                message="Loading session data"
                percentProgress={loadingPercent}
            />
			<nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
				<a className="navbar-brand" href="https://temp.watch">Temp.watch</a>
				<div id="loadingIndicator" className="progress2 mr-auto"><div></div></div>
			</nav>
			<Container style={{ paddingTop: '5rem' }}>
				<Row style={{ textAlign: 'center' }}>
					<Col sm={12}>
						<h1 className="display-3">
							{cookName}
						</h1>
					</Col>
				</Row>
				{
					probes.map(probe => {
						return (
							<Row
								key={probe.name}
							>
								<Col sm={3}>
									<h1>{probe.name}</h1>
									<h2>{formatCToF(probe.values[probe.values.length - 1][0])}</h2>
								</Col>
								<Col sm={9}>
									<SimplyGraph
										graphLines={[
											{
												dataPoints: probe.values,
												name: probe.name,
											}
										]}
										xAccessor='1'
										yAccessor='0'
										forceXDomain={xDomain}
										forceYDomain={probe.name === 'Temp' ? [0,200]: [0,100]}
										xAxisFormat={d3.timeFormat("%I:%M")}
										yAxisFormat={formatCToF}
										margins={{
											top: 25,
											right: 45,
											bottom: 45,
											left: 40,
										}}
										colors={colorsRef.current}
									/>
								</Col>
							</Row>
						);
					})
				}
			</Container>
		</>
		);
}

export default TempReader;

