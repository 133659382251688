import React, { CSSProperties, useState } from 'react'
import reactCSS from 'reactcss'
import { RGBColor, SketchPicker } from 'react-color'

const styles = reactCSS({
    default: {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
      },
      swatch: {
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
});

interface TableColorPickerProps {
    onUpdate: (color: RGBColor) => void;
    defaultColor: [number, number, number];
}

export const TableColorPicker = ({ onUpdate, defaultColor }: TableColorPickerProps) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState({
        r: defaultColor[0],
        g: defaultColor[1],
        b: defaultColor[2],
        a: 1,
    });

    return (
        <div>
            <div style={ styles.swatch } onClick={() => {
                setDisplayColorPicker(!displayColorPicker);
            }}>
                <div
                    style={{
                        ...styles.color,
                        background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
                    }} />
            </div>
            {
                displayColorPicker
                    ? (
                        <div style={ (styles.popover as CSSProperties) }>
                            <div
                                style={ (styles.cover as CSSProperties) }
                                onClick={() => { setDisplayColorPicker(false) }}
                            />
                            <SketchPicker
                                color={ color }
                                onChange={(color) => {
                                    setColor({ ...color.rgb, a: color.rgb.a || 1 });
                                    onUpdate(color.rgb);
                                }}
                            />
                        </div>
                    )
                    : null
            }
      </div>
    );
}