import * as React from 'react';
import Graph, { Data, LatitudeLongitude } from './graph';
import './main.css';
import { apiPath, nominatimApiPath } from './constants';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';

const getLatitudeLongitudeFromSearch = async (search: string) => {
    const url = nominatimApiPath + encodeURIComponent(search);
    return await (await fetch(url)).json();
}

const DewPoint: React.FC = () => {
    const parentContainer = useRef(document.body);
    const [data, setData] = useState<Data[]>();
    const [title, setTitle] = useState('');
    const [latitudeLongitude, setLatitudeLongitude] = useState<LatitudeLongitude>({ latitude: 0, longitude: 0 });
    const [currentTime, setCurrentTime] = useState<Date>(new Date());
    const [zipVisible, setZipVisible] = useState(true);
    const [inputLocation, setInputLocation] = useState('');

    const fetchData = useCallback(async () => {
        var t = toast.loading("Querying location", { position: 'bottom-center' });
        const locations = await getLatitudeLongitudeFromSearch(inputLocation);

        if (locations.length === 0) {
            toast.error("Sorry, couldn't find that location :(", { id: t });
            return;
        }

        const firstLocation = locations[0];
        const latitude = firstLocation.lat;
        const longitude = firstLocation.lon;
        const latlon = {
            latitude: latitude,
            longitude: longitude
        };
        const locationName = firstLocation.display_name
        toast.loading(`Loading dew point for ${locationName}`, { id: t });
        const url = apiPath + `?latitude=${latitude}&longitude=${longitude}`;
        try {
            let dataResponse = await (await fetch(url)).json();
            toast.success("Weather loaded!", { id: t });
            const time = new Date();

            const temperature = dataResponse.map((a: any) => [
                new Date(a.time * 1000),
                parseFloat(a.temperature)
            ]);
            const dewPoint = dataResponse.map((a: any) => [
                new Date(a.time * 1000),
                parseFloat(a.dewPoint)
            ]);

            const data = [
                {
                    name: 'Temperature',
                    values: temperature,
                },
                {
                    name: 'Dew Point',
                    values: dewPoint,
                },
            ];

            setData(data);
            setLatitudeLongitude(latlon);
            setCurrentTime(time);
            setTitle(locationName);
            setInputLocation('');
        } catch (error) {
            toast.error('Failed to load the dew point data :(', { id: t });
        }
    }, [inputLocation]);

    return (
        <div className="parentDiv">
            <div id="chart">
                {
                    data && currentTime && (
                        <Graph
                            parentContainer={parentContainer.current}
                            title={title}
                            latitudeLongitude={latitudeLongitude}
                            currentTime={currentTime}
                            data={data || []}
                        />
                    )
                }
            </div>
            <div className="indexMenu">
                <div
                    className="link content dewpoint"
                    onClick={() => { setZipVisible(!zipVisible); }}
                >
                    10 Day Dew Point &amp; Temp
                </div>
                {
                    zipVisible && (
                        <div className="link zip">
                            <input
                                autoFocus={true}
                                type="text"
                                value={inputLocation}
                                placeholder="Zip or City, State or City, Country"
                                className="zipInput"
                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (event.key === 'Enter') {
                                        setZipVisible(!zipVisible);
                                        setTitle(inputLocation);
                                        fetchData();
                                    }
                                }}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setInputLocation(event.currentTarget.value);
                                }}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default DewPoint;