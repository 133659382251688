import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Position } from './Canvas';

const GetMouseEventRelativePositionForSVGElementRef = (event: MouseEvent|TouchEvent, element: SVGPathElement|null): Position | undefined => {
    let eventX, eventY;
    if (event instanceof MouseEvent) {
        eventX = event.pageX;
        eventY = event.pageY;
    } else {
        eventX = event.touches[0].pageX;
        eventY = event.touches[0].pageY;
    }
    
    return !element
        ? undefined
        : {
            x: eventX - element.getBoundingClientRect().x,
            y: eventY - element.getBoundingClientRect().y,
        };
}

interface Props {
    onPick: (size: number) => void;
    color: string;
}

const pickerHeight = 80;

const clampPosition = (position: number) => {
    return Math.max(15, Math.min(pickerHeight - 5, position))
}

const BrushSizePicker: React.FC<Props> = ({ onPick, color }) => {
    const pickerRef = useRef<SVGPathElement>(null);
    const [picking, setPicking] = useState(false);
    const [position, setPosition] = useState<number>(75);

    const startPicking = (event: MouseEvent|TouchEvent) => {
        const position = GetMouseEventRelativePositionForSVGElementRef(event, pickerRef.current);
        if (position) {
            // console.log('startPicking', position.y);
            const clampedPosition = clampPosition(position.y);
            setPosition(clampedPosition);
            setPicking(true);
            const pickedSize = translatePositionToSize(clampedPosition)
            onPick(pickedSize);
        }
    }

    const pick = (event: MouseEvent|TouchEvent) => {
        if (picking) {
            const currentPosition = GetMouseEventRelativePositionForSVGElementRef(event, pickerRef.current);
            if (position && currentPosition) {
                const clampedPosition = clampPosition(currentPosition.y);
                setPosition(clampedPosition);
                const pickedSize = translatePositionToSize(clampedPosition)
                onPick(pickedSize);
            }
        }
    }

    const stopPicking = () => {
        setPicking(false);
        if (picking) {
            const pickedSize = translatePositionToSize(position || 0)
            onPick(pickedSize);
        }
    }

    const translatePositionToSize = (position: number) => {
        const normalizedPosition = (pickerHeight - position) - 5;
        return ((normalizedPosition / (pickerHeight - 15 - 5)) * 10) + 5;
    }

    const startPickingCallback = useCallback(startPicking, [onPick]);
    const pickCallback = useCallback(pick, [picking, position, onPick]);
    const stopPickingCallback = useCallback(stopPicking, [picking, position, onPick]);

    useEffect(() => {
        const { current: picker } = pickerRef;
        if (!picker) {
            return;
        }
        picker.addEventListener('mousedown', startPickingCallback);
        picker.addEventListener('mousemove', pickCallback);
        picker.addEventListener('mouseup', stopPickingCallback);
        picker.addEventListener('mouseleave', stopPickingCallback);
        picker.addEventListener('touchstart', startPickingCallback);
        picker.addEventListener('touchmove', pickCallback);
        picker.addEventListener('touchend', stopPickingCallback);
        return () => {
            picker.removeEventListener('mousedown', startPickingCallback);
            picker.removeEventListener('mousemove',pickCallback );
            picker.removeEventListener('mouseup', stopPickingCallback);
            picker.removeEventListener('mouseleave', stopPickingCallback);
            picker.removeEventListener('touchstart', startPickingCallback);
            picker.removeEventListener('touchmove', pickCallback);
            picker.removeEventListener('touchend', stopPickingCallback);
        };
    })

    return (
        <div
            style={{
                marginTop: '5px',
            }}
        >
            <svg
                width="30px"
                height={`${pickerHeight}px`}
                viewBox={`-2 0 34 ${pickerHeight}`}
            >
                
                <circle
                    r={`${translatePositionToSize(position)}px`}
                    cx="15px"
                    cy={`${position}px`}
                    style={{
                        fill: color,
                        stroke: 'none',
                    }}
                />
                <path
                    fill="rgba(0,0,0,0.1)"
                    ref={pickerRef}
                    // strokeWidth="1px"
                    // stroke="#333"
                    d="M 0,15 C -1,8 6,0 15,0 25,0 30,7 30,15 L 20,75 c 0,3 -2,5 -5,5 -3,0 -4,-2 -5,-5 z"
                />
            </svg>
        </div>
    );
}

export default BrushSizePicker;