import { CSSProperties } from "react";
import { Table } from 'react-bootstrap';
import { formatter } from "../../lib/d3/common";

interface BalanceTableProps {
    accounts: Set<string>;
    balances: Map<Date,Map<string,number>>;
}

const headerStyle: CSSProperties = {
    position: 'sticky',
    top: '0px',
    backgroundColor: '#FFF'
}

const BalanceTable: React.FC<BalanceTableProps> = ({ accounts, balances }) =>
    <Table
        striped
        bordered
        hover
        size="sm"
        style={{ fontFamily: 'monospace', textAlign: 'right', fontSize: '10px' }}
    >
        <thead>
            <tr>
                <th style={headerStyle}>Date</th>
                {Array.from(accounts, account =>
                    <th key={account} style={headerStyle}>{account}</th>
                )}
            </tr>
        </thead>
        <tbody>
            {Array.from(balances.entries(), ([date, values], k) =>
                <BalanceRow
                    key={k}
                    date={date}
                    values={values}
                />
            )}
        </tbody>
    </Table>

export default BalanceTable;

interface BalanceRowProps {
    date: Date;
    values: Map<string,number>;
}

const BalanceRow: React.FC<BalanceRowProps> = ({ date, values }) =>
    <tr>
        <td>{date.toISOString().substring(0,10)}</td>
        {Array.from(values.keys(), account =>
            <BalanceRowData
                key={account}
                value={values.get(account)}
            />
        )}
    </tr>

interface BalanceRowDataProps {
    value?: number;
}

const BalanceRowData: React.FC<BalanceRowDataProps> = ({ value }) =>
    <td>
        {formatter.format(value || 0)}
    </td>