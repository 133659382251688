import React, { useState } from 'react';
import { Range } from 'react-range';

interface OpacitySliderProps {
    onChange: (value: number) => void;
    defaultValue: number;
}

export const OpacitySlider: React.FC<OpacitySliderProps> = ({ onChange, defaultValue }) => {
    const [value, setValue] = useState(defaultValue);
    return (
        <Range
            step={0.01}
            min={0}
            max={1}
            values={[value]}
            onChange={(values: number[]) => {
                setValue(values[0]);
                onChange(values[0]);
            }}
            renderTrack={({ props, children }) => (
                <div
                    {...props}
                    style={{
                    ...props.style,
                    height: '6px',
                    width: '100%',
                    marginTop: '3px',
                    backgroundColor: '#ccc',
                    borderRadius: '4px',
                    }}
                >
                    {children}
                </div>
                )}
            renderThumb={({ props }) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        height: '12px',
                        width: '12px',
                        borderRadius: '4px',
                        backgroundColor: '#999',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                />
            )}
        />
    );
}