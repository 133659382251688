import React, { ReactElement, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { GameState } from './Common';
import { StaticSVGCanvas } from './SVGCanvas';

interface Props {
    gameState: GameState;
}

const ChainViewer: React.FC<Props> = ({ gameState }) => {
    const [selectedId, setSelectedId] = useState(gameState.order[0]);
    
    const { descriptions, pictures } = gameState.data[selectedId];
    const progression: ReactElement[] = [];
    for (var i = 0; i < descriptions.length + pictures.length; i++) {
        if (i % 2 === 0) {
            const index = i / 2;
            if (index < descriptions.length) {
                progression.push(
                    <h4
                        key={`${selectedId}-${i}`}
                    >
                        {descriptions[index]}
                    </h4>
                );
            }
        } else {
            const index = Math.floor(i / 2);
            if (index < pictures.length) {
                progression.push(
                    <StaticSVGCanvas
                        key={`${selectedId}-${i}`}
                        draws={pictures[index]}
                    />
                );
            }
        }
    }
    return (
        <>
            {
                gameState.order.map(id => {
                    return (
                        <Badge
                            key={id}
                            variant={id === selectedId ? 'dark' : 'light'}
                            onClick={() => {
                                setSelectedId(id);
                            }}
                            style={{
                                margin: '5px 5px',
                                cursor: 'pointer',
                            }}
                        >
                            {gameState.players[id].name}
                        </Badge>
                    );
                })
            }
            <div style={{ overflowY: 'scroll' }}>
                {
                    progression
                }
            </div>
        </>
    );
}

export default ChainViewer;