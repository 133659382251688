import { useCallback, useEffect, useRef, useState } from 'react';
import { Deck } from '@deck.gl/core';
import { MapboxLayer } from '@deck.gl/mapbox';
import { load } from '@loaders.gl/core';
import { Position } from '@deck.gl/core/utils/positions';
import { v4 as uuidv4 } from 'uuid';
import { AWS_ELEVATION_NORMAL_URL, GetMapboxStyleUrl, GetMapboxTileUrl, getURLFromTemplate, Line, MapboxStyle, MAPBOX_ELEVATION_DECODER, SaveLocalGeoJSON, SaveLocalGPX, SaveLocalKML, UPLOAD_TYPE } from 'src/lib/Utils/Maps';
import { faCheckSquare, faFileDownload, faLayerGroup, faMinusCircle, faMountain, faPlusSquare, faQuestionCircle, faRedo, faUpload } from '@fortawesome/free-solid-svg-icons';
import { LayerToggle } from '../Running/MapboxUtils/LayerToggle';
import { CollapsingSideMenu } from 'src/components/CollapsingSideMenu';
import { Button, Col, Container, Dropdown, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import { TableColorPicker } from './TableColorPicker';
import { OpacitySlider } from './OpacitySlider';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisibleToggleSwitch } from 'src/lib/ToggleSwitch';
import { AspectAltitudePicker } from './ApectAltitudePicker';
import DeckGlPreview from './DeckGlPreview';
import { LayerTableRow } from './LayerTableRow';
import SlopeAngleMaskPreview from './SlopeAngleMaskPreview';
import FullScreenLoading from 'src/components/loading';
import { getFileExtension, getFileName } from 'src/lib/Utils/Strings';
import { FeatureCollection, GeoJSON, LineString } from 'geojson';
import { convertGPXTrackToGeoJSONFeature, parseGPXData, readFileFromFileObject } from 'src/lib/Utils/GPX';
import MapboxTerrainCache from 'src/lib/Utils/MapboxTerrainCache';
import TerrainPathLayer from './TerrainPathLayer';
import { TileLayer } from 'deck.gl';
import { TERRAIN_TILE_URL } from './SlopeAngleLayer/Tests/SlopeAngleThree';
import SlopeAngleTerrainLayer from './SlopeAngleTerrainLayer';
import { MAPBOX_ACCESS_TOKEN } from '../Running/Common';
import { Tile } from 'src/types/deck.gl';
import SlopeAngleTileLayer from './SlopeAngleTileLayer';
import { CardinalDirections, RGBColor } from '.';

export const defaultAspectColors: { [key in CardinalDirections]: RGBColor } = {
    north: { r: 31,  g: 120, b: 180 },
    east:  { r: 51,  g: 160, b: 44  },
    south: { r: 166, g: 206, b: 227 },
    west:  { r: 178, g: 223, b: 138 },
};

export const defaultSlopeCutoffRange: [number,number] = [30,60];

const flatGetPath = (d: number[][]) => {
    return d.map(datum => {
        return [datum[0], datum[1]] as Position;
    });
};

const RenderSlopeAngleTileLayer = (props: any) => {
    const {
        bbox: {west, south, east, north},
    } = props.tile;
    const [normal, image] = props.data;
    const slopeAngleTileLayer = new SlopeAngleTileLayer(props, {
        data: null,
        image,
        normal,
        bounds: [west, south, east, north],
    });
    return slopeAngleTileLayer as any;
}

interface SlopeAngleMapProps {
    map: mapboxgl.Map;
}

const SlopeAngleMap: React.FC<SlopeAngleMapProps> = ({ map }) => {
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const [lineLayers, setLineLayers] = useState<{ [id: string] : Line }>({});
    const [zoom, setZoom] = useState(map.getZoom());
    const [drawing, setDrawing] = useState(false);
    const [drawingLineData, setDrawingLineData] = useState([[0,0,0]]);

    const [satellite, setSatellite] = useState(false);
    const [terrain, setTerrain] = useState(false);
    const [slopeMaskEnabled, setSlopeMaskEnabled] = useState(true);
    const [slopeAspectEnabled, setSlopeAspectEnabled] = useState(true);
    const [slopeAngleEnabled, setSlopeAngleEnabled] = useState(true);
    const [slopeAspectPreview, setSlopeAspectPreview] = useState(false);
    const [slopeMaskPreview, setSlopeMaskPreview] = useState(false);
    const [slopeAspectOpacity, setSlopeAspectOpacity] = useState(0.5);
    const [slopeAngleOpacity, setSlopeAngleOpacity] = useState(0.5);
    const [slopeMaskOpacity, setSlopeMaskOpacity] = useState(1);
    const [slopeCutoffRange, setSlopeCutoffRange] = useState(defaultSlopeCutoffRange);
    const [aspectColors, setAspectColors] = useState(defaultAspectColors);

    const TerrainCache = useRef(new MapboxTerrainCache());
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const deck = useRef<Deck>();

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files && files.length > 0) {
            const file = files[0];
            const extension = getFileExtension(file.name);
            const name = getFileName(file.name);
            let data: GeoJSON;
            if (extension === UPLOAD_TYPE.GPX || extension === UPLOAD_TYPE.XML) {
                setLoadingMessage('Detected GPX, parsing...');
                const fileText = await readFileFromFileObject(file);
                const gpxData = parseGPXData(fileText);
                data = convertGPXTrackToGeoJSONFeature(gpxData);
            } else if (extension === UPLOAD_TYPE.GEOJSON) {
                setLoadingMessage('Detected GeoJSON, parsing...');
                const fileText = await readFileFromFileObject(file);
                data = JSON.parse(fileText);
            } else {
                setLoading(false);
                return;
            }

            if (data) {
                const { coordinates } = (data as FeatureCollection).features[0].geometry as LineString;
                const line: Line = {
                    name,
                    data: coordinates.map(p => [p[0], p[1]]),
                    color: [128, 128, 255],
                    opacity: 1,
                    visible: true,
                };
                setLoading(false);
                setLineLayers((oldLineLayers) => {
                    return {
                        ...oldLineLayers,
                        [uuidv4()]: line,
                    }
                });
            }
        } else {
            setLoading(false);
        }
    };

    const loadLayerCache = useCallback(async () => {
        for (const line in lineLayers) {
            const { data } = lineLayers[line];
            await TerrainCache.current.loadTilesForLatitudeLongitudePathAtZoom(data, zoom);
        }
    }, [lineLayers, zoom]);

    useEffect(() => {
        loadLayerCache();
    }, [loadLayerCache]);

    const AddSourcesAndLayers = useCallback(() => {
        if (!deck.current) {
            deck.current = new Deck({
                gl: (map as any).painter.context.gl,
                getCursor: () => 'inherit',
                layers: [],
            });
        }

        map.getLayer('terrain') ||
                map.addLayer(new MapboxLayer({
                    id: 'terrain',
                    deck: deck.current,
                    pickable: true,
                }));
        map.getLayer('terrain-2d') ||
            map.addLayer(new MapboxLayer({ id: 'terrain-2d', deck: deck.current }), satellite ? 'tunnel-street-case' : 'contour-line');
        map.getLayer('drawingLine') ||
            map.addLayer(new MapboxLayer({ id: 'drawingLine', deck: deck.current }));
    }, [map, satellite]);

    useEffect(() => {
        AddSourcesAndLayers();
    }, [AddSourcesAndLayers]);

    useEffect(() => {
        const mouseMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
            if (drawing && deck.current) {
                let coordinates = [e.lngLat.lng, e.lngLat.lat, 0];
                if (terrain) {
                    const { clientX: x, clientY: y } = e.originalEvent;
                    const pick = deck.current.pickObject({
                        x,
                        y,
                        unproject3D: terrain,
                        layerIds: ['terrain']
                    } as any);
                    coordinates = pick?.coordinate;
                }
                if (!coordinates) {
                    return;
                }

                const updatedDrawingLineData: number[][] = [];
                Array.prototype.push.apply(updatedDrawingLineData, drawingLineData);
                updatedDrawingLineData[updatedDrawingLineData.length - 1] = coordinates;
                setDrawingLineData(updatedDrawingLineData);
            }
        };

        const click = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
            if (drawing && deck.current) {
                let coordinates = [e.lngLat.lng, e.lngLat.lat];
                if (terrain) {
                    const { clientX: x, clientY: y } = e.originalEvent;
                    const pick = deck.current.pickObject({
                        x,
                        y,
                        unproject3D: terrain,
                    } as any);
                    coordinates = pick?.coordinate;
                }
                if (!coordinates) {
                    return;
                }
    
                const updatedDrawingLineData: number[][] = [];
                Array.prototype.push.apply(updatedDrawingLineData, drawingLineData);
                // Add "uncommited" point to be overwritten by hovering
                updatedDrawingLineData.push(coordinates);
                setDrawingLineData(updatedDrawingLineData);
            }
        }

        const handleZoomSet = () => {
            setZoom(Math.floor(map.getZoom()));
        }

        map.on('mousemove', mouseMove);
        map.on('click', click);
        map.on('idle', handleZoomSet);

        return(() => {
            map.off('mousemove', mouseMove);
            map.off('click', click);
            map.off('idle', handleZoomSet);
        })
    }, [
        map,
        terrain,
        drawing,
        drawingLineData,
    ]);

    useEffect(() => {
        const groundTextureTerrain = satellite
            ? `${GetMapboxTileUrl(MapboxStyle.SATELLITE)}/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_ACCESS_TOKEN}`
            : `${GetMapboxTileUrl(MapboxStyle.TOPOGRAPHIC)}/tiles/512/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`;
        const groundTexture2D = satellite
            ? `${GetMapboxTileUrl(MapboxStyle.SATELLITE)}/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_ACCESS_TOKEN}`
            : `${GetMapboxTileUrl(MapboxStyle.SKIING)}/tiles/512/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`;

        const layers: any[] = [
            new SlopeAngleTerrainLayer({
                id: 'terrain',
                pickable: true,
                wireframe: false,
                terrainOpacity: 1,
                slopeMaskOpacity,
                slopeAspectOpacity,
                slopeAngleOpacity,
                elevationDecoder: MAPBOX_ELEVATION_DECODER,
                aspectColors,
                slopeCutoffRange,
                slopeMaskEnabled,
                slopeAngleEnabled,
                elevationData: TERRAIN_TILE_URL('{z}', '{x}', '{y}'),
                texture: groundTextureTerrain,
                contourIncrement: 50,
                contourIncrementSmall: 10,
                slopeAspectEnabled,
                visible: terrain,
                maxZoom: 15,
                // onClick: (info) => { console.log('woah', info); }
            } as any),
            new TileLayer({
                id: 'terrain-2d',
                data: '',
                maxZoom: 15,
                renderSubLayers: RenderSlopeAngleTileLayer,
                getTileData: (tile: Tile) => {
                    const dataUrl = getURLFromTemplate(AWS_ELEVATION_NORMAL_URL, (tile as any).index);
                    const textureUrl = getURLFromTemplate(groundTexture2D, (tile as any).index);
                    return Promise.all([
                        dataUrl ? load(dataUrl, []).catch(_ => null) : Promise.resolve(null),
                        textureUrl ? load(textureUrl, []).catch(_ => null) : Promise.resolve(null)
                    ]);
                },
                updateTriggers: { getTileData: [groundTexture2D] },
                slopeMaskOpacity,
                slopeAspectOpacity,
                slopeAngleOpacity,
                aspectColors,
                slopeCutoffRange,
                slopeMaskEnabled,
                slopeAspectEnabled,
                slopeAngleEnabled,
                visible: !terrain,
                pickable: true,
            } as any),
            new TerrainPathLayer({
                id: 'drawingLine',
                widthScale: 5,
                widthMinPixels: 2,
                data: [drawingLineData],
                getWidth: () => 3,
                getColor: () => [255, 128, 128],
                getPath: terrain
                    ? ((d: number[][]) => {
                        return d.map(
                            datum => {
                                return [
                                    datum[0],
                                    datum[1],
                                    datum[2] + 50
                                ] as Position;
                            }
                        )
                    })
                    : flatGetPath,
                // rounded: true,
                visible: drawing,
            }),
        ];

        Array.prototype.push.apply(layers, Object.keys(lineLayers).map(lineKey => {
            const { data, color, opacity, visible } = lineLayers[lineKey];
            return new TerrainPathLayer({
                opacity,
                visible,
                id: lineKey,
                pickable: true,
                widthScale: 2,
                widthMinPixels: 2,
                data: [data],
                getWidth: () => 5,
                getColor: () => color,
                getPath: terrain
                    ? (d: any) => {
                        const terrainPath = d.map((c: number[]) => {
                            return [c[0], c[1], TerrainCache.current.getAltitudeForZoomLatitudeLongitude(zoom, c[1], c[0])];
                        });
                        return terrainPath;
                    }
                    : flatGetPath,
                // rounded: true,
            });
        }));

        deck.current?.setProps({ layers });
    }, [
        aspectColors,
        drawing,
        drawingLineData,
        lineLayers,
        satellite,
        slopeAngleEnabled,
        slopeAngleOpacity,
        slopeAspectEnabled,
        slopeAspectOpacity,
        slopeCutoffRange,
        slopeMaskEnabled,
        slopeMaskOpacity,
        terrain,
        zoom
    ]);

    const handleDrawingOrSave = useCallback(() => {
        if (drawing) {
            const finalLineData: [number,number][] = [];
            Array.prototype.push.apply(finalLineData, drawingLineData);
            finalLineData.splice(finalLineData.length - 1);
            setLineLayers((oldLineLayers) => {
                const newLineId = uuidv4();
                map.addLayer(new MapboxLayer({ id: newLineId, deck: deck.current }));
                const line: Line = {
                    data: finalLineData,
                    name: `Line ${Object.keys(oldLineLayers).length}`,
                    color: [128, 128, 255],
                    opacity: 1,
                    visible: true,
                };
                const updatedLineLayers: { [id: string]: Line } = {
                    ...oldLineLayers,
                    [newLineId]: line,
                };
                return updatedLineLayers;
            });
        }
        setDrawing(!drawing);
        setDrawingLineData([[0, 0, 0]]);
    }, [map, drawing, drawingLineData]);

    return (
        <>
            <div style={{position: 'fixed', right: 0, top: 0, margin: '10px 10px 0px 0px', zIndex: 1 }}>
                <div>
                    <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
                        <LayerToggle
                            title={`${menuIsOpen ? 'Close' : 'Open'} Layer Menu`}
                            icon={faLayerGroup}
                            onClick={() => {
                                setMenuIsOpen(!menuIsOpen);
                            }}
                        />
                        <LayerToggle
                            title={`${drawing ? 'Save current line drawing' : 'Draw a new line'}`}
                            icon={!drawing ? faPlusSquare : faCheckSquare}
                            onClick={handleDrawingOrSave}
                        />
                    </div>
                </div>
            </div>
            <CollapsingSideMenu
                isOpen={menuIsOpen}
                onClose={() => { setMenuIsOpen(false); }}
            >
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h4 style={{ color: '#CCC' }}>Layers</h4>
                            <Table
                                bordered={true}
                                hover={true}
                                variant="dark"
                                size="sm"
                            >
                                <thead>
                                    <tr>
                                        <th>Layer</th>
                                        <th>Opacity</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <VisibleToggleSwitch
                                                visible={terrain}
                                                onClick={() => {
                                                    setTerrain(!terrain);
                                                }}
                                                disabled={false}
                                            />
                                            3D Terrain
                                        </td>
                                        <td>
                                            <OverlayTrigger
                                                    overlay={
                                                        <Tooltip
                                                            id="3d-popover"
                                                        >
                                                            Makes the terrain three-dimensional<br/>
                                                            Use three fingers to tilt on mobile
                                                        </Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faQuestionCircle}/>
                                                </OverlayTrigger>
                                        </td>
                                    </tr>
                                    <LayerTableRow
                                        enabled={slopeMaskEnabled}
                                        name="Slope-Angle Mask"
                                        opacity={slopeMaskOpacity}
                                        disabled={false}
                                        onToggleVisible={() => { setSlopeMaskEnabled(!slopeMaskEnabled); }}
                                        onOpacityChange={(updatedOpacity) => { setSlopeMaskOpacity(updatedOpacity); }}
                                        help={<span>Masks (hatches) a range of slopes</span>}
                                        configuration={(
                                            <div>
                                                <Button
                                                    size="sm"
                                                    variant="outline-secondary"
                                                    onClick={() => { setSlopeMaskPreview(!slopeMaskPreview); }}
                                                >
                                                    Preview
                                                    <FontAwesomeIcon
                                                        style={{
                                                            marginLeft: '4px',
                                                            cursor: 'pointer',
                                                        }}
                                                        size="xs"
                                                        icon={faMountain}
                                                    />
                                                </Button>
                                                <Button
                                                    style={{ float: 'right' }}
                                                    size="sm"
                                                    variant="outline-secondary"
                                                    onClick={() => { setSlopeCutoffRange(defaultSlopeCutoffRange); }}
                                                >
                                                    Reset
                                                    <FontAwesomeIcon
                                                        style={{
                                                            marginLeft: '4px',
                                                            cursor: 'pointer',
                                                        }}
                                                        size="xs"
                                                        icon={faRedo}
                                                    />
                                                </Button>
                                                {
                                                    slopeMaskPreview
                                                        ? (
                                                            <div style={{ height: '400px', minWidth: '350px' }}>
                                                                <DeckGlPreview
                                                                    aspectColors={aspectColors}
                                                                    wireframe={false}
                                                                    slopeCutoffRange={slopeCutoffRange}
                                                                    slopeAspectOpacity={slopeAspectOpacity}
                                                                    slopeMaskOpacity={slopeMaskOpacity}
                                                                    terrainOpacity={1}
                                                                    slopeAngleOpacity={slopeAngleOpacity}
                                                                />
                                                            </div>
                                                        )
                                                        : (
                                                            <SlopeAngleMaskPreview
                                                                min={slopeCutoffRange[0]}
                                                                max={slopeCutoffRange[1]}
                                                                changeStart={(start) => { setSlopeCutoffRange([start, slopeCutoffRange[1]]); }}
                                                                changeEnd={(end) => { setSlopeCutoffRange([slopeCutoffRange[0], end]); }}
                                                            />
                                                        )
                                                }
                                                
                                            </div>
                                        )}
                                    />
                                    <LayerTableRow
                                        enabled={slopeAspectEnabled}
                                        name="Aspect Shading"
                                        opacity={slopeAspectOpacity}
                                        disabled={false}
                                        onToggleVisible={() => { setSlopeAspectEnabled(!slopeAspectEnabled); }}
                                        onOpacityChange={(updatedOpacity) => { setSlopeAspectOpacity(updatedOpacity); }}
                                        help={<span>Shades aspects (directions) of the terrain</span>}
                                        configuration={(
                                            <div>
                                                <Button
                                                    size="sm"
                                                    variant="outline-secondary"
                                                    onClick={() => { setSlopeAspectPreview(!slopeAspectPreview); }}
                                                >
                                                    Preview
                                                    <FontAwesomeIcon
                                                        style={{
                                                            marginLeft: '4px',
                                                            cursor: 'pointer',
                                                        }}
                                                        size="xs"
                                                        icon={faMountain}
                                                    />
                                                </Button>
                                                <Button
                                                    style={{ float: 'right' }}
                                                    size="sm"
                                                    variant="outline-secondary"
                                                    onClick={() => { setAspectColors(defaultAspectColors); }}
                                                >
                                                    Reset
                                                    <FontAwesomeIcon
                                                        style={{
                                                            marginLeft: '4px',
                                                            cursor: 'pointer',
                                                        }}
                                                        size="xs"
                                                        icon={faRedo}
                                                    />
                                                </Button>
                                                {
                                                    slopeAspectPreview
                                                        ? (
                                                            <div style={{ height: '400px', minWidth: '350px' }}>
                                                                <DeckGlPreview
                                                                    aspectColors={aspectColors}
                                                                    wireframe={false}
                                                                    slopeCutoffRange={slopeCutoffRange}
                                                                    slopeAspectOpacity={slopeAspectOpacity}
                                                                    slopeMaskOpacity={slopeMaskOpacity}
                                                                    terrainOpacity={1}
                                                                    slopeAngleOpacity={slopeAngleOpacity}
                                                                />
                                                            </div>
                                                        )
                                                        : (
                                                            <AspectAltitudePicker
                                                                aspectColors={aspectColors}
                                                                onUpdate={(aspect: string, color: RGBColor) => {
                                                                    setAspectColors({
                                                                        ...aspectColors,
                                                                        [aspect]: color,
                                                                    });
                                                                }}
                                                            />
                                                        )
                                                }
                                                
                                            </div>
                                        )}
                                    />
                                    <tr>
                                        <td>
                                            <VisibleToggleSwitch
                                                visible={slopeAngleEnabled}
                                                onClick={() => { setSlopeAngleEnabled(!slopeAngleEnabled); }}
                                                disabled={false}
                                            />
                                            Slope Angle Shading
                                        </td>
                                        <td>
                                            <input
                                                style={{ width: '100%' }}
                                                type="range"
                                                max={1}
                                                min={0}
                                                step={0.01}
                                                defaultValue={slopeAngleOpacity}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setSlopeAngleOpacity(parseFloat(event.currentTarget.value));
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip
                                                        id="slope-angle-popover"
                                                    >
                                                        Shades areas of slope angles:<br/>
                                                        0-5° <span style={{ color: 'rgb(255,255,191)' }}>■</span><br/>
                                                        5-10° <span style={{ color: 'rgb(255,255,0)' }}>■</span><br/>
                                                        10-15° <span style={{ color: 'rgb(255,192,0)' }}>■</span><br/>
                                                        15-20° <span style={{ color: 'rgb(255,162,0)' }}>■</span><br/>
                                                        20-30° <span style={{ color: 'rgb(255,128,0)' }}>■</span><br/>
                                                        30°+ <span style={{ color: 'rgb(162,75,30)' }}>■</span>
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle}/>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <VisibleToggleSwitch
                                                visible={satellite}
                                                onClick={() => {
                                                    var style = !satellite
                                                        ? MapboxStyle.SATELLITE
                                                        : MapboxStyle.SKIING;
                                                    map.setStyle(GetMapboxStyleUrl(style));
                                                    map.once('idle', () => {
                                                        setSatellite(!satellite);
                                                    });
                                                }}
                                                disabled={false}
                                            />
                                            Satellite
                                        </td>
                                        <td>
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip
                                                        id="satellite-popover"
                                                    >
                                                        The satellite base-layer
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle}/>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <h4 style={{ color: '#CCC' }}>
                                Paths
                                <Button
                                    style={{ marginLeft: '8px' }}
                                    type="button"
                                    variant="outline-success"
                                    size="sm"
                                    onClick={() => {
                                        if (hiddenFileInput.current) {
                                            hiddenFileInput.current.click();
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faUpload}
                                        style={{ marginRight: '4px' }}
                                    />
                                    Upload GPX / GeoJSON
                                </Button>
                                <input
                                    type="file"
                                    onChange={handleFileUpload}
                                    ref={hiddenFileInput}
                                    style={{ display: 'none' }}
                                />
                            </h4>
                            <Table
                                striped={true}
                                bordered={true}
                                hover={true}
                                variant="dark"
                                size="sm"
                            >
                                <tbody>
                                    {
                                        Object.keys(lineLayers).map(lineKey => {
                                            const line = lineLayers[lineKey];
                                            return (
                                                <tr
                                                    key={lineKey}
                                                >
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faMinusCircle}
                                                            style={{ cursor: 'pointer', color: '#F88', marginRight: '4px' }}
                                                            onClick={() => {
                                                                const updatedLineLayers: { [id: string]: Line } = {
                                                                    ...lineLayers
                                                                };
                                                                delete updatedLineLayers[lineKey];
                                                                map.removeLayer(lineKey);
                                                                setLineLayers(updatedLineLayers);
                                                            }}
                                                        />
                                                        <VisibleToggleSwitch
                                                            disabled={false}
                                                            visible={line.visible}
                                                            onClick={() => {
                                                                const updatedLineLayers: { [id: string]: Line } = {
                                                                    ...lineLayers,
                                                                    [lineKey]: {
                                                                        ...line,
                                                                        visible: !line.visible,
                                                                    },
                                                                };
                                                                setLineLayers(updatedLineLayers);
                                                            }}
                                                        />
                                                        {line.name}
                                                    </td>
                                                    <td>
                                                        <TableColorPicker
                                                            onUpdate={(color) => {
                                                                const updatedLineLayers: { [id: string]: Line } = {
                                                                    ...lineLayers,
                                                                    [lineKey]: {
                                                                        ...line,
                                                                        color: [color.r, color.g, color.b],
                                                                    },
                                                                }
                                                                setLineLayers(updatedLineLayers);
                                                            }}
                                                            defaultColor={line.color}
                                                        />
                                                    </td>
                                                    <td style={{ minWidth: '100px', paddingTop: '11px', paddingRight: '8px' }}>
                                                        <OpacitySlider
                                                            defaultValue={line.opacity}
                                                            onChange={(value: number) => {
                                                                const updatedLineLayers: { [id: string]: Line } = {
                                                                    ...lineLayers,
                                                                    [lineKey]: {
                                                                        ...line,
                                                                        opacity: value,
                                                                    },
                                                                }
                                                                setLineLayers(updatedLineLayers);
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Dropdown>
                                                            <DropdownToggle
                                                                size="sm"
                                                                style={{ padding: '1px 4px', marginTop: '-4px' }}
                                                            >
                                                                <FontAwesomeIcon icon={faFileDownload}/>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem
                                                                    style={{ fontSize: '.85em', padding: '2px 8px' }}
                                                                    onClick={() => { SaveLocalGPX(line) }}
                                                                >
                                                                    GPX
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    style={{ fontSize: '.85em', padding: '2px 8px' }}
                                                                    onClick={() => { SaveLocalKML(line) }}
                                                                >
                                                                    KML
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    style={{ fontSize: '.85em', padding: '2px 8px' }}
                                                                    onClick={() => { SaveLocalGeoJSON(line) }}
                                                                >
                                                                    GeoJSON
                                                                </DropdownItem>
                                                            </DropdownMenu>                                                                
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </CollapsingSideMenu>
            <FullScreenLoading
                isOpen={loading}
                message={loadingMessage}
                percentProgress={40}
            />
        </>
    )
}

export default SlopeAngleMap;