import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import * as d3 from 'd3';
const EPOCH_IN_DAY = 86400000;

export default class HeartRate extends React.Component<RouteProps> {
    private static MARGINS = { top: 65, right: 40, bottom: 45, left: 60 };
    private static GRADIENT_STEPS = 10;
    private static COLOR = d3.interpolateCubehelixLong(
        d3.hsl(-100, 0.75, 0.35),
        d3.hsl(  352, 1.00, 0.55));
    private static WEEK_FORMAT = d3.timeFormat("%U");

    private graphParent: React.RefObject<HTMLDivElement> = React.createRef();
    private x = d3.scaleLinear().domain([0, EPOCH_IN_DAY]);
    private y = d3.scaleLinear();

    private weekHeight = 0;
    private dayWidth = 0;
    private firstWeek = 0;

    private line = d3.line()
        .curve(d3.curveBasis)
        .x((d: any) => {
            return this.x(d[0] - d3.timeSunday(d[0]).getTime());
        })
        .y((d: any) => {
            return this.y(d[1]) + (
                this.weekHeight * (
                    parseInt(HeartRate.WEEK_FORMAT(d3.timeSunday(d[0]))) - this.firstWeek));
        });
    
    public componentDidMount = () => {
        this.getData(
            this.props.location?.hash
                ? this.props.location.hash.substr(1)
                : '2016-09'
        );
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    public componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleResize);
    }

    private getData = async (file: string) => {
        const data: number[][] = await (await fetch(`/data/heartRate/${file}.json`)).json();
        const lastEpoch = data[data.length -1][0];

        const firstDate = d3.timeSunday(new Date(data[0][0]));
        const lastDate = d3.timeSunday(new Date(lastEpoch));

        this.firstWeek = parseInt(HeartRate.WEEK_FORMAT(firstDate));
        const lastWeek = parseInt(HeartRate.WEEK_FORMAT(lastDate));
        const weeks = lastWeek - this.firstWeek;

        const weekSplits: number[][][] = d3.range(0, weeks + 1).map(i => { return [] });
        for (var i = 0; i < data.length; i++)
        {
            const week = parseInt(HeartRate.WEEK_FORMAT(d3.timeSunday(new Date(data[i][0]))));
            const weekIndexFromFirst = week - this.firstWeek;
            weekSplits[weekIndexFromFirst].push(data[i])
        }

        this.y.domain([
            d3.min(data, function(d) { return d[1]; }) || 0,
            d3.max(data, function(d) { return d[1]; }) || 0
        ])

        this.setState({
            lastWeek,
            weekSplits,
        });
    }

    private handleResize = () => {
        const width = this.graphParent.current?.offsetWidth || 0;
        const height = this.graphParent.current?.offsetHeight || 0;
        const graphWidth = width - HeartRate.MARGINS.left - HeartRate.MARGINS.right;
        const graphHeight = height - HeartRate.MARGINS.top - HeartRate.MARGINS.bottom;

        this.dayWidth = graphWidth / 7;
        this.weekHeight = graphHeight / 5;

        this.x.range([ 0, this.dayWidth ]);
        this.y.range([this.weekHeight, 0]);

        this.setState({ width, height, graphWidth, graphHeight });
    }

    public state = {
        data: [],
        width: 0,
        height: 0,
        graphWidth: 0,
        graphHeight: 0,
        weekSplits: [],
        lastWeek: 0,
    }

    public render() {
        const {
            width,
            height,
            graphHeight,
            graphWidth,
            weekSplits,
            lastWeek,
        } = this.state;

        return (
            <div
                style={{ height: '100%', width: '100%', overflow: 'hidden' }}
                ref={this.graphParent}
            >
                <svg width={width} height={height}>
                    <g transform={`translate(${HeartRate.MARGINS.left},${HeartRate.MARGINS.top})`}>
                        <linearGradient
                            id="temperature-gradient"
                            gradientUnits="userSpaceOnUse"
                            spreadMethod="repeat"
                            x1={0}
                            x2={0}
                            y1={0}
                            y2={graphHeight / 5}
                        >
                            {
                                d3.range(0, HeartRate.GRADIENT_STEPS + 1).map(i => {
                                    return (
                                        <stop
                                            key={i}
                                            offset={`${(i / HeartRate.GRADIENT_STEPS) * 100}%`}
                                            stopColor={HeartRate.COLOR((HeartRate.GRADIENT_STEPS - i) / HeartRate.GRADIENT_STEPS)}
                                        />
                                    )
                                })
                            }

                        </linearGradient>
                        <g>
                            {
                                weekSplits.map((week: any, index: number) => (
                                    <path
                                        key={index}
                                        style={{ fill: 'none', stroke: 'url(#temperature-gradient)', strokeWidth: '.5px' }}
                                        d={this.line(week) || ''}
                                    />
                                ))
                            }
                        </g>
                        {
                            d3.range(0, (lastWeek - this.firstWeek)).map(i => (
                                <line
                                    key={i}
                                    style={{ fill: 'none', strokeWidth: '.5px' }}
                                    stroke="#CCC"
                                    x1={0}
                                    x2={graphWidth}
                                    y1={(i + 1) * (graphHeight / 5)}
                                    y2={(i + 1) * (graphHeight / 5)}
                                />
                            ))
                        }
                        {
                            d3.range(0,6).map(i => (
                                <line
                                    key={i}
                                    stroke="#CCC"
                                    style={{ fill: 'none', strokeWidth: '.5px' }}
                                    x1={(i + 1) * this.dayWidth}
                                    x2={(i + 1) * this.dayWidth}
                                    y1={0}
                                    y2={graphHeight}
                                />
                            ))
                        }
                    </g>
                </svg>
            </div>
        );
    }
}