import { faEraser, faFill, faPaperPlane, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import BrushSizePicker from './BrushSizePicker';
import HuePicker from './HuePicker';
import { hslFor } from './SVGCanvas';
import { FloodFillCanvasAtPixel } from './Utils';

export interface Position {
    x: number;
    y: number;
}

export const GetMouseEventPositionForHTMLElementRef = (event: MouseEvent|TouchEvent, element: HTMLElement|SVGSVGElement|null): Position | undefined => {
    if (!element) {
        return undefined;        
    }
    let eventX, eventY;
    if (event instanceof MouseEvent) {
        eventX = event.pageX;
        eventY = event.pageY;
    } else {
        eventX = event.touches[0].pageX;
        eventY = event.touches[0].pageY;
    }

    let offsetLeft, offsetTop;
    if (element instanceof HTMLElement) {
        offsetLeft = element.offsetLeft;
        offsetTop = element.offsetTop;
    } else {
        const bound = element.getBoundingClientRect();
        const doc = document.documentElement;

        offsetTop = bound.top + window.pageYOffset - doc.clientTop;
        offsetLeft = bound.left + window.pageXOffset - doc.clientLeft;
    }
    
    return {
        x: parseFloat((eventX - offsetLeft).toFixed(2)),
        y: parseFloat((eventY - offsetTop).toFixed(2)),
    };
};

const ClearCanvas = (canvas: HTMLCanvasElement|null) => {
    if (!canvas) {
        return;
    }
    // console.log('clearing?')
    const context = canvas.getContext('2d');
    if (context) {
        context.fillStyle = 'white';
        context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    }
}

const DrawLineOnCanvas = (
    originalMousePosition: Position,
    newMousePosition: Position,
    color: string,
    lineWidth: number,
    canvas: HTMLCanvasElement|null
) => {
    if (!canvas) {
        return;
    }
    const context = canvas.getContext('2d');
    if (context) {
        context.strokeStyle = color;
        context.lineJoin = 'round';
        context.lineWidth = lineWidth * window.devicePixelRatio;

        context.beginPath();
        context.moveTo(originalMousePosition.x * window.devicePixelRatio, originalMousePosition.y * window.devicePixelRatio);
        context.lineTo(newMousePosition.x * window.devicePixelRatio, newMousePosition.y * window.devicePixelRatio);
        context.closePath();

        context.stroke();
    }
};

export interface Draw {
    color: string;
    path: [Position, Position][];
    lineWidth: number;
}

export interface Props {
    onSend: (draws: Draw[]) => void;
}

const CanvasWidth = 600;
const CanvasHeight = 600;

const Canvas: React.FC<Props> = ({ onSend }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [drawing, setDrawing] = useState(false);
    const [position, setPosition] = useState<Position | undefined>(undefined);
    const [draws, setDraws] = useState<Draw[]>([]);
    const [currentDraw, setCurrentDraw] = useState<[Position,Position][]>([]);
    const [color, setColor] = useState<string>('#000');
    const [lineWidth, setLineWidth] = useState<number>(5);
    const [bucket, setBucket] = useState(false);
    const [eraser, setEraser] = useState(false);

    const startDrawing = (event: MouseEvent|TouchEvent) => {
        const position = GetMouseEventPositionForHTMLElementRef(event, canvasRef.current);
        if (position) {
            if (bucket && canvasRef.current) {
                FloodFillCanvasAtPixel(canvasRef.current, position.x, position.y, color);
                const updatedDraws = draws.concat({
                    color,
                    lineWidth: -1,
                    path: [[position, { x: 0, y: 0}]],
                });
                setDraws(updatedDraws);
                // TODO: add bucket fill to draws array somehow...
            } else {
                setPosition(position);
                setDrawing(true);
            }
        }
    };

    const draw = (event: MouseEvent|TouchEvent) => {
        if (drawing) {
            const currentPosition = GetMouseEventPositionForHTMLElementRef(event, canvasRef.current);
            if (position && currentPosition) {
                DrawLineOnCanvas(position, currentPosition, eraser ? 'rgb(255,255,255)' : color, lineWidth, canvasRef.current);
                const updatedCurrentDraw = currentDraw.concat([[position, currentPosition]]);
                setCurrentDraw(updatedCurrentDraw);
                setPosition(currentPosition);
            }
        }
    };

    const stopDraw = () => {
        setDrawing(false);
        setPosition(undefined);
        if (drawing) {
            const updatedDraws = draws.concat({
                color: eraser ? 'rgb(255,255,255)' : color,
                lineWidth,
                path: currentDraw,
            });
            setDraws(updatedDraws);
            setCurrentDraw([]);
        }
    };

    const undo = () => {
        const updatedDraws = draws.slice(0, draws.length - 1);
        ClearCanvas(canvasRef.current);
        for (var i = 0; i < updatedDraws.length; i++) {
            const draw = updatedDraws[i];
            if (draw.lineWidth === -1) {
                const position = draw.path[0][0];
                FloodFillCanvasAtPixel(canvasRef.current, position.x, position.y, draw.color);
            } else {
                for (var j = 0; j < draw.path.length; j++) {
                    DrawLineOnCanvas(draw.path[j][0], draw.path[j][1], draw.color, draw.lineWidth, canvasRef.current);    
                }
            }
        }
        setDraws(updatedDraws);
    }

    const stopDrawCallback = useCallback(stopDraw, [drawing, eraser, currentDraw, color, lineWidth, draws]);
    const drawCallback = useCallback(draw, [drawing, eraser, position, color, lineWidth, currentDraw]);
    const startDrawingCallback = useCallback(startDrawing, [bucket, color, draws]);

    useEffect(() => {
        const { current: canvas } = canvasRef;
        if (!canvas) {
            return;
        }
        ClearCanvas(canvas);
    }, []);

    useEffect(() => {
        const { current: canvas } = canvasRef;
        if (!canvas) {
            return;
        }
        canvas.addEventListener('mousedown', startDrawingCallback);
        canvas.addEventListener('mousemove', drawCallback);
        canvas.addEventListener('mouseup', stopDrawCallback);
        canvas.addEventListener('mouseleave', stopDrawCallback);
        canvas.addEventListener('touchstart', startDrawingCallback);
        canvas.addEventListener('touchmove', drawCallback);
        canvas.addEventListener('touchend', stopDrawCallback);
        return () => {
            canvas.removeEventListener('mousedown', startDrawingCallback);
            canvas.removeEventListener('mousemove', drawCallback);
            canvas.removeEventListener('mouseup', stopDrawCallback);
            canvas.removeEventListener('mouseleave', stopDrawCallback);
            canvas.removeEventListener('touchstart', startDrawingCallback);
            canvas.removeEventListener('touchmove', drawCallback);
            canvas.removeEventListener('touchend', stopDrawCallback);
        };
    }, [
        startDrawingCallback,
        drawCallback,
        stopDrawCallback,
    ]);

    return (
        <div>
            <div style={{
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}>
                <div style={{
                    marginTop: '5px',
                    marginRight: '3px',
                }}>
                    <ButtonGroup vertical={true}>
                        <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={undo}
                        >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>
                        <Button
                            size="sm"
                            variant="outline-primary"
                            active={bucket}
                            onClick={() => {
                                setBucket(!bucket);
                                if (!bucket) {
                                    setEraser(false);
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faFill} />
                        </Button>
                        <Button
                            size="sm"
                            variant="outline-primary"
                            active={eraser}
                            onClick={() => {
                                setEraser(!eraser);
                                if (!eraser) {
                                    setBucket(false);
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faEraser} />
                        </Button>
                    </ButtonGroup>
                </div>
                <canvas
                    ref={canvasRef}
                    style={{
                        marginTop: '5px',
                        border: 'solid 1px #CCC',
                        width: `${CanvasWidth}px`,
                        height: `${CanvasHeight}px`,
                    }}
                    width={CanvasWidth * window.devicePixelRatio}
                    height={CanvasHeight * window.devicePixelRatio}
                />
                <div
                    style={{
                        marginTop: '5px',
                        marginLeft: '3px',
                    }}
                >
                    <HuePicker
                        onPick={(color: number) => {
                            setColor(hslFor(color, 100, 50));
                        }}
                        saturation={0}
                    />
                    <BrushSizePicker
                        onPick={(size: number) => {
                            setLineWidth(size);
                        }}
                        color={color}
                    />
                </div>
            </div>
            <Button
                variant="success"
                block={true}
                onClick={() => {
                    onSend(draws);
                }}
            >
                Send
                <FontAwesomeIcon
                    icon={faPaperPlane}
                    style={{
                        marginLeft: '4px'
                    }}
                />
            </Button>
        </div>
    );
}

export default Canvas;

export interface StaticCanvasProps {
    draws: Draw[];
}

export const StaticCanvas: React.FC<StaticCanvasProps> = ({ draws }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        ClearCanvas(canvasRef.current);
        for (var i = 0; i < draws.length; i++) {
            const draw = draws[i];
            if (draw.lineWidth === -1) {
                const position = draw.path[0][0];
                FloodFillCanvasAtPixel(canvasRef.current, position.x, position.y, draw.color);
            } else {
                for (var j = 0; j < draw.path.length; j++) {
                    DrawLineOnCanvas(draw.path[j][0], draw.path[j][1], draw.color, draw.lineWidth, canvasRef.current);    
                }
            }
        }
    }, [draws]);
    
    return (
        <div>
            <div style={{
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}
            >
                <canvas
                    ref={canvasRef}
                    style={{
                        marginTop: '5px',
                        border: 'solid 1px #CCC',
                        width: `${CanvasWidth}px`,
                        height: `${CanvasHeight}px`,
                    }}
                    width={CanvasWidth * window.devicePixelRatio}
                    height={CanvasHeight * window.devicePixelRatio}
                />
            </div>
        </div>
    );
}