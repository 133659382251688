import React, { useCallback, useEffect, useState } from 'react';
import { GetTimestamp } from '../../projects/Drawgression/Common';
import LogViewer, { Log, Severity } from '../../projects/Drawgression/LogViewer';
import { ClientConfiguration } from '../../projects/Drawgression/RTCClientConnection';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';

interface WebRTCTestProps {
    host: boolean;
}

const WebRTCTest: React.FC<WebRTCTestProps> = ({ host }) => {
    const [logs, setLogs] = useState<Log[]>([]);
    const [connectionState, setConnectionState] = useState<RTCPeerConnectionState>();
    const [dataChannel, setDataChannel] = useState<RTCDataChannel>();
    const [iceError, setIceError] = useState<RTCPeerConnectionIceErrorEvent>();
    const [negotiationNeeded, setNegotiationNeeded] = useState<Event>();
    const [singalingState, setSingalingState] = useState<RTCSignalingState>();
    const [statsEnded, setStatsEnded] = useState<RTCStatsEvent>();
    const [track, setTrack] = useState<RTCTrackEvent>();
    const [iceConnectionState, setIceConnectionState] = useState<RTCIceConnectionState>();
    const [iceCandidates, setIceCandidates] = useState<(RTCIceCandidate | null)[]>([]);
    const [iceGatheringState, setIceGatheringState] = useState<RTCIceGatheringState>();
    const [localDescription, setLocalDescription] = useState<RTCSessionDescription | null>();
    const [peerConnection, setPeerconnection] = useState<RTCPeerConnection>(new RTCPeerConnection(ClientConfiguration));
    const [input, setInput] = useState<RTCSessionDescriptionInit>();
    const [message, setMessage] = useState<any>();

    const clientReconnect = useCallback(async () => {
        log(`going to try to reconnect:\nremoteDescription:\n${JSON.stringify(peerConnection.remoteDescription)}\nlocalDescription:\n${JSON.stringify(peerConnection.localDescription)}`);
        const rtcSessionDescription = (navigator as any).mozGetUserMedia ? (window as any).mozRTCSessionDescription : RTCSessionDescription;
        const rtcSessionDescriptionInit: RTCSessionDescriptionInit = {
            sdp: peerConnection.remoteDescription?.sdp,
            type: peerConnection.remoteDescription?.type,
        }
        await peerConnection.setRemoteDescription(new rtcSessionDescription(rtcSessionDescriptionInit));
        log('set remote description from offer');
    }, [peerConnection]);

    const log = (message: string, severity?: Severity) => {
        setLogs((previousLogs) => {
            return previousLogs.concat({ message, time: GetTimestamp(), severity });
        });
    }

    const hostSetup = useCallback(async () => {
        log('Hi. This is the "Server" window.', 'Warning');
        log('To test WebRTC, wait for "Ice Gathering State" to be "complete"', 'Warning');
        log('This may happen very fast so you should not have to wait', 'Warning');
        log('Then hit "Copy Offer" and paste it in the "Client" box for "Offer"', 'Warning');
        log('The rest of the instructions are waiting in the "Client" window.', 'Warning');
        const dataChannel = peerConnection.createDataChannel('test');
        setDataChannel(dataChannel);
        log('created dataChannel');
        const offer = await peerConnection.createOffer();
        log(`peerConnection offer created:\n${JSON.stringify(offer, null, 2)}`);
        if (!offer) {
            log('offer null or undefined :(');
            return;
        }

        log(`establishing listeners`);
        peerConnection.onconnectionstatechange = (ev: Event) => {
            log(`peerConnection onconnectionstatechange:\n${JSON.stringify(ev)}`);
            setConnectionState(peerConnection.connectionState);
        };
        peerConnection.oniceconnectionstatechange = (event: Event) => {
            log(`peerConnection state changed to: ${peerConnection.iceConnectionState}`);
            setIceConnectionState(peerConnection.iceConnectionState);
        }
        peerConnection.onicecandidate = (ev: RTCPeerConnectionIceEvent) => {
            log(`peerConnection icecandidate:\n${JSON.stringify(ev.candidate, null, 2)}\n${JSON.stringify(peerConnection.localDescription, null, 2)}`);
            setIceCandidates((previousIceCandiates) => {
                return previousIceCandiates.concat(ev.candidate);
            });
            setLocalDescription(peerConnection.localDescription);
            if (ev.candidate === null) {
                log(`final icecandate found:\n${JSON.stringify(peerConnection.localDescription)}`);
            }
        }
        peerConnection.onicecandidateerror = (ev: RTCPeerConnectionIceErrorEvent) => {
            log(`peerConnection onIceCandidateError:\n${JSON.stringify(ev)}`, 'Error');
            setIceError(ev);
        }
        peerConnection.onsignalingstatechange = (ev: Event) => {
            log(`peerConnection onSignalingStateChange:\n${JSON.stringify(ev)}`);
            setSingalingState(peerConnection.signalingState);
        }
        peerConnection.onnegotiationneeded = (ev: Event) => {
            log(`peerConnection onNegotiationNeeded:\n${JSON.stringify(ev)}`, 'Error');
            setNegotiationNeeded(ev)
        }
        peerConnection.onicegatheringstatechange = (ev: Event) => {
            log(`peerConnection ice gathering state change: ${peerConnection.iceGatheringState}\n(${JSON.stringify(peerConnection, null, 2)})`);
            setIceGatheringState(peerConnection.iceGatheringState);
        }
        peerConnection.onstatsended = (ev: RTCStatsEvent) => {
            log(`peerConnection onStatsEnded:\n${JSON.stringify(ev)}`);
            setStatsEnded(ev);
        }
        peerConnection.ontrack = (ev: RTCTrackEvent) => {
            log(`peerConnection onTrack:\n${JSON.stringify(ev)}`);
            setTrack(ev);
        }
        dataChannel.onopen = (ev: Event) => {
            log(`dataChannel open, peerConnection state: ${peerConnection.iceConnectionState}`);
        }
        dataChannel.onmessage = (ev: MessageEvent) => {
            log(`dataChannel message: ${JSON.stringify(ev)}`);
            setMessage(ev.data);
        }
        log('listeners set');

        log('setting local description to offer');
        await peerConnection.setLocalDescription(offer);
        log(`local description set:\n${JSON.stringify(peerConnection.localDescription, null, 2)}`);
        log('as setup as it gets for host');
    }, [peerConnection]);

    const hostSetRemoteDescription = async (rTCSessionDescriptionInit: RTCSessionDescriptionInit) => {
        log('setting remote description from offer');
        const rtcSessionDescription = (navigator as any).mozGetUserMedia ? (window as any).mozRTCSessionDescription : RTCSessionDescription;
        await peerConnection.setRemoteDescription(new rtcSessionDescription(rTCSessionDescriptionInit));
        log('set remote description from offer, thats a wrap?');
    }

    const clientSetup = useCallback(() => {
        log('Hi. This is the "Client" window.', 'Warning');
        log('To test WebRTC, first follow the instructions on the "Server" window.', 'Warning');
        log('Ok, all set?', 'Warning');
        log('Paste the "Offer" from the "Server" into the "Offer" box and click "Connect"', 'Warning');
        log('If you are on Chrome, this may take a bit and the connection will disconnect', 'Warning');
        log('Thanks chrome devs...', 'Warning');
        log('Once both sides are "connected", you can type in the "Data to send" box.', 'Warning');
        log('and what you type should appear on the other side.', 'Warning');
        log(`establishing listeners`);
        peerConnection.onconnectionstatechange = (ev: Event) => {
            log(`peerConnection onconnectionstatechange:\n${JSON.stringify(ev)}`);
            setConnectionState(peerConnection.connectionState);
        };
        peerConnection.ondatachannel = (ev: RTCDataChannelEvent) => {
            log(`peerConenction onDataChannel:\n${JSON.stringify(ev)}`);
            const dataChannel = ev.channel;
            dataChannel.onclose = () => {
                log(`dataChannel closed`);
            }
            dataChannel.onmessage = (ev: MessageEvent) => {
                setMessage(ev.data);
            }
            setDataChannel(dataChannel);
            
        };
        peerConnection.onicecandidateerror = (ev: RTCPeerConnectionIceErrorEvent) => {
            log(`peerConnection onIceCandidateError:\n${JSON.stringify(ev)}`, 'Error');
            setIceError(ev);
        }
        peerConnection.onnegotiationneeded = (ev: Event) => {
            log(`peerConnection onNegotiationNeeded:\n${JSON.stringify(ev)}`, 'Error');
            setNegotiationNeeded(ev)
        }
        peerConnection.onsignalingstatechange = (ev: Event) => {
            log(`peerConnection onSignalingStateChange:\n${JSON.stringify(ev)}`);
            setSingalingState(peerConnection.signalingState);
        }
        peerConnection.onstatsended = (ev: RTCStatsEvent) => {
            log(`peerConnection onStatsEnded:\n${JSON.stringify(ev)}`);
            setStatsEnded(ev);
        }
        peerConnection.ontrack = (ev: RTCTrackEvent) => {
            log(`peerConnection onTrack:\n${JSON.stringify(ev)}`);
            setTrack(ev);
        }
        peerConnection.oniceconnectionstatechange = (event: Event) => {
            log(`peerConnection iceConnectionState changed to: ${peerConnection.iceConnectionState}`);
            if (peerConnection.iceConnectionState === 'disconnected') {
                clientReconnect();
            }
            setIceConnectionState(peerConnection.iceConnectionState);
        }
        peerConnection.onicecandidate = (ev: RTCPeerConnectionIceEvent) => {
            log(`peerConnection icecandidate:\n${JSON.stringify(ev.candidate, null, 2)}`);
            setIceCandidates((previousIceCandiates) => {
                return previousIceCandiates.concat(ev.candidate);
            });
            setLocalDescription(peerConnection.localDescription);
            if (ev.candidate === null) {
                log(`final icecandate found:\n${JSON.stringify(peerConnection.localDescription)}`);
            }
        }
        peerConnection.onicegatheringstatechange = (ev: Event) => {
            log(`peerConnection ice gathering state change: ${peerConnection.iceGatheringState}\n(${JSON.stringify(peerConnection, null, 2)})`);
            setIceGatheringState(peerConnection.iceGatheringState);
        }

        log('client as setup as can be');
    }, [peerConnection, clientReconnect]);

    const clientSetRemoteDescription = async (rtcRemoteDescriptionInit: RTCSessionDescriptionInit) => {
        log(`setting remote description from offer: ${JSON.stringify(rtcRemoteDescriptionInit)}`);
        const rtcSessionDescription = (navigator as any).mozGetUserMedia ? (window as any).mozRTCSessionDescription : RTCSessionDescription;
        await peerConnection.setRemoteDescription(new rtcSessionDescription(rtcRemoteDescriptionInit))
        log('set remote description from offer, creating answer');
        const answer = await peerConnection.createAnswer();
        log(`answer created, setting local description:\n${JSON.stringify(answer)}`)
        await peerConnection.setLocalDescription(answer);
        log('local description set, ice should flow');
    }

    const setup = () => {
        if (host) {
            hostSetup();
        } else {
            clientSetup();
        }
        log('setup complete');
    }
    useEffect(setup, [peerConnection, clientSetup, host, hostSetup]);

    const data = [
        { name: 'Connection State', value: connectionState },
        { name: 'Data Channel', value: dataChannel?.readyState },
        { name: 'Ice Error', value: `${iceError?.errorCode}-${iceError?.errorText}` },
        { name: 'Negotiation Needed', value: negotiationNeeded ? 'no' : 'yes' },
        { name: 'Signaling State', value: singalingState },
        { name: 'Stats Ended', value: statsEnded ? 'no': 'yes' },
        { name: 'Track', value: track ? 'no' : 'yes' },
        { name: 'Ice Connection State', value: iceConnectionState},
        { name: 'Ice Candidates', value: iceCandidates.length },
        { name: 'Ice Gathering State', value: iceGatheringState},
        { name: 'Local Description (Offer/Answer)', value: (<>
                <Button
                    onClick={() => {
                        navigator.clipboard.writeText(JSON.stringify(localDescription, null, 2));
                    }}
                    style={{ marginRight: '3px' }}
                >
                    Copy {host ? 'Offer' : 'Answer'}
                </Button>
                {
                    localDescription
                        && `sdp.length ${localDescription.sdp.length} | type: ${localDescription.type}`
                }
            </>)},
        { name: host ? 'Answer' : 'Offer', value: (
            <input
                type="textarea"
                onChange={(e) => { setInput(JSON.parse(e.currentTarget.value)) }}
            />
        )},
        { name: 'Connect', value: (
            <>
                <Button
                    onClick={() => {
                        if (input) {
                            if (host) {
                                hostSetRemoteDescription(input);
                            }
                            else {
                                clientSetRemoteDescription(input);
                            }
                        }
                    }}
                    style={{ marginRight: '3px' }}
                >
                    Connect
                </Button>
                <Button
                    onClick={() => {
                        if (input) {
                            if (host) {
                                return;
                            } else {
                                clientReconnect()
                            }
                        }
                    }}
                >
                    Reconnect
                </Button>
            </>
        )},
        { name: 'Reset', value: (
            <Button
                onClick={() => {
                    setInput(undefined);
                    setConnectionState(undefined);
                    setDataChannel(undefined);
                    setIceError(undefined);
                    setNegotiationNeeded(undefined);
                    setSingalingState(undefined);
                    setStatsEnded(undefined);
                    setTrack(undefined);
                    setIceConnectionState(undefined);
                    setIceCandidates([]);
                    setLocalDescription(undefined);
                    setPeerconnection(new RTCPeerConnection(ClientConfiguration));
                }}
            >
                Reset
            </Button>
        )},
        { name: 'Data to send', value: (
            <input
                type="textarea"
                onChange={(e) => {
                    if (dataChannel) {
                        log(`trying to send ${e.currentTarget.value} to ${dataChannel?.readyState}`)
                        dataChannel.send(e.currentTarget.value);
                    }
                }}
            />
        )},
        { name: 'Data recieved', value: (
            <pre>{JSON.stringify(message)}</pre>
        )}
    ];

    return (
        <div
            style={{ 
                width: '100%',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '75%',
                    overflowY: 'scroll',
                }}
            >
                <Table
                    striped={true}
                    size="sm"
                >
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(d => {
                            return (
                                <tr
                                    key={d.name}
                                >
                                    <td>{d.name}</td>
                                    <td>{d.value}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            <div
                style={{
                    width: '100%',
                    height: '25%',
                    overflowY: 'scroll',
                    backgroundColor: '#333',
                }}
            >
                <LogViewer
                    logs={logs}
                />
            </div>
        </div>        
    );
}

const Wrapper: React.FC = () => {
    const [host, setHost] = useState<boolean>();

    return host === undefined
        ? (
            <Container>
                <Row>
                    <Col sm={12}>
                        <h4>
                            Read the logs for instructions after opening a window each for "Test Host" and "Test Client"
                        </h4>
                        <Button
                            onClick={() => { setHost(true) }}
                            style={{ marginRight: '3px' }}
                        >
                            Test Host
                        </Button>
                        <Button
                            onClick={() => { setHost(false) }}
                        >
                            Test Client
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
        : (
            <WebRTCTest host={host}/>
        )
}

export default Wrapper;
