import { Message } from "./Common";
import { Severity } from "./LogViewer";

export const ClientConfiguration = { iceServers: [{ urls: ['stun:stun2.l.google.com:19302'] }]};
// const ConnectionConfiguration = { optional: [{ DtlsSrtpKeyAgreement: true }]};

export class HostToClientConnection {
    private AnswerDescription: RTCSessionDescription|null = null;
    public IceTrickleComplete = false;
    public ConnectionComplete = false;
    public InitialLocalOfferSet = false;
    private PeerConnection: RTCPeerConnection = new RTCPeerConnection(ClientConfiguration);
    private DataChannel: RTCDataChannel;
    private log: (s: string, severity?: Severity) => void;
    private messageHandler: (message: any) => void;
    private onStateChange: (state: RTCIceConnectionState) => void;

    constructor(
        log: (s: string, severity?: Severity) => void,
        messageHandler: (message: any) => void,
        onStateChange: (state: RTCIceConnectionState) => void) {
        this.log = log;
        this.messageHandler = messageHandler;
        this.onStateChange = onStateChange;

        this.log('Created dataChannel');
        this.DataChannel = this.PeerConnection.createDataChannel('test');

        this.log('Adding listeners for PeerConnection and DataChannel');
        this.PeerConnection.onconnectionstatechange = this.OnConnectionStateChange;
        this.PeerConnection.onicecandidate = this.OnIceCandidate;
        this.PeerConnection.onicecandidateerror = this.OnIceCandidateError;
        this.PeerConnection.oniceconnectionstatechange = this.OnIceConnectonStateChange;
        this.PeerConnection.onicegatheringstatechange = this.OnIceGatheringStateChange;
        // this.PeerConnection.onnegotiationneeded
        this.PeerConnection.onsignalingstatechange = this.OnSignalingStateChange;

        this.DataChannel.onopen = this.handleDataChannelOpen;
        this.DataChannel.onmessage = this.handleDataChannelMessage;
        // console.log(this.RoomId, this.PeerConnection, this.DataChannel);
        this.log('Listeners added');

        this.log('Creating offer');
        this.PeerConnection.createOffer()
            .then(this.handlePeerCreateOffer)
            .catch(this.handlePeerCreateOfferFailure);
    }

    private OnConnectionStateChange = (ev: Event) => {
        this.log(`peerConnection.onconnectionstatechange: ${this.PeerConnection.connectionState}`);
    };

    private OnIceCandidate = (ev: RTCPeerConnectionIceEvent) => {
        this.log(`peerConnection.onicecandidate:\n${JSON.stringify(ev.candidate, null, 2)}\nlocalDescription:\n${JSON.stringify(this.PeerConnection.localDescription, null, 2)}`)
        if (ev.candidate === null) {
            this.log('Null ice candidate, trickle complete');
            this.IceTrickleComplete = true;
        }
	}

    private OnIceCandidateError = (ev: RTCPeerConnectionIceErrorEvent) => {
        this.log(`peerConnection.onicecandidateerror: ${ev.errorCode} - ${ev.errorText}`, 'Error');
    }

    private OnIceConnectonStateChange = (event: Event) => {
        const { iceConnectionState } = this.PeerConnection;
        this.log(`peerConnection.oniceconnectionstatechange: ${iceConnectionState}`)
        this.onStateChange(iceConnectionState);
	}

    private OnIceGatheringStateChange = (ev: Event) => {
        this.log(`peerConnection.onicegatheringstatechange: ${this.PeerConnection.iceGatheringState}\nlocalDescription:\n${JSON.stringify(this.PeerConnection.localDescription, null, 2)})`);
        // TODO: could switch to checking for "complete" here to indicate IceTrickleComplete'
    }

    private OnSignalingStateChange = (ev: Event) => {
        this.log(`peerConnection.onsignalingstatechange: ${this.PeerConnection.signalingState}`);
    }

    public GetIceConnectionState = () => {
        return this.PeerConnection.iceConnectionState;
    }

    private handlePeerCreateOffer = async (value: RTCSessionDescriptionInit) => {
        await this.PeerConnection.setLocalDescription(value);
        this.log('Offer created and set as LocalDescription');
        this.InitialLocalOfferSet = true;
    }

    private handlePeerCreateOfferFailure = () => {
        this.IceTrickleComplete = false;
        this.log('Could not create offer');
    }

    private handleDataChannelOpen = () => {
        this.log(`Data channel connected`);
        this.onStateChange(this.PeerConnection.iceConnectionState);
    }

    private handleDataChannelMessage = (messageEvent: MessageEvent) => {
        const { data } = messageEvent;
        this.log(`Got message: ${data}`);
        if (data.charCodeAt(0) === 2) {
            return;
        }
        const parsed = JSON.parse(data);
        this.messageHandler(parsed);
    }

    public GetPeerConnectionLocalDescription = () => {
        return this.PeerConnection.localDescription
    }

    public ConnectWithAnswer = (answer: RTCSessionDescriptionInit) => {
        this.AnswerDescription = new RTCSessionDescription(answer)
        this.PeerConnection.setRemoteDescription(this.AnswerDescription);
        this.ConnectionComplete = true;
    }

    public SendData = (message: Message) => {
        const dataToSend = JSON.stringify(message);
        this.DataChannel.send(dataToSend);
        this.log(`sent data to client`);
    }

    public GetDataChannelState = () => {
        return this.DataChannel.readyState;
    }
}

export class ClientToHostConnection {
    private PeerConnection = new RTCPeerConnection(ClientConfiguration);
    private DataChannel: RTCDataChannel|null = null;
    public IceTrickleComplete = false;
    private log: (s: string, severity?: Severity) => void;
    private messageHandler: (message: Message) => void;
    private onStateChange: (state: RTCIceConnectionState) => void;
    private reconnectCount = 0;

    constructor(
        log: (s: string, severity?: Severity) => void,
        messageHandler: (message: Message) => void,
        onStateChange: (state: RTCIceConnectionState) => void,
    ) {
        this.log = log;
        this.messageHandler = messageHandler;
        this.onStateChange = onStateChange;

        this.log('Adding listeners for PeerConnection and DataChannel');
        this.PeerConnection.onconnectionstatechange = this.OnConnectionStateChange;
        this.PeerConnection.onicecandidate = this.OnIceCandidate;
        this.PeerConnection.onicecandidateerror = this.OnIceCandidateError;
        this.PeerConnection.oniceconnectionstatechange = this.OnIceConnectonStateChange;
        this.PeerConnection.onicegatheringstatechange = this.OnIceGatheringStateChange;
        // this.PeerConnection.onnegotiationneeded
        this.PeerConnection.onsignalingstatechange = this.OnSignalingStateChange;

        this.PeerConnection.ondatachannel = this.OnDataChannel;
    }

    private OnConnectionStateChange = (ev: Event) => {
        this.log(`peerConnection.onconnectionstatechange: ${this.PeerConnection.connectionState}`);
    };

    private OnIceCandidate = (ev: RTCPeerConnectionIceEvent) => {
        if (ev.candidate === null) {
            this.log(`We've got our final Ice Candidate`);
            this.IceTrickleComplete = true
        }
    }

    private OnIceCandidateError = (ev: RTCPeerConnectionIceErrorEvent) => {
        this.log(`peerConnection.onicecandidateerror: ${ev.errorCode} - ${ev.errorText}`, 'Error');
    }

    private OnIceConnectonStateChange = (ev: Event) => {
        const { iceConnectionState } = this.PeerConnection;
        if (iceConnectionState === 'disconnected' && this.reconnectCount < 5) {
            this.reconnectCount += 1;
            this.Reconnect();
        }
        this.onStateChange(iceConnectionState);
    }

    private OnIceGatheringStateChange = (ev: Event) => {
        this.log(`peerConnection.onicegatheringstatechange: ${this.PeerConnection.iceGatheringState}\nlocalDescription:\n${JSON.stringify(this.PeerConnection.localDescription, null, 2)})`);
        // TODO: could switch to checking for "complete" here to indicate IceTrickleComplete'
    }

    private OnSignalingStateChange = (ev: Event) => {
        this.log(`peerConnection.onsignalingstatechange: ${this.PeerConnection.signalingState}`);
    }

    private OnDataChannel = (ev: RTCDataChannelEvent) => {
        this.DataChannel = ev.channel || ev;
        this.log(`peerConnection.ondatachannel: ${ev.channel.readyState}`);
        this.DataChannel.onopen = this.handleDataChannelOpen;
        this.DataChannel.onmessage = this.handleDataChannelMessage;
    }

    private handleDataChannelOpen = (ev: Event) => {
        this.log(`dataChannel.onopen: ${this.DataChannel?.readyState}`)
    }

    private handleDataChannelMessage = (ev: MessageEvent) => {
        const data: Message = JSON.parse(ev.data);
        this.log(`Sending message to handler: ${ev.data}`);
        this.messageHandler(data);
    }

    public SetPeerConnectionRemoteDescription = async (rtcRemoteDescriptionInit: RTCSessionDescriptionInit) => {
        const rtcSessionDescription = (navigator as any).mozGetUserMedia ? (window as any).mozRTCSessionDescription : RTCSessionDescription;
        await this.PeerConnection.setRemoteDescription(new rtcSessionDescription(rtcRemoteDescriptionInit))
        this.log('set remote description from offer, creating answer');
        const answer = await this.PeerConnection.createAnswer();
        this.log(`answer created, setting local description:\n${JSON.stringify(answer)}`)
        await this.PeerConnection.setLocalDescription(answer);
        this.log('local description set, ice should flow');
    }

    public Reconnect = async () => {
        this.log(`going to try to reconnect:\nremoteDescription:\n${JSON.stringify(this.PeerConnection.remoteDescription)}\nlocalDescription:\n${JSON.stringify(this.PeerConnection.localDescription)}`, 'Warning');
        const rtcSessionDescription = (navigator as any).mozGetUserMedia ? (window as any).mozRTCSessionDescription : RTCSessionDescription;
        const { sdp, type } = this.PeerConnection.remoteDescription || {};
        await this.PeerConnection.setRemoteDescription(
            new rtcSessionDescription({
                sdp,
                type,
            }));
        this.log('set remote description from offer', 'Warning');
    }

    public SendData = (message: Message) => {
        const dataToSend = JSON.stringify(message);
        this.DataChannel?.send(dataToSend);
        this.log(`sent data to host`);
    }

    public GetPeerConnectionLocalDescription = () => {
        return this.PeerConnection.localDescription
    }

    public GetIceConnectionState = () => {
        return this.PeerConnection.iceConnectionState;
    }
}