export const TrigonometryDefines=`
#define TWOPI 6.28318530718
#define PI 3.14159265 
#define HALFPI 1.57079633
#define QUARTERPI 0.785398165
#define EIGTHPI 0.392699081
`;

export const AngleToHue=`
vec3 angle_to_hue_2(float angle, vec3 aspectColors[4]) {
  // NORTH
  if (
      (angle <= (HALFPI + EIGTHPI)) &&
      (angle >= (HALFPI - EIGTHPI))
  ) {
      return aspectColors[0];
  }
  // NORTHEAST
  if (
      (angle < (HALFPI - EIGTHPI)) &&
      (angle > EIGTHPI)
  ) {
      return mix(
          aspectColors[1],
          aspectColors[0],
          (angle - EIGTHPI) / QUARTERPI
      );
  }
  // EAST
  if (
      (angle <= EIGTHPI) &&
      (angle >= -EIGTHPI)
  ) {
      return aspectColors[1];
  }
  // SOUTHEAST
  if (
      (angle < -EIGTHPI) &&
      (angle > (-HALFPI + EIGTHPI))
  ) {
      return mix(
          aspectColors[1],
          aspectColors[2],
          abs(angle + EIGTHPI) / QUARTERPI
      );
  }
  if (
      (angle <= (-HALFPI + EIGTHPI)) &&
      (angle >= (-HALFPI - EIGTHPI))
  ) {
      return aspectColors[2];
  }
  if (
      (angle < (-HALFPI - EIGTHPI)) &&
      (angle > (-PI + EIGTHPI))
  ) {
      return mix(
          aspectColors[2],
          aspectColors[3],
          abs(angle + HALFPI + EIGTHPI) / QUARTERPI
      );
  }
  if (
      (angle <= (-PI + EIGTHPI)) ||
      (angle >= (PI - EIGTHPI))
  ) {
      return aspectColors[3];
  }
  if (
      (angle < (PI - EIGTHPI)) &&
      (angle > (HALFPI + EIGTHPI))
  ) {
      return mix(
          aspectColors[0],
          aspectColors[3],
          (angle - (HALFPI + EIGTHPI)) / QUARTERPI
      );
  }
  return vec3(1.0, 1.0, 1.0);
}
`;

export const SlopeToHue = `
vec4 slope_to_hue(float slope) {
  // 5 Degrees
  if (slope < (HALFPI / 18.0)) {
    return vec4(1.0, 1.0, 0.75, 1.0);
  }

  // 10 Degrees
  if (slope < (HALFPI / 9.0)) {
    return vec4(1.0, 1.0, 0.0, 1.0);
  }
  // 15 Degrees
  if (slope < (HALFPI / 6.0)) {
    return vec4(1.0, 0.74901960784, 0.0, 1.0);
  }
  // 20 Degrees
  if (slope <= (PI / 9.0)) {
    return vec4(1.0, 0.631372549, 0.19607843137, 1.0);
  }
  // 30 Degrees
  if (slope <= (HALFPI / 3.0)) {
    return vec4(1.0, 0.5, 0.0, 1.0);
  }
  return vec4(0.635294118, 0.294117647, 0.117647059, 1.0);
}
`;

const CubicPulse = `float cubicPulse( float center, float width, float x ){
  x = abs(x - center);
  x = min((x/width),1.0);
  return 1.0 - x*x*(3.0-2.0*x);
}`;

export const MixLayers = (vec4ColorName: string) => `
if (slopeAspectEnabled) {
  vec4 slopeAspectColor = vec4(angle_to_hue_2(atan(normal.y, normal.x), aspectColors), 1.0);
  ${vec4ColorName} = mix(${vec4ColorName}, slopeAspectColor, slopeAspectOpacity);
}

float slope = HALFPI - asin(abs(normal.z));
if (slopeAngleEnabled) {
  ${vec4ColorName} = mix(${vec4ColorName}, slope_to_hue(slope), slopeAngleOpacity);
}

// If slopeMask is enabled
if (slopeMaskEnabled && slope >= slopeCutoffMin && slope <= slopeCutoffMax) {
  float position = fract((vTexCoord.x + vTexCoord.y) * 100.0);
  vec3 stripeColor = mix(vec3(0.0, 0.0, 0.0), vec3(1.0, 1.0, 0.5), step(position, 0.5));
  ${vec4ColorName} = mix(${vec4ColorName}, vec4(stripeColor, 1.0), slopeMaskOpacity);
}
`;

export const CommonUniforms = `
uniform float slopeCutoffMin;
uniform float slopeCutoffMax;

uniform float slopeMaskOpacity;
uniform float slopeAspectOpacity;
uniform float slopeAngleOpacity;

uniform vec3 aspectColors[4];

uniform bool slopeMaskEnabled;
uniform bool slopeAspectEnabled;
uniform bool slopeAngleEnabled;
`;

export const ProjectionUtils = `
const float TILE_SIZE = 512.0;
const float PI = 3.1415926536;
const float WORLD_SCALE = TILE_SIZE / PI / 2.0;
// from degrees to Web Mercator
vec2 lnglat_to_mercator(vec2 lnglat) {
  float x = lnglat.x;
  float y = clamp(lnglat.y, -89.9, 89.9);
  return vec2(
    radians(x) + PI,
    PI + log(tan(PI * 0.25 + radians(y) * 0.5))
  ) * WORLD_SCALE;
}
// from Web Mercator to degrees
vec2 mercator_to_lnglat(vec2 xy) {
  xy /= WORLD_SCALE;
  return degrees(vec2(
    xy.x - PI,
    atan(exp(xy.y - PI)) * 2.0 - PI * 0.5
  ));
}
`;

const fragmentShader = `#version 300 es
#define SHADER_NAME slope-angle-layer-fs

precision highp float;

${CubicPulse}

${TrigonometryDefines}

${AngleToHue}

${SlopeToHue}

uniform bool hasTexture;
uniform sampler2D sampler;
uniform bool flatShading;
uniform float opacity;

uniform float contourIncrement;
uniform float contourIncrementHalf;
uniform float contourIncrementSmall;
uniform float contourIncrementSmallHalf;

${CommonUniforms}

in vec2 vTexCoord;
in vec3 cameraPosition;
in vec3 normals_commonspace;
in vec4 position_commonspace;
in vec4 vColor;

varying vec3 v_normal;
varying float vertexHeightMeters;

out vec4 fragColor;

void main(void) {
  geometry.uv = vTexCoord;

  vec3 normal;
  if (flatShading) {

    // NOTE(Tarek): This is necessary because
    // headless.gl reports the extension as
    // available but does not support it in
    // the shader.
  #ifdef DERIVATIVES_AVAILABLE
    normal = normalize(cross(dFdx(position_commonspace.xyz), dFdy(position_commonspace.xyz)));
  #else
    normal = vec3(0.0, 0.0, 1.0);
  #endif
  } else {
    normal = normals_commonspace;
  }

  float g = cubicPulse(
    contourIncrementHalf,
    1.0,
    mod(
        vertexHeightMeters - contourIncrementHalf,
        contourIncrement
    )
  );
  float g2 = cubicPulse(
    contourIncrementSmallHalf,
    0.25,
    mod(
      vertexHeightMeters - contourIncrementSmallHalf,
      contourIncrementSmall
    )
  );
  float combined = max(g, g2);

  
  fragColor = texture(sampler, vTexCoord);
  // vec3 lightColor = lighting_getLightColor(color.rgb, cameraPosition, position_commonspace.xyz, normal);
  // fragColor = vec4(lightColor, color.a * opacity);

  ${MixLayers('fragColor')}

  // Add contour at the end
  if (combined > 0.0) {
    fragColor = mix(fragColor, vec4(0.0, 0.0, 0.0, 1.0), 0.5);
  }
  DECKGL_FILTER_COLOR(fragColor, geometry);
}
`;

export default fragmentShader;