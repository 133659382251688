import { ZoomBehavior, ZoomTransform, axisBottom, axisLeft, extent, line, scaleLinear, scaleTime, select, selectAll, zoom, zoomIdentity} from "d3";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { D3_CATEGORY_10, GraphMargins, PlotSeries } from "./common";
import './style.css';
import AnimatedPath from "./AnimatedPath";
import { Swatches } from "./Swatches";
import { OverlayTrigger, Popover } from "react-bootstrap";

const UpdatingTooltip = forwardRef((props: any, ref) => {
    const { popper, children } = props;
    useEffect(() => {
      popper.scheduleUpdate();
    }, [children, popper]);
    return (
        <Popover ref={ref} body {...props}>
            {children}
        </Popover>
    );
});

interface GraphLine {
    color: string;
    data: [number|Date|any,number][];
    label?: string;
}

interface SimplyPlotProps {
    svgStyle?: React.CSSProperties;
    margins?: GraphMargins;
    series: PlotSeries[];
    lines?: GraphLine[];
    xAxisFormat?: any;
    yAxisFormat?: any;
    forceXDomain?: any;
    forceYDomain?: any;
    xAccessor?: string;
    yAccessor?: string;
    xScaleOverride?: d3.ScaleTime<any,any,any>|d3.ScaleLinear<any,any,any>;
    titleText?: string;
    colors?: d3.ScaleOrdinal<any, any, any>;
    yTicks?: (domain: number[]) => any;
    onClick?: (data: any) => void;
    hoverText?: (data: any) => React.ReactElement;
}

interface FocusOn {
    x: number|Date;
    y: number|Date;
    text?: React.ReactElement;
}

const SimplyPlot: React.FC<SimplyPlotProps> = ({
    svgStyle,
    margins = { top: 10, right: 35, bottom: 45, left: 40 },
    titleText,
    series,
    lines,
    xScaleOverride = scaleTime(),
    xAxisFormat = null,
    yAxisFormat = (a: any) => `${(a as number).toFixed(0)} %`,
    colors = D3_CATEGORY_10,
    yTicks,
    onClick,
    hoverText = null,
}) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const gRef = useRef<SVGGElement>(null);
    const xAxisGRef = useRef<SVGGElement>(null);
    const yAxisGRef = useRef<SVGGElement>(null);
    const plotGRef = useRef<SVGGElement>(null);
    const zoomContainerRef = useRef<SVGRectElement>(null);
    const resizeRef = useRef<ResizeObserver>();

    const xScale = useRef<d3.ScaleTime<any,any,any>|d3.ScaleLinear<any,any,any>>(xScaleOverride);
    const yScale = useRef<d3.ScaleLinear<number,number,any>>(scaleLinear());
    const xAxis = useRef(axisBottom(xScale.current).tickPadding(6).tickFormat(xAxisFormat));
    const yAxis = useRef(axisLeft(yScale.current).tickPadding(6).tickFormat(yAxisFormat));
    const drawLine = useRef<d3.Line<[number,number]>>(line());

    const zoomRef = useRef<ZoomBehavior<Element,unknown>>(zoom().scaleExtent([1, 100]));
    const zoomTransformRef = useRef<ZoomTransform>(zoomIdentity);

    const [graphWidth, setGraphWidth] = useState(0);
    const [graphHeight, setGraphHeight] = useState(0);
    const [focusOn, setFocusOn] = useState<FocusOn>();

    const update = useCallback(({ transform }: { transform: ZoomTransform }) => {
        zoomTransformRef.current = transform;
        plotGRef.current?.setAttribute('transform', transform.toString());

        var newX = transform.rescaleX(xScale.current);
        var newY = transform.rescaleY(yScale.current);

        xAxis.current
            .scale(newX)
            .tickSize(-newY.range()[0]);
        yAxis.current
            .scale(newY)
            .tickSize(-newX.range()[1]);
        if (yTicks) yAxis.current.tickValues(yTicks(newY.domain()) as any);
        select(xAxisGRef.current).call(xAxis.current as any)
        select(yAxisGRef.current).call(yAxis.current as any)
    }, [yTicks]);

    const zoomEnd = useCallback(({ transform }: { transform: ZoomTransform }) => {
        selectAll('circle').transition().attr('r', 4 / transform.k);
        const pathWidth = `${1 / transform.k}px`;
        selectAll('.zoomPath').transition().attr('stroke-width', pathWidth);
    }, []);

    const resize = useCallback(() => {
        const { current: svg } = svgRef;
        if (!svg) { return; }
        const { clientWidth: width, clientHeight: height } = svg;
        const currentGraphWidth = width - margins.left - margins.right;
        const currentGraphHeight = height - margins.top - margins.bottom;
        
        xScale.current.range([0, currentGraphWidth]);
        yScale.current.range([currentGraphHeight, 0]);

        const zoomExtents: [[number,number],[number,number]] = [[0, 0], [currentGraphWidth, currentGraphHeight]];
        zoomRef.current
            .extent(zoomExtents)
            .translateExtent(zoomExtents);
        setGraphWidth(currentGraphWidth);
        setGraphHeight(currentGraphHeight);
        update({ transform: zoomTransformRef.current });
        drawLine.current
            .x((a: any) => xScale.current(a[0]))
            .y((a: any) => yScale.current(a[1]))
    }, [update, margins]);

    useEffect(() => {
        zoomRef.current.on('zoom', update).on('end', zoomEnd);
        select(zoomContainerRef.current).call(zoomRef.current as any);
    }, [resize, update, zoomEnd]);

    useEffect(() => {
        const xDomain = extent(series.reduce((a: number[], s) => {
            return a.concat(s.data.map((d: any) => d[0]));
        }, series.length === 0 ? [0] : []));
        xScale.current.domain(xDomain as any);
        const yDomain = extent(series.reduce((a: number[], s) => {
            return a.concat(s.data.map((d: any) => d[1]));
        }, series.length === 0 ? ([0]) : []));
        yScale.current.domain(yDomain as any);
    }, [series, update]);

    useEffect(() => {
        if (!resizeRef.current) {
            resizeRef.current = new ResizeObserver(resize);
        }
        const { current: currentSvgRef } = svgRef;
        if (currentSvgRef) {
            resizeRef.current.observe(currentSvgRef);
        }
        resize();
        return () => {
            if (resizeRef.current && currentSvgRef) {
                resizeRef.current?.unobserve(currentSvgRef);
            }
        }
    }, [resize]);
    
    const xFocus = zoomTransformRef.current.rescaleX(xScale.current)(focusOn?.x || 0);
    const yFocus = zoomTransformRef.current.rescaleY(yScale.current)(focusOn?.y || 0);
    const focusTranslate = `translate(${xFocus},${yFocus})`;
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
        }}>
            <div style={{ flexGrow: 1, width: '100%', height: '100%' }}>
                {hoverText && <OverlayTrigger
                    overlay={(
                        <UpdatingTooltip
                            id="updating-focus"
                        >
                            {focusOn?.text}
                        </UpdatingTooltip>
                    )}
                    show={focusOn !== undefined}
                    placement={yFocus < 100 ? 'bottom' : 'top'}
                >
                    <div
                        style={{
                            position: 'fixed',
                            left: xFocus + margins.left - 15,
                            top: yFocus + margins.top - 15,
                            width: '18px',
                            height: '40px',
                            pointerEvents: 'none',
                        }}
                    />
                </OverlayTrigger>}
                <svg
                    width="100%"
                    height="100%"
                    style={svgStyle}
                    ref={svgRef}
                >
                    <g
                        transform={`translate(${margins.left},${margins.top})`}
                        ref={gRef}
                    >
                        <defs>
                            <clipPath id="clip">
                                <rect
                                    width={graphWidth}
                                    height={graphHeight}
                                    x={0}
                                    y={0}
                                />
                            </clipPath>
                        </defs>
                        {titleText && (<text
                                textAnchor="middle"
                                fontSize="30px"
                            x={graphWidth / 2}
                            y={-15}
                            children={titleText}
                        />
                        )}
                        {
                            lines && lines.map((line, index) => {
                                return line.label && (<text
                                    key={`${line.label}-${index}`}
                                    style={{ paintOrder: 'stroke' }}
                                    stroke="#FFF"
                                    strokeWidth="2px"
                                    fontSize="18px"
                                    fontWeight="bold"
                                    fill={line.color}
                                    x={8}
                                    y={26 * (index + 1)}
                                >
                                    {line.label}
                                </text>)
                            })
                        }
                        <g
                            ref={xAxisGRef}
                            className="axis"
                            transform={`translate(0, ${graphHeight})`}
                        />
                        <g
                            ref={yAxisGRef}
                            className="axis"
                        />
                        <rect
                            width={graphWidth}
                            height={graphHeight}
                            fill="none"
                            pointerEvents="all"
                            ref={zoomContainerRef}
                        />
                        <g display={focusOn === undefined ? 'none' : 'inherit'}>
                            <line
                                className="focus"
                                stroke="red"
                                strokeDasharray="3,3"
                                y1={0}
                                y2={graphHeight - (yFocus)}
                                transform={focusTranslate}
                            />
                            <line
                                className="focus"
                                stroke="blue"
                                strokeDasharray="3,3"
                                x1={0}
                                x2={xFocus}
                                transform={`translate(0,${yFocus})`}
                            />
                        </g>
                        <g
                            clipPath="url(#clip)"
                        >
                            <g ref={plotGRef}>
                                {series.map((s) => {
                                    const color = colors && colors(s.name);
                                    return <g
                                        key={s.name}
                                    >
                                        {s.data.map((d,i) => {
                                            const cx = xScale.current(d[0]);
                                            const cy = yScale.current(d[1]);
                                            return <circle
                                                key={`${s.name}-${i}`}
                                                fill={color}
                                                r="4"
                                                cx={cx}
                                                cy={cy}
                                                onMouseOver={(event: React.MouseEvent<SVGGElement, MouseEvent>) => {
                                                    setFocusOn({ x: d[0], y: d[1], text: (hoverText && hoverText(d)) || undefined });
                                                    select(event.currentTarget).attr('r', 15 / zoomTransformRef.current.k).transition();
                                                }}
                                                onMouseOut={(event: React.MouseEvent<SVGGElement, MouseEvent>) => {
                                                    setFocusOn(undefined);
                                                    select(event.currentTarget).attr('r', 4 / zoomTransformRef.current.k).transition();
                                                }}
                                                onMouseDown={() => {
                                                    onClick && onClick(d);
                                                }}
                                            />
                                        })}
                                    </g>
                                })}
                                {lines && lines.map((line, index) => <g key={`line-${index}`}>
                                    <AnimatedPath
                                        className="zoomPath"
                                        stroke={line.color}
                                        fill="none"
                                        strokeWidth="1px"
                                        d={drawLine.current(line.data) || ''}
                                    />
                                </g>)}
                            </g>
                        </g>
                        <g display={focusOn === undefined ? 'none' : 'inherit'}>
                            <text
                                dx={5}
                                dy="-.3em"
                                transform={`translate(${xFocus},${graphHeight})`}
                            >
                                {
                                    (xAxisFormat || ((a: any) => a instanceof Date ? a.toLocaleDateString() : a.toString()))(focusOn?.x || 0)}
                            </text>
                            <text
                                dx={5}
                                dy="-.3em"
                                transform={`translate(0,${yFocus})`}
                            >
                                {yAxisFormat(focusOn?.y || 0)}
                            </text>
                            <g
                                transform={focusTranslate}
                            >
                                
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <Swatches
                columns="180px"
                colors={colors}
                domain={series.map(s => s.name)}
                filteredCategories={[]}
                swatchClicked={() => {}}
                marginLeft={margins.left}
                circular={true}
            />
        </div>      
    );
}

export default SimplyPlot;