import { COORDINATE_SYSTEM, WebMercatorViewport } from "@deck.gl/core";
import SlopeAngleSimpleMeshLayer from "./SlopeAngleLayer/SlopeAngleSimpleMeshLayer";
import { load } from '@loaders.gl/core';
import { LoaderOptions, MapboxTerrainLoader } from "./MapboxTerrainLoader";
import { TerrainLayer, TileLayer } from '@deck.gl/geo-layers';
import { TerrainLayerProps, Tile } from "src/types/deck.gl";
import { AspectColors } from ".";
import { getURLFromTemplate } from "src/lib/Utils/Maps";

const DUMMY_DATA = [1];

export const GetBoundsFromTile = (tile: Tile) => {
  const {bbox, index: { z }} = (tile as any);
  const viewport = new WebMercatorViewport({
    longitude: (bbox.west + bbox.east) / 2,
    latitude: (bbox.north + bbox.south) / 2,
    zoom: z
  });
  const bottomLeft = viewport.projectFlat([bbox.west, bbox.south]);
  const topRight = viewport.projectFlat([bbox.east, bbox.north]);
  const bounds: [number,number,number,number] = [bottomLeft[0], bottomLeft[1], topRight[0], topRight[1]];
  return bounds;
}

// Turns array of templates into a single string to work around shallow change
const urlTemplateToUpdateTrigger = (template: string|string[]) => {
  if (Array.isArray(template)) {
    return template.join(';');
  }
  return template;
}

export interface SlopeAngleTerrainProps extends TerrainLayerProps {
  aspectColors: AspectColors;
  slopeCutoffRange: number[];
  slopeMaskEnabled: boolean;
  slopeAspectEnabled: boolean;
  contourIncrement: number;
  contourIncrementSmall: number;
  terrainOpacity: number;
  slopeAspectOpacity: number;
  slopeMaskOpacity: number;
  maxZoom: number;
};

interface LoadTerrainProps {
  elevationData: any;
  bounds: [number,number,number,number];
  elevationDecoder: any;
  meshMaxError: number;
  workerUrl: any;
  tile: Tile;
}

export default class SlopeAngleTerrainLayer extends TerrainLayer<any> {
    loadTerrain({elevationData, bounds, elevationDecoder, meshMaxError, workerUrl, tile}: LoadTerrainProps) {
        if (!elevationData) {
          return null;
        }
        const options: LoaderOptions = {
          tile,
          terrain: {
            bounds,
            meshMaxError,
            elevationDecoder
          },
        };
        if (workerUrl !== null) {
          options.terrain.workerUrl = workerUrl;
        }
        return load(elevationData, MapboxTerrainLoader, options);
    }
    
    getTiledTerrainData(tile: Tile) {
        const {elevationData, texture, elevationDecoder, meshMaxError, workerUrl} = (this as any).props;
        const dataUrl = getURLFromTemplate(elevationData, (tile as any).index);
        const textureUrl = getURLFromTemplate(texture, (tile as any).index);
        const bounds = GetBoundsFromTile(tile);
    
        const terrain = this.loadTerrain({
          elevationData: dataUrl,
          bounds,
          elevationDecoder,
          meshMaxError,
          workerUrl,
          tile,
        });
    
        const surface = textureUrl
          ? // If surface image fails to load, the tile should still be displayed
            load(textureUrl, []).catch(_ => null)
          : Promise.resolve(null);
    
        return Promise.all([terrain, surface]);
    }
    
    renderSubLayers(props: any) {
        const {data, color} = props;

        if (!data) {
          return null;
        }
    
        const [mesh, texture] = data;
        const altSimpleMesh = new SlopeAngleSimpleMeshLayer(props, {
          data: DUMMY_DATA,
          mesh,
          texture,
          coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
          getPosition: (_: any) => [0, 0, 0],
          getColor: color,
        });
        return altSimpleMesh;
    }

    renderLayers() {
      const {
        color,
        material,
        elevationData,
        texture,
        wireframe,
        meshMaxError,
        elevationDecoder,
        tileSize,
        maxZoom,
        minZoom,
        extent,
        maxRequests,
        aspectColors,
        slopeCutoffRange,
        slopeMaskEnabled,
        slopeAspectEnabled,
        slopeAngleEnabled,
        contourIncrement,
        contourIncrementSmall,
        terrainOpacity,
        slopeMaskOpacity,
        slopeAspectOpacity,
        slopeAngleOpacity,
      } = (this as any).props;

      return new (TileLayer as any)(
        (this as any).getSubLayerProps({
          id: 'tiles'
        }),
        {
          wireframe,
          aspectColors,
          slopeCutoffRange,
          slopeMaskEnabled,
          slopeAspectEnabled,
          slopeAngleEnabled,
          contourIncrement,
          contourIncrementSmall,
          color,
          material,
          getTileData: this.getTiledTerrainData.bind(this),
          renderSubLayers: this.renderSubLayers.bind(this),
          updateTriggers: {
            getTileData: {
              elevationData: urlTemplateToUpdateTrigger(elevationData),
              texture: urlTemplateToUpdateTrigger(texture),
              meshMaxError,
              elevationDecoder
            }
          },
          onViewportLoad: (this as any).onViewportLoad.bind(this),
          zRange: (this as any).state.zRange || null,
          tileSize,
          maxZoom,
          minZoom,
          extent,
          maxRequests,
          pickable: true,
          terrainOpacity,
          slopeMaskOpacity,
          slopeAspectOpacity,
          slopeAngleOpacity,
        }
      );
    }
}

(SlopeAngleTerrainLayer as any).layerName = 'SlopeAngleTerrain';