import mapboxgl from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import { GetMapboxStyleUrl, MapboxStyle } from 'src/lib/Utils/Maps';
import SlopeAngleMap from './SlopeAngleMap';

export interface RGBColor {
    r: number;
    g: number;
    b: number;
}

export type CardinalDirections = 'north'|'east'|'south'|'west';

export interface AspectColors {
    north: RGBColor;
    east:  RGBColor;
    south: RGBColor;
    west:  RGBColor;
}

const SlopeAngleWrapper: React.FC = () => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const map = useRef<mapboxgl.Map>();
    const [mapIsReady, setMapIsReady] = useState(false);

    useEffect(() => {
        if (mapContainer.current && !map.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: GetMapboxStyleUrl(MapboxStyle.SKIING),
                hash: true,
            });
            map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
            map.current.addControl(new mapboxgl.GeolocateControl({
                positionOptions: { enableHighAccuracy: true },
                trackUserLocation: true,
                showUserLocation: true,
            }), 'bottom-right');
            map.current.on('load', () => {
                setMapIsReady(true);
            });
            return () => {
                if (map.current) {
                    map.current.remove();
                    setMapIsReady(false);
                    map.current = undefined;
                }
            }
        }
        return;
    }, []);

    return (
        <>
            <div
                style={{ width: '100%', height: '100%' }}
                ref={mapContainer}
            />
            {
                mapIsReady && map.current && (
                    <SlopeAngleMap
                        map={map.current}
                    />
                )
            }
        </>
    );
}

export default SlopeAngleWrapper;