import * as React from 'react';
import * as AWS from 'aws-sdk';
import { Row, Col, FormGroup, Form, FormControl } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { RouteComponentProps } from 'react-router-dom';
import Album from './Album';
import AlbumCover from './AlbumCover';

interface Props extends RouteComponentProps {
    albumName?: string;
}

interface State {
    albums: AWS.S3.CommonPrefix[],
    loggedIn: boolean;
    password: string;
}

const AWS_API_VERSION = '2006-03-01';
const ALBUM_BUCKET_NAME = 'photos.jrgrover';

export default class Photos extends React.Component<Props> {
    private s3: AWS.S3|null = null;

    public state: State = {
        albums: [],
        loggedIn: false,
        password: '',
    };

    componentDidMount = () => {
        document.body.style.backgroundColor = '#111';

        // Initialize the Amazon Cognito credentials provider
        AWS.config.region = 'us-west-2'; // Region

        const passMaybe = window.localStorage.getItem('letmein');
        if (passMaybe) {
            this.setState({ password: passMaybe });
            this.tryToGetIdentityPoolId(passMaybe);
        }
    }

    componentWillUnmount = () => {
        document.body.style.backgroundColor = 'inherit';
    }

    private tryToGetIdentityPoolId = async (password: string) => {
        // TODO: Add headers here
        window.localStorage.setItem('letmein', password);
        const authorizationToken = btoa(`test:${password}`);
        const headers = new Headers({
            'Authorization': `Basic ${authorizationToken}`,
        });

        const identityPoolIdResult = await (await fetch('https://jrgrover.com/api/photos', { headers })).json();
        if (identityPoolIdResult && identityPoolIdResult.identityPoolId) {
            this.initializeAWSConfig(identityPoolIdResult.identityPoolId);
        }
    }

    private initializeAWSConfig = async (IdentityPoolId: string) => {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId,
        });
        this.s3 = new AWS.S3({
            apiVersion: AWS_API_VERSION,
            params: {
                Bucket: ALBUM_BUCKET_NAME,
            }
        });
        this.setState({ loggedIn: true });
        this.listAlbums();
    }

    private listAlbums = async () => {
        const bucketObjects = await this.s3?.listObjectsV2({
            Delimiter: '/',
            Bucket: ALBUM_BUCKET_NAME,
            Prefix: 'original/',
            StartAfter: 'original/'
        }, undefined).promise();

        this.setState({ albums: bucketObjects?.CommonPrefixes });
    }
    
    public render() {
        const { albums, loggedIn, password } = this.state;
        const { albumName } = this.props.match.params as any;

        const albumListItems = albumName
            ? null
            : (
                <Row>
                    {
                        albums.map((album, i) => {
                            const albumName = album.Prefix?.replace('original/', '').replace('/', '');
                            return (albumName && this.s3) ?
                                (
                                    <AlbumCover
                                        key={albumName}
                                        album={albumName}
                                        bucket={ALBUM_BUCKET_NAME}
                                        s3={this.s3}
                                    />
                                )
                                : null;
                        })
                    }
                </Row>
            );

        const albumHeader = albumName
            ? albumName : 'Albums';

        if (!loggedIn) {
            return (
                <Container>
                    <Row>
                        <Col sm={12}>
                            <img
                                style={{
                                    filter: 'invert(1)',
                                    maxHeight: '300px',
                                    margin: '10px auto',
                                    display: 'block',
                                }}
                                src="/img/photos/enter.png"
                                alt="Enter"
                            />
                        </Col>
                        <Col sm={{ span: 4, offset: 4 }}>
                            <Form
                                onSubmit={(e: React.FormEvent<HTMLElement>) => {
                                    e.preventDefault();
                                    this.tryToGetIdentityPoolId(password);
                                }}
                            >
                                <FormGroup>
                                    <FormControl
                                        type="password"
                                        value={password}
                                        onChange={(e) => this.setState({ password: e.currentTarget.value })}
                                    />
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            );
        }

        return (
            <Container fluid={true}>
                <Row>
                    <Col sm={12}>
                        <h1 style={{ textAlign: 'center', color: '#EEE' }}>
                            {albumHeader}
                        </h1>
                        {(albumName && loggedIn && this.s3)
                            ? (
                                <Album
                                    name={albumName}
                                    s3={this.s3}
                                    bucket={ALBUM_BUCKET_NAME}
                                />
                            )
                            : albumListItems
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
};

