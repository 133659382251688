import * as React from 'react';
import { INFLATION_MAP } from './Inflation';
import * as d3 from 'd3';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWave, faSignal } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import SimplyGraph, { GraphLine } from 'src/lib/d3/SimplyGraph';

interface MovieDatum {
    x: number;
    y: number;
    inflation?: number;
    inflationcum?: number;
    cum?: number;
}

interface MovieData {
    year: string;
    title: string;
    dailyGross: MovieDatum[];
}

const MARGINS = {
    top: 90,
    right: 90,
    bottom: 80,
    left: 80,
};

const HighestGrossingFilms: React.FC = () => {
    const [inflation, setInflation] = useState(false);
    const [cumulative, setCumulative] = useState(false);
    const [data, setData] = useState<GraphLine<any>[]>([]);
    const [maxOneDayGross, setMaxOneDayGross] = useState(160);

    const loadData = useCallback(async () => {
        const data: MovieData[] = await (await fetch('/data/HighestGrossingFilms.json')).json();
        for (var i in data) {
            const ticketPrice = INFLATION_MAP[data[i].year]

            let curry = 0;
            let inflationCurry = 0;
            for (var j in data[i].dailyGross) {
                curry += data[i].dailyGross[j].y
                const inflation = (data[i].dailyGross[j].y / ticketPrice) * INFLATION_MAP[2016]
                inflationCurry += inflation
                data[i].dailyGross[j].inflation = inflation
                data[i].dailyGross[j].inflationcum = inflationCurry
                data[i].dailyGross[j].cum = curry
            }
        }
        setData(data.map(datum => {
            return {
                name: datum.title,
                dataPoints: datum.dailyGross
            };
        }));
        const dailyGrossMax = Math.max(
            ...data.map(
                d => Math.max(
                    ...d.dailyGross.map(a => a.y)
                )
            )
        );
        setMaxOneDayGross(dailyGrossMax);
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <div style={{
            width: '100%',
            height: '100%',
            margin: '0 auto',
            fontSize: '11px',
            overflow: 'hidden',
        }}>
            <SimplyGraph
                graphLines={data}
                titleText='Movie Domestic (US) Gross Sales'
                margins={MARGINS}
                lineCurve={d3.curveBasis}
                forceXDomain={cumulative ? undefined : [0,100]}
                forceYDomain={cumulative ? undefined : [0,25,maxOneDayGross + 10]}
                yRangeOverride={cumulative ? undefined : [1,0.25,0]}
                yAccessor={cumulative
                    ? inflation
                        ? 'inflationcum'
                        : 'cum'
                    : inflation
                        ? 'inflation'
                        : 'y'}
                xScaleOverride={d3.scaleLinear()}
                yScaleOverride={d3.scaleLinear()}
                yAxisFormat={(a: number) => a}
                xAxisFormat={(a: number) => a}
                xAxisLabel='Days Since Release'
                yAxisLabel='Gross Sales in Millions $USD'
                yRangeRound={cumulative ? undefined : true}
                omitLastDataLabel
            />
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    marginTop: '10px',
                    marginRight: `${MARGINS.right}px`,
                }}
            >
                <div>
                    <Button
                        onClick={() => setInflation(!inflation)}
                        variant={inflation ? 'success' : 'outline-success'}
                        size="sm"
                    >
                        <FontAwesomeIcon icon={faMoneyBillWave} style={{ marginRight: '4px' }}/>
                        Inflation
                    </Button>
                    <Button
                        onClick={() => setCumulative(!cumulative)}
                        variant={cumulative ? 'primary' : 'outline-primary'}
                        size="sm"
                        style={{ marginLeft: '8px' }}
                    >
                        <FontAwesomeIcon icon={faSignal} style={{ marginRight: '4px' }}/>
                        Cumulative
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default HighestGrossingFilms;