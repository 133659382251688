import * as React from 'react';
import { DataViewPropsBase, ActivityData } from './types';
import ActivityModal from './ActivityModal';
import { secondsToString, MileFormatter } from 'src/lib/Utils/Strings';
import * as d3 from 'd3';
import './timeline.css';
import AnimatedPath from 'src/lib/d3/AnimatedPath';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ZoomBehavior, ZoomTransform, select, zoom } from 'd3';

interface Props extends DataViewPropsBase {
}

const MARGINS = { top: 65, right: 30, bottom: 45, left: 60 };

const TimelineV2: React.FC<Props> = ({
    activities,
    athleteId,
    statistics
}) => {
    const resizeRef = useRef<ResizeObserver>();
    const svgRef = useRef<SVGSVGElement>(null);
    const zoomContainerRef = useRef<SVGRectElement>(null);
    const xAxisElement = useRef<SVGGElement>(null);
    const xScale = useRef(d3.scaleTime());
    const bisector = useRef(d3.bisector(function(d: ActivityData) { return d.start; }).right);
    const zoomRef = useRef<ZoomBehavior<Element,unknown>>(zoom().scaleExtent([1, 100]));
    const previousTransformKRef = useRef(0);
    const xReference = useRef(d3.scaleTime());
    const xAxis = useRef(d3.axisBottom(xScale.current).tickPadding(6));

    const [graphHeight, setGraphHeight] = useState(0);
    const [graphWidth, setGraphWidth] = useState(0);
    const [width, setWidth] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [focusDisplay, setFocusDispay] = useState('none');
    const [focusActivity, setFocusActivity] = useState<ActivityData>();
    const [xDomain, setXDomain] = useState<[Date,Date]>([new Date(), new Date()]);
    
    d3.select(xAxisElement.current).call(xAxis.current as any).transition();

    const resize = useCallback(() => {
        const { current: svg } = svgRef;
        if (!svg) { return; }
        const updatedWidth = svg.clientWidth;
        const updatedHeight = svg.clientHeight;
        const updatedGraphWidth = updatedWidth - MARGINS.left - MARGINS.right;
        const updatedGraphHeight = updatedHeight - MARGINS.bottom - MARGINS.top;

        xScale.current.range([0, updatedGraphWidth]);
        xReference.current.range([0, updatedGraphWidth]);
        zoomRef.current
            .extent([[0,0], [updatedGraphWidth, 0]])
            .translateExtent([[0,0], [updatedGraphWidth, 0]]);
        xAxis.current.scale(xScale.current).tickSize(-updatedGraphHeight);

        setWidth(updatedWidth);
        setGraphWidth(updatedGraphWidth);
        setGraphHeight(updatedGraphHeight);
    }, []);

    useEffect(() => {
        if (!resizeRef.current) {
            resizeRef.current = new ResizeObserver(resize);
        }
        const { current: currentSvgRef } = svgRef;
        if (currentSvgRef) {
            resizeRef.current.observe(currentSvgRef);
        }
        
        resize();
        return () => {
            if (resizeRef.current && currentSvgRef) {
                resizeRef.current?.unobserve(currentSvgRef);
            }
        }
    }, [resize]);

    const update = useCallback(({ transform }: { transform: ZoomTransform }) => {
        const newXScale = transform.rescaleX(xReference.current);
        xAxis.current.scale(newXScale);
        xScale.current = newXScale;
        d3.select(xAxisElement.current).call(xAxis.current as any).transition();
        if (previousTransformKRef.current === transform.k) {
            setXDomain(xScale.current.domain() as [Date,Date]);
        }
        previousTransformKRef.current = transform.k;
    }, []);

    const zoomEnd = useCallback(({ transform }: { transform: ZoomTransform }) => {
        setXDomain(xScale.current.domain() as [Date,Date]);
    }, []);

    useEffect(() => {
        zoomRef.current.on('zoom', update).on('end', zoomEnd);
        select(zoomContainerRef.current).call(zoomRef.current as any);
    }, [resize, update, zoomEnd]);

    useEffect(() => {
        if (activities.length === 0) return;
        const startDate = activities[0].start;
        const endDate = activities[activities.length - 1].start;
        xScale.current.domain([startDate, endDate]);
        xReference.current.domain(xScale.current.domain());
        setXDomain(xScale.current.domain() as [Date,Date]);
    }, [activities]);

    const thirdHeight = graphHeight / 3;

    return xScale && (<>
        <svg
            width="100%"
            height="100%"
            style={{ position: 'absolute' }}
            ref={svgRef}
        >
            <g
                ref={xAxisElement}
                transform={`translate(${MARGINS.left},${MARGINS.top + graphHeight})`}
                className="timelines"
            />
            <TimelineGraph
                formatter={secondsToString}
                value="duration"
                width={graphWidth}
                height={thirdHeight}
                activities={activities}
                top={MARGINS.top}
                xDomain={xDomain}
                yDomain={[0, statistics.maxDuration + 300]}
                yTickValues={d3.range(1200, statistics.maxDuration + 300, 1200)}
                focusDisplay={focusDisplay}
                focusActivity={focusActivity}
            />
            <TimelineGraph
                formatter={MileFormatter}
                value="distance"
                width={graphWidth}
                height={thirdHeight}
                activities={activities}
                top={MARGINS.top + thirdHeight}
                xDomain={xDomain}
                yDomain={[0, statistics.maxDistance + 1]}
                yTickValues={d3.range(2, statistics.maxDistance, 2)}
                focusDisplay={focusDisplay}
                focusActivity={focusActivity}
            />
            <TimelineGraph
                formatter={secondsToString}
                value="pace"
                width={graphWidth}
                height={thirdHeight}
                activities={activities}
                top={MARGINS.top + (thirdHeight * 2)}
                xDomain={xDomain}
                yDomain={[statistics.minPace - 15, statistics.maxPace + 15]}
                focusDisplay={focusDisplay}
                focusActivity={focusActivity}
            />
            <text
                textAnchor="middle"
                fontSize="30px"
                x={width / 2}
                y={10 + (MARGINS.top / 2)}
            >
                Duration, Distance, and Pace
            </text>
            <rect
                ref={zoomContainerRef}
                transform={`translate(${MARGINS.left}, ${MARGINS.top})`}
                onMouseOver={() => { setFocusDispay('block'); }}
                onMouseOut={() => { setFocusDispay('none'); }}
                onMouseMove={(event: React.MouseEvent<SVGRectElement>) => {
                    const rect = event.currentTarget.getBoundingClientRect();
                    const xPosition = event.clientX - rect.left;

                    const xDate = +xScale.current.invert(xPosition);
                    const index = bisector.current(activities, new Date(xDate));

                    setFocusActivity(activities[index]);
                }}
                onClick={() => {
                    setShowModal(true);
                }}
                width={graphWidth}
                height={graphHeight}
                fill="none"
                pointerEvents="all"
            />
        </svg>
        <ActivityModal
            activity={focusActivity}
            showModal={showModal}
            onHide={() => { setShowModal(false) }}
            athleteId={athleteId}
        />
    </>);
}

export default TimelineV2;

type DataType = 'distance'|'pace'|'duration';

interface TimelineGraphProps {
    formatter: (input: any) => string;
    value: DataType;
    height: number;
    width: number;
    top: number;
    activities: ActivityData[];
    xDomain: [Date, Date];
    yDomain: [number, number];
    yTickValues?: any;
    focusDisplay: string;
    focusActivity?: ActivityData;
}

const TimelineGraph: React.FC<TimelineGraphProps> = ({
    formatter,
    value,
    height,
    width,
    top,
    activities,
    yDomain,
    xDomain,
    yTickValues,
    focusDisplay,
    focusActivity,
}) => {
    const yAxisElement = useRef<SVGGElement>(null);
    const yScale = useRef(d3.scaleLinear());
    const xScale = useRef(d3.scaleTime());
    const yAxis = useRef(d3.axisLeft(yScale.current).tickPadding(6).tickFormat(formatter));
    const line = useRef(d3.line()
        .curve(d3.curveLinear)
        .x((a: any) => xScale.current(a.start))
        .y((a: any) => yScale.current(a[value])));

    xScale.current.domain(xDomain).range([0, width]);
    yScale.current.domain(yDomain).range([height, 0]);
    yAxis.current.tickSize(-width).scale(yScale.current);
    if (yTickValues) {
        yAxis.current.tickValues(yTickValues);
    }
    d3.select(yAxisElement.current).call(yAxis.current as any);

    return (
        <g
            width="100%"
            height="100%"
            transform={`translate(${MARGINS.left},${top})`}
        >
            <defs>
                <clipPath
                    id={`clip${value}`}
                >
                    <rect
                        width={width}
                        height={height}
                    />
                </clipPath>
            </defs>
            <g
                ref={yAxisElement}
                className={`${value} timelines`}
            />
            <AnimatedPath
                className={value}
                clipPath={`url(#clip${value})`}
                strokeWidth={1.5}
                fill="none"
                d={line.current(activities as any) || ''}
            />
            <text
                x={5}
                y={MARGINS.top / 3}
                textAnchor="left"
                fontSize="20px"
            >
                {`${value.charAt(0).toUpperCase()}${value.slice(1)}`}
            </text>
            <g
                display={focusActivity ? focusDisplay : 'none'}
                transform={`translate(${focusActivity ? xScale.current(focusActivity.start) : 0},${yScale.current(focusActivity ? focusActivity[value] : 0)})`}
            >
                <circle
                    r={4.5}
                    className={value}
                    fill="none"
                />
                <text
                    y={14}
                    dy=".35em"                   
                >
                    {formatter(focusActivity ? focusActivity[value] : 0)}
                </text>
            </g>
        </g>
    );
}