import * as React from 'react';
import * as d3 from 'd3';
import { secondsToStringD3 } from 'src/lib/Utils/Strings';
import * as THREE from 'three';
import { RacerData, Races, RACE_COLORS } from '.';
import memoize from "memoize-one";

/*
const DASH_ARRAY = [
    '',
    '2 4',
    '3 3',
    '4 2',
    '5 1',
    ''
];
*/

const VERTEX_SHADER = `
uniform float size;
attribute vec3 customColor;
varying vec3 vColor;
void main() {
  vColor = customColor;
  vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
  gl_PointSize = size * ( 300.0 / -mvPosition.z );
  gl_Position = projectionMatrix * mvPosition;
}
`;

const FRAGMENT_SHADER = `
float circle(vec2 p, float radius) {
  return length(p) - radius;
}

varying vec3 vColor;
void main() {
  float d = circle(gl_PointCoord - vec2(0.5), 0.3);
  d = smoothstep(0.0, 0.005, 0.0 - d);
  gl_FragColor = vec4(d * vColor, d);
  if ( gl_FragColor.a < ALPHATEST ) discard;
}
`;

interface Domains {
    minYear: number;
    maxYear: number;
    minTime: number;
    maxTime: number;
}

interface Props {
    data: RacerData[];
    stars: number;
    domains: Domains;
}

const MARGINS = {
    top: 15,
    right: 20,
    bottom: 55,
    left: 70,
}

// const COLOR = d3.interpolateHsl("#19A0E3", "#0A3D56");

interface RaceDatum {
    race: Races;
    year: number;
    time: number;
}

export default class Viewer extends React.PureComponent<Props> {
    private xScale = d3.scaleLinear();
    private yScale = d3.scaleLinear();
    private xAxis = d3.axisBottom(this.xScale).tickFormat(d => `'${d.toString().substr(-2)}`);
    private yAxis = d3.axisLeft(this.yScale).tickFormat(secondsToStringD3).tickValues(d3.range(2 * 60 * 60, 11 * 60 * 60, 30 * 60));
    private xAxisElement: React.RefObject<SVGGElement>;
    private yAxisElement: React.RefObject<SVGGElement>;
    private container: React.RefObject<HTMLDivElement>;
    private threeElement: React.RefObject<HTMLDivElement>;
    private renderer = new THREE.WebGLRenderer({alpha: true, antialias: true});
    private camera = new THREE.OrthographicCamera(0, 1, 1, 0, 1, 100);
    private scene = new THREE.Scene();

    public state = {
        width: 0,
        height: 0,
        graphWidth: 0,
        graphHeight: 0,
    };

    public constructor(props: any) {
        super(props);
        this.xAxisElement = React.createRef();
        this.yAxisElement = React.createRef();
        this.container = React.createRef();
        this.threeElement = React.createRef();

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.camera.position.z = 100;
        this.camera.lookAt(this.scene.position);

        // this.mouse = new THREE.Vector2();
        // document.addEventListener( 'mousemove', onDocumentMouseMove, false );
    }

    public componentDidMount() {
        // Where should this go?
        this.threeElement.current?.appendChild(this.renderer.domElement);
        
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    public setDimensions = () => {
        this.xScale.range([0, this.state.graphWidth]);
        this.yScale.range([this.state.graphHeight, 0]);
        
        this.xAxis.scale(this.xScale);
        this.yAxis.scale(this.yScale);
    }

    private handleResize = () => {
        const width = this.container.current?.offsetWidth || 0;
        const height = this.container.current?.offsetHeight || 0;
        const graphWidth = width - MARGINS.left - MARGINS.right;
        const graphHeight = height - MARGINS.top - MARGINS.bottom;

        this.camera.updateProjectionMatrix();
        this.renderer.setSize(graphWidth, graphHeight);

        this.setState({
            width,
            height,
            graphWidth,
            graphHeight,
        });
        this.animate();
    }

    private animate = () => {
        var self = this;
        const selfAnimate = () => {
            self.animate();
        }
        requestAnimationFrame(selfAnimate);
        this.renderThree();
    }

    private renderThree = () => {
        this.renderer.render(this.scene, this.camera);
    }

    private initializeThree = (data: RacerData[]) => {
        const nonNull: RaceDatum[][] = data.map(datum => {
            return Object.keys(datum.races)
                .filter(race => datum.races[(race as Races)] !== null)
                .map(race => {
                    const raceResult = datum.races[(race as Races)];
                    const raceDatum: RaceDatum = {
                    race: race as Races,
                    time: raceResult?.time || 0,
                    year: raceResult?.year || 0,
                    };
                    return raceDatum;
                })
                .sort((a, b) => {
                    if (a.year < b.year) return 1;
                    else if (a.year > b.year) return -1;
                    return 0;
                });
        });

        const races = nonNull.reduce(
            (a: RaceDatum[], racerData) => {
                for (var i = 0; i < racerData.length; i++) {
                    a.push(racerData[i]);
                }
                return a;
            },
            []);
        const positions = new Float32Array(races.length * 3);
        const colors = new Float32Array(races.length * 3);

        for (var i = 0; i < positions.length; i += 3) {
            const point = races[i/3];
            positions[i] = point.year;
            positions[i+1] = point.time;
            positions[i+2] = 2;
            
            const c = new THREE.Color(RACE_COLORS[point.race].fill.toString());

            colors[i] = c.r;
            colors[i+1] = c.g;
            colors[i+2] = c.b;
        }

        const geometry = new THREE.BufferGeometry()
        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
        geometry.setAttribute('customColor', new THREE.BufferAttribute( colors, 3 ));

        const material = new THREE.ShaderMaterial({
            uniforms: {
            size: { value: 5.0 }
            },
            vertexShader:   VERTEX_SHADER,
            fragmentShader: FRAGMENT_SHADER,
            alphaTest: 0.9
        });

        const particles = new THREE.Points(geometry, material)
        this.scene.add(particles)
        geometry.computeBoundingSphere();
        (geometry.attributes.position as any).needsUpdate = true // Important!

        const lineIndicies: number[][] = Array.apply(null, Array(6)).map(function () { return [] });

        var currentPoint = 0;

        for (var nonNullIndex = 0; nonNullIndex < nonNull.length; nonNullIndex++) {
            var runner = nonNull[nonNullIndex];
            var lineIndexes = lineIndicies[runner.length - 1];
            for (var j = 1; j < runner.length; j++) {
            lineIndexes.push(currentPoint);
            currentPoint = currentPoint + 1;
            lineIndexes.push(currentPoint);
            }
            currentPoint = currentPoint + 1;
        }

        var newColors = d3.scaleOrdinal(d3.schemeCategory10);
        var self = this;
        lineIndicies.forEach(function (i, v) {
            // line material
            const lineMaterial = new THREE.LineBasicMaterial({
                color: new THREE.Color(newColors(v.toString()) as string)
            });
            const lineGeometry = new THREE.BufferGeometry();
            lineGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
            lineGeometry.setIndex(new THREE.BufferAttribute(new Uint16Array(i), 1));
            const line = new THREE.LineSegments(lineGeometry, lineMaterial);
            line.position.z = -1;
            self.scene.add(line);
        });

        this.camera.updateProjectionMatrix();
        this.renderThree();
    }

    private initialize = memoize(this.initializeThree);

    public render() {
        const {
            stars,
            data,
            domains,
        } = this.props;
        const {
            width,
            height,
            graphHeight,
            graphWidth,
        } = this.state;

        // TODO: decide if this shouldn't go here
        this.setDimensions();

        this.xScale.domain([domains.minYear, domains.maxYear]);
        this.yScale.domain([domains.minTime, domains.maxTime]);

        d3.select(this.xAxisElement.current).call(this.xAxis as any);
        d3.select(this.yAxisElement.current).call(this.yAxis as any);

        this.camera.left = domains.minYear;
        this.camera.right = domains.maxYear;
        this.camera.top = domains.maxTime;
        this.camera.bottom = domains.minTime;

        if (data.length !== 0) {
            this.initialize(data);
        }

        return (
            <div
                style={{
                    minWidth: '420px',
                    height: '47.5%',
                    position: 'relative'
                }}
                ref={this.container}
            >
                <div style={{ position: 'absolute' }}>
                    <svg
                        width={width}
                        height={height}
                    >
                        <g
                            transform={`translate(${MARGINS.left},${MARGINS.top})`}
                        >
                            <g>
                                <g
                                    ref={this.xAxisElement}
                                    transform={`translate(0,${graphHeight})`}
                                >

                                </g>
                                <g ref={this.yAxisElement}>

                                </g>
                            </g>
                        </g>
                        <text
                            textAnchor="middle"
                            style={{ fontSize: '1em"'}}
                            x={width / 2}
                            y={height - MARGINS.top}
                        >
                            Year
                        </text>
                        <text
                            textAnchor="middle"
                            style={{ fontSize: '1em' }}
                            transform="rotate(-90)"
                            x={-height / 2}
                            y={MARGINS.left / 3}
                        >
                            Time
                        </text>
                        <text
                            x={width / 2}
                            y={MARGINS.top}
                        >
                            {stars} star finishers: {data?.length}
                        </text>
                    </svg>
                </div>
                <div
                    ref={this.threeElement}
                    style={{
                        width: graphWidth,
                        height: graphHeight,
                        left: MARGINS.left,
                        top: MARGINS.top,
                        position: 'absolute',
                    }}
                />
            </div>
        );
    }
};

