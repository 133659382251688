import { GeoJSON} from "geojson";

interface GPXDataPoint {
    lon: number;
    lat: number;
    altitude: number;
    time: Date;
    heart_rate: number;
    cadence: number;
    temp: number;
}

interface TrackData {
    lon: number;
    lat: number;
    altitude: number;
    time: Date;
    heart_rate: number;
    cadence: number;
    temp: number;
}

export const parseGPXData = (data: string): TrackData[] => {
    console.log('parsing')
    var parser = (new DOMParser()).parseFromString(data, 'text/html')
  
    var trackPoints: any[] = [];
    var trk = parser.getElementsByTagName('trk')[0];
    var pointElementName = 'trkpt';
    if (trk === undefined) {
        trk = parser.getElementsByTagName('rte')[0];
        pointElementName = 'rtept';
    }
    if (trk === undefined) {
        trk = parser.getElementsByTagName('trkseg')[0];
        pointElementName = 'trkpt';
    }

    try {
        trackPoints = Array.from(trk.getElementsByTagName(pointElementName));
    } catch (ex: any) {
        console.warn(`error getting <${pointElementName}> elements, gotta look for something else...`);
        console.log(parser);
    }

    return trackPoints.map(trackPoint => {
        const extensions = trackPoint.getElementsByTagName('extensions')[0];
        const longitudeItem = trackPoint.attributes.getNamedItem('lon');
        const latitudeItem = trackPoint.attributes.getNamedItem('lat');
        const altitudeItem = trackPoint.getElementsByTagName('ele')[0];
        // TODO: change these to find the ns3:hr/ns3:atemp/ns3:cad, etc
        const heartRateItem = extensions?.getElementsByTagName('gpxdata:hr')[0];
        const cadence = extensions?.getElementsByTagName('gpxdata:cadence')[0];
        const temp = extensions?.getElementsByTagName('gpxdata:temp')[0];
        return {
            lon: parseFloat((longitudeItem && longitudeItem.value) || ''),
            lat: parseFloat((latitudeItem && latitudeItem.value) || ''),
            altitude: parseFloat((altitudeItem && altitudeItem.innerHTML) || ''),
            time: new Date(trackPoint.getElementsByTagName('time')[0]?.innerText),
            heart_rate: parseInt((heartRateItem && heartRateItem.nodeValue) || ''),
            cadence: parseInt((cadence && cadence.nodeValue) || ''),
            temp: parseInt((temp && temp.nodeValue) || '')
        }
    });
  }

export const readFileFromFileObject = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => { resolve((fileReader.result as string)) };
        fileReader.readAsText(file);
    })
}

export const convertGPXTrackToGeoJSONFeature = (data: GPXDataPoint[]): GeoJSON => {
    return {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: data.map(d => {
                        return [d.lon, d.lat];
                    }),
                },
                properties: {},
            }
        ]
    }
}