import * as React from 'react';
import { BRACKET_NAMES, Bracket } from './common';
import Histogram from './Histogram';

interface Props {
    chartWidth: number;
    chartHeight: number;
    visibility?: string;
    data: {[key: string]: Bracket};
}

export default class MultiHistogram extends React.Component<Props> {
    public render() {
        const { chartWidth, chartHeight } = this.props;
        
        return (
            <g
                visibility={this.props.visibility}
            >
                {
                    (Object.keys(this.props.data).length !== 0)
                        ? BRACKET_NAMES.map((bracket, index) => {
                        return <Histogram
                            visibility={this.props.visibility}
                            width={chartWidth}
                            height={chartHeight}
                            index={index}
                            key={bracket}
                            data={this.props.data[bracket].bins || []}
                            title={bracket}
                        />
                        })
                        : null
                }
            </g>
        );
    }
}
