import { faExclamationTriangle, faMoon, faQuestion, faShare, faSortNumericDown, faSortNumericUp, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import { Alert, ModalBody, Table } from "react-bootstrap";
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { Button, Col, Container, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import * as d3 from 'd3';
import { SECRET_WORDS } from "./secretWords";
import Confetti from "src/components/Confetti";

const INITIAL_DAY = 19021;
const SEMANTLE_API = 'https://jrgrover.com/api/semantle'

interface Similarity {
    rest: number;
    top: number;
    top10: number;
}

interface Guess {
    similarity: number;
    percentile?: number;
    word: string;
    number: number;
}

interface WordProperties {
    percentile: number;
    vec: number[];
}

const mag = (a: number[]) => {
    return Math.sqrt(a.reduce(function(sum, val) {
        return sum + val * val;
    }, 0));
}

const dot = (f1: number[], f2: number[]) => {
    return f1.reduce(function(sum, a, idx) {
        return sum + a*f2[idx];
    }, 0);
}

const getCosSim = (f1: number[], f2: number[]) => {
    return Math.abs(dot(f1,f2)/(mag(f1)*mag(f2)));
}

type sortType = 'similarity' | 'guess';

const COLORS = {
    night: {
        color: '#DDDDDD',
        backgroundColor: '#121212',
        success: '#018786',
        failure: '#613a90',
    },
    day: {
        success: 'mediumseagreen',
        failure: 'plum',
    }
}

const Semantle: React.FC = () => {
    const [now] = useState(Date.now());
    const [today] = useState(Math.floor(now / 86400000));
    const [secretWord, setSecretWord] = useState<string>();
    const [similarity, setSimilarity] = useState<Similarity>();
    const [gameOver, setGameOver] = useState((window.localStorage.getItem("gameOverState") || 'false') === 'true');
    const [guesses, setGuesses] = useState<Guess[]>(JSON.parse(window.localStorage.getItem("guesses") || '[]'));
    const [darkMode, setDarkMode] = useState((window.localStorage.getItem("darkMode") || 'false') === 'true');
    const [party, setParty] = useState(true);
    const [secretVector, setSecretVector] = useState<number[]>();
    const [guessInput, setGuessInput] = useState("");
    const [puzzleNumber, setPuzzleNumber] = useState<number>();
    const [showHelp, setShowHelp] = useState(false);
    const [showClosest, setShowClosest] = useState(false);
    const [closest, setClosest] = useState<string[]>([]);
    const [latestGuessIndex, setLatestGuessIndex] = useState(0);
    const [isOldGuess, setIsOldGuess] = useState(false);
    const [sort, setSort] = useState<sortType>('similarity');
    const [reverseSort, setReverseSort] = useState(false);
    const [guessing, setGuessing] = useState(false);

    const nightColorRange = useRef(d3.scaleLinear().clamp(true).domain([0, 100])
        .range([COLORS.night.failure, COLORS.night.success] as any));
    const dayColorRange = useRef(d3.scaleLinear().clamp(true).domain([0, 100])
        .range([COLORS.day.failure, COLORS.day.success] as any));

    // const [yesterdayPuzzleNumber, setYesterdayPuzzleNumber] = useState<number>();
    const storage = window.localStorage;
    const firstGuessAboveThousand = guesses.map((a) => { return { number: a.number, percentile: a.percentile }})
        .sort((a, b) => { return a.number - b.number; })
        .find((a) => a.percentile);
    const winningGuess = (secretWord !== undefined && guesses.length > 0) ? guesses.find(guess => guess.word === secretWord) : undefined;
    const guessesToWin = winningGuess && guesses.filter(guess => {
        return guess.number <= winningGuess.number;
    }).length;
    const guessesAfterThousand = winningGuess && firstGuessAboveThousand && guesses.filter(guess => {
        return guess.number > firstGuessAboveThousand.number && guess.number <= winningGuess.number
    }).length;

    const reset = useCallback(() => {
        storage.removeItem('puzzleNumber');
        storage.removeItem('gameOverState');
        storage.removeItem('guesses');
        storage.removeItem('darkMode');
        setGameOver(false);
        setGuesses([]);
    }, [storage]);

    const loadData = useCallback(async () => {
        const t = toast.loading('Loading.');
        var currentPuzzleNumber = (today - INITIAL_DAY) % SECRET_WORDS.length;
        const storagePuzzleNumber = parseInt(storage.getItem("puzzleNumber") || '-1');
        if (storagePuzzleNumber !== currentPuzzleNumber) {
            reset();
            storage.setItem("puzzleNumber", currentPuzzleNumber.toString());
        }
        setPuzzleNumber(currentPuzzleNumber)
        // setYesterdayPuzzleNumber((today - INITIAL_DAY + secretWords.length - 1) % secretWords.length);
        const currentSecretWord = SECRET_WORDS[currentPuzzleNumber].toLowerCase();
        setSecretWord(currentSecretWord);

        const secretStatistics: Similarity = await (await fetch (`${SEMANTLE_API}/similarity/${currentSecretWord}`)).json();
        toast.loading('Loading...', { id: t });
        setSimilarity(secretStatistics);
        dayColorRange.current.domain([secretStatistics.rest * 100, secretStatistics.top * 100]);
        nightColorRange.current.domain([secretStatistics.rest * 100, secretStatistics.top * 100]);

        const currentSecretVector: WordProperties = await (await fetch(`${SEMANTLE_API}/word/${currentSecretWord}/${currentSecretWord}`)).json();
        toast.loading('Loading....', { id: t });
        setSecretVector(currentSecretVector.vec);
        const currentClosest: string[] = await (await fetch(`${SEMANTLE_API}/nearby/${currentSecretWord}`)).json();
        setClosest(currentClosest);
        toast.success('Loaded!', { id: t });
    }, [storage, today, reset]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    // Save game state
    useEffect(() => {
        storage.setItem("gameOverState", gameOver.toString());
        if (guesses.length > 0) {
            storage.setItem("guesses", JSON.stringify(guesses));
        };
        storage.setItem("darkMode", darkMode.toString());
    }, [gameOver, guesses, storage, darkMode]);

    const setSortAndReverse = useCallback((updatedSort: sortType) => {
        setSort(updatedSort);
        if (updatedSort !== sort) {
            setReverseSort(false);
        } else {
            setReverseSort(!reverseSort);
        }
    }, [sort, reverseSort]);

    const makeGuess = useCallback(async () => {
        const currentGuess = guessInput.trim().replace("!", "").replace("*", "");
        if (currentGuess === "" || !secretVector) {
            return;
        }
        const guessExists = guesses.findIndex(guess => {
            return guess.word === currentGuess;
        });

        if (guessExists >= 0) {
            setLatestGuessIndex(guessExists);
            setIsOldGuess(true);
            setGuessInput("");
            return;
        }

        let guessData: WordProperties;
        try {
            setGuessing(true);
            guessData = await (await fetch(`${SEMANTLE_API}/word/${secretWord}/${currentGuess}`)).json();
        } catch (ex: any) {
            toast.error(`The word ${currentGuess} wasn't known!`);
            return;
        } finally {
            setGuessing(false);
        }
        setGuessInput("");

        const { percentile, vec } = guessData;
        const similarity = getCosSim(vec, secretVector);

        const currentGuesses = [...guesses];
        const additionalGuess: Guess = {
            similarity,
            percentile,
            word: currentGuess,
            number: currentGuesses.length + 1,
        };
        currentGuesses.push(additionalGuess);
        currentGuesses.sort((guessA, guessB) => {
            return guessB.similarity - guessA.similarity;
        });
        setGuesses(currentGuesses);
        setLatestGuessIndex(currentGuesses.findIndex(guess => {
            return guess.word === currentGuess;
        }));
        setIsOldGuess(false);
        if (!gameOver && currentGuess === secretWord) {
            setGameOver(true);
        }
    }, [gameOver, guessInput, guesses, secretVector, secretWord]);

    const style: React.CSSProperties = darkMode ? {
        backgroundColor: COLORS.night.backgroundColor,
        color: COLORS.night.color,
    } : {};

    return (
        <div style={{ minHeight: '100%', transition: 'all 0.2s linear', ...style }}>
            <Helmet title="Semantle (but better?)" />
            {party && winningGuess && guessesToWin && <Confetti/>}
            <Container>
                <Row>
                    <Col>
                        <h4 style={{ marginTop: '4px' }}>
                            Semantle <small>(but better<a
                                href="https://meet.google.com/xff-nzmk-ise"
                                style={{
                                    textDecoration: 'none',
                                    color: style.color,
                                }}
                            >?</a>)</small>
                            <div
                                style={{ float: 'right' }}
                            >
                                {(winningGuess && guessesToWin) && (
                                    <>        
                                        <Button
                                            size="sm"
                                            variant={darkMode ? 'dark' : 'light'}
                                            onClick={() => {
                                                setParty(!party);
                                            }}
                                            style={{
                                                marginRight: '2px'
                                            }}
                                        >
                                            <span
                                                style={{
                                                    opacity: party ? 'inherit' : 0.25
                                                }}
                                            >🎉</span>
                                        </Button>
                                    </>
                                )}
                                <Button
                                    size="sm"
                                    variant={darkMode ? 'dark' : 'light' }
                                    onClick={() => {
                                        setDarkMode(!darkMode);
                                    }}
                                    style={{ marginRight: '2px' }}
                                >
                                    <FontAwesomeIcon icon={darkMode ? faSun : faMoon }/>
                                </Button>
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => {
                                        setShowHelp(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faQuestion}/>
                                </Button>
                            </div>
                        </h4>
                    </Col>
                </Row>
                <h6>Today's nearest similarity statistics</h6>
                <Row style={{ marginLeft: 0, marginRight: 0 }}>
                    {similarity && (
                        <>
                            <div style={{ flex: '1 0 auto' }}>
                                <h6>1st: <small>{similarity && (similarity.top * 100).toFixed(2)}</small></h6>
                            </div>
                            <div style={{ flex: '1 0 auto' }}>
                                <h6>10th: <small>{similarity && (similarity.top10 * 100).toFixed(2)}</small></h6>
                            </div>
                            <div style={{ flex: '1 0 auto' }}>
                                <h6>1000th: <small>{similarity && (similarity.rest * 100).toFixed(2)}</small></h6>
                            </div>
                        </>
                    )}
                </Row>
                {gameOver && (
                    <Row>
                        <Col>
                            <Alert
                                variant={winningGuess ? "success" : "warning"}
                                style={(darkMode && winningGuess && {
                                    backgroundColor: '#0b2e13',
                                    color: '#d4edda',
                                }) || (darkMode && {
                                    backgroundColor: '#533f03',
                                    color: '#fff3cd'
                                }) || undefined}
                            >
                                <p>
                                    {(winningGuess && guessesToWin)
                                        ? (<>
                                            You found the secret word <b>"{secretWord}"</b> in {guessesToWin} guess{guessesToWin > 1 && 'es'}!
                                            <Alert.Link
                                                variant="success"
                                                style={(darkMode && winningGuess && { color: '#c3e6cb'})
                                                    || (darkMode && { color: '#ffeeba' }) || undefined
                                                }
                                                size="sm"
                                                onClick={() => {
                                                    var t = toast.loading('Copying to clipboard...');
                                                    try {
                                                        navigator.clipboard.writeText(`I solved Semantle #${puzzleNumber} in ${guessesToWin} guesses.  https://jrgrover.com/semantle`);
                                                        toast.success('Copied!', { id: t });
                                                    } catch (ex: any) {
                                                        toast.error('Failed to copy to clipboard!', { id: t });
                                                    }
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faShare} style={{ margin: '0px 4px' }} />
                                                Share
                                            </Alert.Link>
                                            
                                        </>)
                                        : (<>You gave up! The secret word is <b>"{secretWord}"</b></>)
                                    }
                                </p>
                                <hr/>
                                <p>
                                    Keep guessing if you're curious about other words' similarity to <b>"{secretWord}"</b>.<br/> 
                                    Or <Alert.Link
                                        style={(darkMode && winningGuess && { color: '#c3e6cb'})
                                            || (darkMode && { color: '#ffeeba' }) || undefined
                                        }
                                        onClick={() => setShowClosest(true)}
                                    >
                                        see the 10 closest words to today's word.
                                    </Alert.Link><br/>
                                    Or <Alert.Link
                                        style={(darkMode && winningGuess && { color: '#c3e6cb'})
                                            || (darkMode && { color: '#ffeeba' }) || undefined
                                        }
                                        onClick={() => reset()}
                                    >reset</Alert.Link> the game and play again.
                                </p>
                                {guessesAfterThousand !== undefined && (
                                    <p>
                                        You took {guessesAfterThousand} guesses after finding a word in the top 1000.
                                    </p>
                                )}
                            </Alert>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <InputGroup
                            style={{ marginTop: '4px', marginBottom: '4px' }}
                        >
                            <FormControl
                                disabled={!secretVector || guessing}
                                style={style}
                                as="input"
                                type="text"
                                placeholder="Enter Guess"
                                value={guessInput}
                                onChange={(e) => {
                                    setGuessInput(e.currentTarget.value);
                                }}
                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                    event.preventDefault();
                                    if (event.key === 'Enter') {
                                        makeGuess();
                                    }
                                }}
                                autoCapitalize="off"
                            />
                            <InputGroup.Append>
                                    <Button
                                        disabled={!secretVector || guessing}
                                        onClick={async () => {
                                            makeGuess();
                                        }}
                                    >
                                        {guessing ? 'Thinking' : 'Guess'}
                                    </Button>
                                </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table size="sm" style={{ fontFamily: 'monospace', fontSize: '.9rem' }}>
                            <thead>
                                <tr
                                    style={{ fontSize: '.7rem', color: '#999' }}
                                >
                                    <th
                                        onClick={() => setSortAndReverse('guess')}
                                        style={{ color: (sort === 'guess') ?
                                            darkMode ? '#FFFFFF' : '#000' : 'inherit' }}
                                    >#{<FontAwesomeIcon
                                        icon={reverseSort ? faSortNumericUp : faSortNumericDown}
                                        style={{ marginLeft: '2px' }}
                                    />}</th>
                                    <th>Guess</th>
                                    <th
                                        onClick={() => setSortAndReverse('similarity')}
                                        style={{
                                            textAlign: 'right',
                                            color: (sort === 'similarity') ? darkMode ? '#FFFFFF' : '#000' : 'inherit'
                                        }}
                                    >Similarity{<FontAwesomeIcon
                                        icon={reverseSort ? faSortNumericUp : faSortNumericDown}
                                        style={{ marginLeft: '2px' }}
                                    />}</th>
                                    <th style={{ minWidth: '75px'}}>#/1000</th>
                                </tr>
                            </thead>
                            <tbody>
                                {guesses[latestGuessIndex] && (
                                    <GuessRow
                                        guess={guesses[latestGuessIndex]}
                                        style={{
                                            backgroundColor: isOldGuess ?
                                                darkMode ?
                                                    '#613a90' : 'lavender'
                                                : darkMode ? '#333333' : '#dee2e6',
                                            color: isOldGuess
                                                ? darkMode
                                                    ? 'lavender' : '#cc00cc'
                                                : darkMode ? '#EEEEEE' : 'inherit'
                                        }}
                                        darkMode={darkMode}
                                    />
                                )}
                                {guesses.map(a => {
                                    return {
                                        number: a.number,
                                        percentile: a.percentile,
                                        similarity: a.similarity,
                                        word: a.word,
                                    };
                                }).sort((guessA, guessB) => {
                                    if (sort === 'guess') {
                                        return reverseSort
                                            ? guessA.number - guessB.number
                                            : guessB.number - guessA.number;
                                    } else {
                                        return reverseSort
                                            ? guessA.similarity - guessB.similarity
                                            : guessB.similarity - guessA.similarity;
                                    }
                                }).map((guess) =>
                                    <GuessRow
                                        key={guess.word}
                                        guess={guess}
                                        style={{
                                            color: (guesses[latestGuessIndex].number === guess.number)
                                                ? darkMode
                                                    ? '#BB86FC' : '#cc00cc'
                                                : 'inherit'
                                        }}
                                        darkMode={darkMode}
                                        similarityColor={darkMode
                                            ? nightColorRange.current(guess.similarity * 100)
                                            : dayColorRange.current(guess.similarity * 100) as any}
                                    />
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {!gameOver && (
                    <Row>
                        <Col>
                            <Button
                                variant="outline-danger"
                                onClick={() => {
                                    if (window.confirm("Really give up?")) {
                                        setGameOver(true);
                                    }
                                }}
                                block={true}
                            >
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                Give Up
                            </Button>
                        </Col>
                    </Row>
                )}
            </Container>
            <Modal
                show={showHelp}
                onHide={() => {
                    setShowHelp(false);
                }}
            >
                <ModalHeader closeButton={true} style={style}>
                    <h4>What is this?</h4>
                </ModalHeader>
                <ModalBody style={style}>
                    <p>Semantle is a crazy word game originally hosted <a href="https://semantle.novalis.org/">here</a>.</p>
                    <p>It kind of had a terrible UI so I decided to try to make it a bit better.</p>
                    <p>Here's some paraphrased text from the original about the game:</p>
                    <h6>Guess the secret word</h6>
                    <p> 
                        Each guess must be a word (of any length) or short phrase.
                        The game will tell you how semantically similar it thinks your word is to the secret word.
                        Unlike that other word game, it's not about the spelling; it's about the meaning.
                        The similarity value comes from <a href="https://towardsdatascience.com/word2vec-explained-49c52b4ccb71">Word2vec</a>.
                        The highest possible similarity is 100 (indicating that the words are identical and you have won).
                        "Semantically similar" means roughly "used in the context of similar words, in a database of news articles."
                    </p>
                    <p>
                        Secret words may be any part of speech, but always single words.
                        It's tempting to think only of nouns, since that is how normal semantic word-guessing games work.
                        Don't get caught in the trap! Since the Word2vec data set contains some proper nouns, guesses are case-sensitive.
                        But I removed all but lower-case words from the secret word set, and if your word matches the secret word but for case, you win anyway.
                        So if you want to know if the word is more like nice or Nice (France), you can ask about both.
                    </p>
                    <p>
                        The "Position" indicator tells you how close you are.
                        If your word is one of the 1,000 nearest normal words to the target word, the rank will be given (1000 is the target word itself).
                        If your word is not one of the nearest 1000, you're "cold".
                        (By "normal" words", I mean non-capitalized words that appears in a very large English word list; there are lots of capitalized, misspelled, or obscure words that might be close but that won't get a ranking.). 
                    </p>
                </ModalBody>
            </Modal>
            <Modal
                show={showClosest}
                onHide={() => {
                    setShowClosest(false);
                }}
            >
                <ModalHeader closeButton={true} style={style}>
                    <h4>10 Closest Words to "{secretWord}"</h4>
                </ModalHeader>
                <ModalBody style={style}>
                    <p>This is a list of the 10 closest words to today's target word:</p>
                    <Table size="sm" style={{ fontFamily: 'monospace', fontSize: '.9rem' }}>
                        <thead>
                            <tr
                                style={{ fontSize: '.7rem', color: '#999' }}
                            >
                                <th>Rank</th>
                                <th>Word</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                closest.map((word, index) => {
                                    return (
                                        <tr
                                            key={index}
                                        >
                                            <td>{index + 1}</td>
                                            <td>{word}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default Semantle;

interface GuessRowProps {
    guess: Guess;
    style?: React.CSSProperties;
    darkMode: boolean;
    similarityColor?: string;
}

const GuessRow: React.FC<GuessRowProps> = ({ guess, style, darkMode, similarityColor }) => {
    const { word, similarity, percentile, number } = guess;
    return (
        <tr
            style={{ ...style }}
        >
            <td>
                {number}
            </td>
            <td
                style={{
                    fontSize: word.length > 16
                        ? `${Math.max((16/word.length) * 0.9, 0.5)}rem`
                        : 'inherit',
                    verticalAlign: 'middle',
                }}
            >{word}</td>
            <td
                style={{
                    textAlign: 'right',
                    color: similarityColor,
                }}
            >{(similarity * 100).toFixed(2)}</td>
            <td>
                {percentile && (
                    <div style={{
                        width: '100%',
                        backgroundColor: darkMode ? COLORS.night.failure : COLORS.day.failure,
                        position: 'relative',
                        color: darkMode ? COLORS.night.color : COLORS.night.backgroundColor,
                    }}>
                        <div
                            style={{
                                width: '100%',
                                position: 'absolute',
                            }}
                        >
                            <div style={{ margin: '0px 2px' }}>
                                {percentile === 1000 ? '1000!' : percentile}
                            </div>
                        </div>
                        <div
                            style={{
                                width: `${percentile/10}%`,
                                backgroundColor: darkMode ? COLORS.night.success : COLORS.day.success
                            }}
                        >&nbsp;</div>
                    </div>
                )}
            </td>
        </tr>
    )
}