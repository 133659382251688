import { faCogs, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, ReactElement, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { VisibleToggleSwitch } from 'src/lib/ToggleSwitch';

interface LayerTableRowProps {
    name: string;
    enabled: boolean;
    disabled: boolean;
    onToggleVisible: () => void;
    configuration: ReactElement;
    onOpacityChange: (updatedOpacity: number) => void;
    opacity: number;
    help?: ReactElement;
}

export const LayerTableRow: React.FC<LayerTableRowProps> = ({
    enabled,
    name,
    disabled,
    onToggleVisible,
    onOpacityChange,
    configuration,
    opacity,
    help,
}) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Fragment>
            <tr>
                <td>
                    <VisibleToggleSwitch
                        visible={enabled}
                        onClick={onToggleVisible}
                        disabled={disabled}
                    />
                    <FontAwesomeIcon
                        style={{ margin: 'auto 4px', cursor: 'pointer' }}
                        icon={faCogs}
                        onClick={() => setExpanded(!expanded)}
                    />
                    {name}
                </td>
                <td>
                    <input
                        style={{ width: '100%' }}
                        type="range"
                        max={1}
                        min={0}
                        step={0.01}
                        defaultValue={opacity}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onOpacityChange(parseFloat(event.currentTarget.value));
                        }}
                    />
                </td>
                <td>
                    {help && (
                        <OverlayTrigger
                            overlay={
                                <Tooltip
                                    id={`${name}-popover`}
                                >
                                    {help}
                                </Tooltip>
                            }
                        >
                            <FontAwesomeIcon icon={faQuestionCircle}/>
                        </OverlayTrigger>
                    )}
                </td>
            </tr>
            <tr style={{ display: expanded ? undefined : 'none' }}>
                <td colSpan={3}>
                    {configuration}
                </td>
            </tr>
        </Fragment>
    )
} 