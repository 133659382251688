import * as React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface Props {
    album: string;
    bucket: string;
    s3: AWS.S3;
}

interface State {
    cover?: string;
    endpoint?: string;
    hover: boolean;
}

export default class AlbumCover extends React.PureComponent<Props> {
    public state: State = {
        hover: false,
    };
    
    componentDidMount = () => {
        this.loadCover();
    }

    private loadCover = async () => {
        const prefix = `thumbnail/${this.props.album}/`;
        const thumbnails = await this.props.s3.listObjectsV2({
            Bucket: this.props.bucket,
            Prefix: prefix,
            StartAfter: prefix,
        }, undefined).promise();

        if (thumbnails.Contents) {
            const endpoint = `${(thumbnails.$response as any).request.httpRequest.endpoint.href}${this.props.bucket}/`;
            this.setState({
                endpoint,
                cover: thumbnails.Contents[0].Key,
            });
        }
    }

    render() {
        const { album } = this.props;
        const { endpoint, cover, hover } = this.state;
        const backgroundImage = (cover !== undefined) ? `url("${endpoint}${cover}")` : '';

        return (
            <Col
                sm={2}
                style={{
                    height: '150px',
                    background: `${backgroundImage} no-repeat top center/contain`,
                    marginBottom: '10px',
                }}
            >
                <div
                    style={{ position: 'relative', width: '100%', height: '100%' }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: '#EEE',
                            opacity: hover ? .25 : 0,
                            filter: 'blur(2px)',
                            transition: 'all .3s',
                        }}
                    />
                    <Link
                        to={`/photos/${album}`}
                        onMouseEnter={() => this.setState({ hover: true })}
                        onMouseLeave={() => this.setState({ hover: false })}
                        style={{ color: '#E8E8E8', position: 'absolute', height: '100%', width: '100%' }}
                    >
                        <h5 style={{ textAlign: 'center', backgroundColor: 'rgba(128,128,128,.75)' }}>
                            {decodeURIComponent(album)}
                        </h5>
                    </Link>
                </div>
            </Col>
        );
    }
}