import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CanvasElement, CityDetails, CityWayV2, DataViewPropsBase, GeoJsonToWaypoint, Waypoint, WaypointToGeoJson } from './types';
import mapboxgl, { LngLat, VectorSource } from 'mapbox-gl';
import { EmptyGeoJsonFeatureCollection, DownloadFile, SimplifyPath, MapboxStyle, GetMapboxStyleUrl } from 'src/lib/Utils/Maps';
import { GetAthleteCityCoverageUrl, GetAthleteCityTileUrl, GetAthleteCityWayMapUrl, GetAthleteNodesUpdateUrl, GetCityNodesUrl, GetFromCacheJSON, GetRemainingNodeCountForWay, STRAVA_URL, ToISOStringLocal } from './Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faBorderAll, faChevronLeft, faChevronRight, faCircleNotch, faCity, faDotCircle, faExchangeAlt, faFileExport, faFileVideo, faFire, faLocationArrow, faMap, faMapMarkerAlt, faPause, faPencilRuler, faPlay, faRedoAlt, faRoad, faRoute, faSlash, faSyncAlt, faUndoAlt, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { GetDefaultHeatmapColor, GetWalkingDirectionsForCoordinateString, HeatmapLayerPaint, LongitudeLatitudeCoordinatesAsString, PathPaint, RoadNodesPaint, RunNodesPaint, ThinPath, CITY_MAPPING, TripsData, NODE_RADIUS_PIXELS_FOR_ZOOM, makeTripsLayer, makeTripsHighlightLayer, BASE_ROAD_NODES_CIRCLE_RADIUS, SELECTED_ROAD_NODES_CIRCLE_RADIUS } from './HeatmapUtils';
import { Feature, Position } from 'geojson';
import { GetGeoJSONLineStringForArrayOfLongitudeLatitude } from 'src/lib/Utils/GeoJSON';
import { Deck } from '@deck.gl/core';
import {MapboxLayer} from '@deck.gl/mapbox';
import { LayerToggle } from 'src/projects/Running/MapboxUtils/LayerToggle';
import ExportModal, { GeoPoint } from './MapboxUtils/ExportModal';
import togpx from 'togpx';
import { Route } from '@mapbox/mapbox-sdk/services/directions';
import RouteDetails from './MapboxUtils/RouteDetails';
import toast from 'react-hot-toast';
import simplify from 'simplify-js';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

interface HeatmapV3Props extends DataViewPropsBase {
    map: mapboxgl.Map;
    cache: Cache; 
}

export const HeatmapV3: React.FC<HeatmapV3Props> = ({ athleteId, activities, map, cache }) => {
    // UI
    const [animationMenuIsOpen, setAnimationMenuIsOpen] = useState(false);
    const [cityMenuIsOpen, setCityMenuIsOpen] = useState(false);
    const [selectedCity, setSelectedCity] = useState(parseInt(localStorage.getItem('selectedCity') || '-1'));
    const [selectedCityWayIds, setSelectedCityWayIds] = useState<number[][]>([]);
    const [satelite, setSatellite] = useState(false);

    // Trips
    const [tripsCurrentTime, setTripsCurrentTime] = useState(0);
    const [tripsData, setTripsData] = useState<TripsData[]>([]);
    const [tripsPlaybackSpeed, setTripsPlaybackSpeed] = useState(1);
    const [tripsDuration, setTripsDuration] = useState(0);
    const [trips, setTrips] = useState(false);
    const [animateTrips, setAnimateTrips] = useState(false);
    const [record, setRecord] = useState(false);
    const [loop, setLoop] = useState(false);

    // Nodes data
    const [cityCoverage, setCityCoverage] = useState<any>();
    const [cityDetails, setCityDetails] = useState<CityDetails>();
    const [cityWayMap, setCityWayMap] = useState<{ [ key: string ]: CityWayV2 }>({});
    const [cityWaysFeatures, setCityWaysFeatures] = useState<Array<Feature<any>>>([]);

    // Route drawing
    const drawingRef = useRef(false);
    const movingRef = useRef(false);
    const freehandRef = useRef(false);
    const [routeHistory, setRouteHistory] = useState<Route[][]>([[]]);
    const [route, setRoute] = useState<Route[]>([]);
    const [gpxToExport, setGpxToExport] = useState<string>();
    const [courseGeoPoints, setCourseGeoPoints] = useState<GeoPoint[]>([]);
    const [drawing, setDrawing] = useState(false);
    const [freehand, setFreehand] = useState(false);

    // Refs
    const loopRef = useRef(false);
    loopRef.current = loop;
    const deck = useRef<Deck>();
    const animation = useRef<number>(0);
    const tripsCurrentTimeRef = useRef(tripsCurrentTime);
    const tripsDurationRef = useRef<number>(0);
    tripsDurationRef.current = tripsDuration;
    const tripsPlaybackSpeedRef = useRef<number>(1);
    tripsPlaybackSpeedRef.current = tripsPlaybackSpeed;
    const mediaRecorder = useRef<MediaRecorder>();

    const TripsPositions = useRef<Float32Array>();
	const TripsTimestamps = useRef<Float32Array>();
	const TripsStartIndices = useRef<Uint32Array>();
	const TripsColors = useRef<Uint8Array>();
	const TripsColorsHighlight = useRef<Uint8Array>();
	const TripsLength = useRef<number>();
    const TripsScrubberRef = useRef<HTMLInputElement>(null);
    const CurrentTripRef = useRef<HTMLSpanElement>(null);

    const currentTripDate = tripsData.find(tripData => {
        return (
            tripData.extent[0] < tripsCurrentTime &&
            tripsCurrentTime < tripData.extent[1]
        );
    })?.date;

    const UpdateDeckTrips = (additionalProps?: any) => {
        if (
			TripsStartIndices.current &&
			TripsPositions.current &&
			TripsTimestamps.current &&
			TripsColors.current &&
			TripsLength.current &&
			TripsColorsHighlight.current
		) {
            deck.current?.setProps({
                layers: [
                    makeTripsLayer(
                        TripsLength.current,
                        TripsStartIndices.current,
                        TripsPositions.current,
                        TripsTimestamps.current,
                        TripsColors.current,
                        tripsCurrentTimeRef.current,
                        additionalProps,
                    ),
                    makeTripsHighlightLayer(
                        TripsLength.current,
                        TripsStartIndices.current,
                        TripsPositions.current,
                        TripsTimestamps.current,
                        TripsColorsHighlight.current,
                        tripsCurrentTimeRef.current,
                        additionalProps,
                    ),
                ]
            });
            if (CurrentTripRef.current) {
                const currentTripTimestampIndex = TripsTimestamps.current.indexOf(tripsCurrentTimeRef.current);
                if (currentTripTimestampIndex < 0) {
                    return;
                }
                const currentTripStartIndex = TripsStartIndices.current.findIndex(startIndex => {
                    return startIndex >= currentTripTimestampIndex;
                }) - 1;
                if (currentTripStartIndex < 0) {
                    return;
                }
                const currentTripDate = activities[currentTripStartIndex].date;
                CurrentTripRef.current.innerHTML = ToISOStringLocal(currentTripDate);
            }
        }
    };

    const UpdateCityWayFeatures = useCallback(() => {
        map.getSource('cityWays') &&
        (map.getSource('cityWays') as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: cityWaysFeatures,
        });
    }, [map, cityWaysFeatures]);

    useEffect(() => {
        UpdateCityWayFeatures();
    }, [UpdateCityWayFeatures]);

    const updateCityWayFeatures = useCallback(() => {
        const wayNames = Object.keys(cityWayMap);
        if (!cityDetails) {
            return;
        }
        const { wayNames: cityWayNames, ways: cityWays, nodes: cityNodes } = cityDetails;
        if (wayNames.length === 0 ||
            cityWayNames.length === 0 ||
            cityWays.length === 0 ||
            cityNodes.length === 0) {
            return;
        }

        const cityWaysFeatures = wayNames.map(wayName => {
            const { complete, completePercent } = cityWayMap[wayName];
            const cityWayIndicies = cityWayNames.map((aWayName, wayNameIndex) => {
                return aWayName === wayName
                    ? wayNameIndex
                    : -1;
                });
            const validCityWayIndicies = cityWayIndicies.filter(a => a !== -1);
            const coordinates = validCityWayIndicies.map(wayId =>
                cityWays[wayId].map(wayNodeId => {
                    const coordinate = cityNodes[wayNodeId][0];
                    return [coordinate[1], coordinate[0]];
                }));
            return {
                type: 'Feature',
                geometry: {
                    type: 'MultiLineString',
                    coordinates: coordinates,
                },
                properties: {
                    name: wayName,
                    completePercent,
                    complete: complete,
                    color: complete
                        ? 'rgba(84, 39, 143, 0.5)'
                        : `rgba(${66 + ((255 - 66) * completePercent)}, ${25 + ((255 - 25) * completePercent)}, ${229 * completePercent}, 1.0)`,
                },
            };
        });

        setCityWaysFeatures(cityWaysFeatures as Array<Feature<any>>);
    }, [cityWayMap, cityDetails]);

    const UpdateNodesCoverage = useCallback(() => {
        cityCoverage && map.getSource && map.getSource('nodesCoverage') &&
        (map.getSource('nodesCoverage') as mapboxgl.GeoJSONSource)
            .setData(cityCoverage);
    }, [map, cityCoverage])

    const animate = () => {
        const incrementedTime = Math.ceil(
            tripsCurrentTimeRef.current +
            (
                tripsDurationRef.current /
                (
                    (45 * 60) / tripsPlaybackSpeedRef.current
                )
            )
        );
        if (!loop && incrementedTime >= tripsDurationRef.current) {
            setAnimateTrips(false);
            setRecord(false);
            if (mediaRecorder.current) {
                mediaRecorder.current.stop();
            }
            return;
        }
        tripsCurrentTimeRef.current = incrementedTime % tripsDurationRef.current;
        if (TripsScrubberRef.current) {
            TripsScrubberRef.current.value = tripsCurrentTimeRef.current.toString();
        }
        UpdateDeckTrips();
        animation.current = requestAnimationFrame(animate);
    }

    const hideEphemeralPoint = useCallback(() => {
        map.setLayoutProperty('ephemeralPoint', 'visibility', 'none');
    }, [map]);

    const moveEphemeralpoint = useCallback((lngLat: mapboxgl.LngLat) => {
        const routeWaypointsSource = (map.getSource('routeWaypoints') as mapboxgl.GeoJSONSource);
        const waypoints: Waypoint[] = (routeWaypointsSource as any)._data.features.map(GeoJsonToWaypoint);

        if (!waypoints.find(waypoint => waypoint.selected)) {
            (map.getSource('ephemeralPoint') as mapboxgl.GeoJSONSource).setData({
                features: [{
                    type: 'Feature',
                    id: 'ephemeralPoint',
                    geometry: {
                        type: 'Point',
                        coordinates: lngLat.toArray(),
                    },
                    properties: {}
                }],
                type: 'FeatureCollection',
            });
        }
    }, [map]);

    const updateRouteFromWaypointsAtIndexAndPrevious = useCallback(async (
        inputWaypoints: Waypoint[],
        index: number,
        freehandDraw: boolean = false
    ) => {
        if (inputWaypoints.length <= 1 || index === 0) {
            return;
        }
        if (freehandDraw === true) {
            setRoute((oldRoute) => {
                const updatedRoute = [...oldRoute];
                updatedRoute[index - 1] = {
                    geometry: {
                        coordinates: [
                            inputWaypoints[index - 1].lnglat.toArray(),
                            inputWaypoints[index].lnglat.toArray()
                        ],
                        type: 'LineString'
                    },
                    legs: [],
                    weight: 0,
                    weight_name: '',
                    duration: 0,
                    distance: 0,
                };
                setRouteHistory((oldRouteHistory) => {
                    const updatedRouteHistory = [...oldRouteHistory];
                    updatedRouteHistory.push(updatedRoute);
                    return updatedRouteHistory;
                });
                return updatedRoute;
            });
            return;
        }
        const routeObject = await GetWalkingDirectionsForCoordinateString(
            [
                LongitudeLatitudeCoordinatesAsString(
                    inputWaypoints[index - 1].lnglat.toArray()
                ),
                LongitudeLatitudeCoordinatesAsString(
                    inputWaypoints[index].lnglat.toArray()
                )
            ].join(';')
        );
        setRoute((oldRoute) => {
            if (!routeObject) {
                return oldRoute;
            }
            const updatedRoute = [...oldRoute];
            updatedRoute[index - 1] = routeObject;
            setRouteHistory((oldRouteHistory) => {
                const updatedRouteHistory = [...oldRouteHistory];
                updatedRouteHistory.push(updatedRoute);
                return updatedRouteHistory;
            });
            return updatedRoute;
        });
    }, []);

    const updateRouteFromWaypointsAtIndexPreviousAndNext = useCallback(async (
        inputWaypoints: Waypoint[],
        index: number
    ) => {
        if (inputWaypoints.length <= 1) {
            return;
        }
        const updatedWaypointCoordinatesString = LongitudeLatitudeCoordinatesAsString(
            inputWaypoints[index].lnglat.toArray()
        );
        const previousRouteObject = index !== 0
            ? await GetWalkingDirectionsForCoordinateString([
                    LongitudeLatitudeCoordinatesAsString(
                        inputWaypoints[index - 1].lnglat.toArray()
                    ),
                    updatedWaypointCoordinatesString
                ].join(';'))
            : null;
        const nextRouteObject = index !== inputWaypoints.length - 1
            ? await GetWalkingDirectionsForCoordinateString(
                [
                    updatedWaypointCoordinatesString,
                    LongitudeLatitudeCoordinatesAsString(
                        inputWaypoints[index + 1].lnglat.toArray()
                    )
                ].join(';'))
            : null;

        setRoute((originalRoute) => {
            const updatedRoute = [...originalRoute];
            if (previousRouteObject) {
                updatedRoute[index - 1] = previousRouteObject;
            }
            if (nextRouteObject) {
                if (inputWaypoints.length > (updatedRoute.length + 1)) {
                    updatedRoute.splice(index, 0, nextRouteObject);
                } else {
                    updatedRoute[index] = nextRouteObject;
                }                
            }
            setRouteHistory((oldRouteHistory) => {
                const updatedRouteHistory = [...oldRouteHistory];
                updatedRouteHistory.push(updatedRoute);
                return updatedRouteHistory;
            });
            return updatedRoute;
        })
    }, []);

    const updateRouteFromWaypointsDeletionAtIndex = useCallback(async (
        inputWaypoints: Waypoint[],
        index: number
    ) => {
        const routeWaypointsSource = (map.getSource('routeWaypoints') as mapboxgl.GeoJSONSource);
        const updatedRoute = [...route];
        if (index < inputWaypoints.length) {
            console.log(`splicing route at ${index}, 1`);
            updatedRoute.splice(index, 1);
        }
        if (index > 0) {
            console.log(`splicing route at ${index - 1}, 1`);
            updatedRoute.splice(index - 1, 1);
        }

        if (index !== 0 &&
            index !== inputWaypoints.length) {
                console.log('fetching new route as index was not 0 nor last');
                (async () => {
                    const routeObject = await GetWalkingDirectionsForCoordinateString(
                        [
                            LongitudeLatitudeCoordinatesAsString(
                                inputWaypoints[index - 1].lnglat.toArray()
                            ),
                            LongitudeLatitudeCoordinatesAsString(
                                inputWaypoints[index].lnglat.toArray()
                            )
                        ].join(';')
                    );
                    if (routeObject !== null) {
                        updatedRoute.splice(index - 1, 0, routeObject);
                        setRouteHistory((oldRouteHistory) => {
                            const updatedRouteHistory = [...oldRouteHistory];
                            updatedRouteHistory.push(updatedRoute);
                            return updatedRouteHistory;
                        });
                        setRoute(updatedRoute);
                        routeWaypointsSource.setData({
                            type: 'FeatureCollection',
                            features: inputWaypoints.map(WaypointToGeoJson),
                        });
                    } else {
                        toast.error("Couldn't get route ")
                    }
                })();
        } else {
            setRouteHistory((oldRouteHistory) => {
                const updatedRouteHistory = [...oldRouteHistory];
                updatedRouteHistory.push(updatedRoute);
                return updatedRouteHistory;
            });
            setRoute(updatedRoute);
            routeWaypointsSource.setData({
                type: 'FeatureCollection',
                features: inputWaypoints.map(WaypointToGeoJson),
            });
        }
    }, [map, route]);

    const onMove = useCallback((e) => {
        const coords = e.lngLat;
        const routeWaypointsSource = (map.getSource('routeWaypoints') as mapboxgl.GeoJSONSource);
        const updatedWaypoints: Waypoint[] = (routeWaypointsSource as any)._data.features.map(GeoJsonToWaypoint);

        const selectedWaypointIndex = updatedWaypoints.findIndex(waypoint => {
            return waypoint.selected;
        });

        if (selectedWaypointIndex >= 0) {
            updatedWaypoints[selectedWaypointIndex].lnglat = coords;
            routeWaypointsSource.setData({
                type: 'FeatureCollection',
                features: updatedWaypoints.map(WaypointToGeoJson),
            });
            const ephemeralLineCoordinates = [];
            if (selectedWaypointIndex !== 0) {
                ephemeralLineCoordinates.push(updatedWaypoints[selectedWaypointIndex - 1].lnglat);
            }
            ephemeralLineCoordinates.push(updatedWaypoints[selectedWaypointIndex].lnglat);
            if (selectedWaypointIndex !== updatedWaypoints.length - 1) {
                ephemeralLineCoordinates.push(updatedWaypoints[selectedWaypointIndex + 1].lnglat);
            }
            (map.getSource('ephemeralLine') as mapboxgl.GeoJSONSource).setData({
                features: [{
                    type: 'Feature',
                    id: 'ephemeralLine',
                    geometry: {
                        type: 'LineString',
                        coordinates: ephemeralLineCoordinates.map(lngLat => lngLat.toArray()),
                    },
                    properties: {}
                }],
                type: 'FeatureCollection',
            });
        }
    }, [map]);

    const onUp = useCallback((e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        const coords = e.lngLat;
        const routeWaypointsSource = (map.getSource('routeWaypoints') as mapboxgl.GeoJSONSource);
        const updatedWaypoints: Waypoint[] = (routeWaypointsSource as any)._data.features.map(GeoJsonToWaypoint);
        const selectedWaypointIndex = updatedWaypoints.findIndex(waypoint => {
            return waypoint.selected;
        });
        if (selectedWaypointIndex >= 0) {
            updatedWaypoints[selectedWaypointIndex].lnglat = coords;
            updateRouteFromWaypointsAtIndexPreviousAndNext(
                updatedWaypoints,
                selectedWaypointIndex
            );
            routeWaypointsSource.setData({
                type: 'FeatureCollection',
                features: updatedWaypoints.map(WaypointToGeoJson),
            });
        }

        map.getCanvasContainer().style.cursor = 'move';
        map.setLayoutProperty('ephemeralLine', 'visibility', 'none');
        movingRef.current = false;
        map.off('mousemove', onMove);
        map.off('touchmove', onMove);
    }, [map, onMove, updateRouteFromWaypointsAtIndexPreviousAndNext]);

    const handleRouteWaypointMouseDown = useCallback((e: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData) => {
        if (e.originalEvent.button === 2) {
            if (e.features && e.features.length > 0) {
                const firstFeatureId = e.features[0]?.id;
                if (firstFeatureId !== undefined) {
                    const routeWaypointsSource = (map.getSource('routeWaypoints') as mapboxgl.GeoJSONSource);
                    const updatedWaypoints = (routeWaypointsSource as any)._data.features.map(GeoJsonToWaypoint);
                    const removedWaypointIndex = parseInt(firstFeatureId.toString());
                    updatedWaypoints.splice(removedWaypointIndex, 1);
                    updateRouteFromWaypointsDeletionAtIndex(updatedWaypoints, removedWaypointIndex);
                }
            }
            return;
        }
        movingRef.current = true;
        e.preventDefault();
        map.getCanvasContainer().style.cursor = 'grab';
        map.setLayoutProperty('ephemeralLine', 'visibility', 'visible');
        map.on('mousemove', onMove);
        map.once('mouseup', onUp);
    }, [map, onUp, onMove, updateRouteFromWaypointsDeletionAtIndex]);

    const handleRouteMouseDown = useCallback((e: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData) => {
        if (e.originalEvent.button === 2) {
            return;
        }
        const routeWaypointsSource = (map.getSource('routeWaypoints') as mapboxgl.GeoJSONSource);
        const updatedWaypoints: Waypoint[] = (routeWaypointsSource as any)._data.features.map(GeoJsonToWaypoint);
        const selectedWaypointIndex = updatedWaypoints.findIndex(waypoint => {
            return waypoint.selected;
        });
        if (selectedWaypointIndex >= 0) {
            return;
        }
        const featuresClicked = map.queryRenderedFeatures(e.point, { layers: ['route-thin'] });
        if (featuresClicked.length === 0) {
            return;
        }
        const routeIndex = featuresClicked[0].properties?.startIndex;
        if (routeIndex === undefined) {
            return;
        }
        hideEphemeralPoint();
        movingRef.current = true;
        e.preventDefault();
        map.getCanvasContainer().style.cursor = 'grab';
        map.setLayoutProperty('ephemeralLine', 'visibility', 'visible');
        map.on('mousemove', onMove);
        map.once('mouseup', onUp);
        updatedWaypoints.splice(routeIndex + 1, 0, {
            selected: true,
            lnglat: e.lngLat,
        });
        routeWaypointsSource.setData({
            type: 'FeatureCollection',
            features: updatedWaypoints.map(WaypointToGeoJson),
        });
    }, [map, onMove, onUp, hideEphemeralPoint]);

    const loadAthleteCityNodes = useCallback(async (clearCache?: boolean) => {
        if (selectedCity < 0) {
            return;
        }
        const cityName = CITY_MAPPING[selectedCity].name
        const t = toast.loading(`Loading ${cityName} Nodes...`);
        const url = GetCityNodesUrl(selectedCity, clearCache);

        toast.loading(`Loading ${cityName} ways...`, { id: t });
        try {
            const cityDetails: CityDetails = await GetFromCacheJSON(cache, url);
            setCityDetails(cityDetails);
        } catch (e) {
            console.warn('exception getting city nodes, clearing cache item: ', e);
            await cache.delete(url);
            toast.error(`Exception getting city nodes for ${cityName}`, { id: t });
            return;
        }

        const cityWayMapUrl = GetAthleteCityWayMapUrl(athleteId, selectedCity)
        toast.loading(`Loading ${cityName} way details...`, { id : t })
        try {
            setCityWayMap(await GetFromCacheJSON(cache, cityWayMapUrl));
        } catch (e) {
            console.warn('Exception getting city way map: ', e);
            await cache.delete(cityWayMapUrl);
            toast.error(`Exception getting city way map for ${cityName}`, { id: t });
            return;
        }

        const cityCoverageFile = GetAthleteCityCoverageUrl(athleteId, selectedCity);
        toast.loading(`Loading ${cityName} coverage...`, { id : t });
        try {
            setCityCoverage(await GetFromCacheJSON(cache, cityCoverageFile));
        } catch (e) {
            console.warn('Exception getting city coverage: ', e);
            await cache.delete(cityCoverageFile);
            toast.error(`Exception getting city coverage for ${cityName}`, { id: t });
            return;
        }

        toast.success('Loading complete!', { id: t });
    }, [cache, athleteId, selectedCity]);

    const LoadCoverageAndVectorTiles = useCallback(async (skipZoom: boolean = false) => {
        if (selectedCity < 0) {
            toast.success('Please select a city!');
            return;
        }
        const vectorUrl = GetAthleteCityTileUrl(athleteId, selectedCity);
        if (map.getSource('cityNodes')) {
            map.removeLayer('cityNodes');
            map.removeLayer('runNodes');
            map.removeLayer('heatmap');
            map.removeLayer('runPaths');
            map.removeSource('cityNodes');
        }
        map.addSource(
            'cityNodes',
            {
                type: 'vector',
                url: vectorUrl,
            }
        );
        map.once('idle', () => {
            const bounds = ((map.getSource('cityNodes') as VectorSource).bounds as [number,number,number,number] | undefined);
            if (bounds !== undefined && !skipZoom) {
                map.fitBounds(bounds);
            }

            map.getLayer('cityNodes') || map.addLayer({
                type: 'circle',
                id: 'cityNodes',
                source: 'cityNodes',
                "source-layer": 'cityNodes',
                maxzoom: 24,
                paint: RoadNodesPaint,
                layout: {
                    visibility: 'visible',
                },
            });
            map.getLayer('runNodes') || map.addLayer({
                type: 'circle',
                id: 'runNodes',
                source: 'cityNodes',
                "source-layer": "runNodes",
                minzoom: 17,
                paint: RunNodesPaint,
                layout: { visibility: 'visible' },
            });
            map.getLayer('heatmap') || map.addLayer({
                type: 'heatmap',
                id: 'heatmap',
                source: 'cityNodes',
                "source-layer": "runNodes",
                maxzoom: 20,
                minzoom: 0,
                layout: { visibility: 'none' },
                paint: HeatmapLayerPaint(GetDefaultHeatmapColor()),
            });
            map.getLayer('runPaths') || map.addLayer({
                type: 'line',
                id: 'runPaths',
                source: 'cityNodes',
                "source-layer": "runPaths",
                minzoom: 9,
                maxzoom: 19,
                paint: PathPaint,
                layout: { visibility: 'none' },
            });
        });
    }, [map, athleteId, selectedCity]);

    useEffect(() => {
        LoadCoverageAndVectorTiles();
    }, [LoadCoverageAndVectorTiles]);

    const RefreshNodes = useCallback(async () => {
        await cache.delete(GetCityNodesUrl(selectedCity));
        await cache.delete(GetCityNodesUrl(selectedCity, true));
        await cache.delete(GetAthleteCityWayMapUrl(athleteId, selectedCity));
        await cache.delete(GetAthleteCityCoverageUrl(athleteId, selectedCity));
        loadAthleteCityNodes(true);
    }, [athleteId, selectedCity, cache, loadAthleteCityNodes]);

    useEffect(() => {
        var load = async () => {
            await cache.delete(`activityIds/${athleteId}`);
            await cache.delete(`paths/${athleteId}`);
            await cache.delete(`points/${athleteId}`);

            const t = toast.loading('Loading Activity Paths');
            const tripsData: TripsData[] = [];

            let activityTimeStampStart = 0;

            for (var activitiesIndex = 0; activitiesIndex < activities.length; activitiesIndex++) {
                const activity = activities[activitiesIndex];
                const originalPath = activity.decodedPoly;
                const thresholdSimplifyPath = SimplifyPath(originalPath, 25);
                const path = simplify(
                    thresholdSimplifyPath.map(p => { return { x: p[0] , y: p[1] }}),
                    0.0001,
                    true
                ).map(p => { return [p.x, p.y] });
                const timestamps: number[] = [];
                for (var pathIndex = 0; pathIndex < path.length; pathIndex += 1) {
                    timestamps.push(activityTimeStampStart + pathIndex);
                }
                
                tripsData.push({
                    path,
                    timestamps,
                    date: activity.date,
                    extent: [activityTimeStampStart, activityTimeStampStart + (path.length - 1)],
                });

                activityTimeStampStart += path.length;
            }
            toast.success('Loading Complete!', { id: t, duration: 6000 });

            const tripsDuration = activityTimeStampStart;

            setTripsData(tripsData);
            setTripsDuration(tripsDuration);
            setTripsCurrentTime(tripsDuration);

            TripsPositions.current = new Float32Array(tripsData.map(d => d.path).flat(2));
            TripsTimestamps.current = new Float32Array(tripsData.map(d => d.timestamps).flat());
            TripsStartIndices.current = new Uint32Array(tripsData.reduce((acc, d) => {
                acc.push(acc[acc.length - 1] + d.path.length);
                return acc;
            }, [0]));
            TripsColors.current = new Uint8Array(tripsData.map(d => d.path.map(_ => { return [106, 81, 163]; })).flat(2));
            TripsColorsHighlight.current = new Uint8Array(tripsData.map(d => d.path.map(_ => { return [255, 255, 255]; })).flat(2));
            TripsLength.current = tripsData.length;
        };
        load();
    }, [activities, athleteId, cache]);

    useEffect(() => {
        loadAthleteCityNodes();
    }, [loadAthleteCityNodes]);

    const handleCityNodesClick = useCallback((ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        if (!cityDetails) {
            return;
        }
        const firstFeature = ev.features[0];
        const { wayNames: cityWayNames } = cityDetails;
        if (!drawingRef.current || (firstFeature.properties && firstFeature.properties.id && firstFeature.properties.ways)) {
            // Why is this a string suddenly?
            const { ways: stringWays } = firstFeature.properties;
            const ways: number[] = JSON.parse(stringWays);

            const parsedWayNames = Array.from(new Set(ways.map((i: number) => { return cityWayNames[i] })));
            const wayIds = parsedWayNames.map(selectedWayName => {
                return cityWayNames.map((wayName, wayNameIndex) => {
                    return wayName === selectedWayName
                        ? wayNameIndex
                        : -1;
                }).filter(a => a !== -1);
            });
            setSelectedCityWayIds(wayIds);
            const wayInformation = parsedWayNames.map(wayName => {
                return cityWayMap[wayName];
            });
            const selectedWaysDescription = wayInformation.map(
                (way, index) => {
                    const wayNodeCount = way.incompleted + way.completed;
                    const completedNodeCount = way.completed;
                    const completed = way.complete;
                    const completedPercent = way.completePercent;
                    return `<tr>
                        <td>${completed ? '✅' : '❌'}</td>
                        <td>${parsedWayNames[index]}</td>
                        <td>${wayNodeCount}</td>
                        <td>${completedNodeCount}</td>
                        <td>${wayNodeCount - completedNodeCount}</td>
                        <td>${GetRemainingNodeCountForWay(wayNodeCount, completedNodeCount)}</td>
                        <td>${(completedPercent * 100).toFixed(2)}%</td>
                    </tr>`;
                }
            ).join('<br/>');
            const description = `<div>
                <table class="table table-sm table-striped table-hover" style="font-family: monospace;">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Nodes</th>
                            <th>Completed</th>
                            <th>Remaining</th>
                            <th>Required</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                    ${selectedWaysDescription}
                    </tbody>
                </table>
            </div>`;
            new mapboxgl.Popup()
                .setLngLat(ev.lngLat)
                .setHTML(description)
                .setMaxWidth('700px')
                .addTo(map);
        } else {
            map.setPaintProperty(
                'cityNodes',
                'circle-radius',
                BASE_ROAD_NODES_CIRCLE_RADIUS
            );
        }
    }, [cityDetails, map, cityWayMap]);

    // Drawing
    const handleDrawingClick = useCallback((e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        const routeWaypointsSource = (map.getSource('routeWaypoints') as mapboxgl.GeoJSONSource);
        const updatedWaypoints: Waypoint[] = (routeWaypointsSource as any)._data.features.map(GeoJsonToWaypoint);
        updatedWaypoints.push({
            selected: false,
            lnglat: e.lngLat,
        });
        updateRouteFromWaypointsAtIndexAndPrevious(
            updatedWaypoints,
            updatedWaypoints.length - 1,
            freehandRef.current
        );
        routeWaypointsSource.setData({
            type: 'FeatureCollection',
            features: updatedWaypoints.map(WaypointToGeoJson),
        });
    }, [map, updateRouteFromWaypointsAtIndexAndPrevious]);

    const ToggleDrawing = useCallback((override?: boolean) => {
        if (override !== undefined) {
            drawingRef.current = override;
        } else {
            drawingRef.current = !drawingRef.current;
        }
        if (drawingRef.current) {
            map.getCanvasContainer().style.cursor = 'crosshair';
            map.on('click', handleDrawingClick);
        } else {
            map.off('click', handleDrawingClick);
        }
        setDrawing(drawingRef.current);
    }, [map, handleDrawingClick]);

    const handleRouteWaypointEnter = useCallback((e: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData) => {
        if (e.features && e.features.length > 0) {
            const firstFeatureId = e.features[0]?.id;
            if (firstFeatureId !== undefined)  {
                hideEphemeralPoint();
                ToggleDrawing(false);
                const routeWaypointsSource = (map.getSource('routeWaypoints') as mapboxgl.GeoJSONSource);
                const waypoints: Waypoint[] = (routeWaypointsSource as any)._data.features.map(GeoJsonToWaypoint);
                waypoints[parseInt(firstFeatureId.toString())].selected = true;
                routeWaypointsSource.setData({
                    type: 'FeatureCollection',
                    features: waypoints.map(WaypointToGeoJson),
                });
                map.getCanvasContainer().style.cursor = 'move';
            }
        }
    }, [map, hideEphemeralPoint, ToggleDrawing]);

    const handleRouteWaypointLeave = useCallback((e) => {
        if (!movingRef.current) {
            const routeWaypointsSource = (map.getSource('routeWaypoints') as mapboxgl.GeoJSONSource);
            const waypoints: Waypoint[] = (routeWaypointsSource as any)._data.features.map(GeoJsonToWaypoint);
            const selectedWaypointIndex = waypoints.findIndex(waypoint => {
                return waypoint.selected;
            });
            if (selectedWaypointIndex >= 0) {
                waypoints[selectedWaypointIndex].selected = false;
                routeWaypointsSource.setData({
                    type: 'FeatureCollection',
                    features: waypoints.map(WaypointToGeoJson),
                });
            }
            ToggleDrawing(true);
            map.getCanvasContainer().style.cursor = 'crosshair';
        }
    }, [map, ToggleDrawing]);

    const UndoDrawing = useCallback(() => {
        if (routeHistory.length <= 1) {
            return;
        }
        const currentRoute = routeHistory[routeHistory.length - 2];
        const initialPoint = routeHistory[routeHistory.length - 1][0].geometry.coordinates[0] as GeoJSON.Position;
        const waypoints: Waypoint[] = [];
        waypoints.push({
            selected: false,
            lnglat: new LngLat(initialPoint[0], initialPoint[1]),
        });
        if (currentRoute.length > 0) {
            for (var i = 0; i < currentRoute.length; i++) {
                const currentCoordinates = currentRoute[i].geometry.coordinates;
                const waypoint = currentCoordinates[currentCoordinates.length - 1] as GeoJSON.Position;
                waypoints.push({
                    selected: false,
                    lnglat: new LngLat(waypoint[0], waypoint[1]),
                });
            }
        }
        const routeWaypointsSource = (map.getSource('routeWaypoints') as mapboxgl.GeoJSONSource);
        routeWaypointsSource.setData({
            type: 'FeatureCollection',
            features: waypoints.map(WaypointToGeoJson),
        });
        const updatedRouteHistory = [...routeHistory];
        updatedRouteHistory.pop();
        setRouteHistory(updatedRouteHistory);
        setRoute(currentRoute);
    }, [map, routeHistory]);

    const ToggleFreehand = useCallback(() => {
        freehandRef.current = !freehandRef.current;
        setFreehand(!freehand);
    }, [freehand]);

    /* Map update useEffects */
    const AddSourcesAndLayers = useCallback(() => {
        map.getSource('cityWays') ||
            map.addSource('cityWays', EmptyGeoJsonFeatureCollection());
        map.getSource('nodesCoverage') ||
            map.addSource('nodesCoverage', EmptyGeoJsonFeatureCollection());

        map.getSource('selectedCityWays') ||
            map.addSource('selectedCityWays', EmptyGeoJsonFeatureCollection());
        map.getSource('routeWaypoints') ||
            map.addSource('routeWaypoints', EmptyGeoJsonFeatureCollection());
        map.getSource('route') ||
            map.addSource('route', EmptyGeoJsonFeatureCollection());
        map.getSource('routeDistances') ||
            map.addSource('routeDistances', EmptyGeoJsonFeatureCollection());
        map.getSource('ephemeralPoint') ||
            map.addSource('ephemeralPoint', EmptyGeoJsonFeatureCollection());
        map.getSource('ephemeralLine') ||
            map.addSource('ephemeralLine', EmptyGeoJsonFeatureCollection());

        map.getLayer('cityWays') || map.addLayer({
            type: 'line',
            id: 'cityWays',
            source: 'cityWays',
            minzoom: 9,
            maxzoom: 22,
            paint: {
                'line-width': [
                    "interpolate",
                    ["exponential", 1.5],
                    ["zoom"],
                    12.5,
                    0.5,
                    14,
                    2,
                    18,
                    18
                ],
                'line-color': ['get', 'color'],
            },
            layout: { visibility: 'none' },
        });
        map.getLayer('nodesCoverage') || map.addLayer({
            type: 'fill',
            id: 'nodesCoverage',
            source: 'nodesCoverage',
            layout: {
                visibility: 'visible',
            },
            maxzoom: 22,
            paint: {
                'fill-color': ['get', 'color'],
                'fill-opacity': [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    13, 0.5,
                    14, 0.25,
                    15, 0.125,
                    16, 0.06,
                    22, 0
                ]
            },
        });
        map.getLayer('selectedWays') ||
            map.addLayer({
                type: 'line',
                id: 'selectedWays',
                source: 'selectedCityWays',
                minzoom: 9,
                maxzoom: 22,
                paint: {
                    ...PathPaint,
                    'line-width': [
                        'interpolate',
                        ['exponential', 1],
                        ['zoom'],
                        ...NODE_RADIUS_PIXELS_FOR_ZOOM.slice(9).reduce(
                            (accumulator: number[], pixels: number, index: number) => {
                            accumulator.push(index + 9);
                            accumulator.push(pixels * 2);
                            return accumulator;
                        }, []),
                    ],
                    'line-color': '#33F',
                },
                layout: { visibility: 'visible' },
            });
        map.getLayer('routeWaypoints') ||
            map.addLayer({
                type: 'circle',
                id: 'routeWaypoints',
                source: 'routeWaypoints',
                layout: { visibility: 'visible' },
                paint: {
                    'circle-radius': 10,
                    'circle-color': ['get', 'color'],
                    'circle-stroke-color': [
                        'case',
                        ['get', 'selected'], '#999',
                        'rgba(0,0,0,0)'
                    ],
                    'circle-stroke-width': [
                        'case',
                        ['get', 'selected'], 3,
                        0
                    ],
                },
            });
        map.getLayer('route') ||
            map.addLayer({
                type: 'line',
                id: 'route',
                source: 'route',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: PathPaint,
            }, 'routeWaypoints');
        map.getLayer('routeDistances') ||
            map.addLayer({
                type: 'symbol',
                id: 'routeDistances',
                source: 'routeDistances',
                layout: {
                    "symbol-placement": 'point',
                    "text-font": ['Open Sans Regular','Arial Unicode MS Regular'],
                    "text-field": '{distance} {unit}',
                    "text-anchor": 'center',
                    "text-justify": 'center',
                    "text-size": 13,
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true
                },
                paint: {
                    'text-color': '#FFFFFF',
                    'text-halo-color': '#000000',
                    'text-halo-width': 1,
                },
            });
        map.getLayer('route-thin') ||
            map.addLayer({
                type: 'line',
                id: 'route-thin',
                source: 'route',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: ThinPath,
            }, 'route');
        map.getLayer('ephemeralPoint') ||
            map.addLayer({
                type: 'circle',
                id: 'ephemeralPoint',
                source: 'ephemeralPoint',
                layout: { visibility: 'none' },
                paint: {
                    'circle-radius': 7,
                    'circle-color': '#AAAAFF',
                },
            }, 'routeWaypoints');
        map.getLayer('ephemeralLine') ||
            map.addLayer({
                type: 'line',
                id: 'ephemeralLine',
                source: 'ephemeralLine',
                layout: { visibility: 'none' },
                paint: {
                    ...ThinPath,
                    'line-opacity': 0.75,
                    'line-dasharray': [3,3],
                }
            });

        if (!deck.current) {
            deck.current = new Deck({
                gl: (map as any).painter.context.gl,
                layers: [],
                getCursor: () => 'inherit',
            });
        }
        map.getLayer('trips') ||
            map.addLayer(new MapboxLayer({id: 'trips', deck: deck.current}));
        map.getLayer('tripsHighlight') ||
            map.addLayer(new MapboxLayer({id: 'tripsHighlight', deck: deck.current}));
    }, [map]);

    // Initialization
    useEffect(() => {
        AddSourcesAndLayers();
    }, [AddSourcesAndLayers]);

    // Interactions
    useEffect(() => {
        const handleClick = (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
            const features = map.queryRenderedFeatures([ev.point.x, ev.point.y]);
            if (!features || features.length === 0 || features[0].source !== 'cityNodes2') {
                setSelectedCityWayIds([]);
            } else {
                const topFeature = features[0];
                const topFeatureProperties = topFeature.properties;
                if (!topFeatureProperties?.ways) {
                    setSelectedCityWayIds([]);
                }
            }
        };

        const handlePathClick = (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
            const firstFeature = ev.features[0];
            if (firstFeature.properties && firstFeature.properties.id) {
                const { id } = firstFeature.properties;
                const description = `<a href="${STRAVA_URL}${id}">${id}</a>`;
    
                new mapboxgl.Popup()
                    .setLngLat(ev.lngLat)
                    .setHTML(description)
                    .addTo(map);
            }
        };

        const cityNodesMouseMove = () => {
            if (!drawingRef.current) {
                map.getCanvasContainer().style.cursor = 'crosshair';
            }
        }

        const cityNodesMouseLeave = () => {
            if (!drawingRef.current) {
                map.getCanvasContainer().style.cursor = 'grab';
            }
        }

        const routeEnter = (e: mapboxgl.MapMouseEvent & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData) => {
            const routeWaypointsSource = (map.getSource('routeWaypoints') as mapboxgl.GeoJSONSource);
            const updatedWaypoints: Waypoint[] = (routeWaypointsSource as any)._data.features.map(GeoJsonToWaypoint);
            const selectedWaypointIndex = updatedWaypoints.findIndex(waypoint => {
                return waypoint.selected;
            });
            if (selectedWaypointIndex >= 0) {
                return;
            }
            if (!movingRef.current) {
                moveEphemeralpoint(e.lngLat);
                map.setLayoutProperty('ephemeralPoint', 'visibility', 'visible');
            }
        }

        const routeMove = (e: mapboxgl.MapMouseEvent & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData) => {
            moveEphemeralpoint(e.lngLat);
        };

        map.on('mouseup', handleClick);
        map.on('click', 'runPaths', handlePathClick);
        map.on('mousemove', 'cityNodes', cityNodesMouseMove);
        map.on('mouseleave', 'cityNodes', cityNodesMouseLeave);
        map.on('mouseenter', 'routeWaypoints', handleRouteWaypointEnter);
        map.on('mouseleave', 'routeWaypoints', handleRouteWaypointLeave);
        map.on('mousedown', 'routeWaypoints', handleRouteWaypointMouseDown);
        map.on('mouseenter', 'route-thin', routeEnter);
        map.on('mousemove', 'route-thin', routeMove);
        map.on('mouseleave', 'route-thin', hideEphemeralPoint);
        map.on('mousedown', 'route-thin', handleRouteMouseDown);

        return () => {
            map.off('mouseup', handleClick);
            map.off('click', 'runPaths', handlePathClick);
            map.off('mousemove', 'cityNodes', cityNodesMouseMove);
            map.off('mouseleave', 'cityNodes', cityNodesMouseLeave);
            map.off('mouseenter', 'routeWaypoints', handleRouteWaypointEnter);
            map.off('mouseleave', 'routeWaypoints', handleRouteWaypointLeave);
            map.off('mousedown', 'routeWaypoints', handleRouteWaypointMouseDown);
            map.off('mouseenter', 'route-thin', routeEnter);
            map.off('mousemove', 'route-thin', routeMove);
            map.off('mouseleave', 'route-thin', hideEphemeralPoint);
            map.off('mousedown', 'route-thin', handleRouteMouseDown);
        }
    }, [
        map,
        handleRouteWaypointEnter,
        handleRouteWaypointLeave,
        handleRouteWaypointMouseDown,
        handleRouteMouseDown,
        hideEphemeralPoint,
        moveEphemeralpoint,
    ]);

    // City Nodes interactions
    useEffect(() => {
        map.on('click', 'cityNodes', handleCityNodesClick);
        return () => { map.off('click', 'cityNodes', handleCityNodesClick); }
    }, [map, handleCityNodesClick])

    // selected city
    useEffect(() => {
        localStorage.setItem('selectedCity', selectedCity.toString());
        setCityMenuIsOpen(false);
        if (selectedCity < 0) {
            if (map.getSource('cityNodes') &&
                (map.getSource('cityNodes') as mapboxgl.GeoJSONSource).setData) {
                (map.getSource('cityNodes') as mapboxgl.GeoJSONSource).setData({
                    type: 'FeatureCollection',
                    features: [],
                });
            }
            setCityCoverage({});
            setCityWayMap({});
            setCityDetails(undefined);
            return;
        }
    }, [map, selectedCity]);

    // city ways
    useEffect(() => {
        updateCityWayFeatures();
    }, [updateCityWayFeatures]);

    // city coverage
    useEffect(() => {
        UpdateNodesCoverage();
    }, [UpdateNodesCoverage])

    // Selected ways
    useEffect(() => {
        if (!map.getLayer('cityNodes') || !cityDetails) {
            return;
        }
        const { ways: cityWays, nodes: cityNodes } = cityDetails;
        const selectedCityNodeIds = selectedCityWayIds.reduce((a: string[], wayIds) => {
            const nodeIds = wayIds.reduce((a: string[], wayId) => {
                const wayIds = cityWays[wayId].map(a => a.toString());
                return a.concat(wayIds);
            }, []);
            return a.concat(nodeIds);
        }, []);

        if (selectedCityNodeIds.length > 0) {
            map.setPaintProperty(
                'cityNodes',
                'circle-radius',
                SELECTED_ROAD_NODES_CIRCLE_RADIUS(selectedCityNodeIds)
            );
        } else {
            map.setPaintProperty(
                'cityNodes',
                'circle-radius',
                BASE_ROAD_NODES_CIRCLE_RADIUS
            );
        }

        const selectedWays = selectedCityWayIds.map(wayIds => {
            return wayIds.map(wayId => {
                return cityWays[wayId].map(wayNodeId => {
                    const coordinate = cityNodes[wayNodeId][0];
                    return [coordinate[1], coordinate[0]];
                });
            });
        });

        (map.getSource('selectedCityWays') as mapboxgl.GeoJSONSource).setData({
            features: selectedWays.map(way => {
                return {
                    type: 'Feature',
                    geometry: {
                        type: 'MultiLineString',
                        coordinates: way,
                    },
                    properties: {
                        'color': '#33F',
                    },
                };
            }),
            type: 'FeatureCollection',
        });
    }, [map, cityDetails, selectedCityWayIds]);

    // Route
    useEffect(() => {
        const routeSource = (map.getSource('route') as mapboxgl.GeoJSONSource);
        routeSource && routeSource.setData({
            type: 'FeatureCollection',
            features: route.map((segment: Route, startIndex: number) => {
                const { geometry } = segment;
                return {
                    type: 'Feature',
                    properties: {
                        startIndex,
                        color: '#1382e0',
                    },
                    geometry,
                };
            }),
        });
    }, [map, route]);

    const updateRouteDistances = useCallback((metric: boolean, locations: Position[]) => {
        const routeDistancesSource = (map.getSource('routeDistances') as mapboxgl.GeoJSONSource);
        if (!routeDistancesSource) {
            return;
        }
        const unit = metric ? 'km' : 'mi';
        const routeDistanceFeatures: Feature[] = locations.reduce(
            (
                features: Feature[],
                location: Position,
                index: number) => {
            return features.concat([{
                type: 'Feature',
                properties: {
                    unit,
                    distance: index + 1,
                },
                geometry: {
                    type: 'Point',
                    coordinates: location
                },
            }]);
        }, []);

        routeDistancesSource.setData({
            type: 'FeatureCollection',
            features: routeDistanceFeatures,
        });
    }, [map]);

    /* End Map Update useEffects */
    return (
        <>
            <div style={{position: 'fixed', right: 0, top: 0, margin: '10px 10px 0px 0px', zIndex: 1 }}>
                <div>
                    <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
                        <LayerToggle
                            onClick={() => {
                                var style = MapboxStyle.DARK;
                                if (!satelite) {
                                    style = MapboxStyle.SATELLITE;
                                }
                                map.setStyle(GetMapboxStyleUrl(style));
                                setSatellite(!satelite);
                                map.once('idle', async () => {
                                    AddSourcesAndLayers();
                                    UpdateCityWayFeatures();
                                    UpdateNodesCoverage();
                                    await LoadCoverageAndVectorTiles(true);
                                })
                            }}
                            icon={faMap}
                            defaultDisabled={true}
                            title="Satellite view"
                        />
                        <LayerToggle
                            id="nodesCoverage"
                            map={map}
                            icon={faBorderAll}
                            title="Nodes coverage"
                        />
                        <LayerToggle
                            id="cityNodes"
                            map={map}
                            icon={faCircleNotch}
                            title="City Nodes"
                        />
                        <LayerToggle
                            id="runNodes"
                            map={map}
                            icon={faDotCircle}
                            title="Activity Nodes"
                        />
                        <LayerToggle
                            id="heatmap"
                            map={map}
                            icon={faFire}
                            defaultDisabled={true}
                            title="Activity Heatmap"
                        />
                        <LayerToggle
                            id="runPaths"
                            map={map}
                            icon={faLocationArrow}
                            defaultDisabled={true}
                            title="Activity Paths"
                        />
                        <LayerToggle
                            onClick={() => {
                                UpdateDeckTrips({ visible: !trips })
                                setTrips(!trips);
                                cancelAnimationFrame(animation.current);
                            }}
                            icon={faRoute}
                            defaultDisabled={true}
                            onMouseEnter={() => {
                                if (trips) {
                                    setAnimationMenuIsOpen(true);
                                }
                            }}
                            title="Paths animation"
                        />
                        <LayerToggle
                            id="cityWays"
                            map={map}
                            icon={faRoad}
                            defaultDisabled={true}
                            title="City Roads"
                        />
                    </div>
                    <div
                        style={{ marginTop: '10px' }}
                        className="mapboxgl-ctrl mapboxgl-ctrl-group"
                    >
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip id="select-a-city">
                                    Select a city
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                            >
                                <span
                                    className="fa-layers fa-fw"
                                    onClick={() => { setCityMenuIsOpen(!cityMenuIsOpen) }}
                                >
                                    <FontAwesomeIcon icon={faCity}/>
                                </span>
                            </button>
                        </OverlayTrigger>
                        {
                            cityMenuIsOpen && (
                                <InputGroup
                                    size="sm"
                                    style={{
                                        width: '175px',
                                        top: '0px',
                                        right: '30px',
                                        position: 'fixed',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <FormControl
                                        as="select"
                                        size="sm"
                                        value={selectedCity.toString()}
                                        onChange={(e) => {
                                            const currentSelectedCity = parseInt(e.currentTarget.value);
                                            setSelectedCity(currentSelectedCity);
                                        }}
                                        custom={true}
                                        style={{ width: '100%' }}
                                    >
                                        <option
                                            key="-"
                                            value={-1}
                                        >
                                            Select A City
                                        </option>
                                        {
                                            Object.keys(CITY_MAPPING).map(cityId => {
                                                const city = CITY_MAPPING[parseInt(cityId)];
                                                return (
                                                    <option
                                                        key={cityId}
                                                        value={cityId.toString()}
                                                    >
                                                        {city.name}
                                                    </option>
                                                );
                                            })
                                        }
                                    </FormControl>
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={async () => {
                                            const t = toast.loading(`Queuing refresh...`);
                                            try {
                                                await fetch(
                                                    GetAthleteNodesUpdateUrl(athleteId),
                                                    {
                                                        method: 'POST'
                                                    }
                                                );
                                            } catch (e) {
                                                toast.error(`Error queueing refresh!`, { id: t });
                                            }
                                            toast.success(`Queued refresh! Please reload in ~10 minutes`, { id: t });
                                            RefreshNodes();
                                        }}
                                        style={{
                                            color: '#212529',
                                            backgroundColor: '#ffc107',
                                            borderColor: '#ffc107',
                                            width: '100%',
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={faRedoAlt}
                                            style={{ marginRight: '4px' }}
                                        />
                                        Refresh Nodes
                                    </Button>
                                </InputGroup>
                            )
                        }
                    </div>
                    <div
                        style={{ marginTop: '10px' }}
                        className="mapboxgl-ctrl mapboxgl-ctrl-group"
                    >
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip id="draw">
                                    Draw a route
                                </Tooltip>
                            }
                        >
                            <button type="button">
                                <span
                                    className="fa-layers fa-fw"
                                    onClick={() => {
                                        ToggleDrawing();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                </span>
                            </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip id="return-to-start">
                                    Return route to start
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                style={{ display: drawing ? 'inherit' : 'none' }}
                            >
                                <span
                                    className="fa-layers fa-fw"
                                    onClick={() => {
                                        const routeWaypointsSource = (map.getSource('routeWaypoints') as mapboxgl.GeoJSONSource);
                                        const updatedWaypoints: Waypoint[] = (routeWaypointsSource as any)._data.features.map(GeoJsonToWaypoint);
                                        const firstWaypoint = updatedWaypoints[0];
                                        updatedWaypoints.push({
                                            selected: false,
                                            lnglat: firstWaypoint.lnglat,
                                        });
                                        updateRouteFromWaypointsAtIndexAndPrevious(updatedWaypoints, updatedWaypoints.length - 1);

                                        routeWaypointsSource.setData({
                                            type: 'FeatureCollection',
                                            features: updatedWaypoints.map(WaypointToGeoJson),
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon icon={faExchangeAlt}/>
                                </span>
                            </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip id="frehand">
                                    {`${freehand ? 'Disable' : 'Enable' } freehand route drawing`}
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                style={{ display: drawing ? 'inherit' : 'none' }}
                                title={`${freehand ? 'Disable' : 'Enable'} freehand route drawing`}
                            >
                                <span
                                    className="fa-layers fa-fw"
                                    onClick={ToggleFreehand}
                                >
                                    <FontAwesomeIcon icon={faPencilRuler} color={!freehand ? '#CCC' : undefined}/>
                                    {!freehand && (<FontAwesomeIcon icon={faSlash} color="#CCC" />)}
                                </span>
                            </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip id="undo">
                                    Undo last route change
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                style={{ display: drawing ? 'inherit' : 'none' }}
                                title="Undo"
                            >
                                <span
                                    className="fa-layers fa-fw"
                                    onClick={UndoDrawing}
                                >
                                    <FontAwesomeIcon icon={faUndoAlt}/>
                                </span>
                            </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Tooltip id="export">
                                    Export route to GPX
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                style={{ display: drawing ? 'inherit' : 'none' }}
                            >
                                <span
                                    className="fa-layers fa-fw"
                                    onClick={() => {
                                        const coordinates: [number, number][] = [];
                                        const geoPoints: GeoPoint[] = [];
                                        let totalDistanceMeters = 0;
                                        for (const segment of route) {
                                            const { coordinates: segmentCoordinates } = segment.geometry;
                                            totalDistanceMeters += segment.distance;
                                            for (const coordinate of segmentCoordinates as [number, number][]) {
                                                coordinates.push([coordinate[0], coordinate[1]]);
                                                // TODO: Get height and distance for geopoints
                                                geoPoints.push({
                                                    latitude: coordinate[1],
                                                    longitude: coordinate[0],
                                                    elevation: 0,
                                                    distance: 0,
                                                });
                                            }
                                        }
                                        geoPoints[geoPoints.length - 1].distance = totalDistanceMeters;
                                        const geoJson = GetGeoJSONLineStringForArrayOfLongitudeLatitude(coordinates);
                                        setGpxToExport(togpx(geoJson));
                                        setCourseGeoPoints(geoPoints);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFileExport}/>
                                </span>
                            </button>
                        </OverlayTrigger>
                    </div>
                </div>
                <div
                    className="mapboxgl-ctrl-group"
                    style={{
                        position: 'absolute',
                        right: animationMenuIsOpen ? '-10px' : '-500px',
                        marginTop: '10px',
                        marginRight: '-10px',
                        width: 'max-content',
                        paddingRight: '12px',
                        display: 'flex',
                        transition: '1s all',
                    }}
                >
                    <button
                        type="button"
                        style={{
                            marginLeft: '2px',
                            alignItems: 'center',
                            display: 'flex',
                            borderRight: '1px solid #CCC',
                            marginRight: '5px',
                            paddingRight: '1px',
                            height: 'auto',
                            width: 'auto',
                        }}
                        onClick={() => { setAnimationMenuIsOpen(false) }}
                    >
                        <FontAwesomeIcon
                            size="sm"
                            icon={faAngleDoubleRight}
                        />
                    </button>
                    <div>
                        <div
                            style={{ width: '100%' }}
                        >
                            Current Run:
                            <span
                                style={{ float: 'right', fontFamily: 'monospace' }}
                                ref={CurrentTripRef}
                            >
                                {ToISOStringLocal(currentTripDate || new Date())}
                            </span>
                        </div>
                        <div>
                            Playback Speed:
                            <InputGroup
                                size="sm"
                                style={{
                                    maxWidth: '80px',
                                    float: 'right',
                                }}
                            >
                                <FormControl
                                    as="select"
                                    size="sm"
                                    value={tripsPlaybackSpeed}
                                    onChange={(e) => {
                                        setTripsPlaybackSpeed(parseFloat(e.currentTarget.value));
                                    }}
                                    custom={true}
                                >
                                    <option value="0.25">.25x</option>
                                    <option value="0.5">.5x</option>
                                    <option value="0.75">.75x</option>
                                    <option value="1">1x</option>
                                    <option value="1.25">1.25x</option>
                                    <option value="1.5">1.5x</option>
                                    <option value="1.75">1.75x</option>
                                    <option value="2">2x</option>
                                </FormControl>
                            </InputGroup>
                        </div>
                        <div>
                            Current Time:
                        </div>
                        <div
                            style={{ width: '100%', display: 'flex' }}
                        >
                            <FontAwesomeIcon
                                icon={faChevronLeft}
                                onClick={() => {
                                    tripsCurrentTimeRef.current = 0;
                                    if (TripsScrubberRef.current) {
                                        TripsScrubberRef.current.value = tripsCurrentTimeRef.current.toString();
                                    }
                                    UpdateDeckTrips();
                                }}
                            />
                            <input
                                type="range"
                                ref={TripsScrubberRef}
                                min={0}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    tripsCurrentTimeRef.current = parseInt(e.currentTarget.value);
                                    UpdateDeckTrips();
                                }}
                                max={tripsDuration}
                                style={{ width: '100%' }}
                            />
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                onClick={() => {
                                    tripsCurrentTimeRef.current = tripsDuration;
                                    if (TripsScrubberRef.current) {
                                        TripsScrubberRef.current.value = tripsCurrentTimeRef.current.toString();
                                    }
                                    UpdateDeckTrips();
                                }}
                            />
                        </div>
                        <div
                            style={{
                                width: '100%',
                                paddingRight: '3px',
                                marginBottom: '4px',
                            }}
                        >
                            <InputGroup>
                                <Button
                                    as="div"
                                    size="sm"
                                    onClick={() => {
                                        setAnimateTrips(!animateTrips);
                                        if (animateTrips) {
                                            cancelAnimationFrame(animation.current);
                                            if (record && mediaRecorder.current) {
                                                setRecord(false);
                                                mediaRecorder.current.stop();
                                            }
                                        } else {
                                            if (record) {
                                                mediaRecorder.current = new MediaRecorder(
                                                    (map.getCanvas() as CanvasElement).captureStream(),
                                                    { mimeType: "video/webm; codecs=vp8" }
                                                );
                                                mediaRecorder.current.ondataavailable = (ev: BlobEvent) => {
                                                    DownloadFile(ev.data, "recording.webm", "video/webm");
                                                }
                                                mediaRecorder.current.start();
                                            }
                                            animate();
                                        }
                                    }}
                                    variant="outline-secondary"
                                    active={animateTrips}
                                    style={{
                                        flex: '1 0 auto',
                                        margin: '0px 5px',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={animateTrips ? faPause : faPlay}
                                        style={{ marginRight: '4px' }}
                                    />
                                </Button>
                                <Button
                                    as="div"
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() => {
                                        setRecord(!record);
                                    }}
                                    active={record}
                                    disabled={animateTrips && !record}
                                    style={{
                                        flex: '1 0 auto',
                                        margin: '0px 5px',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={record
                                            ? faVideo
                                            : animateTrips
                                                ? faVideoSlash
                                                : faFileVideo
                                        }
                                        style={{ marginRight: '4px' }}
                                    />
                                </Button>
                                <Button
                                    as="div"
                                    variant="outline-warning"
                                    active={loop}
                                    size="sm"
                                    onClick={() => {
                                        setLoop(!loop);
                                    }}
                                    style={{
                                        flex: '1 0 auto',
                                        margin: '0px 5px',
                                    }}
                                >
                                    <span className="fa-layers fa-fw">
                                        <FontAwesomeIcon
                                            icon={faSyncAlt}
                                            style={{ marginRight: '4px' }}
                                        />
                                        {!loop && (<FontAwesomeIcon icon={faSlash}/>)}
                                    </span>
                                </Button>
                            </InputGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    display: route.length > 0 ? 'inherit' : 'none',
                }}
            >
                <RouteDetails
                    route={route}
                    backgroundColor="rgba(200, 200, 200, 0.9)"
                    onMouseOver={() => {
                        map.setLayoutProperty('ephemeralPoint', 'visibility', 'visible');
                    }}
                    onMouseMove={(position: Position) => {
                        moveEphemeralpoint(new mapboxgl.LngLat(position[0], position[1]));
                    }}
                    onMouseOut={() => {
                        hideEphemeralPoint();
                    }}
                    setDistanceMarkers={updateRouteDistances}
                />
            </div>
            {
                gpxToExport && (
                    <ExportModal
                        gpx={gpxToExport}
                        geoPoints={courseGeoPoints}
                        onHide={() => {
                            setGpxToExport(undefined);
                        }}
                    />
                )
            }
        </>
    );
}
