import React, { useState } from 'react';
import { Button, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { Draw } from './Canvas';
import SVGCanvas, { StaticSVGCanvas } from './SVGCanvas';

interface Props {
    sendDescription: (description: string) => void;
    sendImage: (draws: Draw[]) => void;
    waiting: boolean;
    image?: Draw[];
    description?: string;
}

const Game: React.FC<Props> = ({
    sendDescription,
    sendImage,
    waiting,
    image,
    description,
}) => {
    return (
        <>
            {
                waiting
                ? (
                    <h5>Waiting for other players...</h5>
                )
                : description
                    ? (
                        <>
                            <h5>
                                {description}
                            </h5>
                            <SVGCanvas
                                onSend={(draws: Draw[]) => {
                                    sendImage(draws);
                                }}
                            />
                        </>
                    )
                    : (
                        <Description
                            image={image}
                            onSend={(description: string) => {
                                sendDescription(description);
                            }}
                        />
                    )
            }
        </>
    )
}

export default Game;

interface DescriptionProps {
    image?: Draw[];
    onSend: (description: string) => void;
}

const Description: React.FC<DescriptionProps> = ({ image, onSend }) => {
    const [description, setDescription] = useState('');
    return (
        <>
            <h5>
                {
                    !image
                        ? 'Write something for the next person to draw:'
                        : 'Describe the following drawing'
                }
            </h5>
            {
                image && (<StaticSVGCanvas draws={image} />)
            }
            <FormGroup>
                <InputGroup>
                    <FormControl
                        as="textarea"
                        onChange={(e) => {
                            setDescription(e.currentTarget.value);
                        }}
                    />
                    <InputGroup.Append>
                        <Button
                            variant="success"
                            onClick={() => {
                                onSend(description);
                            }}
                        >
                            Send
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </FormGroup>
        </>
    );
}