import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Position } from './Canvas';
import { hslFor } from './SVGCanvas';

const GradientColors = 20;
const gradient = (saturation: number) => {
    return `linear-gradient(180deg, ${Array.from(Array(GradientColors).keys()).map(k => `hsl(${k * (360 / GradientColors)}, ${saturation}%, 50%)`).join(',')})`;
}

interface Props {
    onPick: (hue: number) => void;
    saturation: number;
}

export const GetMouseEventRelativePositionForHTMLElementRef = (event: MouseEvent|TouchEvent, element: HTMLElement|null): Position | undefined => {
    let eventX, eventY;
    if (event instanceof MouseEvent) {
        eventX = event.pageX;
        eventY = event.pageY;
    } else {
        eventX = event.touches[0].pageX;
        eventY = event.touches[0].pageY;
    }
    
    return !element
        ? undefined
        : {
            x: eventX - element.getBoundingClientRect().x,
            y: eventY - element.getBoundingClientRect().y,
        };
}

const PickerHeight = 150;

const HuePicker: React.FC<Props> = ({ onPick, saturation }) => {
    const pickerRef = useRef<HTMLDivElement>(null);
    const [picking, setPicking] = useState(false);
    const [position, setPosition] = useState<number | undefined>(undefined);
    const [hue, setHue] = useState(0);

    const startPicking = (event: MouseEvent|TouchEvent) => {
        const position = GetMouseEventRelativePositionForHTMLElementRef(event, pickerRef.current);
        if (position) {
            setPosition(position.y);
            setPicking(true);
            const pickedHue = translatePositionToHue(position.y || 0)
            setHue(pickedHue);
            onPick(pickedHue);
        }
    }

    const pick = (event: MouseEvent|TouchEvent) => {
        if (picking) {
            const currentPosition = GetMouseEventRelativePositionForHTMLElementRef(event, pickerRef.current);
            if (position && currentPosition) {
                setPosition(currentPosition.y);
                const pickedHue = translatePositionToHue(currentPosition.y || 0)
                setHue(pickedHue);
                onPick(pickedHue);
            }
        }
    }

    const stopPicking = () => {
        setPicking(false);
        setPosition(undefined);
        if (picking) {
            const pickedHue = translatePositionToHue(position || 0);
            setHue(pickedHue);
            onPick(pickedHue);
        }
    }

    const translatePositionToHue = (position: number) => {
        return (position / PickerHeight) * 360;
    }

    const startPickingCallback = useCallback(startPicking, [onPick]);
    const pickCallback = useCallback(pick, [picking, position, onPick]);
    const stopPickingCallback = useCallback(stopPicking, [picking, position, onPick]);

    useEffect(() => {
        const { current: picker } = pickerRef;
        if (!picker) {
            return;
        }
        picker.addEventListener('mousedown', startPickingCallback);
        picker.addEventListener('mousemove', pickCallback);
        picker.addEventListener('mouseup', stopPickingCallback);
        picker.addEventListener('mouseleave', stopPickingCallback);
        picker.addEventListener('touchstart', startPickingCallback);
        picker.addEventListener('touchmove', pickCallback);
        picker.addEventListener('touchend', stopPickingCallback);
        return () => {
            picker.removeEventListener('mousedown', startPickingCallback);
            picker.removeEventListener('mousemove',pickCallback );
            picker.removeEventListener('mouseup', stopPickingCallback);
            picker.removeEventListener('mouseleave', stopPickingCallback);
            picker.removeEventListener('touchstart', startPickingCallback);
            picker.removeEventListener('touchmove', pickCallback);
            picker.removeEventListener('touchend', stopPickingCallback);
        };
    })

    return (
        <div>
            <div
                style={{
                    position: 'absolute',
                    display: picking ? undefined : 'none',
                    marginLeft: '-20px',
                    marginTop: '-10px',
                }}
            >
                <svg
                    width="20px"
                    height="20px"
                    viewBox="-10 -10 120 120"
                    style={{
                        position: 'absolute',
                        top: position,
                    }}
                >
                    <path
                        d="M 100,50 L 50 0 C 25,0,0,25,0,50 C 0,75,25,100,50,100 Z"
                        fill={hslFor(hue, saturation, 50)}
                        stroke="#888"
                        strokeWidth="5px"
                    />
                </svg>
            </div>
            <div
                ref={pickerRef}
                style={{
                    height: `${PickerHeight}px`,
                    background: gradient(100),
                    // border: `1px solid ${color}`,
                    borderRadius: '5px',
                }}
            />            
        </div>
    );
}

export default HuePicker;