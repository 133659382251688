import { faClipboard, faExclamationTriangle, faPlay, faTerminal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import FullScreenMenu from 'src/components/fullScreenMenu';
import { APIBase, DescriptionMessage, DrawgressionData, escapeHTML, GameState, GetTimestamp, ImageMessage, Message, MessageType, Player, ShuffleArray } from './Common';
import LogViewer, { Log, Severity } from './LogViewer';
import { Button, Col, Container, Row } from 'react-bootstrap';
import PlayerStatus from './PlayerStatus';
import Game from './Game';
import { Draw } from './Canvas';
import ChainViewer from './ChainViewer';
import Peer from 'peerjs';

interface Props {
    username: string;
    room: string;
}

const gameDataToString = (gameState: GameState) => {
    const { order, data } = gameState;
    const rows: string[] = [];
    // console.log(`converting game data to string, order.length: ${order.length}`);

    for (let playerSpot = 0; playerSpot < order.length; playerSpot++) {
        const playerId = order[playerSpot];
        const shortPlayerId = playerId.substr(0,3).padEnd(3, " ");
        const row: string[] = [shortPlayerId];
        const playerData = data[playerId];
        const { descriptions, pictures } = playerData;
        const chainLength = descriptions.length + pictures.length;
        // console.log(`${shortPlayerId} descriptions: ${descriptions.length} pictures: ${pictures.length} chainLength ${chainLength}`);
        for (let chainSpot = 0; chainSpot < order.length; chainSpot++) {
            const filled = (chainLength - 1) >= chainSpot;
            const fill = !filled
                ? ' - '
                : 
                (
                    (chainSpot % 2)
                        ? ' I '
                        : ' D '
                );
            // console.log(`${shortPlayerId} going to fill spot (${chainSpot}/${order.length})? ${filled} (${fill})`);
            row.push(fill);
        }
        
        const rowString = row.join('|');
        // console.log(`${shortPlayerId} row: ${rowString}`);
        rows.push(rowString);
    }
    const gameDataString = rows.join('\n');
    // console.log(gameDataString);
    return gameDataString;
}

// const PlayerCount = 10;
const getNextMovesForGameStateAndId = (gameState: GameState, id: string, moveId: string) => {
    const { order, data } = gameState;
    const moves: { [id: string]: string } = {};

    // Looking for two moves overall:
    // -  one for if anyone can "accept" the chain that was moved on
    // -  one for if the current id has a chain ready to accept
    
    const initialOrderIndex = order.indexOf(id);
    for (var i = 0; i < order.length; i++) {
        let orderIndex = initialOrderIndex + i;
        let normalizedOrderIndex = orderIndex % order.length;
        let playerId = order[normalizedOrderIndex];
        let { pictures, descriptions } = data[playerId];
        let chainLength = pictures.length + descriptions.length;
        if (chainLength === i) {
            moves[id] = playerId;
            break;
        }
    }

    const moveOrderIndex = order.indexOf(moveId);
    const nextOrderIndex = moveOrderIndex === 0 ? (order.length - 1) : (moveOrderIndex - 1);
    const nextPlayerId = order[nextOrderIndex];
    const nextPlayerData = data[nextPlayerId];
    const { pictures, descriptions } = nextPlayerData;
    const chainLength = pictures.length + descriptions.length;
    
    const currentChain = data[moveId];
    const { pictures: currentChainPictures, descriptions: currentChainDescriptions } = currentChain;
    const currentChainLength = currentChainPictures.length + currentChainDescriptions.length;

    if ((chainLength >= currentChainLength) && (chainLength !== order.length)) {
        const followingOrderIndex = initialOrderIndex === 0 ? (order.length - 1) : (initialOrderIndex - 1);
        const followingPlayerId = order[followingOrderIndex];
        moves[followingPlayerId] = moveId;
    }

    return moves;
}

enum UpdateGameActionsTypes {
    'Fresh',
    'Image',
    'Description'
};

interface UpdateGameAction {
    type: UpdateGameActionsTypes,
}

interface FreshGameStateAction extends UpdateGameAction {
    playerIds: string[];
}

interface PlayerAction extends UpdateGameAction {
    id: string;
    moveId: string;
    order: string[];
    callback: (moves: { [id: string]: string }, updatedGameData: {[id: string]: DrawgressionData}) => void;
}

interface ImageGameStateAction extends PlayerAction {
    draws: Draw[];
}

interface DescriptionGameStateAction extends PlayerAction {
    description: string;
}

type UpdateGameActions = FreshGameStateAction|ImageGameStateAction|DescriptionGameStateAction;

const updateGameData = (gameData: {[key: string]: DrawgressionData}, action: UpdateGameActions) => {
    const { type } = action;
    if (type === UpdateGameActionsTypes.Fresh) {
        return (action as FreshGameStateAction).playerIds.reduce(
            (accumulator: { [key: string] : DrawgressionData }, id): { [key: string]: DrawgressionData } => {
            accumulator[id] = {
                descriptions: [],
                pictures: [],
            };
            return accumulator;
        }, {})
    } else {
        let updatedGameData;
        const { moveId, id, order, callback } = (action as PlayerAction);
        if (type === UpdateGameActionsTypes.Image) {
            const { draws } = (action as ImageGameStateAction);
            updatedGameData = {
                ...gameData,
                [moveId]: {
                    ...gameData[moveId],
                    pictures: gameData[moveId].pictures.concat([draws]),
                },
            };
        } else if (type === UpdateGameActionsTypes.Description) {
            const { description } = (action as DescriptionGameStateAction);
            updatedGameData = {
                ...gameData,
                [moveId]: {
                    ...gameData[moveId],
                    descriptions: gameData[moveId].descriptions.concat([description]),
                },
            };
        } else {
            return gameData;
        }
        const tempGameState = { data: updatedGameData, players: {}, order };
        console.log(`updatedGameState:\n${gameDataToString(tempGameState)}`);
        const moves = getNextMovesForGameStateAndId(tempGameState, id, moveId);
        callback(moves, updatedGameData);

        return updatedGameData;
    }
}

const Host: React.FC<Props> = ({ username, room }) => {
    const [logsOpen, setLogsOpen] = useState(false);
    const [roomReady, setRoomReady] = useState(true);
    const [logs, setLogs] = useState<Log[]>([]);
    const [gameData, dispactchGameData] = useReducer(updateGameData, {});
    const [order, setOrder] = useState<string[]>([]);
    const [players, setPlayers] = useState<{[key: string]: Player}>({
        '-1': {
            name: username,
            state: 'connected',
        }
    });
    const [peer] = useState(new Peer());
    const [connections, setConnections] = useState<{[key: string]: Peer.DataConnection}>({});
    const [actionId, setActionId] = useState<string>();
    const [image, setImage] = useState<Draw[]>();
    const [description, setDescription] = useState<string>();
    const [waiting, setWaiting] = useState(true);
    const [bail, setBail] = useState(false);
    const connectionsRef = useRef(connections);
    connectionsRef.current = connections;
    const orderRef = useRef(order);
    orderRef.current = order;

    const log = (message: string, severity?: Severity) => {
        setLogs((previousLogs) => {
            return previousLogs.concat({ message, time: GetTimestamp(), severity });
        });
    }

    useEffect(() => {
        Object.keys(connectionsRef.current).filter(id => {
            const connection = connectionsRef.current[id];
            return connection.open &&
                connection.dataChannel.readyState === 'open' &&
                connection.peerConnection.connectionState === 'connected';
        }).forEach(id => {
            log(`sending [${id}]: ${JSON.stringify(players)}`);
            const message: Message = {
                data: { players },
                type: MessageType.Players,
            };
            connectionsRef.current[id].send(JSON.stringify(message))
        })
    }, [players]);


    useEffect(() => {
        Object.keys(connectionsRef.current).filter(id => {
            const connection = connectionsRef.current[id];
            return connection.open &&
                connection.dataChannel.readyState === 'open' &&
                connection.peerConnection.connectionState === 'connected';
        }).forEach(id => {
            const connection = connectionsRef.current[id];
            const message: Message = {
                data: { order },
                type: MessageType.Order,
            }
            connection.send(JSON.stringify(message));
            // Send initial message to start by describing a null image
            const startMessage: Message = {
                data: {
                    id: id,
                },
                type: MessageType.Start,
            };
            connection.send(JSON.stringify(startMessage));
        });
        setDescription(undefined);
        setImage(undefined);
        setActionId("-1");
        setWaiting(false);
    }, [order]);

    useEffect(() => {
        const playerIds = Object.keys(gameData);
        const gameSize = orderRef.current.length;
        const gameOver = playerIds.every(playerId => {
            const data = gameData[playerId];
            const { descriptions, pictures } = data;
            return (descriptions.length + pictures.length) === gameSize;
        });
        log(`useEffect on gameData:\n${gameDataToString({ data: gameData, players: {}, order: orderRef.current })}`);
        if (gameOver) {
            log(`game over, all chains are full`);
            Object.keys(connectionsRef.current).filter(id => {
                const connection = connectionsRef.current[id];
                return connection.open &&
                    connection.dataChannel.readyState === 'open' &&
                    connection.peerConnection.connectionState === 'connected';
            }).forEach(id => {
                const message: Message = {
                    data: { data: gameData },
                    type: MessageType.Data,
                };
                connectionsRef.current[id].send(JSON.stringify(message));
            })
        }
    }, [gameData]);

    const handleMessage = useCallback((message: Message, id: string) => {
        log(`[${id}]: ${JSON.stringify(message)}`);

        const findMovesAndSend = (moves: { [id: string]: string }, updatedGameData: {[id: string]: DrawgressionData}) => {
            log(`finding moves based on:\n${gameDataToString({ data: updatedGameData, order: orderRef.current, players: {} })}`)
            Object.keys(moves).forEach(playerId => {
                const nextMoveId = moves[playerId];
                const { pictures, descriptions } = updatedGameData[nextMoveId];
                const drawing = pictures.length < descriptions.length;
                const description = drawing ? descriptions[descriptions.length - 1] : undefined;
                const image = drawing ? undefined : pictures[pictures.length - 1];
                if (playerId === '-1') {
                    setImage(image);
                    setDescription(description);
                    setWaiting(false);
                    setActionId(nextMoveId);
                } else {
                    let message: Message;
                    if (drawing && description) {
                        message = {
                            data: { description, id: nextMoveId },
                            type: MessageType.Description,
                        };
                    } else {
                        message = {
                            data: { draws: image, id: nextMoveId },
                            type: MessageType.Image,
                        };
                    }
                    connectionsRef.current[playerId].send(JSON.stringify(message));
                }
            });
        }

        if (message.type === MessageType.Description) {
            const data = (message.data as DescriptionMessage);
            const { description, id: moveId } = data;
            dispactchGameData({
                type: UpdateGameActionsTypes.Description,
                moveId,
                id,
                description,
                order: orderRef.current,
                callback: findMovesAndSend,
            });
        } else if (message.type === MessageType.Image) {
            const data = (message.data as ImageMessage);
            const { draws, id: moveId } = data;
            dispactchGameData({
                type: UpdateGameActionsTypes.Image,
                moveId,
                id,
                draws,
                order: orderRef.current,
                callback: findMovesAndSend,
            });
        } else if (message.type === MessageType.Name) {
            const data = (message.data as string);
            setPlayers((currentPlayers) => {
                const updatedPlayers = { ...currentPlayers };
                updatedPlayers[id] = {
                    name: escapeHTML(data.substring(0,25)),
                    state: connectionsRef.current[id].peerConnection.iceConnectionState,
                };
                return updatedPlayers;
            });
        }
    }, []);

    const handlePeerConnection = useCallback((dataConnection: Peer.DataConnection) => {
        log('Handling peer connection, adding data connection to pool');
        const dataConnectionPeer = dataConnection.peer;
        const handleData = (data: any) => {
            handleMessage(JSON.parse(data), dataConnectionPeer);
        }
        dataConnection.on('data', handleData);
        dataConnection.on('close', () => {
            dataConnection.off('data', handleData);
            setConnections((previousConnections) => {
                const updatedDataConnections = {
                    ...previousConnections,
                };
                delete updatedDataConnections[dataConnectionPeer];
                return updatedDataConnections;
            });
            setPlayers((currentPlayers) => {
                const updatedPlayers = { ...currentPlayers };
                updatedPlayers[dataConnectionPeer].state = 'closed';
                return updatedPlayers;
            });
        });
        dataConnection.on('error', (err: any) => {
            console.warn('data connection error', dataConnectionPeer, err);
            log(`[${dataConnectionPeer}]: ${err}`, 'Error');
        });

        setConnections((previousConnections) => {
            const updatedDataConnections = {
                ...previousConnections,
            };
            updatedDataConnections[dataConnectionPeer] = dataConnection;
            return updatedDataConnections;
        });
    }, [handleMessage]);

    useEffect(() => {
        peer.on('connection', handlePeerConnection);
        peer.on('close', () => {
            log('got a close', 'Warning');
        });
        peer.on('disconnected', () => {
            log('got a disconnection', 'Error');
        });
        peer.on('error', (err: any) => {
            log(`${err}`, 'Error');
        });
        peer.on('open', (id: string) => {
            log(`peer open: ${id}, sending room request: ${room}`);
            fetch(
                `${APIBase}/room/${room}`,
                {
                    method: 'POST',
                    body: id,
                })
                .then((response: Response) => {
                    log(`Room has been created`);
                    setRoomReady(true);
                })
                .catch((e) => {
                    log(`error: ${e}`)
                })
        });

        return () => {
            peer.disconnect();
        }
    }, [peer, handlePeerConnection, room]);

    const startGame = useCallback(() => {
        log('starting game');
        const updatedPlayers  = Object.keys(players).reduce(
            (accumulator: { [key: string] : Player }, id) => {
            if (players[id].state === 'connected') {
                accumulator[id] = players[id];
            }
            return accumulator;
        }, {});

        setOrder(ShuffleArray(Object.keys(updatedPlayers)));
        dispactchGameData({ type: UpdateGameActionsTypes.Fresh, playerIds: Object.keys(players) });
        setPlayers(updatedPlayers);
    }, [players]);

    const playerCount = Object.keys(connections).length;
    const quorum = playerCount > 0;
    const ids = Object.keys(gameData);
    const gameHasStarted = order.length > 0;
    const gameOver = roomReady && (ids.length !== 0) && ids.every(id => {
        const { descriptions, pictures } = gameData[id];
        const chainLength = descriptions.length + pictures.length;
        return (chainLength !== 0) && (chainLength === ids.length);
    });

    return (
        <div style={{ height: '100%', width: '100%', overflow: gameOver ? 'scroll': 'hidden'}}>
            <FullScreenMenu
                isOpen={logsOpen}
                onMenuToggle={() => {
                    setLogsOpen(false);
                }}
                noOpenButton={true}
            >
                <LogViewer logs={logs}/>
            </FullScreenMenu>
            <FontAwesomeIcon
                style={{ position: 'absolute', top: 10, right: 10 }}
                icon={faTerminal}
                onClick={() => {
                    setLogsOpen(true);
                }}
            />
            <FontAwesomeIcon
                style={{ position: 'absolute', top: 10, left: 10 }}
                icon={faExclamationTriangle}
                onClick={() => {
                    setBail(!bail);
                }}
            />
            <Container>
                <Row>
                    <Col sm={12}>
                        <h3>
                            Look at me. I'm the captain now.
                            <Button
                                style={{
                                    margin: '0px 4px'
                                }}
                                size="sm"
                                variant="info"
                                disabled={!roomReady}
                                onClick={() => {
                                    navigator.clipboard.writeText(`${window.location}`);
                                }}
                            >
                                <FontAwesomeIcon icon={faClipboard} style={{ marginRight: '4px' }} />
                                #{room}
                            </Button>
                            <Button
                                size="sm"
                                variant="success"
                                disabled={!roomReady || !quorum || (roomReady && quorum && !gameOver && gameHasStarted)}
                                onClick={startGame}
                            >
                                {
                                    gameOver
                                        ? 'New Game'
                                        : (
                                            <>Start Game <FontAwesomeIcon icon={faPlay} /></>
                                        )
                                }
                            </Button>
                        </h3>
                    </Col>
                </Row>
                <PlayerStatus
                    onKick={(id: string) => {
                        const updatedPlayers = { ...players };
                        delete updatedPlayers[id];
                        setPlayers(updatedPlayers);
                    }}
                    players={players}
                />
                {
                    (gameOver || bail) && (
                        <ChainViewer
                            gameState={{
                                players,
                                order,
                                data: gameData,
                            }}
                        />
                    )
                }
                {
                    gameHasStarted && actionId && !bail && (
                        <Game
                            sendDescription={(description: string) => {
                                setWaiting(true);
                                handleMessage(
                                    {
                                        type: MessageType.Description,
                                        data: { description, id: actionId },
                                    },
                                    "-1"
                                );
                            }}
                            sendImage={(draws: Draw[]) => {
                                setWaiting(true);
                                handleMessage(
                                    {
                                        type: MessageType.Image,
                                        data: { draws, id: actionId },
                                    },
                                    "-1"
                                );
                            }}
                            waiting={waiting}
                            image={image}
                            description={description}
                        />
                    )
                }
            </Container>
        </div>
    );
}

export default Host;