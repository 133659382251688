export const vertexShader = `
  uniform float vCorrection;
  uniform float renderVerticalScale;
  varying vec2 vUV;
  varying float vAmount;

  void main()
  {
    vUV = uv;
    vAmount = position.z / renderVerticalScale;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position.x, position.y, position.z - vCorrection, 1.0 );
  }
`

export const environmentMapVertexShader = `
  uniform float vCorrection;
  uniform float renderVerticalScale;
  varying vec2 vUV;
  varying float vAmount;
  varying vec2 vN;
  varying vec3 v_normal;

  void main() 
  {
    vUV = uv;
    v_normal = normal;
    vAmount = position.z / renderVerticalScale;

    vec4 p = vec4( position, 1. );

    vec3 e = normalize( vec3( modelViewMatrix * p ) );
    vec3 n = normalize( normalMatrix * normal );

    vec3 r = reflect( e, n );
    float m = 2. * sqrt(
      pow( r.x, 2. ) +
      pow( r.y, 2. ) +
      pow( r.z + 1., 2. )
    );
    vN = r.xy / m + .5;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(position.x, position.y, position.z - vCorrection, 1.0 );
  }
`

export const testFragmentShader4 = `
  #ifdef GL_ES
  precision highp float;
  #endif

  float cubicPulse( float center, float width, float x ){
    x = abs(x - center);
    x = min((x/width),1.0);
    return 1.0 - x*x*(3.0-2.0*x);
  }

  varying float vAmount;
  uniform sampler2D masterTexture;
  varying vec2 vUV;
  uniform float contourIncrement;
  uniform float contourIncrementHalf;

  void main() {
    float g = cubicPulse(contourIncrementHalf,1.0, mod(vAmount-contourIncrementHalf, contourIncrement));

    gl_FragColor = texture2D(masterTexture, vUV);
    if (g > 0.0)
    {
      gl_FragColor = vec4(0.698039 , 0.8470588 , 1.0, 1.0);
    }
  }
`

export const contourIncrementShader = `
  #ifdef GL_ES
  precision highp float;
  #endif

  float cubicPulse( float center, float width, float x ){
    x = abs(x - center);
    x = min((x/width),1.0);
    return 1.0 - x*x*(3.0-2.0*x);
  }

  varying float vAmount;
  uniform sampler2D masterTexture;
  varying vec2 vUV;
  uniform float contourIncrement;
  uniform float contourIncrementHalf;
  uniform float contourIncrementSmall;
  uniform float contourIncrementSmallHalf;
  uniform vec4 contourColor;

  void main() {
    float g = cubicPulse(contourIncrementHalf,1.0, mod(vAmount-contourIncrementHalf, contourIncrement));
    float g2 = cubicPulse(contourIncrementSmallHalf,0.25, mod(vAmount-contourIncrementSmallHalf, contourIncrementSmall));
    float combined = max(g, g2);

    gl_FragColor = texture2D(masterTexture, vUV);
    if (combined > 0.0)
    {
      gl_FragColor = mix(gl_FragColor, contourColor , 0.5);
    }
  }
`

export const environmentMapFragmentShader = `
  #ifdef GL_ES
  precision highp float;
  #endif

  float cubicPulse( float center, float width, float x ){
    x = abs(x - center);
    x = min((x/width),1.0);
    return 1.0 - x*x*(3.0-2.0*x);
  }

  uniform float contourIncrement;
  uniform float contourIncrementHalf;
  uniform sampler2D tMatCap;
  uniform float widthMultiplier;
  uniform vec4 contourColor;
  varying vec2 vUV;
  varying vec2 vN;
  varying vec3 v_normal;
  varying float vAmount;

  void main() {
    vec3 base = texture2D(tMatCap, vN).rgb;
    vec4 groundColor = vec4(base, 1.0);

    // adjust width of line based on normals to produce thinner line on flatter surfaces
    vec3 n_v_normal = normalize(v_normal);
    float adj = (1.0 / n_v_normal.z) - 1.0;
    float w = widthMultiplier * adj;

    float whr = contourIncrementHalf - (w/2.0);
    float y = mod(vAmount,contourIncrement);
    y = y - contourIncrementHalf;
    y = abs(y);
    y = step(whr,y);

    //float g = cubicPulse(contourIncrementHalf,1.0, mod(vAmount-contourIncrementHalf, contourIncrement));
    //gl_FragColor = mix(groundColor, contourColor , g);
    gl_FragColor = mix(groundColor, contourColor , y);
  }
`

export const HelloWorldVertexShader = `
  #ifdef GL_ES
  precision highp float;
  #endif
  varying vec2 vUv;

  void main()
  {
    vUv = uv;
    gl_Position = projectionMatrix *
                modelViewMatrix *
                vec4(position,1.0);
  }
`

export const modelShadowFragmentShader = `
  varying vec2 vUv;
  uniform float xLeftStart;
  uniform float xLeftEnd;
  uniform float xRightStart;
  uniform float xRightEnd;
  uniform float yLeftStart;
  uniform float yLeftEnd;
  uniform float yRightStart;
  uniform float yRightEnd;
  uniform vec3 baseColor;
  
  void main()
  {
     vec2 st = vUv;

    float y = smoothstep(xLeftStart,xLeftEnd,st.x) * 
        (1.0 - smoothstep(xRightStart, xRightEnd, st.x)) *
        smoothstep(yLeftStart,yLeftEnd,st.y) *
        (1.0 - smoothstep(yRightStart, yRightEnd, st.y));

    gl_FragColor = mix(vec4(baseColor, 1.0), vec4(0.0,0.0, 0.0, 1.0), y);
  }
`

export const basicVertexShader = `
  varying vec2 vN;

  void main() 
  {
    vec4 p = vec4( position, 1. );

    vec3 e = normalize( vec3( modelViewMatrix * p ) );
    vec3 n = normalize( normalMatrix * normal );

    vec3 r = reflect( e, n );
    float m = 2. * sqrt(
      pow( r.x, 2. ) +
      pow( r.y, 2. ) +
      pow( r.z + 1., 2. )
    );
    vN = r.xy / m + .5;

      gl_Position = projectionMatrix * modelViewMatrix * p;
  }
`

export const basicFragmentEnvironmentShader = `
  uniform sampler2D tMatCap;

  varying vec2 vN;

  void main() {
    vec3 base = texture2D( tMatCap, vN ).rgb;
    gl_FragColor = vec4( base, 1. );
  }
`

export const basicPhongVertexShader = `
  varying vec3 e;
  varying vec3 n;

  void main() {
    vec4 p = vec4( position, 1. );
    e = normalize( vec3( modelViewMatrix * p ) );
    n = normalize( normalMatrix * normal );

    gl_Position = projectionMatrix * modelViewMatrix * p;
  }
`

export const bufferPhongVertexShader = `
  varying vec3 e;
  varying vec3 n;

  uniform float vCorrection;
  uniform float renderVerticalScale;
  varying float vAmount;
  varying vec3 v_normal;

  void main() {
    vec4 p = vec4( position, 1. );
    e = normalize( vec3( modelViewMatrix * p ) );
    n = normalize( normalMatrix * normal );

    v_normal = normal;
    vAmount = position.z / renderVerticalScale;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(position.x, position.y, position.z - vCorrection, 1.0 );
  }
`

export const basicPhongFragmentEnvironmentShader = `
  uniform sampler2D tMatCap;

  varying vec3 e;
  varying vec3 n;

  void main() {
    vec3 r = reflect( e, n );
    float m = 2. * sqrt(
      pow( r.x, 2. ) +
      pow( r.y, 2. ) +
      pow( r.z + 1., 2. )
    );
    vec2 vN = r.xy / m + .5;

    vec3 base = texture2D( tMatCap, vN ).rgb;

    gl_FragColor = vec4( base, 1. );
  }
`

export const bufferPhongFragmentShader = `
  #ifdef GL_ES
  precision highp float;
  #endif

  float cubicPulse( float center, float width, float x ){
    x = abs(x - center);
    x = min((x/width),1.0);
    return 1.0 - x*x*(3.0-2.0*x);
  }

  uniform float contourIncrement;
  uniform float contourIncrementHalf;
  uniform sampler2D tMatCap;
  uniform float widthMultiplier;
  uniform vec4 contourColor;
  varying vec3 e;
  varying vec3 n;
  varying vec3 v_normal;
  varying float vAmount;

  void main() {
    vec3 r = reflect( e, n );
    float m = 2. * sqrt(
      pow( r.x, 2. ) +
      pow( r.y, 2. ) +
      pow( r.z + 1., 2. )
    );
    vec2 vN = r.xy / m + .5;

    vec3 base = texture2D(tMatCap, vN).rgb;
    vec4 groundColor = vec4(base, 1.0);

    // adjust width of line based on normals to produce thinner line on flatter surfaces
    vec3 n_v_normal = normalize(v_normal);
    float adj = (1.0 / n_v_normal.z) - 1.0;
    float w = widthMultiplier * adj;

    float whr = contourIncrementHalf - (w/2.0);
    float y = mod(vAmount,contourIncrement);
    y = y - contourIncrementHalf;
    y = abs(y);
    y = step(whr,y);

    gl_FragColor = mix(groundColor, contourColor , y);
  }
`