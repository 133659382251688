import * as React from 'react';
import { DataViewPropsBase, ActivityData } from './types';
import * as d3 from 'd3';
import './cumulative.css';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faSignal } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import SimplyGraph from 'src/lib/d3/SimplyGraph';

const Cumulative: React.FC<DataViewPropsBase> = ({
    activities,
}) => {
    const [cumulative, setCumulative] = useState(false);
    const [yearlyData, setYearlyData] = useState<any[]>([]);

    useEffect(() => {
        setYearlyData(Array.from(
            new Set(
                activities.map(
                    a => a.date.getFullYear()
                )
            )
        ).map(
            y => activities.filter(
                a => a.date.getFullYear() === y
            )
        ));
    }, [activities]);

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <SimplyGraph
                margins={{
                    top: 125,
                    left: 120,
                    right: 60,
                    bottom: 90,
                }}
                graphLines={yearlyData.map((yearData) => {
                    const fullYear = yearData[0].date.getFullYear();
                    const name = fullYear.toString();
                    return {
                        name,
                        dataPoints: yearData,
                    }
                })}
                yAccessor={cumulative ? 'totalCumulativeDistance' : 'yearlyCumulativeDistance'}
                xAccessor={cumulative ? 'date' : (activity: ActivityData) => {
                    return new Date(0,activity.date.getMonth(), activity.date.getDate());
                }}
                yAxisFormat={(a: number) => `${a.toFixed(2)} mi`}
                xAxisFormat={d3.timeFormat("%b %-d") as any}
                titleText='Yearly Mileage'
            />
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    marginTop: '10px',
                    marginRight: `30px`,
                }}
            >
                <ToggleButtonGroup
                    type="checkbox"
                    value={[cumulative.toString()]}
                    onChange={() => {
                        setCumulative(!cumulative);
                    }}
                    size='sm'
                >
                    <ToggleButton
                        id="cumulative-off"
                        value={false.toString()}
                        variant='outline-info'
                    >
                        <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '4px' }}/>
                        Yearly
                    </ToggleButton>
                    <ToggleButton
                        id="cumulative-on"
                        value={true.toString()}
                        variant='outline-info'
                    >
                        <FontAwesomeIcon icon={faSignal} style={{ marginRight: '4px' }}/>
                        Cumulative
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
        </div>
    );
}

export default Cumulative;