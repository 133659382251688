import * as THREE from 'three';
import { GetGeoJSONLineStringForArrayOfLongitudeLatitude } from 'src/lib/Utils/GeoJSON';
import tokml from 'tokml';
import togpx from 'togpx';
import { HelloWorldVertexShader, modelShadowFragmentShader } from './Shaders';
import { BufferGeometry } from 'three';
import { MeshLine, MeshLineMaterial } from 'three.meshline';
import { RGBColor } from 'react-color';
import { LayerProperties } from 'src/projects/Running/LayersControl';
import { HeatmapLayerPaint, PathPaint, RunNodesPaint } from '../../projects/Running/HeatmapUtils';
import mapboxgl, { GeoJSONSourceRaw, Visibility } from 'mapbox-gl';
import { getFileExtension } from './Strings';
import { convertGPXTrackToGeoJSONFeature, parseGPXData, readFileFromFileObject } from './GPX';

export const AWS_ELEVATION_NORMAL_URL = 'https://s3.amazonaws.com/elevation-tiles-prod/normal/{z}/{x}/{y}.png';

export const getURLFromTemplate = (template: string|string[]|null|undefined, properties: { x: number, y: number, z: number}) => {
  if (!template || !template.length) {
    return null;
  }
  if (Array.isArray(template)) {
    const index = Math.abs(properties.x + properties.y) % template.length;
    template = template[index];
  }

  const {x, y, z} = properties;
  return template
    .replace('{x}', x.toString())
    .replace('{y}', y.toString())
    .replace('{z}', z.toString())
    .replace('{-y}', (Math.pow(2, z) - y - 1).toString());
}

export enum MapboxStyle {
  SATELLITE = 'satellite',
  DARK = 'dark',
  SKIING = 'skiing',
  TOPOGRAPHIC = 'topographic',
  MONOCHROME = 'monochrome',
}

export const STYLES: { [key in MapboxStyle] : string } = {
  satellite:   'ckl17nx12076y17rukwieltrw',
  dark:        'ckjc7r8kk1xho19mgmkt8vpd7',
  skiing:      'ckl19r4k909cq17qm9ywjusf4',
  topographic: 'ckcgof9ci0rlf1ipea1oern43',
  monochrome:  'cl0q9kgs6000c14o5mmb0bwmk',
};
export const GetMapboxStyleUrl = (style: MapboxStyle, userId: string = 'jogrover') => {
  return `mapbox://styles/${userId}/${STYLES[style]}`;
}

export const GetMapboxTileUrl = (style: MapboxStyle, userId: string = 'jogrover') => {
  return `https://api.mapbox.com/styles/v1/${userId}/${STYLES[style]}`;
}

export function RGBColorToArray(color: RGBColor) {
  return [
    color.r / 255,
    color.g / 255,
    color.b / 255,
  ];
}

/* http://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#ECMAScript_.28JavaScript.2FActionScript.2C_etc..29 */
export function long2tile(lon : number, zoom: number) {
    return (Math.floor((lon+180)/360*Math.pow(2,zoom)));
}
  
export function lat2tile(lat: number, zoom: number) {
    return (Math.floor((1-Math.log(Math.tan(lat*Math.PI/180) + 1/Math.cos(lat*Math.PI/180))/Math.PI)/2 *Math.pow(2,zoom)));
}
  
export function tile2long(x: number, z: number) {
    return (x/Math.pow(2,z)*360-180);
}
  
export function tile2lat(y: number, z: number) {
    var n=Math.PI-2*Math.PI*y/Math.pow(2,z);
    return (180/Math.PI*Math.atan(0.5*(Math.exp(n)-Math.exp(-n))));
}

const DegreeToRadians = 0.017453292519943295;    // Math.PI / 180
const EARTH_RADIUS_METERS = 6371000;
const Sin = Math.sin;
const Cos = Math.cos;
const Atan2 = Math.atan2;
const Sqrt = Math.sqrt;

// https://www.movable-type.co.uk/scripts/latlong.html
export const MeterDistanceBetweenLatitudeLongitude = (
  { lat: Alat, lon: Alon }: { lat: number, lon: number },
  { lat: Blat, lon: Blon }: { lat: number, lon: number }
) => {
  /* WTF did this do?
  var a = 0.5 - Cos((lat2 - lat1) * DegreeToRadians)/2 +
    Cos(lat1 * DegreeToRadians) * Cos(lat2 * DegreeToRadians) *
    (1 - Cos((lon2 - lon1) * DegreeToRadians))/2;
  */
  const SinHalfLatitudeDeltaRadians = Sin(((Blat - Alat) * DegreeToRadians) / 2);
  const SinHalfLongitudeDeltaRadians = Sin(((Blon - Alon) * DegreeToRadians) / 2);

  const a = SinHalfLatitudeDeltaRadians * SinHalfLatitudeDeltaRadians +
    Cos(Alat * DegreeToRadians) * Cos(Blat * DegreeToRadians) *
    SinHalfLongitudeDeltaRadians * SinHalfLongitudeDeltaRadians;

  const c = 2 * Atan2(Sqrt(a), Sqrt(1-a));

  return EARTH_RADIUS_METERS * c; // 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

export const createShadowPlane = (yLeftStart: number, xLeftStart: number, size: number, background: number|THREE.Color) => {
    var smoothDistance = 0.05;
    var xLeftEnd = xLeftStart + smoothDistance;
    var xRightEnd = 1 - xLeftStart;
    var xRightStart = xRightEnd - smoothDistance;
  
    var yLeftEnd = yLeftStart + smoothDistance;
    var yRightEnd = 1 - yLeftStart;
    var yRightStart = yRightEnd - smoothDistance;
  
    var planeShaderMaterial = new THREE.ShaderMaterial(
      {
        uniforms: {
          xLeftStart: {value: xLeftStart},
          xLeftEnd: {value: xLeftEnd},
          xRightStart: {value: xRightStart},
          xRightEnd: {value: xRightEnd},
          yLeftStart: {value: yLeftStart},
          yLeftEnd: {value: yLeftEnd},
          yRightStart: {value: yRightStart},
          yRightEnd: {value: yRightEnd},
          baseColor: {value : background }
        },
        vertexShader:   HelloWorldVertexShader,
        fragmentShader: modelShadowFragmentShader,
        //shading: THREE.SmoothShading
      }
    );
  
    var geometry = new THREE.PlaneGeometry(size + 100, size + 100)
    var plane = new THREE.Mesh(geometry, planeShaderMaterial);
    plane.rotation.x = -Math.PI / 2
    plane.position.y = -10
    return plane;
}

// This is intended to produce an NxN grid for visualizing terrain DEM
// Given we have an WxH grid of height values (typically W === H for a single heightmap png),
/* Eg: a 4x4 heightmap:
┌┬┬┬┐
├┼┼┼┤
├┼┼┼┤
├┼┼┼┤
└┴┴┴┘
Since there are 16 distinct height values, we turn this into a 4x4 grid of vertices.
Each corner here is a vertex and a square is made of two triangular faces.
┌┬┬┐
├┼┼┤
├┼┼┤
└┴┴┘

The four attribute arrays will have the following lengths:
indices (faces, cosnist of three vertices, two per square):
  (W - 1)*(H -1) * 3 * 2
vertices (each vertex has 3 position values, XYZ):
  (W * H) * 3
normals (each face has 3 normal values, XYZ):
  (W - 1)*(H - 1) * 3 * 2
uv (each vertex has an XY position):
  (W * H) * 2

indices: [
  0,0,0  0,0,0  0,0,0,
  0,0,0  0,0,0  0,0,0,

  0,0,0  0,0,0  0,0,0,
  0,0,0  0,0,0  0,0,0,

  0,0,0  0,0,0  0,0,0,
  0,0,0  0,0,0  0,0,0
]

vertices: [
  0,0,0  0,0,0  0,0,0  0,0,0,
  0,0,0  0,0,0  0,0,0  0,0,0,
  0,0,0  0,0,0  0,0,0  0,0,0,
  0,0,0  0,0,0  0,0,0  0,0,0
]

normals: [
  0,0,0  0,0,0  0,0,0,
  0,0,0  0,0,0  0,0,0,

  0,0,0  0,0,0  0,0,0,
  0,0,0  0,0,0  0,0,0,

  0,0,0  0,0,0  0,0,0,
  0,0,0  0,0,0  0,0,0
]

uv: [
  0,0  0,0  0,0  0,0,
  0,0  0,0  0,0  0,0,
  0,0  0,0  0,0  0,0,
  0,0  0,0  0,0  0,0
]
*/

export const GetPlaneBufferGeometryAttributes = (
  planeWidth: number,
  planeHeight: number,
  widthOffset: number,
  heightOffset: number,
  verticesWidthCount: number,
  verticesHeightCount: number,
  unInvertYVertexPosition?: boolean) => {

  const invertY = (unInvertYVertexPosition === undefined || !unInvertYVertexPosition)
    ? -1
    : 1;

  const gridWidth = verticesWidthCount - 1;
  const gridHeight = verticesHeightCount - 1;

  const vertexSpacingWidth = planeWidth / gridWidth;
  const vertexSpacingHeight = planeHeight / gridHeight;

  // buffers
  const indicesLength = (gridHeight * gridWidth) * 3 * 2;
  const verticesLength = (verticesHeightCount * verticesWidthCount) * 3;
  const normalsLength = verticesLength;
  const uvsLength = (verticesHeightCount * verticesWidthCount) * 2;

  const indices = new Uint32Array(indicesLength);
  const vertices = new Float32Array(verticesLength);
  const normals = new Float32Array(normalsLength);
  const uvs = new Float32Array(uvsLength);

  // generate vertices, normals and uvs
  for (let row = 0; row < verticesHeightCount; row++ ) {
    const rowIndexPosition = row * verticesWidthCount;
    const rowVertexPosition = invertY * (row * vertexSpacingHeight - heightOffset);
    const rowUV = 1 - ( row / gridHeight );
    for (let column = 0; column < verticesWidthCount; column++ ) {
      // Vertex positions
      const currentVertexIndex = (rowIndexPosition * 3) + (column * 3);
      vertices[currentVertexIndex] = column * vertexSpacingWidth - widthOffset;
      vertices[currentVertexIndex + 1] = rowVertexPosition;

      // UV values
      const currentUVIndex = (rowIndexPosition * 2) + (column * 2)
      uvs[currentUVIndex] = column / gridWidth;
      uvs[currentUVIndex + 1] = rowUV;

      normals[currentVertexIndex] = 0;
      normals[currentVertexIndex + 1] = 0;
      normals[currentVertexIndex + 2] = 1;

      // Don't over-index
      if (column === (verticesWidthCount - 1) || row === (verticesHeightCount - 1)) {
        continue;
      }

      // indices
      const a = column + rowIndexPosition;
      const b = column + rowIndexPosition + verticesWidthCount;
      const c = column + 1 + rowIndexPosition + verticesWidthCount;
      const d = column + 1 + rowIndexPosition;

      const currentIndex = (row * gridWidth * 6) + (column * 6);

      indices[currentIndex] = a;
      indices[currentIndex + 1] = b;
      indices[currentIndex + 2] = d;
      indices[currentIndex + 3] = b;
      indices[currentIndex + 4] = c;
      indices[currentIndex + 5] = d;

      /* Face Normals?
      normals[currentIndex + 2] = 1;
      normals[currentIndex + 5] = 1;
      */
    }
  }
  /*
  // indices
  for (let iy = 0; iy < gridHeight; iy ++ ) {
    for (let ix = 0; ix < gridWidth; ix ++ ) {
      var a = ix + verticesWidthCount * iy;
      var b = ix + verticesWidthCount * ( iy + 1 );
      var c = ( ix + 1 ) + verticesWidthCount * ( iy + 1 );
      var d = ( ix + 1 ) + verticesWidthCount * iy;

      // faces
      var currentIndex = (iy * gridWidth * 6)+ (ix * 6)

      indices[currentIndex] = a
      indices[currentIndex + 1] = b
      indices[currentIndex + 2] = d
      indices[currentIndex + 3] = b
      indices[currentIndex + 4] = c 
      indices[currentIndex + 5] = d
    }
  }
  */

  return {
    indices,
    vertices,
    normals,
    uvs,
  };
}

// Re-implement PlaneBufferGeometry better, faster, stronger
export const MakePlaneBufferGeometry = (width: number, height: number, verticesWidthCount: number, verticesHeightCount: number , bufferGeometry: BufferGeometry) => {
    bufferGeometry.type = 'PlaneBufferGeometry';

    const {
      indices,
      vertices,
      normals,
      uvs,
    } = GetPlaneBufferGeometryAttributes(
      width,
      height,
      width / 2,
      height / 2,
      verticesWidthCount,
      verticesHeightCount
    );
  
    // build geometry
    bufferGeometry.setIndex( new THREE.Uint32BufferAttribute(indices, 1) );
    bufferGeometry.addAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
    bufferGeometry.addAttribute( 'normal', new THREE.Float32BufferAttribute( normals, 3 ) );
    bufferGeometry.addAttribute( 'uv', new THREE.Float32BufferAttribute( uvs, 2 ) );
}

export const CreateContourMesh = (color: string, width: number, geometry: any, options: any) => {
  var contourColor = parseInt(color.substring(1,color.length), 16)
  var contourLine = new MeshLine()
  if (options === undefined)
  {
      options = {}
  }
  options.color = new THREE.Color(contourColor)
  
  contourLine.setPoints(geometry, function() { return width; })
  var material = new MeshLineMaterial(options)
  var mesh = new THREE.Mesh(contourLine.geometry, material)
  return mesh;
}

export const LATITUDE_BRACKET_SIZE = 0.00045;
export const LATITUDE_BRACKET_SIZE_HALF = LATITUDE_BRACKET_SIZE / 2;
export const LONGITUDE_BRACKET_SIZE = 0.00067;
export const LONGITUDE_BRACKET_SIZE_HALF = LONGITUDE_BRACKET_SIZE / 2;

export interface Bracket {
  [bracket: number]: {
    [bracket: number]: number[][],
  }
}

export const BracketRunNodes = (runNodes: number[][]) => {
  const brackets: Bracket = {};

  for (let i = 0; i < runNodes.length; i++) {
    const [longitude, latitude] = runNodes[i];
    const longitudeBracket = Math.floor(longitude / LONGITUDE_BRACKET_SIZE);
    const latitudeBracket = Math.floor(latitude / LATITUDE_BRACKET_SIZE);
    if (!brackets[longitudeBracket]) {
      brackets[longitudeBracket] = {};
    }
    if (!brackets[longitudeBracket][latitudeBracket]) {
      brackets[longitudeBracket][latitudeBracket] = [];
    }
    brackets[longitudeBracket][latitudeBracket].push(runNodes[i]);
  }

  return brackets;
}

/*https://stackoverflow.com/a/10316616*/
export const ArgumentsEqual = (aArguments: any[], bArguments: any[]) => {
  const a = [...aArguments[0]].sort();
  const b = [...bArguments[0]].sort();
  
  if (a.length !== b.length) {
      return false;
  }

  for(var i = 0; i < a.length; i++) {
      const aItem = a[i];
      const bItem = b[i];
      if (aItem !== bItem) {
          return false;
      }
  }

  return true;
}

export const MAPBOX_ELEVATION_DECODER = {
  rScaler: 6553.6,
  gScaler: 25.6,
  bScaler: 0.1,
  offset: -10000
};

export interface MapLayerDefinition {
  controlProperties: LayerProperties;
  layerDefinition?: any;
  sourceDataOrId?: any | string;
}

export const EmptyGeoJsonFeatureCollection = (): GeoJSONSourceRaw => {
  return {
      type: 'geojson',
      data: {
          type: 'FeatureCollection',
          features: []
      }
  }
}

interface PathsProps {
  id?: string;
  source?: string;
  visibility?: Visibility;
}

export const PathsLayer = ({ id, source, visibility }: PathsProps): mapboxgl.AnyLayer => {
  return {
      id: id || 'paths',
      type: 'line',
      source: source || 'paths',
      paint: PathPaint,
      minzoom: 9,
      maxzoom: 19,
      layout: {
        visibility: visibility !== undefined ? visibility : 'visible',
      }
  };
}

export const HeatmapLayer = (id: string, paintColor: any[], sourceName: string): mapboxgl.AnyLayer => {
  return {
      id,
      type: 'heatmap',
      source: sourceName,
      maxzoom: 20,
      minzoom: 0,
      paint: HeatmapLayerPaint(paintColor as any),
  };
}

export const PointsLayer = (): mapboxgl.CircleLayer => {
  return {
      id: 'points',
      type: 'circle',
      source: 'points',
      paint: RunNodesPaint,
      minzoom: 19
  };
}
/*
const NodesLayer = () => {
  return {
      id: 'nodes',
      type: 'circle',
      source: 'composite',
      'source-layer': 'road',
      maxzoom: 22,
      filter: RoadNodesFilter,
      paint: RoadNodesPaint,
  };
}
*/

export const FindRelevantNodesForLatitudeLongitudePoint = (latitude: number, longitude: number, bracketedRunNodes: {
  [bracket: number]: {
      [bracket: number]: any[]
  }
}, debug: boolean = false) => {
  const longitudeBracket = Math.floor(longitude / LONGITUDE_BRACKET_SIZE);
  const latitudeBracket = Math.floor(latitude / LATITUDE_BRACKET_SIZE);
  if (debug) {
      // console.log('brackets', longitudeBracket, latitudeBracket);
  }

  let relevantNodes = bracketedRunNodes[longitudeBracket]
      ? (bracketedRunNodes[longitudeBracket][latitudeBracket] || [])
      : [];

  if (debug) {
      // console.log('direclty relevant:', relevantNodes);
  }

  const longitudeBracketPosition = Math.abs(longitude % LONGITUDE_BRACKET_SIZE);
  const latitudeBracketPosition = Math.abs(latitude % LATITUDE_BRACKET_SIZE);
  const checkLeft = (longitude < 0)
      ? longitudeBracketPosition > LONGITUDE_BRACKET_SIZE_HALF
      : longitudeBracketPosition < LONGITUDE_BRACKET_SIZE_HALF;
  const checkBelow = (latitude < 0)
      ? latitudeBracketPosition > LATITUDE_BRACKET_SIZE_HALF
      : latitudeBracketPosition < LATITUDE_BRACKET_SIZE_HALF;

  if (debug) {
      // console.log('checkLeft, checkBelow:', checkLeft, checkBelow, longitude);
      // console.log('mod', longitude % LONGITUDE_BRACKET_SIZE);
      // console.log('abs', Math.abs(longitude % LONGITUDE_BRACKET_SIZE), '<', LONGITUDE_BRACKET_SIZE_HALF);
  }

  if (checkLeft) {
      if (bracketedRunNodes[longitudeBracket - 1]) {
          relevantNodes = relevantNodes.concat(bracketedRunNodes[longitudeBracket - 1][latitudeBracket] || []);
          if (checkBelow) {
              relevantNodes = relevantNodes.concat(bracketedRunNodes[longitudeBracket - 1][latitudeBracket - 1] || []);
          } else {
              relevantNodes = relevantNodes.concat(bracketedRunNodes[longitudeBracket - 1][latitudeBracket + 1] || []);
          }
      }
  } else {
      if (bracketedRunNodes[longitudeBracket + 1]) {
          relevantNodes = relevantNodes.concat(bracketedRunNodes[longitudeBracket + 1][latitudeBracket] || []);
          if (checkBelow) {
              relevantNodes = relevantNodes.concat(bracketedRunNodes[longitudeBracket + 1][latitudeBracket - 1] || []);
          } else {
              relevantNodes = relevantNodes.concat(bracketedRunNodes[longitudeBracket + 1][latitudeBracket + 1] || []);
          }
      }
  }

  if (bracketedRunNodes[longitudeBracket]) {
      if (checkBelow) {
          relevantNodes = relevantNodes.concat(bracketedRunNodes[longitudeBracket][latitudeBracket - 1] || []);
      } else {
          relevantNodes = relevantNodes.concat(bracketedRunNodes[longitudeBracket][latitudeBracket + 1] || []);
      }
  }

  return relevantNodes;
}

export enum UPLOAD_TYPE { GEOJSON = 'json', GPX = 'gpx', UNKNOWN = '', XML = 'xml' }

export const GetDataFromFile = async (file: File) => {
  const extension = getFileExtension(file.name);
  if (extension === UPLOAD_TYPE.GPX || extension === UPLOAD_TYPE.GEOJSON) {
    const fileText = await readFileFromFileObject(file);
    return ParseDataByExtension(fileText, extension);
  } else {
    return null;
  }
}

export const ParseDataByExtension = (data: string, extension: string) => {
  if (extension === UPLOAD_TYPE.GPX) {
    const gpxData = parseGPXData(data);
    return convertGPXTrackToGeoJSONFeature(gpxData);
  }
  if (extension === UPLOAD_TYPE.GEOJSON) {
    return JSON.parse(data);
  }
  return null;
}

export const SmoothProfile = (profile: [number, number][], threshold: number) => {
  const smoothProfile: [number, number][] = [profile[0]];
  for (var i = 1; i < profile.length - 1; i++) {
    const previous = smoothProfile[smoothProfile.length - 1];
    const current = profile[i];
    if (Math.abs(previous[1] - current[1]) > threshold) {
      smoothProfile.push(current);
    }
  }
  smoothProfile.push(profile[profile.length - 1]);
  return smoothProfile;
}

export const SimplifyPath = (originalPath: [number,number][], threshold: number = 4) => {
  if (originalPath.length === 0) {
    return [];
  }
  const path: [number,number][] = [originalPath[0]];
  if (originalPath.length === 1) {
    return path;  
  }
  for (var i = 1; i < originalPath.length - 1; i++) {
    const previous = path[path.length - 1];
    const current = originalPath[i];
    const delta = MeterDistanceBetweenLatitudeLongitude(
      {
        lat: previous[1],
        lon: previous[0],
      },
      {
        lat: current[1],
        lon: current[0],
      }
    );
    if (delta >= threshold) {
      path.push(current);
    }
  }
  path.push(originalPath[originalPath.length - 1]);
  return path;
}

// https://stackoverflow.com/a/30832210
export const DownloadFile = (data: any, filename: string, type: string) => {
  const file = new Blob([data], { type });
  const a = document.createElement("a");
  const url = URL.createObjectURL(file);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(url);  
  }, 0);
}

const m1 = 111132.92;     // latitude calculation term 1
const m2 = -559.82;       // latitude calculation term 2
const m3 = 1.175;         // latitude calculation term 3
const m4 = -0.0023;       // latitude calculation term 4
const p1 = 111412.84;     // longitude calculation term 1
const p2 = -93.5;         // longitude calculation term 2
const p3 = 0.118;         // longitude calculation term 3

export const getLatitudeLongitudeLengthsForLatitude = (inputLat: number) => {
  const lat = inputLat * DegreeToRadians;
  // Calculate the length of a degree of latitude and longitude in meters
  return {
    latlen: m1 +
      (m2 * Math.cos(2 * lat)) +
      (m3 * Math.cos(4 * lat)) +
      (m4 * Math.cos(6 * lat)),
    longlen: (p1 * Math.cos(lat)) +
      (p2 * Math.cos(3 * lat)) +
      (p3 * Math.cos(5 * lat))};
}

export interface Line {
  name: string;
  data: [number,number][];
  color: [number, number, number];
  opacity: number;
  visible: boolean;
}

export const SaveLocalGeoJSON = (line: Line) => {
  const geoJSON = LineToGeoJSON(line);
  DownloadFile(JSON.stringify(geoJSON), `${line.name}.json`, 'application/json');
}

export const SaveLocalGPX = (line: Line) => {
  const geoJSON = LineToGeoJSON(line);
  const gpx = togpx(geoJSON);
  DownloadFile(gpx, `${line.name}.gpx`, 'application/');
}

export const SaveLocalKML = (line: Line) => {
  const geoJSON = LineToGeoJSON(line);
  const kml = tokml(geoJSON);
  DownloadFile(kml, `${line.name}.kml`, 'application/vnd.google-earth.kml+xml');
}

export const LineToGeoJSON = (line: Line) => {
  const geoJSON = GetGeoJSONLineStringForArrayOfLongitudeLatitude(line.data);
  geoJSON.properties['name'] = line.name;
  geoJSON.properties['color'] = line.color;
  return geoJSON;
}