import * as React from 'react';
import { ParseCSVText } from 'src/lib/Utils/CSV';
import { useCallback, useEffect, useState } from 'react';
import SimplyGraph from 'src/lib/d3/SimplyGraph';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVials, faChartLine, faChartArea } from '@fortawesome/free-solid-svg-icons';
import * as d3 from 'd3';

const COVID_TRACKING_SOURCE = "https://covidtracking.com/"
const DATA_URL_ROOT = "/data/covid-trajectory"
const COVID_TRACKING_DATA_URL = `https://s3.us-west-2.amazonaws.com/jrgrover.com${DATA_URL_ROOT}/daily.json`;
const STATE_POPULATIONS_URL = `${DATA_URL_ROOT}/StatePopulations.csv`;
const STATE_DATES_URL = `${DATA_URL_ROOT}/dates.json`;

interface StateData {
    state: string;
    date: Date;
    daySinceHundredthCase: number;
    daySinceHundredthDeath: number;
    perCapitaValue?: number;
    perCapitaDeath?: number;
    totalTestResultsIncrease: number;
    perCapitaTotalTestResultsIncrease: number;
    positiveIncrease: number;
    deathIncrease: number;
    positivePastWeek?: number;
    deathPastWeek?: number;
    perCapitaPositivePastWeek?: number;
    perCapitaDeathPastWeek?: number;
    positive: number;
    death?: number;
    value?: number;
    positiveTestPercent?: number;
    totalTestResults?: number;
}

interface PopulationData {
    population: number;
    region: string;
    name: string;
}

interface ProcessedData {
    name: string;
    values: StateData[];
    dates: {
        start: Date | null;
        stop: Date | null;
    };
}

enum XVALUES {
    DAY_SINCE_HUNDREDTH_DEATH = 'daySinceHundredthDeath',
    DAY_SINCE_HUNDREDTH_CASE = 'daySinceHundredthCase',
    PER_CAPITA_DEATH = 'perCapitaDeath',
    DEATH = 'death',
    PER_CAPITA_VALUE = 'perCapitaValue',
    VALUE = 'value',
    DATE = 'date',
}

enum YVALUES {
    PER_CAPITA_DEATH_PAST_WEEK = 'perCapitaDeathPastWeek',
    PER_CAPITA_POSITIVE_PAST_WEEK = 'perCapitaPositivePastWeek',
    DEATH_PAST_WEEK = 'deathPastWeek',
    POSITIVE_PAST_WEEK = 'positivePastWeek',
    POSITIVE_TEST_PERCENT = 'positiveTestPercent',
    PER_CAPITA_DEATH = 'perCapitaDeath',
    PER_CAPITA_VALUE = 'perCapitaValue',
    DEATH = 'death',
    VALUE = 'value',
}

const DATE_PATTERN = /(\d{4})(\d{2})(\d{2})/;
const COLORS = d3.scaleOrdinal([
    "#1f77b4","#aec7e8","#ff7f0e","#ffbb78","#2ca02c","#98df8a","#d62728","#ff9896","#9467bd","#c5b0d5","#8c564b","#c49c94","#e377c2","#f7b6d2","#7f7f7f","#c7c7c7","#bcbd22","#dbdb8d","#17becf","#9edae5",
    "#393b79","#5254a3","#6b6ecf","#9c9ede","#637939","#8ca252","#b5cf6b","#cedb9c","#8c6d31","#bd9e39","#e7ba52","#e7cb94","#843c39","#ad494a","#d6616b","#e7969c","#7b4173","#a55194","#ce6dbd","#de9ed6",
    "#3182bd","#6baed6","#9ecae1","#c6dbef","#e6550d","#fd8d3c","#fdae6b","#fdd0a2","#31a354","#74c476","#a1d99b","#c7e9c0","#756bb1","#9e9ac8","#bcbddc","#dadaeb","#636363","#969696","#bdbdbd","#d9d9d9",
]);

const CovidTrajectory: React.FC = () => {
    const [data, setData] = useState<ProcessedData[]>();
    const [linear, setLinear] = useState(false);
    const [lockToHundredthDay, setLockToHundredthDay] = useState(false);
    const [perCapita, setPerCapita] = useState(false);
    const [deaths, setDeaths] = useState(false);
    const [trajectory, setTrajectory] = useState(false);
    const [positiveTestPercent, setPositiveTestPercent] = useState(false);
    const [xScale, setXScale] = useState<d3.ScaleTime<any,any,any>|d3.ScaleLinear<any,any,any>>(() => d3.scaleTime());
    const [yScale, setYScale] = useState<d3.ScaleLogarithmic<any,any,any>|d3.ScaleLinear<any,any,any>>(() => d3.scaleLog());
    const [xAccessor, setXAccessor] = useState('date');
    const [yAccessor, setYAccessor] = useState('value');
    const [yAxisLabel, setYAxisLabel] = useState('Date');
    const [xAxisLabel, setXAxisLabel] = useState('Date');

    useEffect(() => {
        const yScaleUpdate = linear
            ? d3.scaleLinear()
            : d3.scaleLog();
        const xScaleUpdate = trajectory
            ? linear
                ? d3.scaleLinear()
                : d3.scaleLog()
            : lockToHundredthDay
                ? d3.scaleLinear()
                : d3.scaleTime();

        setXScale(() => xScaleUpdate);
        setYScale(() => yScaleUpdate);
        setXAccessor(
            lockToHundredthDay
                ? deaths
                    ? XVALUES.DAY_SINCE_HUNDREDTH_DEATH
                    : XVALUES.DAY_SINCE_HUNDREDTH_CASE
                : trajectory
                    ? deaths
                        ? perCapita
                            ? XVALUES.PER_CAPITA_DEATH
                            : XVALUES.DEATH
                        : perCapita
                            ? XVALUES.PER_CAPITA_VALUE
                            : XVALUES.VALUE
                    : XVALUES.DATE
        );
        setYAccessor(
            trajectory
                ? perCapita
                    ? deaths
                        ? YVALUES.PER_CAPITA_DEATH_PAST_WEEK
                        : YVALUES.PER_CAPITA_POSITIVE_PAST_WEEK
                    : deaths
                        ? YVALUES.DEATH_PAST_WEEK
                        : YVALUES.POSITIVE_PAST_WEEK
                : positiveTestPercent
                    ? YVALUES.POSITIVE_TEST_PERCENT
                    : perCapita
                        ? deaths
                            ? YVALUES.PER_CAPITA_DEATH
                            : YVALUES.PER_CAPITA_VALUE
                        : deaths
                            ? YVALUES.DEATH
                            : YVALUES.VALUE
        );
        const typeText = deaths
            ? 'Deaths'
            : 'Confirmed Cases';
        const capitaText = perCapita ? ' Per 1M' : '';
        setYAxisLabel(trajectory
            ? `${typeText}${capitaText} in the Past Week`
            : `Cumulative ${typeText}${capitaText}`);

        setXAxisLabel(trajectory
            ? `Total Confirmed ${deaths ? 'Deaths' : 'Confirmed Cases'}${perCapita ? ' Per 1M' : ''}`
            : lockToHundredthDay
                ? 'Day Since Hundredth Case'
                : 'Date')
    }, [linear, lockToHundredthDay, perCapita, deaths, trajectory, positiveTestPercent]);

    const loadData = useCallback(async () => {
        const fetchStateData = fetch(COVID_TRACKING_DATA_URL);
        const fetchDates = fetch(STATE_DATES_URL);
        const fetchStatePopulationData = fetch(STATE_POPULATIONS_URL);
        const stateData: StateData[] = await (await fetchStateData).json();
        const dates = await (await fetchDates).json();
        const populationData: {[abbreviation: string]: PopulationData} = ParseCSVText(
            await (await fetchStatePopulationData).text()
        ).reduce((accumulator, item) => {
            accumulator[item.Abbreviation] = {
                population: parseInt(item.Population),
                region: item.Region,
                name: item.State,
            };
            return accumulator;
        }, {})
        const parsedData = stateData.reduce(
            (accumulator: {[key: string]: StateData[]}, currentItem: StateData) => {
                const state = currentItem.state;
                const stringDate = currentItem.date.toString();
                if (!accumulator.hasOwnProperty(currentItem.state)) {
                    accumulator[state] = [];
                }
                currentItem.date = new Date(stringDate.replace(DATE_PATTERN, '$1-$2-$3T00:00:00'));
                accumulator[state].push(currentItem);
                return accumulator;
            },
            {}
        );
        const processedData = Object.keys(parsedData).map((key) => {
            const region = parsedData[key];
            region.sort((a, b) => {
                return (a.date > b.date)
                    ? 1
                    : (a.date < b.date)
                        ? -1
                        : 0;
            });
            let daySinceHundredthCase = 0;
            let daySinceHundredthDeath = 0;
            let weekCases = [0,0,0,0,0,0,0];
            let weekDeaths = [0,0,0,0,0,0,0];
            const statePopulationData = populationData[key];
            const statePopulation = statePopulationData.population;
            const stateName = statePopulationData.name;
            const stateDates = (dates.hasOwnProperty(stateName))
                ? {
                    'start': dates[stateName].keyDates[0] ? new Date(dates[stateName].keyDates[0].date) : null,
                    'stop': dates[stateName].keyDates[1] ? new Date(dates[stateName].keyDates[1].date) : null
                }
                : {
                    'start': null,
                    'stop': null
                };
            

            for (var j = 0; j < region.length; j++) {
                const datum = region[j];

                datum.daySinceHundredthCase = daySinceHundredthCase;
                datum.daySinceHundredthDeath = daySinceHundredthDeath;
                datum.perCapitaValue = Math.floor(1000000 * (datum.positive / statePopulation)) || undefined;
                datum.perCapitaDeath = Math.floor(1000000 * ((datum.death || 0) / statePopulation)) || undefined;

                datum.totalTestResultsIncrease = 
                    (datum.totalTestResultsIncrease && datum.totalTestResultsIncrease >= 0)
                            ? datum.totalTestResultsIncrease
                            : 0;
                // datum.perCapitaTotalTestResultsIncrease = Math.floor(1000000 * (datum.totalTestResultsIncrease / statePopulation));

                weekCases.shift();
                weekCases.push((datum.positiveIncrease && datum.positiveIncrease > 0) ? datum.positiveIncrease : 0);
                weekDeaths.shift();
                weekDeaths.push((datum.deathIncrease && datum.deathIncrease > 0) ? datum.deathIncrease : 0);

                datum.positivePastWeek = d3.sum(weekCases.filter(c => c >= 0)) || undefined;
                datum.deathPastWeek = d3.sum(weekDeaths) || undefined;
                datum.perCapitaPositivePastWeek = Math.floor(1000000 * ((datum.positivePastWeek || 0) / statePopulation)) || undefined;
                datum.perCapitaDeathPastWeek = Math.floor(1000000 * ((datum.deathPastWeek || 0) / statePopulation)) || undefined;

                if (datum.positive >= 100) {
                    daySinceHundredthCase++;
                }
                if (datum.death && datum.death >= 100) {
                    daySinceHundredthDeath++;
                }
                datum.value = datum.positive || undefined;
                datum.positiveTestPercent = (
                    datum.totalTestResultsIncrease &&
                    datum.positiveIncrease &&
                    datum.totalTestResultsIncrease > 0 &&
                    datum.positiveIncrease >= 0 &&
                    datum.totalTestResultsIncrease > datum.positiveIncrease
                ) ? (datum.positiveIncrease / datum.totalTestResultsIncrease) : undefined;
                datum.death = datum.death || undefined;

            }

            return {
                "name": key,
                "values": parsedData[key],
                "dates": stateDates
            };
        });
        
        setData(processedData);
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return <div
        style={{
            width: '100%',
            height: '100%',
            margin: '0 auto',
            overflow: 'hidden',
            fontFamily: 'Quicksand, sans-serif',
        }}
    >
        <div
            style={{
                position: 'absolute',
                left: 10,
                top: 5,
            }}
        >
            <h3
                style={{
                    marginBottom: '0',
                }}
            >
                {(!data) ? 'Loading...' : 'COVID-19 Trajectory'}
            </h3>
            <h5>
                {(!data) ? '' : 'By US Territory'}
            </h5>
        </div>
        {data &&
            <SimplyGraph
                graphLines={data.map(datum => {
                    return {
                        name: datum.name,
                        dataPoints: datum.values,
                    }
                })}
                colors={COLORS}
                margins={{ top: 140, right: 50, bottom: 100, left: 70 }}
                yAxisLabel={yAxisLabel}
                xAxisLabel={xAxisLabel}
                yAccessor={yAccessor}
                xAccessor={xAccessor}
                swatchColumnWidth={40}
                xScaleOverride={xScale}
                yScaleOverride={yScale}
                lineDefined={function(d: any, i) {
                    const valueToCheck = d[yAccessor];
                    return !isNaN(valueToCheck) &&
                        valueToCheck !== 0;
                }}
                yTicksArguments={!linear ? [deaths ? 3 : 5, "~s"] : undefined}
                xTicksArguments={(trajectory && !linear) ? [deaths ? 3 : 5, "~s"] : undefined}
                lineCurve={d3.curveLinear}
                yAxisFormat={positiveTestPercent ? d3.format(".2p") : d3.format("~s")}
                niceYScale
            />
        }
        <div
            id="switches"
            style={{
                position: 'fixed',
                top: 0,
                right: 50,
                margin: '30px 0px',
            }}
        >
            <ButtonGroup style={{ marginRight: '8px' }}>
                <Button
                    variant="outline-primary"
                    active={!positiveTestPercent && !trajectory}
                    size="sm"
                    onClick={() => {
                        if (trajectory) setTrajectory(false);
                        if (positiveTestPercent) setPositiveTestPercent(false);
                    }}
                >
                    <FontAwesomeIcon icon={faChartArea} style={{ marginRight: '4px' }} />
                    Cumulative
                </Button>
                <Button
                    variant="outline-primary"
                    size='sm'
                    active={positiveTestPercent}
                    onClick={() => {
                        if (trajectory) setTrajectory(false);
                        if (!linear) setLinear(true);
                        if (deaths) setDeaths(false);
                        if (perCapita) setPerCapita(false);
                        setPositiveTestPercent(!positiveTestPercent);
                    }}
                >
                    <FontAwesomeIcon icon={faVials} style={{ marginRight: '4px' }} />
                    Positive Test %
                </Button>
                <Button
                    variant='outline-primary'
                    size='sm'
                    active={trajectory}
                    onClick={() => {
                        if (positiveTestPercent) {
                            setPositiveTestPercent(!positiveTestPercent);
                        }
                        setLockToHundredthDay(false);

                        setTrajectory(!trajectory)
                    }}
                >
                    <FontAwesomeIcon icon={faChartLine} style={{ marginRight: '4px' }} />
                    Trajectory
                </Button>
            </ButtonGroup>
            <div
                style={{
                    verticalAlign: 'middle',
                    display: 'inline'
                }}
            >
                <Form.Switch
                    disabled={positiveTestPercent}
                    label="Linear"
                    checked={linear}
                    onChange={() => { setLinear(!linear) }}
                    id="linear"
                    inline
                />
                <Form.Switch
                    disabled={trajectory}
                    label="Align X-axis at 100"
                    checked={lockToHundredthDay}
                    onChange={() => setLockToHundredthDay(!lockToHundredthDay)}
                    id="AlignX100"
                    inline
                />
                <Form.Switch
                    disabled={positiveTestPercent}
                    label="Per-Capita"
                    checked={perCapita}
                    onChange={() => setPerCapita(!perCapita)}
                    id="PerCapita"
                    inline
                />
                <Form.Switch
                    disabled={positiveTestPercent}
                    label="Deaths"
                    isInvalid={true}
                    variant='outline-danger'
                    checked={deaths}                
                    onChange={() => setDeaths(!deaths)}
                    id="Deaths"
                    inline
                />
            </div>
        </div>
        <a
            href={COVID_TRACKING_SOURCE}
            style={{
                position: 'fixed',
                right: 0,
                bottom: 0,
                textAnchor: 'end',
                fontSize: '.8em',
                textDecoration: 'underline',
            }}
        >
            Source
        </a>
    </div>;
}

export default CovidTrajectory;