import { Draw } from "./Canvas";

export const APIBase = 'https://jrgrover.com/api/peerJs';

export const shortGuid = () => {
    return (() => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    })();
}

type escapeableHTML = '&'|'<'|'>'|'"'|"'"|'/'|'`'|'=';

const entityMap: { [key in escapeableHTML] : string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;'
};

const replaceEscapeHTML = (match: string) => {
    return entityMap[(match as escapeableHTML)];
}

export const escapeHTML = (input: string) => {
    return String(input).replace(/[&<>"'`=/]/g, replaceEscapeHTML);
}

export const GetTimestamp = () => {
	const totalSec = new Date().getTime() / 1000
	const hours   = Math.floor((totalSec / 3600) % 24);
	const minutes = Math.floor((totalSec / 60) % 60);
	const seconds = Math.floor(totalSec % 60);

	return `${(hours < 10 ? '0' + hours : hours)}:${(minutes < 10 ? '0' + minutes : minutes)}:${(seconds < 10 ? '0' + seconds : seconds)}`;
}

// http://stackoverflow.com/a/2450976
export const ShuffleArray = <T>(array: T[]) => {
    let currentIndex = array.length;
    let temporaryValue: T;
    let randomIndex: number;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
}

export const ColorForState = (state: RTCIceConnectionState) => {
    let color = '#FFF';
    switch(state) {
        case 'new':
        case 'checking':
            color = '#333';
            break;
        case 'closed':
        case 'disconnected':
        case 'failed':
            color = '#D33';
            break;
        case 'completed':
        case 'connected':
            color = '#3D3';
            break;
    }
    return color;
}

type Variant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';

const ConnectionStateToVariantMap: { [key in RTCIceConnectionState]: Variant } = {
    new: 'light',
    checking: 'info',
    completed: 'primary',
    connected: 'success',
    disconnected: 'warning',
    closed: 'dark',
    failed: 'danger',
};

export const VariantForState = (state: RTCIceConnectionState) => {
    return ConnectionStateToVariantMap[state];
}

const ConnectingStates: RTCIceConnectionState[] = [
    'new',
    'checking',
    'completed',
];

const ConnectedStates: RTCIceConnectionState[] = [
    'connected',
];

const FailedStates: RTCIceConnectionState[] = [
    'closed',
    'disconnected',
    'failed',
];

export const IsConnecting = (state: RTCIceConnectionState|undefined) => {
    return state && ConnectingStates.includes(state)
}

export const IsConnected = (state: RTCIceConnectionState|undefined) => {
    return state && ConnectedStates.includes(state)
}

export const IsFailed = (state: RTCIceConnectionState|undefined) => {
    return state && FailedStates.includes(state)
}

export interface Player {
    name: string;
    state: RTCIceConnectionState;
}

export interface DrawgressionData {
    descriptions: string[];
    pictures: Draw[][];
}

export interface GameState {
    order: string[];
    players: { [key: string]: Player };
    data: { [key: string]: DrawgressionData };
}

export enum MessageType {
    'Name',
    'Data',
    'Players',
    'Order',
    'Start',
    'Description',
    'Image',
};

export interface MessageData {
    id: string;
}

export interface OrderMessage {
    order: string[];
}

export interface PlayersMessage {
    players: { [key: string]: Player };
}

export interface DataMessage {
    data: { [key: string]: DrawgressionData };
}

export interface DescriptionMessage extends MessageData {
    description: string;
}

export interface ImageMessage extends MessageData {
    draws: Draw[],
}

export interface Message {
    data: MessageData|DescriptionMessage|ImageMessage|DataMessage|OrderMessage|PlayersMessage|string;
    type: MessageType;
}