import * as React from 'react';
import * as d3 from 'd3';
import MarathonStatistics from '.';
import { BRACKET_NAMES, COLOR, OverallResult, AGE_EXTENT, TIME_EXTENT, BRACKET_SIZE_SECONDS, Gender, GetBracketForAge, GetPaceForBracketGenderRace } from './common';
import { secondsToString, secondsToStringD3 } from 'src/lib/Utils/Strings';

const BRACKET_COLOR_SCALES = BRACKET_NAMES.map(b => {
    return d3.interpolateHcl(
        d3.hcl(
            158.19859051364818,
            0.00001795054880958058,
            100.00000386666655
        ) || '#FFF', COLOR(b));
});

interface Props {
    visibility?: string;
    width: number;
    height: number;
    data: OverallResult[];
    title: string;
}

export default class Overall extends React.Component<Props> {
    private xAxisElement: React.RefObject<SVGGElement> = React.createRef();
    private yAxisElement: React.RefObject<SVGGElement> = React.createRef();
    private x = d3.scaleLinear();
    private y = d3.scaleLinear();
    private xAxis = d3.axisBottom(this.x);
    private yAxis = d3.axisLeft(this.y).tickPadding(6).tickFormat(secondsToStringD3);

    public render() {
        this.x.range([0, this.props.width])
            .domain(AGE_EXTENT);
        this.y.range([this.props.height, 0])
            .domain(TIME_EXTENT);
        this.xAxis.scale(this.x);
        this.yAxis.scale(this.y);
        d3.select(this.xAxisElement.current).call(this.xAxis as any);
        d3.select(this.yAxisElement.current).call(this.yAxis as any);

        const binWidth = (this.x(AGE_EXTENT[0] + 1) - this.x(AGE_EXTENT[0])) / 2;
        const binHeight = this.y(TIME_EXTENT[0]) - this.y(TIME_EXTENT[0] + BRACKET_SIZE_SECONDS)

        return (
            <g
                visibility={this.props.visibility}
                transform={`translate(${MarathonStatistics.margins.left},${MarathonStatistics.margins.top})`}
            >
                <g
                    ref={this.xAxisElement}
                    transform={`translate(${0},${this.y(TIME_EXTENT[0])})`}
                />
                <g ref={this.yAxisElement}/>
                <text
                    textAnchor="middle"
                    style={{ fontSize: '20px' }}
                    x={this.props.width / 2}
                    y={-5}
                >
                    {this.props.title}
                </text>
                {
                    this.props.data.map((d, i) => {
                        const binsExtent = d3.max(d.bins.map(bin => bin.length)) || 1000;
                        const innerData = d.bins.map(b => {
                            const value = b.length;
                            let color = "#FFF";
                            if (value !== 0) {
                                var bracket = GetBracketForAge(b[0].Age);
                                var bracketIndex = BRACKET_NAMES.indexOf(bracket);
                                color = BRACKET_COLOR_SCALES[bracketIndex](value / binsExtent);
                            }
                            var times = b.map(b => b.Time).sort();
                            return {
                                x1: b.x1,
                                color: color,
                                lower: secondsToString(b.x0 || 0),
                                upper: secondsToString(b.x1 || 0),
                                age: b[0].Age,
                                gender: b[0].Gender,
                                times: times,
                                value: value,
                            }
                        });
                        const bracketLineHeight = this.y(GetPaceForBracketGenderRace(
                            GetBracketForAge(d.age),
                            d.gender, "Boston"));

                        return (
                            <g
                                key={`${i}-${d.age}-${d.gender}`}
                                transform={`translate(${this.x((d.gender === Gender.M) ? d.age : d.age - .5)},0)`}
                            >
                                {innerData.map(d => {
                                    return (
                                        <rect
                                            key={`${d.age}-${d.gender}-${d.x1}`}
                                            stroke="#111"
                                            strokeWidth={0}
                                            fill={d.color}
                                            width={binWidth}
                                            height={binHeight}
                                            transform={`translate(0,${this.y(d.x1 || 0)})`}
                                            onMouseEnter={e => { d3.select(e.currentTarget).transition().attr("stroke-width", 2) }}
                                            onMouseLeave={e => { d3.select(e.currentTarget).transition().attr("stroke-width", 0) }}
                                            // onClick={this.handleBinClick}
                                        >
                                            <title>
                                                {`Time: ${d.lower}-${d.upper}\nAge|Gender: ${d.age}|${d.gender}\nRunners: ${d.value}`}
                                            </title>
                                        </rect>
                                    );
                                })}
                                <line
                                    stroke="#333"
                                    x1={0}
                                    x2={binWidth}
                                    y1={bracketLineHeight}
                                    y2={bracketLineHeight}
                                />
                            </g>
                        );
                    })
                }
            </g>
        );
    }
}