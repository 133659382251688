import * as React from 'react';
import Countdown from 'src/lib/Countdown';

// TODO: Get this from json stored in S3
// TODO: Make a secret or auth-ed UI for editing this JSON
const timers = [
  {
    'isoDateTime': '2021-06-28',
    'description': 'Bail 2.0',
  }
]

export default class Timers extends React.Component {
  private static createTimerDiv(datetime: string, description: string) {
    return (
      <div
        key={datetime}
        style={{
          margin: '0 auto',
          width: '100%',
          color: '#FFF',
          backgroundColor: 'rgba(204,204,204,0.1)',
          display: 'inline-block',
          fontWeight: 100,
          textAlign: 'center',
          fontSize: '30px',
          paddingBottom: '20px',
        }}
      >
        <h1>
          {description}
        </h1>
        <Countdown
          date={Date.parse(datetime)}
        />
      </div>
    );
  }

  public render() {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          margin: '0 auto',
          fontFamily: 'sans-serif',
          backgroundColor: '#222',
        }}
      >
        {timers.map(timer =>
          Timers.createTimerDiv(
            timer.isoDateTime, timer.description))}
      </div>
    );
  }
}
