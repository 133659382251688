import * as React from 'react';
import { TopologicalMap } from '../RouteVisualizer/TopologicalMap';
import { Object3D, PerspectiveCamera } from 'three';
import { ResortData, SkiActivityData } from '.';
import { GetGeoJSONLineStringForArrayOfLongitudeLatitude } from 'src/lib/Utils/GeoJSON';
import { useCallback, useEffect, useRef } from 'react';

interface Props {
    resort: ResortData;
    activities: SkiActivityData[];
}

const convertUserDataToGeoJsonFeatures = (data: SkiActivityData[]) => {
    return {
        type: 'FeatureCollection',
        features: data.map(datum => {
            return GetGeoJSONLineStringForArrayOfLongitudeLatitude(
                datum.data.latlng.map(d => {
                    return [
                        d[1],
                        d[0]
                    ];
                })
            )
        })
    }
}

const SkiingTopologicalMap: React.FC<Props> = ({ activities }) => {
    const containerRef = useRef<HTMLDivElement|null>(null);
    const topologicalMapRef = useRef<TopologicalMap>();
    const animation = useRef<number>(0);

    const animateTopologicalScene = useCallback(() => {
        const { current: topologicalMap } = topologicalMapRef;
        topologicalMap?.controls?.update();
        animation.current = requestAnimationFrame(animateTopologicalScene);
        topologicalMap?.renderer?.render((topologicalMap.scene as Object3D), topologicalMap.camera as PerspectiveCamera);
    }, []);

    const loadData = useCallback(() => {
        const { current: topologicalMap } = topologicalMapRef;
        topologicalMap?.resetScene();
        if (activities.length > 0) {
            topologicalMap?.setData(convertUserDataToGeoJsonFeatures(activities));
        }
        topologicalMap?.createScene();
    }, [activities]);

    useEffect(() => {
        if (!topologicalMapRef.current) {
            topologicalMapRef.current = new TopologicalMap({
                'snapLinesToGeometry': true,
                'loading': null,
                'container': document.getElementById('renderCanvas')
            });
        }
        topologicalMapRef.current.scaleZoom = true;
        animateTopologicalScene();
        loadData();
        return () => {
            cancelAnimationFrame(animation.current);
        }
    }, [containerRef, animateTopologicalScene, loadData]);

    return (
        <div
            ref={containerRef}
            id="renderCanvas"
            style={{
                width: '100%',
                height: '100%',
                overflowY: 'hidden'
            }}
        />
    );
}

export default SkiingTopologicalMap;