import * as React from 'react';
import { useState } from 'react';
import { ChromePicker, RGBColor } from 'react-color';
import { CardinalDirections } from '.';

const OctogonPolygonPoints = (startX: number, startY: number, sideLength: number) => {
    const points: number[][] = [];
    let currentX = startX;
    let currentY = startY;

    const dxdy = sideLength / Math.SQRT2;

    currentX += dxdy;
    points.push([currentX, currentY]);

    currentX += sideLength;
    points.push([currentX, currentY]);

    currentX += dxdy;
    currentY += dxdy;
    points.push([currentX, currentY]);

    currentY += sideLength;
    points.push([currentX, currentY]);

    currentY += dxdy;
    currentX -= dxdy;
    points.push([currentX, currentY]);

    currentX -= sideLength;
    points.push([currentX, currentY]);

    currentX -= dxdy;
    currentY -= dxdy;
    points.push([currentX, currentY]);

    currentY -= sideLength;
    points.push([currentX, currentY]);

    return points.map(p => p.join(',')).join(" ")
}

const OctogonAngle = (6 * Math.PI) / 8;
const Width = 400;
const Margin = 20;

interface AspectAltitudePickerProps {
    aspectColors: { [key in CardinalDirections]: RGBColor };
    onUpdate: (aspect: string, color: RGBColor) => void;
}

export const AspectAltitudePicker = ({ aspectColors, onUpdate }: AspectAltitudePickerProps) => {
    const [aspectSelected, setAspectSelected] = useState<CardinalDirections>();
    const [colorPosition, setColorPosition] = useState([0,0]);

    const BelowTreelineOctogonSideLength = (Width - Margin * 2) / (1 + 2 / Math.SQRT2);
    const BelowTreelineOctogonSideX = BelowTreelineOctogonSideLength / Math.SQRT2;
    const NearTreelineOffset = ((BelowTreelineOctogonSideX + (BelowTreelineOctogonSideLength / 2)) / 3);

    return (
        <>
            <svg
                width="100%"
                height="350px"
                viewBox={`0 0 ${Width} ${Width}`}
            >
                <g>
                    <text
                        y={Margin - (Margin / 3)}
                        x={Width / 2}
                        textAnchor="middle"
                        fill="#EEE"
                    >
                        North
                    </text>
                    <text
                        transform={`translate(${Width},${Width / 2}) rotate(90)`}
                        textAnchor="middle"
                        dy={Margin - (Margin / 3)}
                        fill="#EEE"
                    >
                        East
                    </text>
                    <text
                        y={Width - (Margin / 5)}
                        x={Width / 2}
                        textAnchor="middle"
                        fill="#EEE"
                    >
                        South
                    </text>
                    <text
                        textAnchor="middle"
                        dy={Margin - (Margin / 3)}
                        transform={`translate(0,${Width / 2}) rotate(-90)`}
                        fill="#EEE"
                    >
                        West
                    </text>
                    <AspectPath
                        direction="north"
                        fill={aspectColors["north"]}
                        sideLength={BelowTreelineOctogonSideLength}
                        offset={Width / 2}
                        onClick={(position: number[]) => {
                            setAspectSelected('north');
                            setColorPosition(position);
                        }}
                    />
                    <AspectPath
                        direction="east"
                        fill={aspectColors["east"]}
                        sideLength={BelowTreelineOctogonSideLength}
                        offset={Width / 2}
                        onClick={(position: number[]) => {
                            setAspectSelected('east');
                            setColorPosition(position);
                        }}
                    />
                    <AspectPath
                        direction="south"
                        fill={aspectColors["south"]}
                        sideLength={BelowTreelineOctogonSideLength}
                        offset={Width / 2}
                        onClick={(position: number[]) => {
                            setAspectSelected('south');
                            setColorPosition(position);
                        }}
                    />
                    <AspectPath
                        direction="west"
                        fill={aspectColors["west"]}
                        sideLength={BelowTreelineOctogonSideLength}
                        offset={Width / 2}
                        onClick={(position: number[]) => {
                            setAspectSelected('west');
                            setColorPosition(position);
                        }}
                    />
                    <AltitudeAspectOctogon
                        offset={Margin}
                        sideLength={BelowTreelineOctogonSideLength}
                        onClick={() => { }}
                    />
                    <AltitudeAspectOctogon
                        offset={Margin + NearTreelineOffset}
                        sideLength={BelowTreelineOctogonSideLength - (NearTreelineOffset  / Math.tan(OctogonAngle / 2)) * 2}
                        onClick={() => { }}
                    />
                    <AltitudeAspectOctogon
                        offset={Margin + NearTreelineOffset * 2}
                        sideLength={BelowTreelineOctogonSideLength - ((NearTreelineOffset * 2)  / Math.tan(OctogonAngle / 2)) * 2}
                        onClick={() => { }}
                    />
                </g>
            </svg>
            {aspectSelected && (
                <div style={{
                    position: 'absolute',
                    zIndex: 2,
                }}>
                    <div
                        style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }}
                        onClick={() => { setAspectSelected(undefined) }}
                    />
                    <div
                        style={{
                            position: 'fixed',
                            top: colorPosition[1],
                            left: colorPosition[0],
                        }}
                    >
                        <ChromePicker
                            color={aspectColors[aspectSelected]}
                            onChangeComplete={(color) => {
                                onUpdate(aspectSelected, color.rgb);
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

interface AltitudeAspectOctogonProps {
    offset: number;
    sideLength: number;
    onClick: () => void;
}

const AltitudeAspectOctogon: React.FC<AltitudeAspectOctogonProps> = ({ offset, sideLength, onClick }) => {
    const [hovering, ] = useState(false);

    return (
        <polygon
            points={OctogonPolygonPoints(offset, offset, sideLength)}
            fill={hovering ? "#CCC" : "none"}
            stroke="#EEE"
            strokeWidth={hovering ? 3 : 1}
            onMouseEnter={() => { /*setHovering(true)*/ }}
            onMouseLeave={() => { /*setHovering(false)*/ }}
            onClick={onClick}
        />
    );
}

const AspectPolygonPath = (direction: string, offset: number, sideLength: number) => {
    const pathCommands: string[] = [`M${offset},${offset} `];
    const NorthSouth = direction === 'north' || direction === 'south';
    let x = 0;
    let y = 0;
    if (NorthSouth) {
        x = -sideLength / 2;
        if (direction === 'north') {
            y = -offset + Margin;
        } else {
            y = offset - Margin;
        }
    } else {
        y = -sideLength / 2;
        if (direction === 'east') {
            x = offset - Margin;
        } else {
            x = -offset + Margin;
        }
    }

    pathCommands.push(`l${x},${y}`)
    pathCommands.push(`${NorthSouth ? 'h' : 'v'}${sideLength}`);
    pathCommands.push(`L${offset},${offset}`);
    return pathCommands.join(' ');
}

interface AspectPathProps {
    direction: string;
    fill: RGBColor;
    sideLength: number;
    onClick: (position: [number,number]) => void;
    offset: number;
}

const AspectPath: React.FC<AspectPathProps> = ({
    direction,
    fill,
    sideLength,
    onClick,
    offset,
}) => {
    const [hovering, setHovering] = useState(false);
    return (
        <path
            d={AspectPolygonPath(direction, offset, sideLength)}
            fill={`rgb(${fill.r},${fill.g},${fill.b})`}
            stroke="#EEE"
            strokeWidth={hovering ? 3 : 0}
            onMouseEnter={() => { setHovering(true) }}
            onMouseLeave={() => { setHovering(false) }}
            onClick={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                onClick([
                    event.clientX,
                    event.clientY
                ])
            }}
        />
    )
}
