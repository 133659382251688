import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Gallery, { ImageOptions } from 'react-grid-gallery';

interface Props {
    name: string;
    bucket: string;
    s3: AWS.S3;
}

const Album: React.FC<Props> = ({
    name, bucket, s3,
}) => {
    const [imageOptions, setImageOptions] = useState<ImageOptions[]>([]);
    const [endpoint, setEndpoint] = useState('');
    const [videos, setVideos] = useState<string[]>([]);

    const getPhotoMetadata = useCallback(async (endpoint: string, photoKey: string) => {
        const thumbnailKey = photoKey.replace('original', 'thumbnail');
        const metadata = (await s3.headObject({
            Key: thumbnailKey,
            Bucket: bucket,
        }, undefined).promise()).Metadata;
        const imageOptions: ImageOptions = {
            src: `${endpoint}${photoKey}`,
            thumbnail: `${endpoint}${thumbnailKey}`,
            thumbnailWidth: parseInt(metadata?.width || '1'),
            thumbnailHeight: parseInt(metadata?.height || '1'),
        };

        setImageOptions((oldImageOptions) => {
            return oldImageOptions.concat(imageOptions);
        });
    }, [s3, bucket]);

    const loadPhotos = useCallback(async () => {
        const albumPhotosKey = `original/${name}/`;
        const listObjectResults = await s3.listObjects({
            Prefix: albumPhotosKey,
            Bucket: bucket,
        }, undefined).promise();
        const endpoint = `${(listObjectResults.$response as any).request.httpRequest.endpoint.href}${bucket}/`;
        const objects = (listObjectResults.Contents || [])?.map(content => content.Key || '').slice(1).filter(key => key !== '');
        const photos = objects.filter(object => object.toLocaleLowerCase().endsWith('jpg') || object.toLocaleLowerCase().endsWith('png'));
        const videos = objects.filter(object => object.toLocaleLowerCase().endsWith('mov'));
        
        photos.forEach(photo => {
            getPhotoMetadata(endpoint, photo);
        });

        setEndpoint(endpoint);
        setVideos(videos);
    }, [name, s3, bucket, getPhotoMetadata]);

    useEffect(() => {
        loadPhotos();
    }, [loadPhotos]);

    return (<>
        <Row>
            <Col sm={12}>
            {imageOptions.length > 0
            ? (
                <Gallery
                    enableImageSelection={false}
                    images={imageOptions}
                />
            )
            : (
                <h3>
                    No photos found!
                </h3>
            )
            }
            </Col>
        </Row>
        <Row>
        {
            videos.length > 0
            ? (
                <Col sm={12}>
                    <h4 style={{ color: '#EEE' }}>Videos:</h4>
                    {
                        videos.map(video => {
                            const original = `${endpoint}${video}`;
                            const thumbnail = original.replace('original', 'thumbnail').replace('.mov', '.jpg');
                            return (
                                <Col sm={4}>
                                    <a href={original}>
                                        <img
                                            src={thumbnail}
                                            alt=""
                                        />
                                    </a>
                                </Col>
                            );
                        })
                    }
                </Col>
            )
            : null
        }
        </Row>
    </>);
}

export default Album;