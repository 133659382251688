import { faFilter, faSortNumericUp, faSortNumericDown, faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useCallback } from "react";
import { Table } from "react-bootstrap";
import { CreateGeoJsonForFoundUnfoundNodes } from "src/lib/Utils/GeoJSON";
import { GetRemainingNodeCountForWay } from "../Common";
import StreetModal from "../MapboxUtils/StreetModal";
import { FeatureCollection } from 'geojson';
import RunsTooltip, { RunInformation } from "./RunsTooltip";
import { CityWay } from "./NodesProgress";

type sortType = 'Name' | 'Nodes' | 'Completed' | 'Percent' | 'Missing' | 'Required';

const StreetsTable: React.FC<{
    cityWays: {[name: string]: CityWay}
}> = ({
    cityWays,
}) => {
    const [filterCompleted, setFilterCompleted] = useState(false);
    const [reverse, setReverse] = useState(false);
    const [sort, setSort] = useState<sortType>('Name');
    const [showModal, setShowModal] = useState(false);
    const [modalStreet, setModalStreet] = useState<string>();
    const [modalGeoJson, setModalGeoJson] = useState<FeatureCollection>();

    const ways = Object.keys(cityWays);

    const setSortAndReverse = useCallback((updatedSort: sortType) => {
        setSort(updatedSort);
        if (updatedSort !== sort) {
            setReverse(false);
        } else {
            setReverse(!reverse);
        }
    }, [sort, reverse]);

    const filteredWays = ways.reduce((accumulator: {[name: string]: CityWay}, way) => {
        const cityWay = cityWays[way];
        if (filterCompleted) {
            if (!cityWay.complete) {
                accumulator[way] = cityWay;
            }
        } else {
            accumulator[way] = cityWay;
        }
        return accumulator;
    }, {});

    return (
        <>
            <h4>Streets Progress Table <small>{ways.filter(way => cityWays[way].complete).length} / {ways.length} complete</small></h4>
            <Table
                striped={true}
                hover={true}
                size="sm"
                style={{ fontFamily: 'monospace' }}
            >
                <thead style={{ position: 'sticky', top: 0 }}>
                    <tr
                        style={{
                            display: 'sticky',
                            top: 0,
                            backgroundColor: '#FFF',
                            color: '#999',
                        }}
                    >
                        <th>
                            <FontAwesomeIcon
                                title="Click to hide completed streets"
                                icon={faFilter}
                                color={!filterCompleted ? '#CCC' : undefined}
                                onClick={() => setFilterCompleted(!filterCompleted)}
                            />
                        </th>
                        <th
                            onClick={() => setSortAndReverse('Name')}
                            style={{
                                color: sort === 'Name' ? '#000' : 'inherit'
                            }}
                        >
                            Name{<FontAwesomeIcon
                                icon={reverse ? faSortNumericUp : faSortNumericDown}
                                style={{ marginLeft: '2px' }}
                            />}
                        </th>
                        <th
                            onClick={() => setSortAndReverse('Nodes')}
                            style={{
                                textAlign: 'right',
                                color: sort === 'Nodes' ? '#000' : 'inherit'
                            }}
                        >
                            {<FontAwesomeIcon
                                icon={reverse ? faSortNumericUp : faSortNumericDown}
                                style={{ marginRight: '2px' }}
                            />}Nodes
                        </th>
                        <th
                            onClick={() => setSortAndReverse('Completed')}
                            style={{
                                textAlign: 'right',
                                color: sort === 'Completed' ? '#000' : 'inherit'
                            }}
                        >
                            {<FontAwesomeIcon
                                icon={reverse ? faSortNumericUp : faSortNumericDown}
                                style={{ marginRight: '2px' }}
                            />}Complete
                        </th>
                        <th
                            onClick={() => setSortAndReverse('Missing')}
                            style={{
                                textAlign: 'right',
                                color: sort === 'Missing' ? '#000' : 'inherit'
                            }}
                        >
                            {<FontAwesomeIcon
                                icon={reverse ? faSortNumericUp : faSortNumericDown}
                                style={{ marginRight: '2px' }}
                            />}Remaining
                        </th>
                        <th
                            onClick={() => setSortAndReverse('Required')}
                            style={{
                                textAlign: 'right',
                                color: sort === 'Required' ? '#000' : 'inherit'
                            }}
                        >
                            {<FontAwesomeIcon
                                icon={reverse ? faSortNumericUp : faSortNumericDown}
                                style={{ marginRight: '2px' }}
                            />}Required
                        </th>
                        <th
                            onClick={() => setSortAndReverse('Percent')}
                            style={{
                                textAlign: 'right',
                                color: sort === 'Percent' ? '#000' : 'inherit'
                            }}
                        >
                            {<FontAwesomeIcon
                                icon={reverse ? faSortNumericUp : faSortNumericDown}
                                style={{ marginRight: '2px' }}
                            />}%
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(filteredWays)
                        .sort((wayA, wayB) => {
                            const propertiesMap: {[key in sortType]: (a: string) => string|number} = {
                                'Name': (way: string) => way,
                                'Completed': (way: string) => filteredWays[way].completed,
                                'Nodes': (way: string) => filteredWays[way].completed + filteredWays[way].incompleted,
                                'Percent': (way: string) => filteredWays[way].completePercent,
                                'Missing': (wayName: string) => filteredWays[wayName].incompleted,
                                'Required': (wayName: string) => {
                                    const way = filteredWays[wayName];
                                    return GetRemainingNodeCountForWay(way.completed + way.incompleted, way.completed);
                                },
                            }
                            let propA = propertiesMap[sort](wayA);
                            let propB = propertiesMap[sort](wayB);

                            const result = reverse
                                ? propA < propB
                                : propA > propB;
                            return result ? 1 : -1;
                        })
                        .map(way => <CityWayRow
                            key={way}
                            name={way}
                            way={cityWays[way]}
                            onClick={() => {
                                const nodes = cityWays[way].nodeInformation || [];
                                const foundNodes = nodes.filter(n => n.activityId !== undefined);
                                const unfoundNodes = nodes.filter(n => n.activityId === undefined);
                                setShowModal(true);
                                setModalStreet(way);
                                setModalGeoJson(CreateGeoJsonForFoundUnfoundNodes(foundNodes, unfoundNodes));
                            }}
                        />
                    )}
                </tbody>
                {showModal && modalStreet && modalGeoJson && <StreetModal
                    onHide={() => setShowModal(false)}
                    showModal={showModal}
                    name={modalStreet}
                    geoJson={modalGeoJson}
                />}
            </Table>
        </>
    );
}

export default StreetsTable;

const CityWayRow: React.FC<{
    name: string;
    way: CityWay;
    onClick: () => void;
}> = ({
    name,
    way,
    onClick,
}) => {
    const {
        completed,
        complete,
        completePercent,
        completedBy,
        incompleted,
        nodeInformation,
    } = way;
    const wayNodes = completed + incompleted;
    const runs: RunInformation[] = (nodeInformation || []).reduce(
        (accumulator: RunInformation[], node) => {
        if (node.activityId) {
            if (accumulator.map(runInformation => runInformation.id).indexOf(node.activityId) < 0) {
                accumulator.push({
                    name: node.activityId.toString(),
                    id: node.activityId,
                    nodes: [node],
                });
            } else {
                accumulator[
                    accumulator.findIndex(r => r.id === node.activityId)
                ].nodes.push(node);
            }
        }
        return accumulator;
    }, []);

    return (
        <tr>
            <td>
                <RunsTooltip
                    way={name}
                    runs={runs}
                    completedBy={completedBy}
                >
                    <FontAwesomeIcon
                        title="click to see activities that progressed this way"
                        icon={faTasks}
                        style={{
                            color: (complete && completedBy) ? '#33BB33' : 'inherit',
                        }}
                    />
                </RunsTooltip>
            </td>
            <td>
                <span
                    onClick={() => {
                        onClick();
                    }}
                    style={{ cursor: 'pointer' }}>{name}
                </span>
            </td>
            <td style={{ textAlign: 'right' }}>{wayNodes}</td>
            <td style={{ textAlign: 'right' }}>{completed}</td>
            <td style={{ textAlign: 'right' }}>{wayNodes - completed}</td>
            <td style={{ textAlign: 'right' }}>{GetRemainingNodeCountForWay(wayNodes, completed)}</td>
            <td style={{ textAlign: 'right' }}>{(completePercent * 100).toFixed(2)}%</td>
        </tr>
    );
}