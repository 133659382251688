import React, { useEffect, useState } from "react";
import { Button, Form, FormControl, InputGroup, ModalBody, ModalFooter } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ROUTE_URI, UNOFFICIAL_GARMIN_URI } from '../Common';
import toast from "react-hot-toast";

export interface GeoPoint {
	latitude: number;
	longitude: number;
	distance: number;
	elevation: number;
}

interface RoutePostResult {
	qrCode: string;
	url: string;
}

interface ExportModalProps {
	gpx?: string;
	onHide: () => void;
	geoPoints: GeoPoint[],
}

const ExportModal: React.FC<ExportModalProps> = ({ gpx, geoPoints, onHide }) => {
	const [qrCode, setQrCode] = useState<string>();
	const [url, setUrl] = useState<string>();
	const [error, setError] = useState<string>();
	const [courseName, setCourseName] = useState(new Date(Date.now()).toISOString().substring(0, 19));
	const [garminUsername, setGarminUsername] = useState(localStorage.getItem('garminUsername') || '');
	const [courseId, setCourseId] = useState<number>();
	const [uploading, setUploading] = useState(false);

	useEffect(() => {
		localStorage.setItem('garminUsername', garminUsername);
	}, [garminUsername]);

	useEffect(() => {
		const loadData = async () => {
			try {
				const result: RoutePostResult = await (await fetch(ROUTE_URI, {
					method: 'POST',
					body: gpx,
				})).json();
				setQrCode(result.qrCode);
				setUrl(result.url);
			} catch (exception: any) {
				setError(exception.toString());
			}
		}
		loadData();
	}, [gpx]);

	return (
		<Modal
			show={gpx !== undefined}
			onHide={onHide}
			size="lg"
		>
			<ModalHeader closeButton={true}>
				<h4>
					{
						!qrCode
							? 'Uploading Activity...'
							: (
								<span>
									Activity uploaded, scan QR code or click <a href={url}>here
									</a>
								</span>
							)
					}
				</h4>
			</ModalHeader>
			<ModalBody
				style={{
					margin: '0 auto'
				}}
			>
				{
					!qrCode
						? (
							<h3>Loading...</h3>
						)
						: (
							<img
								alt="QRCode for route"
								src={qrCode}
								width="250px"
								height="250px"
							/>
						)
				}
				{
					error && (
						<pre>
							{error}
						</pre>
					)
				}
			</ModalBody>
			<ModalFooter style={{ justifyContent: 'flex-start' }}>
				{ courseId === undefined
					? (<>
					<Form.Label>Send To First Garmin Device</Form.Label>
						<InputGroup>
							<FormControl
								as="input"
								type="text"
								placeholder="Enter Course Name"
								value={courseName}
								onChange={(e) => {
									setCourseName(e.currentTarget.value);
								}}
							/>
							<FormControl
								as="input"
								type="text"
								placeholder="Enter Garmin Username"
								value={garminUsername}
								onChange={(e) => {
									setGarminUsername(e.currentTarget.value);
								}}
							/>
							<InputGroup.Append>
								<Button
									variant="success"
									onClick={async () => {
										setUploading(true);
										var fetchResult = await fetch(
											`${UNOFFICIAL_GARMIN_URI}/${garminUsername}/createCourse`,
											{
												method: 'POST',
												headers: new Headers({'content-type': 'application/json'}),
												body: JSON.stringify({
													courseName,
													geoPoints,
												})
											}
										);
										setUploading(false);
										if (!fetchResult.ok) {
											toast.error(`Failed to upload! (${fetchResult.status}: ${fetchResult.statusText})`);
										}
										toast.success('Upload successful!')
										setCourseId((await fetchResult.json()).courseId);
									}}
									disabled={courseName === '' || garminUsername === '' || uploading}
								>
									Send
								</Button>
							</InputGroup.Append>
						</InputGroup>
					</>)
					: (
						<a
							href={`https://connect.garmin.com/modern/course/${courseId}`}
						>
							Garmin course {courseId}
						</a>
					)}
			</ModalFooter>
		</Modal>
	);
}

export default ExportModal;