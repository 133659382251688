import { NumberValue} from "d3";

export const secondsToString = (inputSeconds: number) => {
    let seconds = inputSeconds;
    let output = '';
    const hours = Math.floor(seconds / 3600);
    if (hours){
        output = `${hours}:`;
        seconds = seconds - (hours * 3600);
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 10 && hours){
        output = `${output}0`;
    }
    output = `${output}${minutes}:`;
    seconds = seconds - (minutes * 60);
    
    if (seconds < 10){
        output = `${output}0`;
    }

    output = `${output}${seconds}`;
    return output;
}

export const secondsToStringD3 = (inputSeconds: NumberValue) => {
    return secondsToString(inputSeconds.valueOf());
}

export const MileFormatter = (input: number) => `${input.toFixed(0)} mi`;

export const getFileExtension = (fileName: string) => {
    const finalDotIndex = fileName.lastIndexOf('.') + 1;
    const extensionLength = fileName.length - finalDotIndex;
    return fileName.substr(finalDotIndex, extensionLength);
}

export const getFileName = (file: string) => {
    return file.substr(0, file.lastIndexOf('.'));
}