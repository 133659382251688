import * as React from 'react';
import { DataViewPropsBase, ActivityData } from './types';
import * as d3 from 'd3';
import ActivityModal from './ActivityModal';
import { MileFormatter } from 'src/lib/Utils/Strings';
import { COMMON_COLOR_INTERPOLATION } from './Common';

const MonthPath = (firstDayOfMonth: Date, cellSize: number) => {
    const firstDayNextMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() + 1, 0);
    const firstDayNumber = firstDayOfMonth.getDay();
    const startingWeek = d3.timeWeek.count(d3.timeYear(firstDayOfMonth), firstDayOfMonth);
    const endingDay = firstDayNextMonth.getDay();
    const endingWeek = d3.timeWeek.count(d3.timeYear(firstDayNextMonth), firstDayNextMonth);
    return `M${firstDayNumber * cellSize},${((startingWeek + 1) * cellSize)}` +
            `V${startingWeek * cellSize}H${7 * cellSize}` +
            `V${endingWeek * cellSize}H${(endingDay + 1) * cellSize}` +
            `V${(endingWeek + 1) * cellSize}H0` +
            `V${(startingWeek + 1) * cellSize}Z`;
}

interface Props extends DataViewPropsBase {
}

interface State {
    width: number;
    height: number;
    graphWidth: number;
    graphHeight: number;
    focusActivity?: ActivityData;
    showModal: boolean;
    cellSize: number;
}

export default class Calendar extends React.Component<Props> {
    private static MARGINS = { top: 65, right: 30, bottom: 45, left: 60 };
    private graphParent: React.RefObject<SVGSVGElement> = React.createRef();

    public state: State = {
        width: 0,
        height: 0,
        graphWidth: 0,
        graphHeight: 0,
        showModal: false,
        cellSize: 0,
    };

    public componentDidMount() {
        window.addEventListener('resize', this.resize);
        this.resize();
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    private resize = () => {
        const width = this.graphParent.current?.clientWidth || 0;
        const height = this.graphParent.current?.clientHeight || 0;
        const graphWidth = width - Calendar.MARGINS.left - Calendar.MARGINS.right;
        const graphHeight = height - Calendar.MARGINS.top - Calendar.MARGINS.bottom;

        let cellSize = width / 4 / 8;
        if ((cellSize * 53) > graphHeight) {
            cellSize = graphHeight / 54;
        }

        this.setState({
            width, height, graphWidth, graphHeight, cellSize,
        });
    }

    public render() {
        const { activities, athleteId, statistics } = this.props;
        const { maxDistance } = statistics;
        const { width, focusActivity, showModal, cellSize } = this.state;
        
        const startYear = (activities[0]?.date || new Date()).getFullYear();
        let endYear = (activities[activities.length - 1]?.date || new Date()).getFullYear() + 1;
        if (startYear === endYear) {
            endYear = startYear + 1;
        }
        const yearCount = endYear - startYear;
        const centeringLeftMargin = (width / 2) - (yearCount * cellSize * 4);
        const runningDays = activities.map(activity => Number(new Date(activity.date.toLocaleDateString())));

        return (
            <>
                <svg
                    width="100%"
                    height="100%"
                    ref={this.graphParent}
                >
                    <g
                        transform={`translate(0,${Calendar.MARGINS.top})`}
                    >
                        {
                            d3.range(startYear, endYear).map((year, i) => {
                                const firstDayOfYear = new Date(year, 0, 1);
                                const lastDayOfYear = new Date(year + 1, 0, 1);
                                return (
                                    <g
                                        key={year}
                                        transform={`translate(${centeringLeftMargin + (cellSize * 8 * i)},${0})`}
                                    >
                                        <text
                                            textAnchor="middle"
                                            transform={`translate(${cellSize * 4}, -6)`}
                                        >
                                            {year}
                                        </text>
                                        <g
                                            transform={`translate(${cellSize / 2}, 0)`}
                                        >
                                            {
                                                d3.timeDays(firstDayOfYear, lastDayOfYear)
                                                    .filter(date => runningDays.indexOf(+date) >= 0)
                                                    .map(date => {
                                                        const index = runningDays.indexOf(+date);
                                                        const activity = activities[index]
                                                    return (
                                                        <rect
                                                            key={date.toString()}
                                                            width={cellSize}
                                                            height={cellSize}
                                                            x={date.getDay() * cellSize}
                                                            y={d3.timeWeek.count(firstDayOfYear, date) * cellSize}
                                                            fill={COMMON_COLOR_INTERPOLATION(activity.distance / maxDistance)}
                                                            stroke="#EEE"
                                                            strokeWidth=".5px"
                                                            onMouseOver={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                                                                this.setState({
                                                                    focusActivity: activity,
                                                                    focusDisplay: undefined,
                                                                    translate: `translate(${event.clientX}, ${event.clientY})`,
                                                                })
                                                                d3.select(event.target as SVGRectElement).attr('opacity', 0.25).transition();
                                                            }}
                                                            onMouseOut={(event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
                                                                this.setState({
                                                                    focusDisplay: 'none',
                                                                });
                                                                d3.select(event.target as SVGRectElement).attr('opacity', 1).transition();
                                                            }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    focusActivity: activity,
                                                                    showModal: true,
                                                                });
                                                            }}
                                                        >
                                                            <title>
                                                                {MileFormatter(activity.distance)}
                                                            </title>
                                                        </rect>
                                                    );
                                                })
                                            }
                                            {
                                                d3.timeMonths(firstDayOfYear, lastDayOfYear).map(month => {
                                                    return (
                                                        <path
                                                            key={month.toString()}
                                                            fill="none"
                                                            strokeWidth="1px"
                                                            stroke="#CCC"
                                                            d={MonthPath(month, cellSize)}
                                                        />
                                                    );
                                                })
                                            }
                                        </g>
                                        {

                                        }
                                    </g>
                                )
                            })
                        }

                    </g>
                    <text
                        textAnchor="middle"
                        fontSize="30px"
                        x={width / 2}
                        y={10 + (Calendar.MARGINS.top / 2)}
                    >
                        Activity Calendar
                    </text>
                </svg>
                <ActivityModal
                    activity={focusActivity}
                    showModal={showModal}
                    onHide={() => this.setState({ showModal: false })}
                    athleteId={athleteId}
                />
            </>
        );
    }
}