import * as React from 'react';
import { Row, Jumbotron, Col, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

interface State {
    tracking: boolean;
    rants: number[];
}

export default class RantTracker extends React.Component {
    public state: State = {
        tracking: false,
        rants: [],
    }

    private currentRantStart: Date|null = null;
    private interval: number = 0;

    update = () => {
        const { rants } = this.state;
        const duration = (new Date()).getTime() - (this.currentRantStart?.getTime() || 0);
        rants[rants.length - 1] = duration;
        this.setState({ rants });
    }

    handleClick = () => {
        const tracking = this.state.tracking;
        const rants = this.state.rants;
        if (!tracking) {
            this.currentRantStart = new Date();
            rants.push(0);
            this.interval = window.setInterval(this.update, 1000);
        } else {
            window.clearInterval(this.interval);
        }

        this.setState({
            tracking: !tracking,
            rants
        });
    }

    createMinuteSecondsTR = (milliseconds: number, key: string|undefined) => (
        <tr key={key}>
            <td>{Math.floor((milliseconds / 1000 / 60) % 60)}</td>
            <td>{Math.floor((milliseconds / 1000) % 60)}</td>
        </tr>
    );
    
    public render() {
        const { rants } = this.state;
        const averageMilliseconds = rants.reduce((a, i) => a+i, 0) / (rants.length || 1);

        return (
            <Container style={{ fontFamily: 'Quicksand' }}>
                <Row style={{ textAlign: 'center' }}>
                    <Col sm={12}>
                        <Jumbotron style={{ padding: '.25em 2em' }}>
                            <h1 className="display-1">
                                Rant Tracker
                            </h1>
                        </Jumbotron>
                    </Col>                    
                </Row>
                <Row style={{ textAlign: 'center' }}>
                    <Col
                        lg={6}
                        className="offset-lg-3"
                        style={{
                            fontSize: '34px',
                            padding: '11px 0px',
                        }}
                    >
                        <Row>
                            <Col
                                lg={8}
                                className="offset-lg-2"
                                style={{
                                    fontSize: '26px',
                                    padding: '11px 0px',
                                }}
                            >
                                <Button
                                    size="lg"
                                    variant={!this.state.tracking ? 'success' : 'danger'}
                                    onClick={this.handleClick}
                                >
                                    {!this.state.tracking ? 'Start' : 'Stop'} Rant
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                Average:
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <td>Minutes</td>
                                            <td>Seconds</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.createMinuteSecondsTR(averageMilliseconds, undefined)}
                                    </tbody>
                                </table>
                                Current:
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <td>Minutes</td>
                                            <td>Seconds</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.createMinuteSecondsTR(rants[rants.length - 1] || 0, undefined)}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                Rants
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <td>Minutes</td>
                                            <td>Seconds</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rants.map((r, i) => {
                                            return this.createMinuteSecondsTR(r, `${r}${i}`);
                                        })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}