import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

interface CameraLocation {
    Name: string;
    Cameras: string[];
}

export default class TrafficCameras extends React.Component {
    public state = {
        cameraLocations: [] 
    }

    componentDidMount() {
        this.loadCameras();
    }

    loadCameras = async () => {
        const cameras = await (await fetch('/data/EclipseCams.json')).json();
        this.setState({ cameraLocations: cameras.CameraLocations });
    }
    
    public render() {
        const { cameraLocations } = this.state;
        
        return (
            <Container>
                <h2>Oregon Traffic Cameras (South Portland to RockyTop)</h2>
                {
                    cameraLocations.map((location: CameraLocation, index: number) => {
                        return (
                            <div key={index}>
                                <Row>
                                    <Col md={12}>
                                        <h3>{location.Name}</h3>    
                                    </Col>
                                </Row>
                                <Row>
                                    {location.Cameras.map(camera => {
                                        return (
                                            <Col md={6} key={camera}>
                                                <img src={camera} alt="" />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        );
                    })
                }
            </Container>
        );
    }
}