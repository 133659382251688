import * as d3 from 'd3';
import { ResortBounds, SkiActivityData } from "src/projects/SkiingVisualizer";
import { getTrackPointsFromActivityStreams } from "src/projects/SkiingVisualizer/SkiingHeatmap";
import { getLatitudeLongitudeLengthsForLatitude } from "./Maps";
import { Feature, Geometry, GeoJsonProperties, Position } from 'geojson';

const SQUARE_ACRE_SIDE_IN_METERS = 63.61490723;

interface RunInfo {
	bins: number;
	uniqueBins: number;
};

export interface Bin {
	extent: ResortBounds;
	runs: number[];
}

interface Point extends Array<number> {
	runIndex: number;
}

export const GetAcreBinDeduplicateDays = (activities: SkiActivityData[], bounds: ResortBounds) => {
	var dedupedPoints: Point[] = [];
	var bins: Bin[] = []
	var boundaries: [[number,number],[number,number]][] = [];
	var latitudeThresholds: number[] = [];
	var longitudeHistograms: d3.HistogramGeneratorNumber<number, number>[] = [];
	var runInfo: RunInfo[]  = [];

	var northBin = bounds.north + 
		(SQUARE_ACRE_SIDE_IN_METERS / getLatitudeLongitudeLengthsForLatitude(bounds.north).latlen);
	for (var l = bounds.south;
	  l <= northBin;
	  l += (SQUARE_ACRE_SIDE_IN_METERS / getLatitudeLongitudeLengthsForLatitude(l).latlen)) {
	  latitudeThresholds.push(l);
	  boundaries.push([
		[bounds.west, l],
		[bounds.east, l]
	  ])
	}

	for (var i = 0; i < latitudeThresholds.length - 1; i++) {
		var threshold = latitudeThresholds[i];
		var nextThreshold = latitudeThresholds[i+1];
		var longitudeThresholds = [];

		for (var lon = bounds.west;
			lon < bounds.east;
			lon += (SQUARE_ACRE_SIDE_IN_METERS / getLatitudeLongitudeLengthsForLatitude(threshold).longlen)) {
				longitudeThresholds.push(lon);
				boundaries.push([
					[lon, threshold],
					[lon, nextThreshold]
				]);
		}

		var bin = d3.bin();
		bin.value(function(d: Point){ return d[0] } as any);
		bin.thresholds(longitudeThresholds);
		bin.domain([bounds.west, bounds.east]);
		longitudeHistograms.push(bin);
	}

	var latitudeHistogram = d3.bin()
	  .value(function(d: Point){ return d[1] } as any)
	  .thresholds(latitudeThresholds)
	  .domain([bounds.south, bounds.north]);

	activities.forEach((activity, activityIndex) => {
		console.log(activity);
		var line = getTrackPointsFromActivityStreams(activity);
		runInfo[activityIndex] = { bins: 0, uniqueBins: 0 };
		var latitudeHistogramBins = latitudeHistogram(line as any);

		latitudeHistogramBins.forEach((latitudeBin, index)  => {
			if (latitudeBin.length !== 0) {
				var longitudeHistogramBins = longitudeHistograms[index](latitudeBin);
				longitudeHistogramBins.forEach(longitudeBin => {
					if (longitudeBin.length !== 0) {
						runInfo[activityIndex].bins += 1;
						var savedPoint = longitudeBin[0];
						(savedPoint as any).runIndex = activityIndex;
						dedupedPoints.push(longitudeBin[0] as any);
					}
				})
			}
		})
	})

	var latitudeHistogramBins = latitudeHistogram(dedupedPoints as any);
	latitudeHistogramBins.forEach((latitudeBin, index) => {
		if (latitudeBin.length !== 0) {
			var longitudeHistogramBins = longitudeHistograms[index](latitudeBin);
			longitudeHistogramBins.forEach(longitudeBin => {
				if (longitudeBin.length !== 0) {
					var firstActivityIndex = Math.min(...longitudeBin.map(p => (p as any).runIndex));
					runInfo[firstActivityIndex].uniqueBins += 1;
					bins.push({
						extent:  {
							south: latitudeBin.x0 || 0,
							north: latitudeBin.x1 || 0,
							east: longitudeBin.x0 || 0,
							west: longitudeBin.x1 || 0
						},
						runs: longitudeBin.map(v => (v as any).runIndex),
					});
				}
			})
		}
	})
	const binExtent = d3.extent(bins, function(b){ return b.runs.length});
	const binRange = (binExtent[1] || 0) - (binExtent[0] || 0);

	return {
		bins,
		boundaries,
		runInfo,
		binExtent: [
			binExtent[0] || 0,
			binExtent[1] || 0
		],
		binRange,
	}
}

export const GetGeoJsonBinWithCoordinatesAndColor = (coordinates: Position[], color: string, runs: number[]): Feature<Geometry, GeoJsonProperties> => {
	return {
		geometry: {
			coordinates: [coordinates],
			type: "Polygon"
		},
		type: "Feature",
		properties: {
			color: color,
			runs: runs,
		}
	}
}

export const CreateGeoJSONBoxForBinExtent = (extent: ResortBounds) => {
	const nw = [extent.west, extent.north];
	const sw = [extent.west, extent.south];
	const se = [extent.east, extent.south];
	const ne = [extent.east, extent.north];
	return [nw, sw, se, ne, nw];
}