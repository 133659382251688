import * as React from 'react';
import { DataViewPropsBase, ActivityData } from './types';
import * as d3 from 'd3';
import ActivityModal from './ActivityModal';
import { COMMON_COLOR_INTERPOLATION } from './Common';

interface Props extends DataViewPropsBase {
}

const TimeToArcDegrees = (time: Date) => {
    const hourAngle = ((time.getHours() % 12) / 12) * 2 * Math.PI;
    const minuteAngle = (time.getMinutes() / (12*60)) * 2 * Math.PI;
    return hourAngle + minuteAngle;
}

const RadiansToDegrees = (radians: number) => ((180 * radians) / (Math.PI));

const MinutesToArcDegreesHours = (minutes: number) => (minutes / (12*60)) * (2 * Math.PI);

const EndAngle = (_: any, index: number, paths: any) => {
    const duration = parseFloat((paths[index] as Element)?.getAttribute('data-duration') || '0');
    return MinutesToArcDegreesHours(duration / 60);
}

interface State {
    width: number;
    height: number;
    showModal: boolean;
    focusActivity?: ActivityData;
    focusDisplay?: string;
    focusTranslate?: string;
}

export default class Circles extends React.Component<Props> {
    private static MARGINS = { top: 65, right: 30, bottom: 45, left: 60 };
    private minRadius = 10;
    private maxRadius = 0;
    private radiusIncrement = 0;
    private graphParent: React.RefObject<SVGSVGElement> = React.createRef();
    private arcGenerator = d3.arc()
        .startAngle(0)
        .endAngle(EndAngle);
    
    public state: State = {
        width: 0,
        height: 0,
        showModal: false,
        focusDisplay: 'none',
    };

    public componentDidMount() {
        window.addEventListener('resize', this.resize);
        this.resize();
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    private innerRadius = (a: any, i: number) => {
        return this.minRadius + (i * this.radiusIncrement);
    }

    private outerRadius = (a: any, i: number) => {
        return this.minRadius + ((i + 1) * this.radiusIncrement);
    }

    private resize = () => {
        const width = this.graphParent.current?.clientWidth || 0;
        const height = this.graphParent.current?.clientHeight || 0;
        const halfWidth = width / 2;
        const halfHeight = height / 2;
        this.maxRadius = ((width < height)
            ? halfWidth
            : halfHeight) - Circles.MARGINS.top;
        this.radiusIncrement = (this.maxRadius - this.minRadius) / this.props.activities.length;
        this.arcGenerator.innerRadius(this.innerRadius).outerRadius(this.outerRadius);

        this.setState({
            width,
            height,
        });
    }

    private update = () => {
        d3.selectAll('path.arc').attr('d', this.arcGenerator as any);
    }

    public componentDidUpdate = () => {
        this.update();
    }

    public render() {
        const { width, height, showModal, focusActivity, focusDisplay, focusTranslate } = this.state;
        const { activities, statistics, athleteId } = this.props;
        const { minSplitPace, maxSplitPace } = statistics;
        return (
            <>
                <svg
                    width="100%"
                    height="100%"
                    style={{ position: 'absolute' }}
                    ref={this.graphParent}
                >
                    <g
                        transform={`translate(${width / 2},${height / 2})`}
                    >
                        {
                            activities.map(activity => {
                                return (
                                    <path
                                        key={activity.id}
                                        fill={COMMON_COLOR_INTERPOLATION((activity.pace - minSplitPace) / (maxSplitPace - minSplitPace))}
                                        transform={`rotate (${RadiansToDegrees(TimeToArcDegrees(activity.start))})`}
                                        onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                                            this.setState({
                                                focusActivity: activity,
                                                focusDisplay: undefined,
                                                focusTranslate: `translate(${event.clientX},${event.clientY})`,
                                            });
                                            d3.select(event.target as SVGPathElement).transition().duration(100).attr('stroke', '#333');
                                        }}
                                        onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                                            this.setState({
                                                focusDisplay: 'none',
                                            })
                                            d3.select(event.target as SVGPathElement).transition().duration(100).attr('stroke', null);
                                        }}
                                        onClick={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                                            this.setState({
                                                focusActivity: activity,
                                                showModal: true,
                                            })
                                        }}
                                        data-duration={activity.duration}
                                        className="arc"
                                    />
                                );
                            })
                        }
                    </g>
                    <g
                        display={focusDisplay}
                        transform={focusTranslate}
                    >
                        <text
                            dx={15}
                            dy="-.7em"
                        >
                            {focusActivity?.name}
                        </text>
                        <text
                            dx={15}
                            dy=".3em"
                        >
                            {new Date(focusActivity?.date || 0).toDateString()}
                        </text>
                    </g>
                    <text
                        textAnchor="middle"
                        fontSize="30px"
                        x={width / 2}
                        y={10 + (Circles.MARGINS.top / 2)}
                    >
                        Clocked
                    </text>
                </svg>
            <ActivityModal
                activity={focusActivity}
                showModal={showModal}
                onHide={() => this.setState({ showModal: false })}
                athleteId={athleteId}
            />
        </>
        );
    }
}