import { Feature } from 'geojson';
import { LngLat } from 'mapbox-gl';

export interface ActivityStatistics {
    maxDistance: number;
    maxDuration: number;
    maxPace: number;
    minPace: number;
    minSplitPace: number;
    maxSplitPace: number;
    maxDiffX: number;
    maxDiffY: number;
    averagePace: number;
}

export interface DataViewPropsBase {
    activities: ActivityData[];
    statistics: ActivityStatistics;
    athleteId: number;
}

export interface MileData {
    pace: number;
    count: number;
    runIds: string;
}

export interface ActivitySplit {
    time: number;
    distance: number;
    moving_time: number;
    totalTime?: number;
    pace?: number;
    runStandardDeviation?: number;
    overallStandardDeviation?: number;
    mileStandardDeviation?: number;
}

interface ActivityDataCommon {
    distance: number;
    duration: number;
    type: string;
    polyline?: string;
    id: number;
    speed: number;
    pace: number;
    calories: number;
    average_heartrate?: number;
    name: string;
    splits: ActivitySplit[];
}

export interface RawActivityData extends ActivityDataCommon {
    date: string;
    start: string;
}

export interface ActivityData extends ActivityDataCommon {
    date: Date;
    start: Date;
    yearlyCumulativeDistance?: number;
    totalCumulativeDistance?: number;
    splitTimes?: number;
    splitSquaredTimes?: number;
    splitTimeAverage?: number;
    splitSquaredTimeAverage?: number;
    splitTimeStandardDeviation?: number;
    decodedPoly: [number,number][];
    maxY: number;
    maxX: number;
    minY: number;
    minX: number;
    diffX: number;
    diffY: number;
    fixedArray: number[][];
}

export interface CityDetails {
    // Array is ordered alongside 'ways', indexes are used in the nodes array
    wayNames: string[];

    // Multiple parts of ways are separated into different arrays, values are node indexes from below array
    ways: number[][];

    // [[ latitude, longitude ], [ wayIndex, ...]]
    // wayIndex is from above arrays' indicies
    nodes: [[number,number],number[]][];
}

export interface Waypoint {
    selected: boolean;
    lnglat: mapboxgl.LngLat;
}

export interface CityNodeFeature {
    type: string;
    geometry: {
        type: string;
        coordinates: number[];
    };
    properties: {
        color: string;
        id: number;
        ways: number[];
        nodeFound: boolean;
        activityId?: number;
    };
}

export interface CityWay {
    ids: number[];
    nodes: number[][];
    completed: number;
    complete: boolean;
    completePercent: number;
    completedBy?: number;
}

export interface CityWayV2 {
    incompleted: number;
    completed: number;
    complete: boolean;
    completePercent: number;
    completedBy: number;
    ids?: number[];
}

export interface CanvasElement extends HTMLCanvasElement {
    captureStream(frameRate?: number): MediaStream;
}

export interface Node {
    ways: number[];
    id: number;
    activityId: number;
    coordinates: [number, number];
}

export interface PathCoordinate {
    longitude: number;
    latitude: number;
    activityId: number;
}

export interface CoordinateBracket {
    [bracket: number]: {
        [bracket: number]: PathCoordinate[];
    }
}

export const WaypointToGeoJson = (waypoint: Waypoint, index: number, waypoints: Waypoint[]): Feature => {
    return {
        type: 'Feature',
        id: index,
        geometry: {
            type: 'Point',
            coordinates: waypoint.lnglat.toArray(),
        },
        properties: {
            color: index === 0
                ? '#99FF99'
                : index === waypoints.length - 1
                    ? '#FF9999'
                    : '#9999FF',
            selected: waypoint.selected,
        }
    }
}

export const GeoJsonToWaypoint = (geoJsonFeature: any): Waypoint => {
    const { coordinates } = geoJsonFeature.geometry;
    const { selected } = geoJsonFeature.properties as any
    return {
        selected,
        lnglat: new LngLat(coordinates[0], coordinates[1])
    };
}