import { useCallback, useEffect, useRef, useState } from "react";
import AnimatedPath from "./AnimatedPath";
import * as d3 from 'd3';
import { D3_CATEGORY_10, GraphMargins } from "./common";
import { Swatches } from "./Swatches";

export interface GraphLine<T> {
    dataPoints: T[];
    name: string;
    width?: number;
}

interface Focus {
    x: number;
    y: number;
    text: string;
}

interface SimplyGraphProps<T> {
    svgStyle?: React.CSSProperties;
    margins?: GraphMargins;
    graphLines: GraphLine<T>[];
    xAxisFormat?: any;
    yAxisFormat?: any;
    xScaleOverride?: d3.ScaleTime<any,any,any>|d3.ScaleLinear<any,any,any>|d3.ScaleLogarithmic<any,any,any>;
    yScaleOverride?: d3.ScaleLinear<any,any,any>|d3.ScaleLogarithmic<any,any,any>;
    forceXDomain?: any;
    forceYDomain?: any;
    yRangeOverride?: number[];
    xAccessor?: string|((datum: T) => number|Date);
    yAccessor?: string;
    titleText?: string;
    colors?: d3.ScaleOrdinal<any, any, any>;
    lineCurve?: d3.CurveFactory;
    omitLastDataLabel?: boolean;
    xAxisLabel?: string;
    yAxisLabel?: string;
    yRangeRound?: boolean;
    swatchColumnWidth?: number;
    lineDefined?: (d: [number, number], index: number, data: [number, number][]) => boolean;
    yTicksArguments?: [number,string];
    xTicksArguments?: [number,string];
    niceYScale?: boolean;
    tickColor?: string;
    rotateYAxisLabel?: boolean;
    xAxisMaxExtent?: boolean;
    disableHoverFocus?: boolean;
    disableLegend?: boolean;
    globalFocus?: string;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    onMouseMove?: (index: number) => void;
}

const accessValue = (datum: any, accessor?: string|((datum: any) => number|Date)) => {
    if (accessor === undefined) {
        return 0;
    }
    if (typeof(accessor) === 'string') {
        return datum[accessor];
    }
    return accessor(datum);
}

const SimplyGraph: React.FC<SimplyGraphProps<any>> = ({
    svgStyle,
    margins = { top: 10, right: 35, bottom: 45, left: 40 },
    graphLines,
    xAxisFormat = null,
    yAxisFormat = null,
    xScaleOverride = d3.scaleTime(),
    yScaleOverride = d3.scaleLinear(),
    forceXDomain,
    forceYDomain,
    yRangeOverride,
    xAccessor = 'x',
    yAccessor = 'y',
    titleText,
    colors = D3_CATEGORY_10,
    lineCurve = d3.curveBasis,
    omitLastDataLabel = false,
    xAxisLabel,
    yAxisLabel,
    yRangeRound,
    swatchColumnWidth = 180,
    lineDefined,
    yTicksArguments,
    xTicksArguments,
    niceYScale = false,
    tickColor = '#CCC',
    rotateYAxisLabel = true,
    xAxisMaxExtent = false,
    disableHoverFocus = false,
    disableLegend = false,
    globalFocus,
    onMouseMove = () => {},
    onMouseOut = () => {},
    onMouseOver = () => {},
}) => {
    const svgRef = useRef<SVGSVGElement>(null);

    const xAxisRef = useRef<SVGGElement>(null);
    const yAxisRef = useRef<SVGGElement>(null);

    const xScale = useRef<d3.ScaleTime<any,any,any>|d3.ScaleLinear<any,any,any>>(xScaleOverride);
    const yScale = useRef<d3.ScaleLinear<number,number,any>|d3.ScaleLogarithmic<any,any,any>>(yScaleOverride);

    const xAxis = useRef<d3.Axis<d3.NumberValue | Date>>(d3.axisBottom(xScale.current).tickPadding(6).tickFormat(xAxisFormat as any));
    const yAxis = useRef<d3.Axis<d3.NumberValue | Date>>(d3.axisLeft(yScale.current).tickPadding(6).tickFormat(yAxisFormat));

    const resizeRef = useRef<ResizeObserver>();

    const drawLine = useRef<d3.Line<[number,number]>>(d3.line().curve(lineCurve));

    const [graphWidth, setGraphWidth] = useState(0);
    const [graphHeight, setGraphHeight] = useState(0);
    const [xDomain, setXDomain] = useState(xScale.current.domain());
    const [yDomain, setYDomain] = useState(yScale.current.domain());

    const [selectedLine, setSelectedLine] = useState<string>();
    const [focus, setFocus] = useState<Focus>();

    xScale.current.range([0, graphWidth]);
    const yRange = (yRangeOverride && yRangeOverride.map(i => i * graphHeight)) || [graphHeight, 0];
    if (yRangeRound) {
        yScale.current.rangeRound(yRange);
    } else {
        yScale.current.range(yRange);
    }
    xScale.current.domain(forceXDomain || xDomain);
    yScale.current.domain(forceYDomain || yDomain);
    if (niceYScale) {
        yScale.current.nice();
    }
    d3.select(xAxisRef.current).call(xAxis.current as any).transition('100').style('color', tickColor);
    d3.select(yAxisRef.current).call(yAxis.current as any).transition('100').style('color', tickColor);

    useEffect(() => {
        if (yTicksArguments !== undefined) {
            yAxis.current.ticks(yTicksArguments[0], yTicksArguments[1]);
        } else {
            yAxis.current.ticks(null);
        }
        d3.select(yAxisRef.current).call(yAxis.current as any)
    }, [yTicksArguments]);

    useEffect(() => {
        if (xTicksArguments !== undefined) {
            xAxis.current.ticks(xTicksArguments[0], xTicksArguments[1]);   
        } else {
            xAxis.current.ticks(null);
        }
        d3.select(xAxisRef.current).call(xAxis.current as any)
    }, [xTicksArguments]);

    useEffect(() => {
        if (yAxisFormat !== undefined) {
            yAxis.current.tickFormat(yAxisFormat);
        } else {
            yAxis.current.tickFormat(null);
        }
        d3.select(yAxisRef.current).call(yAxis.current as any)
    }, [yAxisFormat]);

    useEffect(() => {
        if (xAxisFormat !== undefined) {
            xAxis.current.tickFormat(xAxisFormat);
        } else {
            xAxis.current.tickFormat(null);
        }
        d3.select(xAxisRef.current).call(xAxis.current as any)
    }, [xAxisFormat]);

    useEffect(() => {
        xScale.current = xScaleOverride;
    }, [xScaleOverride]);

    useEffect(() => {
        yScale.current = yScaleOverride;
    }, [yScaleOverride]);

    const resize = useCallback(() => {
        const { current: svg } = svgRef;
        if (!svg) { return; }

        const updatedWidth = svg.clientWidth;
        const updatedHeight = svg.clientHeight;
        const updatedGraphWidth = updatedWidth - margins.left - margins.right;
        const updatedGraphHeight = updatedHeight - margins.bottom;

        xAxis.current.tickSize(-updatedGraphHeight);
        yAxis.current.tickSize(-updatedGraphWidth);
        xAxis.current.scale(xScale.current);
        yAxis.current.scale(yScale.current);

        setGraphWidth(updatedGraphWidth);
        setGraphHeight(updatedGraphHeight);
    }, [margins]);

    useEffect(() => {
        const allXValues = graphLines.reduce(
            (accumulator: any[], line) =>
                accumulator.concat(
                    line.dataPoints.map(
                        point => accessValue(point, xAccessor)
                    )
                ), []
        );
        const updatedXDomain = d3.extent(allXValues);
        const updatedYDomain = d3.extent(
            graphLines.reduce(
                (accumulator: any[], line) =>
                    accumulator.concat(
                        line.dataPoints.map(
                            point => point[yAccessor]
                        )
                    ), []
            )
        );
        if (forceXDomain || (updatedXDomain[0] !== undefined && updatedXDomain[1] !== undefined)) {
            setXDomain(updatedXDomain as [any,any]);
        }
        if (forceYDomain || (updatedYDomain[0] !== undefined && updatedYDomain[1] !== undefined)) {
            setYDomain(updatedYDomain as [any,any]);
        }
        
        drawLine.current
            .x((a: any) => xScale.current(accessValue(a, xAccessor)))
            .y((a: any) => yScale.current(a[yAccessor]));
        if (lineDefined) {
            drawLine.current.defined(lineDefined);
        }
    }, [graphLines, xAccessor, yAccessor, forceXDomain, forceYDomain, lineDefined]);

    useEffect(() => {
        if (!resizeRef.current) {
            resizeRef.current = new ResizeObserver(resize);
        }
        const { current: currentSvgRef } = svgRef;
        if (currentSvgRef) {
            resizeRef.current.observe(currentSvgRef);
        }
        
        resize();
        return () => {
            if (resizeRef.current && currentSvgRef) {
                resizeRef.current?.unobserve(currentSvgRef);
            }
        }
    }, [resize]);

    const bisector = useCallback(() => {
        return d3.bisector((d) => {
            return accessValue(d, xAccessor);
        })
    }, [xAccessor]);

    const handleOverlayMouseMove = useCallback((event: React.MouseEvent<SVGRectElement>) => {
        const lineToFocus = graphLines.find(a => a.name === globalFocus);
        if (lineToFocus === undefined) return;
        const { dataPoints } = lineToFocus;
        const xPosition = event.clientX - event.currentTarget.getBoundingClientRect().left;
        const xScaleInverted = xScale.current.invert(xPosition);
        
        const bisect = Math.min(
            bisector().right(dataPoints, xScaleInverted),
            dataPoints.length - 1
        );
        onMouseMove(bisect);
        const value = dataPoints[bisect];
        setFocus({
            x: accessValue(value, xAccessor),
            y: value[yAccessor],
            text: (yAxisFormat && yAxisFormat(value[yAccessor])) || value[yAccessor],
        })
    }, [xAccessor, yAccessor, yAxisFormat, bisector, globalFocus, graphLines, onMouseMove]);

    const xFocus = xScale.current(focus?.x || 0);
    const yFocus = yScale.current(focus?.y || 0);
    const focusTranslate = `translate(${xFocus},${yFocus})`;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
        }}>
            <div style={{ flexGrow: 1, width: '100%', height: '100%' }}>
                <svg
                    width="100%"
                    height="100%"
                    style={svgStyle}
                    ref={svgRef}
                >
                    <g
                        transform={`translate(${margins.left},${margins.top / 2})`}
                    >
                        <defs>
                            <clipPath id="clip">
                                <rect
                                    width={graphWidth}
                                    height={graphHeight}
                                    x={0}
                                    y={0}
                                />
                            </clipPath>
                        </defs>
                        {titleText && (<text
                            textAnchor="middle"
                            fontSize="30px"
                            x={graphWidth / 2}
                            y={-15}
                            children={titleText}
                        />)}
                        {xAxisLabel && (<text
                            textAnchor="middle"
                            fontSize="16px"
                            x={graphWidth / 2}
                            y={graphHeight + 26}
                            children={xAxisLabel}
                        />)}
                        {yAxisLabel && (<text
                            textAnchor="middle"
                            fontSize="16px"
                            transform={rotateYAxisLabel ? "rotate(-90)" : undefined}
                            x={rotateYAxisLabel ? -graphHeight / 2 : -40}
                            y={rotateYAxisLabel ? -45 : graphHeight / 2}
                            children={yAxisLabel}
                        />)}
                        {xAxisMaxExtent && (<text
                            textAnchor="end"
                            fontSize="16px"
                            x={graphWidth}
                            y={graphHeight + 26}
                        >
                            {xScale.current.tickFormat()(xScale.current.domain()[1] as any)}{xAxisLabel}
                        </text>)}
                        <g
                            ref={xAxisRef}
                            transform={`translate(0, ${graphHeight})`}
                        />
                        <g
                            ref={yAxisRef}
                        />
                        {focus && (
                            <g>
                                <line
                                    style={{ stroke: 'white', strokeDasharray: '3,3' }}
                                    y1={0}
                                    y2={graphHeight - yFocus}
                                    transform={focusTranslate}
                                />
                                <line
                                    style={{ stroke: 'white', strokeDasharray: '3,3' }}
                                    x1={0}
                                    x2={xFocus}
                                    transform={`translate(0,${yFocus})`}
                                />
                            </g>
                        )}
                        <g clipPath="url(#clip)">
                            {graphLines.map((line, index) => <g key={index}>
                                <AnimatedPath
                                    className="animatedLine"
                                    stroke={colors && colors(line.name)}
                                    fill="none"
                                    strokeWidth={selectedLine === line.name ? 4.0 : line.width || 1.5}
                                    opacity={selectedLine
                                        ? selectedLine === line.name
                                            ? 1.0
                                            : 0.2
                                        : 1.0}
                                    d={drawLine.current(line.dataPoints) || ''}
                                    onMouseEnter={(event: React.MouseEvent<SVGPathElement,MouseEvent>) => {
                                        // d3.selectAll('.animatedLine').attr('opacity', 0.2);
                                        // d3.select(event.currentTarget).attr('opacity', 1.0).attr('stroke-width', 4.0);
                                        !disableHoverFocus && setSelectedLine(line.name);
                                    }}
                                    onMouseLeave={(event: React.MouseEvent<SVGPathElement,MouseEvent>) => {
                                        // d3.selectAll('.animatedLine').attr("opacity", 1.0).attr("stroke-width", 1.5);
                                        !disableHoverFocus && setSelectedLine(undefined);
                                    }}
                                />
                            </g>)}
                        </g>
                        {!omitLastDataLabel && graphLines.reduce(
                            (
                                accumulator: { color: string; x: number, y: number, name: string }[],
                                line
                            ) => {
                                const { dataPoints, name } = line;
                                const color = colors && colors(name);
                                const lastValue = dataPoints[dataPoints.length - 1];
                                if (lastValue === undefined) { return accumulator; }
                                const additionalLastValue = {
                                    name,
                                    color,
                                    x: accessValue(dataPoints[dataPoints.length - 1], xAccessor),
                                    y: dataPoints[dataPoints.length - 1][yAccessor],
                                };
                                return accumulator.concat([additionalLastValue]);
                            },
                            []).sort((a, b) => b.y - a.y)
                            .map((lineLastValue, index) => {
                                const x = xScale.current(lineLastValue.x);
                                const y = yScale.current(lineLastValue.y);
                                return (y !== undefined) && (x !== undefined) && <text
                                    key={index}
                                    strokeWidth="2px"
                                    fontSize="12px"
                                    fontWeight="bold"
                                    opacity={selectedLine
                                        ? selectedLine === lineLastValue.name
                                            ? 1.0
                                            : 0.2
                                        : 1.0}
                                    x={x}
                                    y={y + 10}
                                    fill={lineLastValue.color}
                                    style={{
                                        // dominantBaseline: index === 0 ? 'text-after-edge' : index === (graphLines.length - 1) ? 'hanging' : 'auto',
                                    }}
                                >
                                    {(yAxisFormat && yAxisFormat(lineLastValue.y)) || lineLastValue.y}
                                </text>
                            })
                        }
                        {focus && (
                            <g
                                transform={focusTranslate}
                            >
                                <circle
                                    r={4.5}
                                    stroke="#FFF"
                                    strokeWidth="1.5px"
                                    fill="none"
                                />
                                <text
                                    y={-14}
                                    dy=".35em"
                                >
                                    {(yAxisFormat && yAxisFormat(focus.y)) || focus.y}
                                </text>
                            </g>)}
                        {globalFocus && <rect
                            style={{
                                fill: 'none',
                                pointerEvents: 'all',
                            }}
                            onMouseOver={() => {
                                onMouseOver();
                            }}
                            onMouseOut={() => {
                                onMouseOut();
                            }}
                            onMouseMove={handleOverlayMouseMove}
                            width={graphWidth}
                            height={graphHeight}
                        />}
                    </g>
                </svg>
            </div>
            {!disableLegend && <Swatches
                columns={`${swatchColumnWidth}px`}
                colors={colors}
                domain={graphLines.map(s => s.name)}
                filteredCategories={[]}
                swatchClicked={(value) => {
                    setSelectedLine(selectedLine === value ? undefined : value)
                }}
                marginLeft={margins.left}
                selectedSwatch={selectedLine}
            />}
        </div>
        
    )
}

export default SimplyGraph;