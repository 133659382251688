import * as React from 'react';
import './loading.css';

interface Props {
    className?: string;
    isOpen: boolean;
    percentProgress?: number;
    message?: string;
}

export default class FullScreenLoading extends React.Component<Props> {
    public render() {
        const {
            className,
            isOpen,
            percentProgress,
            message,
        } = this.props;

        const menuStyle: React.CSSProperties = isOpen
            ? {
                height: '100%',
                top: 0,
                overflowY: 'auto',
            }
            : {};

        return (
            <div
                style={{
                    zIndex: 100,
                    width: '100%',
                    height: 0,
                    left: 0,
                    position: 'fixed',
                    top: '-2000px',
                    backgroundColor: 'rgba(14, 14, 14, .65)',
                    transition: '0.9s',
                    ...menuStyle
                }}
                className={className}
            >
                <div
                    style={{
                        height: '20px',
                        backgroundColor: 'rgba(241, 241, 241, .1)',
                        padding: '5px',
                        borderRadius: '3px',
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            backgroundColor: 'rgba(241, 241, 241, .9)',
                            transition: '0.3s'
                        }}                    
                    >
                        <div
                            style={{
                                width: `${percentProgress || 0}%`,
                                backgroundColor: 'rgba(255, 255, 255, .9)',
                                height: '100%',
                                transformOrigin: 'left',
                                animation: `loadingAnimation 3s infinite`,
                            }}
                        />
                    </div>
                </div>
                <h1
                    style={{
                        color: 'rgb(255, 255, 255, .9',
                        textAlign: 'center',
                    }}
                >
                    {message || ''}
                </h1>
            </div>
        );

    }
}