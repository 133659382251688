export const apiPath = 'https://jrgrover.com/api/dewpoint';
export const nominatimApiPath = "https://nominatim.openstreetmap.org/search?format=json&q="

export const linearGradientStops = [
  {
    temp: 150,
    color: 'rgba(244,204,204,1)'
  },
  {
    temp: 100,
    color: 'rgba(244,204,204,1)'
  },
  {
    temp: 80,
    color: 'rgba(244,204,204,.8)'
  },
  {
    temp: 75,
    color: 'rgba(244,204,204,.75)'
  },
  {
    temp: 70,
    color: 'rgba(244,204,204,.5)'
  },
  {
    temp: 65,
    color: 'rgba(182,215,152,.5)'
  },
  {
    temp: 60,
    color: 'rgba(182,215,152,.6)'
  },
  {
    temp: 55,
    color: 'rgba(207,226,243,.9)'
  },
  {
    temp: 50,
    color: 'rgba(207,226,243,.8)'
  },
  {
    temp: 25,
    color: 'rgba(255,224,132,.5)'
  },
  {
    temp: 0,
    color: 'rgba(207,226,243,.8)'
  },
];

export const margin = {
  top: 65,
  right: 40,
  bottom: 45,
  left: 60,
};
