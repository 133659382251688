import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Ascent } from '.';

export const PeakbaggerPeakLinkForPeakId = (peakId: number) =>
    `https://www.peakbagger.com/peak.aspx?pid=${peakId}`;

interface PopupProps {
    id: number;
    name: string;
    ascents: Ascent[];
    photo: string;
}

const PeakPopup: React.FC<PopupProps> = ({ name, photo, ascents, id }) => {
    const [showAscents, setShowAscents] = useState(false);
    
    return (
        <div>
            {photo && photo !== 'null' && (
                <img
                    alt="peakbagger"
                    style={{ width: '200px' }}
                    src={`https://www.peakbagger.com/${photo}`}
                />
            )}
            <h4>
                {name} <a href={PeakbaggerPeakLinkForPeakId(id)}>⛰️</a>
            </h4>
            <b>{ascents.length} ascents <FontAwesomeIcon
                icon={showAscents ? faChevronDown : faChevronRight}
                onClick={() => setShowAscents(!showAscents)}
            /></b>
            {
                showAscents && (
                    <ul
                        style={{
                            maxHeight: '200px',
                            overflow: 'auto',
                        }}
                    >
                        {ascents.map((ascent, index) =>
                            <li
                                key={`${id}-${index}`}
                            >
                                <a
                                    href={`https://www.peakbagger.com/climber/ascent.aspx?aid=${ascent.id}`}
                                >
                                    {ascent.date}
                                </a>
                            </li>
                        )}
                    </ul>
                )
            }
        </div>
    )
}

export default PeakPopup;