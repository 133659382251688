import * as THREE from 'three';
import { vertexShader, contourIncrementShader } from '../../lib/Utils/Shaders';
import { OrbitControls } from './OrbitControls';
import TextureManager from 'three-sprite-texture-atlas-manager';
import * as d3 from 'd3';
import {
  lat2tile,
  long2tile,
  tile2long,
  tile2lat,
  MeterDistanceBetweenLatitudeLongitude,
  createShadowPlane,
  MakePlaneBufferGeometry,
  CreateContourMesh,
  GetMapboxTileUrl,
  MapboxStyle,
} from '../../lib/Utils/Maps';
import { Conrec } from 'conrec';

const MAX_VERTEX_HEIGHT = 6263.47;
const MIN_VERTEX_HEIGHT = -418;
const TERRAIN_TILE_URL = 'https://s3.amazonaws.com/elevation-tiles-prod/terrarium/';
const MAPZEN_KEY = '?api_key=mapzen-2p1DuB5';
const SYMBOL_MAP = {
  "spelunking": "👁",
  "nps-scramble": "⛰️",
  "walking": "🚶",
  "point": "●"
};

global.THREE = require('three');

export class TopologicalMap{
  constructor(options) {
    this.scope = this;
    this.geoJson = false;
    this.locationEnabled = false;
    this.wpid = undefined;
    this.locationSprite = undefined;

    // Const type stuff
    this.imageLoader = new THREE.ImageLoader();
    this.imageLoader.crossOrigin = '';

    this.loading = options.loading || {
      'setProgress': (progress, text) => {/*console.info("set", progress, text)*/},
      'toggle': () => {/*console.info("toggling")*/}
    };

    this.heightMapLoaded = 0;
    this.textureMapLoaded = 0;

    this.canvasBuffer = document.createElement( 'canvas' );
    this.canvasBufferContext = this.canvasBuffer.getContext( '2d' );
    
    this.planeCanvas = document.createElement("canvas");
    this.planeCanvasContext = this.planeCanvas.getContext("2d");
    this.planeTexture = new THREE.Texture(this.planeCanvas)
    this.planeGeometryWithShader = new THREE.BufferGeometry();
    this.bufferMaterial = new THREE.ShaderMaterial({
      uniforms: {
        masterTexture: {type: "t", value: this.planeTexture}
      },
      vertexShader:   vertexShader,
      fragmentShader: contourIncrementShader,
    });

    this.contourIncrement = 50.0;
    this.contourIncrementSmall = 10.0;

    // Settings for the text we're creating:
    this.textureManager = new TextureManager(1024);
    //this.textureManager.debug = true;
    this.contourFontSize = 30;
    this.contourMarkerSpacing = 400;
    this.fontStyle = "Bold " + this.contourFontSize.toString() + "px 'Segoe UI', 'Lucida Grande', 'Tahoma', 'Calibri', 'Roboto', sans-serif";
    this.planeCanvasContext.font = this.fontStyle;

    this.renderDimension = options.renderDimension || 1000;
    this.zoom = options.zoom || 15;

    //Changing these might actually break stuff
    this.tileHeightmapDimension = 256;
    this.materialDimension = 512;

    this.minVerticesHeight = undefined;
    this.maxVerticesHeight = undefined;

    this.terrainTileUrl = options.terrainTileUrl || TERRAIN_TILE_URL;
    this.mapZenKey = options.mapZenKey || MAPZEN_KEY;
    // Not sure all of these would work...
    // This one is used in GetPromises, if it was false, we'd need to use a different shader for the plane
    this.useMapboxTiles = true;
    // This probably crashes if true since mapzen is gone
    this.addBuildings = false;
    this.animateLines = false;

    this.statsVisible = options.stats || false;
    this.antialias = options.antialias || true;
    this.styleUrl = options.styleUrl || GetMapboxTileUrl(MapboxStyle.TOPOGRAPHIC);
    this.mapzenAccessToken = options.mapzenAccessToken || "pk.eyJ1Ijoiam9ncm92ZXIiLCJhIjoiY2lvM2ZzNW9rMDBkcnZpbHp4aXFydnRrZSJ9.PQ5c63LutrKr0tmiyx76uw"

    this.snapLinesToGeometry = options.snapLinesToGeometry || false;
    this.backgroundColor = options.backgroundColor || 0xB3B3B3;
    if (!options.container) {
      this.parentElement = document.body;
    } else {
      this.parentElement = options.container;
    }
    this.initializeScene(options);
    this.resetHeights();
  }

  resetHeights() {
    this.minVerticesHeight = MAX_VERTEX_HEIGHT;
    this.maxVerticesHeight = MIN_VERTEX_HEIGHT;
  }

  getImageData(img) {
      this.canvasBuffer.width = img.width;
      this.canvasBuffer.height = img.height;
      this.canvasBufferContext.drawImage(img,0,0);
      return this.canvasBufferContext.getImageData(0, 0, img.width, img.height);
  }

  setSourceDataFromFit(data) {
    if (!this.sourceData) {
      this.sourceData = [];
    }
    this.sourceData.push(data);
    this.domain = [
      d3.min(this.sourceData, d => d3.min(d.records, v => v.timestamp)),
      d3.max(this.sourceData, d => d3.max(d.records, v => v.timestamp))
    ];
    this.zoomToDomain();
  }

  zoomToDomain(domain) {
    this.scaleZoom = true;
    this.domain = domain || this.domain;
    this.zoom = 15;
    // Add color here?
    var features = this.sourceData.map((data, i) => {
      return {
        "type": "Feature",
        "id": this.sourceData.length,
        "properties": {
          "stroke": data.color,
          "stroke-width": 1
        },
        "geometry": {
          "type": "LineString",
          "coordinates": data.records.filter(record => 
            record.timestamp <= this.domain[1] &&
              record.timestamp >= this.domain[0]
            ).map(record => [
              record.position_long,
              record.position_lat
            ])
        }
      }
    });
    this.removeSceneItems();
    this.resetHeights();
    this.setData({
      "type": "FeatureCollection",
      "features": features
    });
    this.createScene();
  }

  resetScene() {
    this.removeSceneItems()
  }

  removeSceneItems() {
    var itemsToRemove = this.scene.children.map(v => v);
    itemsToRemove.forEach(v => {
      if (v.material !== undefined)
      {
        v.material.dispose();  
      }
      if (v.geometry !== undefined)
      {
        v.geometry.dispose();  
      }
      this.scene.remove(v);
    });
  }

  setData(data) {
    // Add Points here
    this.points = data.features.filter((d) => d.geometry.type === "Point");
    this.lines = data.features.filter((d) => d.geometry.type === "LineString");
    this.geoJson = true;

    var lineExtents = this.lines.map((line) => {
      return {
        "north": d3.max(line.geometry.coordinates, function(l){ return l[1] }),
        "south": d3.min(line.geometry.coordinates, function(l){ return l[1] }),
        "east": d3.max(line.geometry.coordinates, function(l){ return l[0] }),
        "west": d3.min(line.geometry.coordinates, function(l){ return l[0] })
      }
    });
    var pointExtents = this.points.map((point) => {
      return {
        "north": point.geometry.coordinates[1],
        "south": point.geometry.coordinates[1],
        "east": point.geometry.coordinates[0],
        "west": point.geometry.coordinates[0]
      }
    });
    var extents = lineExtents.concat(pointExtents);

    var northEdge = d3.max(extents, function(l){ return l.north });
    var southEdge = d3.min(extents, function(l){ return l.south });
    var eastEdge = d3.max(extents, function(l){ return l.east });
    var westEdge = d3.min(extents, function(l){ return l.west });

    this.setTileInformationFromEdges(northEdge, southEdge, eastEdge, westEdge)
  }

  setTileInformationFromEdges(north, south, east, west) {
    var tileInformation = {};

    // Not the best, but it models my approach well enough
    while(true) {
      // console.log("trying to close in on tile size")
      // At a given zoom level, we can get the ecompassing tiles
      tileInformation.top     = lat2tile(north, this.zoom);
      tileInformation.left    = long2tile(west, this.zoom);
      tileInformation.bottom  = lat2tile(south, this.zoom);
      tileInformation.right   = long2tile(east, this.zoom);

      // These are used somewhere...the count of tiles wide and high?
      tileInformation.width   = Math.abs(tileInformation.left - tileInformation.right) + 1;
      tileInformation.height  = Math.abs(tileInformation.top - tileInformation.bottom) + 1;

      var tileCount = tileInformation.width * tileInformation.height;
      // If not set to prevent crashes or within reasonable scale
      if (!this.scaleZoom ||
        tileCount <= 25 || this.zoom <= 14) {
        // console.info("few enough tiles?", tileCount, this.zoom);
        break;
      }
      // console.info("too many tiles?", tileCount, this.zoom)
      this.zoom -= 1;
    }

    // Latitude Longitude bounding box for the tiles
    tileInformation.northEdge = tile2lat(tileInformation.top, this.zoom)
    tileInformation.westEdge  = tile2long(tileInformation.left, this.zoom)
    tileInformation.eastEdge  = tile2long(tileInformation.right + 1 , this.zoom)
    tileInformation.southEdge = tile2lat(tileInformation.bottom + 1 , this.zoom)

    // Get the latlong points for the furthest distance we'll cover
    var lat1, lon1, lat2, lon2;
    lat1 = tileInformation.northEdge
    lon1 = tileInformation.westEdge

    if (tileInformation.width > tileInformation.height)
    {
      lat2 = tileInformation.northEdge
      lon2 = tileInformation.eastEdge
    }
    else
    {
      lat2 = tileInformation.southEdge
      lon2 = tileInformation.westEdge
    }

    // Render dimension to real distance
    // Eg: 1000 render units == 
    tileInformation.renderScaleInMeters = this.renderDimension / MeterDistanceBetweenLatitudeLongitude({ lat: lat1, lon: lon1 }, { lat: lat2, lon: lon2 });
    tileInformation.minRenderHeight = MIN_VERTEX_HEIGHT * tileInformation.renderScaleInMeters;

    // more scaled stuff used later to make the tiles sized correctly?
    tileInformation.tileScale = (tileInformation.width > tileInformation.height) ? this.renderDimension / tileInformation.width : this.renderDimension / tileInformation.height
    tileInformation.xmin = -((tileInformation.tileScale * tileInformation.width)/2)
    tileInformation.zmin = -((tileInformation.tileScale * tileInformation.height)/2)
    tileInformation.xmax = -tileInformation.xmin
    tileInformation.zmax = -tileInformation.zmin

    tileInformation.longDiff = tileInformation.eastEdge - tileInformation.westEdge
    tileInformation.latDiff = tileInformation.northEdge - tileInformation.southEdge
    tileInformation.latTileScale = (tileInformation.tileScale * tileInformation.height) / tileInformation.latDiff
    tileInformation.longTileScale = (tileInformation.tileScale * tileInformation.width) / tileInformation.longDiff

    tileInformation.dataWidth = tileInformation.width * this.tileHeightmapDimension
    tileInformation.dataHeight = tileInformation.height * this.tileHeightmapDimension
    tileInformation.dataArea = tileInformation.dataWidth * tileInformation.dataHeight;
    tileInformation.longIndexPerOffset = tileInformation.dataWidth / tileInformation.longDiff
    tileInformation.latIndexPerOffset = tileInformation.dataHeight / tileInformation.latDiff

    tileInformation.vertexIndexOffsetWidth = tileInformation.dataWidth / (tileInformation.width * tileInformation.tileScale);
    tileInformation.vertexIndexOffsetHeight = tileInformation.dataHeight / (tileInformation.height * tileInformation.tileScale);

    tileInformation.heightSum = 0

    this.tileInformation = tileInformation;
    this.planeCanvas.width = tileInformation.width * this.materialDimension;
    this.planeCanvas.height = tileInformation.height * this.materialDimension;
  }

  setTileInformationForData(data) {
    this.data = data;
    var tempData = [];
    if (this.data.latlng[0].length !== 2) {
      this.data.latlng.forEach(d => {
        var tempArray = tempData.concat(d.latlng);
        tempData = tempArray;
      })
    } else {
      tempData = this.data.latlng;
    }

    // tmpData is an array of [lat,lng]
    var north_edge = d3.max(tempData, function(l){ return l[0] })
    var south_edge = d3.min(tempData, function(l){ return l[0] })
    var east_edge = d3.max(tempData, function(l){ return l[1] })
    var west_edge = d3.min(tempData, function(l){ return l[1] })
    this.setTileInformationFromEdges(north_edge, south_edge, east_edge, west_edge);
  }

  initializeScene() {
    // console.log('initializing')
    // Initialize stats
    /*
    this.stats = new Stats();
    this.stats.domElement.style.position = 'absolute';
    this.stats.domElement.style.top = '0px';
    this.stats.domElement.style.display = (this.statsVisible) ? '' : 'none';
    this.parentElement.appendChild(this.stats.domElement);
    */

    // Setup scene with size and stuff
    this.renderer = new THREE.WebGLRenderer({
      antialias: this.antialias
    });

    // Could make more of this options-driven
    this.width = this.parentElement.offsetWidth;
    this.height = this.parentElement.offsetHeight;
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize(this.width, this.height);
    this.parentElement.appendChild(this.renderer.domElement);
    
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(this.backgroundColor)

    this.camera = new THREE.PerspectiveCamera(45, this.width/this.height, 1, 10000);
    this.camera.position.y = 160;
    this.camera.position.z = 400;
    this.camera.lookAt (new THREE.Vector3(0,0,0));

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.maxPolarAngle = Math.PI/2;

    this.controls.rotateSpeed = 1.0;
    this.controls.zoomSpeed = 1.2;
    this.controls.panSpeed = 0.8;
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.15;

    window.addEventListener ('resize', () => this.onWindowResize(), false);
  }

  processPlaneGeometryData() {
    this.tileInformation.averageHeight = this.tileInformation.heightSum / (this.tileInformation.dataArea)

    // Calculate std. deviation of heights to throw out 3.2 sigma data
    this.tileInformation.sumSquaredVariance = 0
    for (var i = 0; i < this.planeGeometryWithShader.attributes.position.count * 3; i=i+3)
    {
      this.tileInformation.sumSquaredVariance += Math.pow((this.planeGeometryWithShader.attributes.position.array[i+2] - this.tileInformation.averageHeight) , 2)
    }
    this.tileInformation.standardDeviation = Math.pow((this.tileInformation.sumSquaredVariance / ((this.tileInformation.dataArea) - 1)) , 0.5)

    this.planeGeometryWithShader.attributes.position.needsUpdate = true;

    var anomolyCutoffHeight = this.tileInformation.averageHeight - (this.tileInformation.standardDeviation * 3)
    for (var position = 0; position < this.planeGeometryWithShader.attributes.position.count * 3; position=position+3)
    {
      var vertexHeight = this.planeGeometryWithShader.attributes.position.array[position+2]
      if (vertexHeight < anomolyCutoffHeight)
      {
        var newVertexHeightValue// = GetZAverageAdjacentToVertex(i, planeGeometryWithShader.attributes.position.array)
        //console.warn("new vertext height value:", newVertexHeightValue)
        //newVertexHeightValue = tileOptions.lowestEarthPoint
        newVertexHeightValue = this.tileInformation.averageHeight
        vertexHeight = newVertexHeightValue
        this.planeGeometryWithShader.attributes.position.array[position+2] = newVertexHeightValue
      }
      if (vertexHeight < this.minVerticesHeight)
      {
        this.minVerticesHeight = vertexHeight
      }
      if (vertexHeight > this.maxVerticesHeight)
      {
        this.maxVerticesHeight = vertexHeight
      }
    }
    //this.planeGeometryWithShader.computeVertexNormals()
  }

  GetBufferPositionToSnapTo(latitude, longitude)
  {
    // Point is outside the bounds of the height array
    if (longitude < this.tileInformation.westEdge || 
      latitude > this.tileInformation.northEdge ||
      longitude > this.tileInformation.eastEdge ||
      latitude < this.tileInformation.southEdge)
    {
      return -1
    }

    var longOffset = Math.round(Math.abs(this.tileInformation.westEdge - longitude) * this.tileInformation.longIndexPerOffset)
    var latOffset = Math.round(Math.abs(this.tileInformation.northEdge - latitude) * this.tileInformation.latIndexPerOffset)

    // Object would be out of bounds for the height array
    if (longOffset >= this.tileInformation.dataWidth || latOffset >= this.tileInformation.dataHeight)
    {
      return -1
    }

    return (((latOffset * this.tileInformation.dataWidth) + longOffset) * 3) + 2
  }

  CreateGeoJsonPoints() {
    var group = new THREE.Group();

    this.points.forEach(point => {
      var text = SYMBOL_MAP[point.properties["marker-symbol"]] || SYMBOL_MAP.point;
      var color = point.properties["marker-color"] || "rgb( 57, 114, 29 )";

      var sprite = this.CreateSpriteFromTextCoordinatesColor(text, point.geometry.coordinates, color);
      group.add(sprite);

      var spriteTitle = this.CreateSpriteFromTextCoordinatesColor(point.properties.title, point.geometry.coordinates, color, 10);
      group.add(spriteTitle);
    });

    this.scene.add( group );
  }

  CreateSpriteFromTextCoordinatesColor(text, coordinates, color, offsetHeight) {
    // A bit of space around the text to try to avoid hitting the edges:
    var xPadding = 15;
    var yPadding = 5;
    // Shift the text rendering up or down:
    var yOffset = -5;
    var pointScale = 15;

    this.planeCanvasContext.font = this.fontStyle;

    var offset = offsetHeight || 3;
    // Calculate the width of the text
    var width = this.widthOfText(text) + xPadding;
    // You base this height on your font size (may take some fiddling)
    var height = this.contourFontSize + (xPadding * 2) + yPadding;

    // Allocate a node for the text and draw the contents
    var node = this.textureManager.allocate(width, height);
    var context = node.clipContext();
    // console.log(width, height, node.texture.offset, context);
    context.font = this.fontStyle;
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillStyle = color;
    context.fillText(text, 0, yOffset);
    node.restoreContext();

    // The node is built, now make a Sprite out of it
    var aspectRatio = node.width / node.height;

    var material = new THREE.SpriteMaterial({
      map: node.texture
    });
    
    node.texture.needsUpdate = true;

    var sprite = this.CreateSpriteAtLatitudeLongitudeWithMaterial(coordinates[0], coordinates[1], material, offset);
    sprite.scale.set(pointScale * aspectRatio, pointScale, 1 );
    return sprite;
  }

  CreateSpriteAtLatitudeLongitudeWithMaterial(longitude, latitude, material, heightOffset) {
    var geometryToSnapTo = this.planeGeometryWithShader.attributes.position.array;
    var offset = heightOffset || 3;
    var sprite = new THREE.Sprite(material);
    sprite.name = "Label: " + longitude.toString() + "," + latitude.toString();

    var zPosition = ((latitude - this.tileInformation.southEdge) * this.tileInformation.latTileScale);
    zPosition += this.tileInformation.zmin

    var xPosition = ((this.tileInformation.eastEdge - longitude) * -this.tileInformation.longTileScale);
    xPosition -= this.tileInformation.xmin

    // TODO: Could check for -1 return here, but that should be impossible for current usage
    var index = this.GetBufferPositionToSnapTo(latitude, longitude)
    var yPosition = geometryToSnapTo[index] + offset - this.minVerticesHeight;

    sprite.position.set(xPosition, yPosition, -zPosition);
    return sprite;
  }

  CreateGeoJsonLine(line) {
    var geometry = [];
    var geometryToSnapTo = this.planeGeometryWithShader.attributes.position.array;

    var contourWidth = line.properties["stroke-width"] || 1;
    var color = line.properties.stroke || "#fc4c02";
    var coordinates = line.geometry.coordinates;

    for (var i = 0; i < coordinates.length; i++)
    {
      var zPosition = ((coordinates[i][1] - this.tileInformation.southEdge) * this.tileInformation.latTileScale);
      zPosition += this.tileInformation.zmin

      var xPosition = ((this.tileInformation.eastEdge - coordinates[i][0]) * -this.tileInformation.longTileScale);
      xPosition -= this.tileInformation.xmin

      // TODO: Could check for -1 return here, but that should be impossible for current usage
      var index = this.GetBufferPositionToSnapTo(coordinates[i][1], coordinates[i][0])
      var yPosition = geometryToSnapTo[index] + 1 - this.minVerticesHeight;

      geometry.push(xPosition, yPosition , -zPosition);
    }

    var contourMesh = CreateContourMesh(color, contourWidth, geometry)
    this.scene.add(contourMesh)
  }

  createGeoJsonElements() {
    this.lines.forEach(l => this.CreateGeoJsonLine(l));
    this.CreateGeoJsonPoints();
  }

  CreateRunLine(lineWidth) {
    var contourWidth = lineWidth || 3;
    var geometryPositions = [];
    var geometryToSnapTo = this.planeGeometryWithShader.attributes.position.array;

    for (var j = 0; j < this.data.latlng.length; j++)
    {
      var zPosition = ((this.data.latlng[j][0] - this.tileInformation.southEdge) * this.tileInformation.latTileScale);
      zPosition += this.tileInformation.zmin
      
      var xPosition = ((this.tileInformation.eastEdge - this.data.latlng[j][1]) * -this.tileInformation.longTileScale);
      xPosition -= this.tileInformation.xmin

      var yPosition

      if (this.snapLinesToGeometry) {
        // TODO: Could check for -1 return here, but that should be impossible for current usage
        var index = this.GetBufferPositionToSnapTo(this.data.latlng[j][0], this.data.latlng[j][1])
        yPosition = geometryToSnapTo[index] + 1 - this.minVerticesHeight;
      } else {
        yPosition = (this.data.altitude[j] + 2) * this.tileInformation.renderScaleInMeters;
        yPosition -= this.minVerticesHeight
      }

      geometryPositions.push(xPosition, yPosition , -zPosition);
    }

    var color = "#fc4c02"
    var contourMesh = CreateContourMesh(color, contourWidth, geometryPositions)
    this.scene.add(contourMesh)
  }

  getContours() {
    // var HeightRange = (this.maxVerticesHeight - this.minVerticesHeight) / this.tileInformation.renderScaleInMeters

    var contour = new Conrec();
    var data = [];
    var row;
    for (var i = 0; i < this.planeGeometryWithShader.attributes.position.count * 3; i=i+3)
    {
      var vertexHeight = this.planeGeometryWithShader.attributes.position.array[i+2] / this.tileInformation.renderScaleInMeters;
      if ((i / 3) % this.tileInformation.dataWidth === 0) {
        data.push([])
        row = data.length - 1;
      }
      data[row].push(vertexHeight);
    }

    var ilb = 0;
    var jlb = 0;
    // with cliff additon
    var x = d3.range(0, data.length);
    var y = d3.range(0, data[0].length);
    var iub = x.length - 1;
    var jub = y.length - 1;
    var minHeight = this.minVerticesHeight / this.tileInformation.renderScaleInMeters;
    var maxHeight = this.maxVerticesHeight / this.tileInformation.renderScaleInMeters;
    var lowestContour = minHeight + (this.contourIncrement - (minHeight % this.contourIncrement));
    var highestContour = maxHeight - (maxHeight % this.contourIncrement);
    var z = d3.range(lowestContour, highestContour, this.contourIncrement);
    var nc = z.length

    contour.contour(data, ilb, iub, jlb, jub, x, y, nc, z);
    return contour.contourList();
  }

  // https://jsfiddle.net/jzwg8ngn/61/  
  placeContourSprites(contours) {
    // A bit of space around the text to try to avoid hitting the edges:
    var xPadding = 15;
    var yPadding = 5;
    // Shift the text rendering up or down:
    var yOffset = -5;

    this.planeCanvasContext.font = this.fontStyle;
    //textureManager.debug = true;
    var group = new THREE.Group();

    var contourScale = 7;
    contours.forEach(contour => {
      var text = contour.level.toString() + " m";
      // Calculate the width of the text
      var width = this.widthOfText(text) + xPadding;
      // You base this height on your font size (may take some fiddling)
      var height = this.contourFontSize + (xPadding * 2) + yPadding;
      
      // Allocate a node for the text and draw the contents
      var node = this.textureManager.allocate(width, height);
      var context = node.clipContext();
      context.font = this.fontStyle;
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      //context.strokeStyle = 'rgba( 255, 255, 255, 0.5)';
      //context.lineWidth = 3;
      //context.lineJoin = "miter";
      //context.strokeText(text, 0, yOffset);
      context.fillStyle = 'rgb( 57, 114, 29 )';
      context.fillText(text, 0, yOffset);
      node.restoreContext();

      // The node is built, now make a Sprite out of it
      var aspectRatio = node.width / node.height;
      node.texture.needsUpdate = true;

      var material = new THREE.SpriteMaterial({
        map: node.texture
        //transparent: true,
        //blending: THREE.AdditiveBlending
      });

      var contourMarkersCount = Math.floor(contour.length / 400);
      if (contourMarkersCount <= 0) {
        return;
      }

      var contourMarkersIndexSpacing = Math.floor(contour.length / contourMarkersCount);
      for (var i = 1; i < contourMarkersCount; i++) {
        var contourIndex = i * contourMarkersIndexSpacing;

        var sprite = new THREE.Sprite(material);
        sprite.name = "Label: " + text;
        sprite.scale.set( contourScale * aspectRatio, contourScale, 1 );

        var x = contour[contourIndex].x / this.tileInformation.vertexIndexOffsetWidth;
        var y = contour[contourIndex].y / this.tileInformation.vertexIndexOffsetHeight;
        var z = ((contour.level + 5) * this.tileInformation.renderScaleInMeters) - this.minVerticesHeight;

        sprite.position.set(y, z, x);
        group.add(sprite);
      }
    });
    group.position.x = this.tileInformation.xmin;
    group.position.z = this.tileInformation.zmin;
    this.scene.add( group );
  }

  handlePromises() {
    this.loading.setProgress(0.9, "processing data")
    this.processPlaneGeometryData();
    this.loading.setProgress(0.95, "creating line")
    if (this.geoJson) {
      this.createGeoJsonElements();
    } else {
      this.CreateRunLine();
    }
    this.loading.setProgress(0.975, "computing contours")
    var contours = this.getContours();
    this.loading.setProgress(0.976, "placing contour sprites");
    this.placeContourSprites(contours);

    /*
    if (addBuildings)
    {
      console.log("trying to add buildings")
      var buildings = GetBuildingMeshFromTopoJson(topoJsonData)
      scene.add(buildings)
      
      var whiteMaterial = new THREE.MeshPhongMaterial( { color: 0xeeeeee, specular: 0x555555, shininess: 30 } );
      buildings.material = whiteMaterial
      plane.material = whiteMaterial
      plane.position.y = -minVerticesHeight
      plane.castShadow = true

      AddLightsToScene(scene)
    }
    */

    this.bufferMaterial.needsUpdate = true;
    this.bufferMaterial.uniforms.vCorrection = { type: "f", value: this.minVerticesHeight }
    this.bufferMaterial.uniforms.renderVerticalScale = { type: "f", value: this.tileInformation.renderScaleInMeters }
    this.bufferMaterial.uniforms.contourIncrement = { type: "f", value: this.contourIncrement }
    this.bufferMaterial.uniforms.contourIncrementHalf = { type: "f", value: this.contourIncrement / 2.0 }
    this.bufferMaterial.uniforms.contourIncrementSmall = { type: "f", value: this.contourIncrementSmall }
    this.bufferMaterial.uniforms.contourIncrementSmallHalf = { type: "f", value: this.contourIncrementSmall / 2.0 }
    this.bufferMaterial.uniforms.widthMultiplier = { type: "f", value: 0.2 * Math.pow(2., 20. - this.zoom) }
    this.bufferMaterial.uniforms.contourColor = { type: "v4", value: new THREE.Vector4(78 / 256, 135/256, 54/256, 0.5) }
    this.loading.setProgress(1, "done!")
    this.loading.toggle();
  }

  widthOfText(text) {
    return (Math.floor(this.planeCanvasContext.measureText(text).width));
  }

  createScene() {
    this.addShadowPlaneToScene();
    this.loading.setProgress(0.66, "requesting data")
    var promises = this.getPromises();

    //var gridXZ = new THREE.GridHelper(this.renderDimension, this.renderDimension / (tileOptions.renderScaleInMeters * 1000), new THREE.Color(0xff0000), new THREE.Color(0xffffff) );
    //scene.add(gridXZ);
    this.loading.setProgress(0.72, "creating terrain buffer")
    MakePlaneBufferGeometry(
      this.tileInformation.tileScale * this.tileInformation.width,
      this.tileInformation.tileScale * this.tileInformation.height,
      this.tileInformation.dataWidth,
      this.tileInformation.dataHeight,
      this.planeGeometryWithShader
    );

    this.plane = new THREE.Mesh(this.planeGeometryWithShader, this.bufferMaterial );
    this.plane.rotation.x = -Math.PI / 2;
    this.scene.add(this.plane);

    var tempCoordinates = [
      this.tileInformation.westEdge,
      this.tileInformation.northEdge
    ];
    this.locationSprite = this.CreateSpriteFromTextCoordinatesColor("  ▼  ", tempCoordinates, "#4477FF", 10);
    this.scene.add(this.locationSprite);
    this.locationSprite.visible = false;

    this.loading.setProgress(0.8, "waiting for data")
    Promise.all(promises).then(() => this.handlePromises(), function(error) {
        console.error("Could not load all textures: ", error)
    });
  }

  getPromises() {
    // Concept lifted from http://stackoverflow.com/a/33292152
    var allPromises = []

    for (var tileRow = 0; tileRow < this.tileInformation.height; tileRow++)
    {    
      for (var tileCol = 0; tileCol < this.tileInformation.width; tileCol++)
      {
        var tilePath = this.zoom + "/" + (this.tileInformation.left + tileCol) + "/" + (this.tileInformation.bottom - tileRow)
        var heightmapUrl = this.terrainTileUrl + tilePath + ".png" + this.mapZenKey
        if (this.useMapboxTiles)
        {
          var materialUrl = this.styleUrl + "/tiles/" + this.materialDimension + "/" + tilePath + "?access_token=" + this.mapzenAccessToken;
          //"https://b.tile.openstreetmap.org/",
          //"http://a.tile.stamen.com/toposm-color-relief/",
          this.addImageToCanvasAndUpdate(materialUrl, ((this.tileInformation.height - 1) - tileRow), tileCol)
        }
        /*
        if (this.addBuildings)
        {
          var tileVectorUrl = "https://tile.mapzen.com/mapzen/vector/v1/all/" + tilePath + ".topojson" + this.mapZenKey
          GetJsonPromiseAndPutDataIntoArray(tileVectorUrl, topoJsonData)
        }
        */
        allPromises.push(this.getPromiseForHeightmapWithOffset(heightmapUrl, tileRow, tileCol))
      }
    }

    return allPromises
  }

  incrementDataProgress() {
    this.textureMapLoaded += 1;
    var totalLoaded = this.textureMapLoaded + this.heightMapLoaded;
    var totalTiles = 2 * this.tileInformation.width * this.tileInformation.height;
    var progress = totalLoaded / totalTiles;
    this.loading.setProgress(0.8 + (progress * 0.1), "data loaded: " + totalLoaded + " / " + totalTiles)
  }

  addImageToCanvasAndUpdate(imageUrl, rowOffset , columnOffset) {
    var materialDimension = this.materialDimension;
    var planeCanvasContext = this.planeCanvasContext;
    var planeTexture = this.planeTexture;
    var incrementProgress = () => this.incrementDataProgress();

    this.imageLoader.load(
      imageUrl,
      function(image)
      {
        incrementProgress();
        var xOffset = columnOffset * materialDimension
        var yOffset = rowOffset * materialDimension

        planeCanvasContext.drawImage(image, xOffset, yOffset)
        planeTexture.needsUpdate = true
      },
      function (xhr)
      {
        // Progress callback
      },
      function (xhr)
      {
        console.error(`Could not load file: ${imageUrl}`);
      }
    )
  }

  getPromiseForHeightmapWithOffset(heightmapUrl, row, column) {
    var imageLoader = this.imageLoader;
    var addImageFunction = (i,c,r) => this.addImageDataToBufferGeometry(i,c,r);
    var incrementProgress = () => this.incrementDataProgress();

    return new Promise( function (resolve, reject) {
      imageLoader.load(
        heightmapUrl,
        function(image)
        {
          incrementProgress();
          addImageFunction(image, column, row);
          resolve(1)
        },
        function (xhr)
        {
          // Progress callback
        },
        function (xhr)
        {
          reject (new Error('Could not load file ' + heightmapUrl))
        }
      )
    })
  }

  addImageDataToBufferGeometry(image, column, row) {
    var xOffset = column * this.tileHeightmapDimension
    var zOffset = ((this.tileInformation.height - 1) - row) * this.tileHeightmapDimension

    var index = (zOffset * this.tileHeightmapDimension * this.tileInformation.width) + (xOffset)
    var wrapOffset = (this.tileInformation.width - 1) * this.tileHeightmapDimension
    
    var pix = this.getImageData(image).data
    for (var pos = 0; pos<pix.length; pos +=4) {
      var r = pix[pos];
      var g = pix[pos + 1];
      var b = pix[pos + 2];
      var mapzen = (r * 256 + g + b / 256) - 32768

      var zBufferIndex = (index * 3) + 2
      var scaledHeight = (mapzen * this.tileInformation.renderScaleInMeters)
      if (scaledHeight < this.tileInformation.minRenderHeight)
      {
        scaledHeight = this.tileInformation.minRenderHeight
      }
      this.planeGeometryWithShader.attributes.position.array[zBufferIndex] = scaledHeight;
      this.tileInformation.heightSum += this.planeGeometryWithShader.attributes.position.array[zBufferIndex]

      index += 1          
      if ((index % this.tileHeightmapDimension) === 0)
      {
        index += wrapOffset
      }
    }
  }

  addShadowPlaneToScene() {
    var xLeftStart = 0.0;
    var yLeftStart = 0.0;

    if (this.tileInformation.width > this.tileInformation.height)
    {
      yLeftStart = (1.0 - (this.tileInformation.height / this.tileInformation.width)) / 2.0
    }
    else if (this.tileInformation.width < this.tileInformation.height)
    {
      xLeftStart = (1.0 - (this.tileInformation.width / this.tileInformation.height)) / 2.0
    }

    this.scene.add(createShadowPlane(yLeftStart, xLeftStart, this.renderDimension, this.scene.background))
  }

  enableLocation() {
    function geo_error() {
      alert("Sorry, no position available.");
    }

    var geo_options = {
      enableHighAccuracy: true, 
    };

    this.locationSprite.visible = true;

    this.wpid = navigator.geolocation.watchPosition((position) => this.updateLocation(position.coords), geo_error, geo_options);    
  }

  updateLocation(position) {
    var latitude = position.latitude;
    var longitude = position.longitude;
    var geometryToSnapTo = this.planeGeometryWithShader.attributes.position.array;

    var zPosition = ((latitude - this.tileInformation.southEdge) * this.tileInformation.latTileScale);
    zPosition += this.tileInformation.zmin

    var xPosition = ((this.tileInformation.eastEdge - longitude) * -this.tileInformation.longTileScale);
    xPosition -= this.tileInformation.xmin

    // TODO: Could check for -1 return here, but that should be impossible for current usage
    var index = this.GetBufferPositionToSnapTo(latitude, longitude)
    var yPosition = geometryToSnapTo[index] + 3 - this.minVerticesHeight;

    this.locationSprite.position.set(xPosition, yPosition, -zPosition);
  }

  disableLocation() {
    navigator.geolocation.clearWatch(this.wpid);
    this.locationSprite.visible = false;
  }

  toggleLocation() {
    this.locationEnabled = !this.locationEnabled;
    if (this.locationEnabled) {
      this.enableLocation();
    } else {
      this.disableLocation();
    }
  }

  onWindowResize() {
    this.camera.aspect = this.parentElement.offsetWidth / this.parentElement.offsetHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.parentElement.offsetWidth, this.parentElement.offsetHeight);
  }
}