import { useCallback, useEffect, useRef } from "react";

const randomRange = (min: number, max: number) => Math.random() * (max - min) + min;

interface XY {
    x: number;
    y: number;
}

interface FrontBack {
    front: string;
    back: string;
}

interface Confetto {
    color: FrontBack;
    dimensions: XY;
    position: XY;
    rotation: number;
    scale: XY;
    velocity: XY;
}

interface ConfettiProps {
    confettiCount?: number;
    gravity?: number;
    terminalVelocity?: number;
    drag?: number;
    colors?: FrontBack[]
}

const Confetti: React.FC<ConfettiProps> = ({
    confettiCount = 300,
    gravity = 0.5,
    terminalVelocity = 5,
    drag = 0.075,
    colors = [
    { front : 'red', back: 'darkred'},
    { front : 'green', back: 'darkgreen'},
    { front : 'blue', back: 'darkblue'},
    { front : 'yellow', back: 'darkyellow'},
    { front : 'orange', back: 'darkorange'},
    { front : 'pink', back: 'darkpink'},
    { front : 'purple', back: 'darkpurple'},
    { front : 'turquoise', back: 'darkturquoise'},
    ],
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const confettiRef = useRef<Confetto[]>([]);
    const animation = useRef<number>();

    const resizeCanvas = useCallback(() => {
        const { current: canvas } = canvasRef;
        if (!canvas) { return }
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
    }, []);

    const generateConfetti = useCallback(() => {
        const { current: canvas } = canvasRef;
        if (!canvas) { return }
        // https://codepen.io/n33kos/pen/gjVQwv
        return {
            color      : colors[Math.floor(randomRange(0, colors.length))],
            dimensions : {
              x: randomRange(10, 20),
              y: randomRange(10, 30),
            },
            position   : {
              x: randomRange(0, canvas.width),
              y: canvas.height - 1,
            },
            rotation   : randomRange(0, 2 * Math.PI),
            scale      : {
              x: 1,
              y: 1,
            },
            velocity   : {
              x: randomRange(-25, 25),
              y: randomRange(0, -50),
            },
          }
    }, [canvasRef, colors]);

    const initConfetti = useCallback(() => {
        const { current: confetti} = confettiRef;
        if (!confetti) { return }
        for (let i = 0; i < confettiCount; i++) {
            const freshConfetti = generateConfetti();
            if (freshConfetti) {
                confetti.push(freshConfetti);
            }
        }
    }, [confettiCount, generateConfetti]);

    const render = useCallback(() => {
        const { current: canvas } = canvasRef;
        if (!canvas) { return }
        const ctx = canvas.getContext('2d');
        if (!ctx) { return }

        const { current: confetti } = confettiRef;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
    
        confetti.forEach((confetto, index) => {
            let width = (confetto.dimensions.x * confetto.scale.x);
            let height = (confetto.dimensions.y * confetto.scale.y);
            
            // Move canvas to position and rotate
            ctx.translate(confetto.position.x, confetto.position.y);
            ctx.rotate(confetto.rotation);
            
            // Apply forces to velocity
            confetto.velocity.x -= confetto.velocity.x * drag;
            confetto.velocity.y = Math.min(confetto.velocity.y + gravity, terminalVelocity);
            confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();
            
            // Set position
            confetto.position.x += confetto.velocity.x;
            confetto.position.y += confetto.velocity.y;
            
            // Delete confetti when out of frame
            if (confetto.position.y >= canvas.height) confetti.splice(index, 1);

            // Loop confetto x position
            if (confetto.position.x > canvas.width) confetto.position.x = 0;
            if (confetto.position.x < 0) confetto.position.x = canvas.width;

            // Spin confetto by scaling y
            confetto.scale.y = Math.cos(confetto.position.y * 0.1);
            ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;
            
            // Draw confetto
            ctx.fillRect(-width / 2, -height / 2, width, height);
            
            // Reset transform matrix
            ctx.setTransform(1, 0, 0, 1, 0, 0);
        });

        // Fire off another round of confetti
        if (confetti.length <= 10) initConfetti();
        animation.current = window.requestAnimationFrame(render);
    }, [drag, gravity, initConfetti, terminalVelocity]);

    useEffect(() => {
        window.addEventListener('resize', resizeCanvas);
        animation.current = window.requestAnimationFrame(render)

        return () => {
            window.removeEventListener('resize', resizeCanvas);
            animation.current && window.cancelAnimationFrame(animation.current);
        }
    }, [resizeCanvas, initConfetti, render]);
    
    return (
        <canvas
            style={{ position: 'fixed', top: 0, left: 0, pointerEvents: 'none' }}
            ref={canvasRef}
            width={window.innerWidth}
            height={window.innerHeight}
        />
    );
}

export default Confetti;