import React, { useState } from 'react';
import { Button, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router';
import Client from './Client';
import { shortGuid } from './Common';
import Host from './Host';

const Drawgression: React.FC = () => {
    const { hash } = useLocation();
    const history = useHistory();
    const room = hash.substring(1);
    const [host, setHost] = useState(false);
    const [join, setJoin] = useState(false);
    const [username, setUsername] = useState('');
    const [roomInput, setGameInput] = useState(room || '');

    return (room !== '' && username !== '' && join) || host
        ? host
            ? <Host username={username} room={room} />
            : <Client room={room || roomInput} username={username} />
        : (
            <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
                <h3>Wilkomen!</h3>
                <div>
                    <div style={{ maxWidth: '300px', margin: '0 auto' }}>
                        <FormGroup>
                            <FormControl
                                as="input"
                                type="text"
                                placeholder="Enter Username"
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.currentTarget.value);
                                }}
                            />
                        </FormGroup>
                        <div>
                            <Button
                                onClick={() => {
                                    setHost(true);
                                    history.push({
                                        hash: `${shortGuid()}`,
                                    });
                                }}
                                disabled={username === ''}
                            >
                                Host A Game
                            </Button>
                        </div>
                        <div>
                            <i>or</i>
                        </div>
                        <h5>Join A Game</h5>
                        <FormGroup>
                            <InputGroup>
                                <FormControl
                                    as="input"
                                    type="text"
                                    placeholder="Enter Room Code"
                                    value={roomInput}
                                    onChange={(e) => {
                                        setGameInput(e.currentTarget.value);
                                    }}
                                />
                                <InputGroup.Append>
                                    <Button
                                        disabled={username === '' || roomInput === ''}
                                        onClick={() => {
                                            setJoin(true);
                                            history.push({
                                                hash: `${roomInput}`,
                                            })
                                        }}
                                    >
                                        Join
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
            </div>
        );
};

export default Drawgression;