import { color as Color, RGBColor } from "d3";

// https://github.com/williammalone/HTML5-Paint-Bucket-Tool/blob/master/html5-canvas-paint-bucket.js
export const FloodFillCanvasAtPixel = (canvas: HTMLCanvasElement|null, x: number, y: number, color: string) => {
    if (!canvas) {
        return;
    }
    
    const context = canvas.getContext('2d');
    if (!context) {
        return;
    }
    const { width, height } = context.canvas;
    const imageData = context?.getImageData(0, 0, width, height);
    if (!imageData) {
        return;
    }

    const fillColor = Color(color)?.rgb();
    if (!fillColor) {
        return;
    }

    const pixelPosition = ((y * window.devicePixelRatio * width) + (x * window.devicePixelRatio)) * 4;
    // console.log('filling', fillColor, 'at', pixelPosition);

    if (PixelMatchesColor(imageData, pixelPosition, fillColor, true)) {
        return;
    }
    floodFill(imageData, x * window.devicePixelRatio, y * window.devicePixelRatio, fillColor);

    context?.putImageData(imageData, 0, 0);
}

const PixelMatchesColor = (imageData: ImageData, pixelPosition: number, color: RGBColor, debug?: boolean) => {
    if (debug) {
        // console.log('id', imageData.data[pixelPosition], imageData.data[pixelPosition + 1], imageData.data[pixelPosition + 2], color.r, color.g, color.b);
    }

    return (
        imageData.data[pixelPosition] === Math.round(color.r) &&
        imageData.data[pixelPosition + 1] === Math.round(color.g) &&
        imageData.data[pixelPosition + 2] === Math.round(color.b)
    );
}

const ColorPixel = (imageData: ImageData, pixelStart: number, color: RGBColor) => {
    imageData.data[pixelStart] = color.r;
    imageData.data[pixelStart + 1] = color.g;
    imageData.data[pixelStart + 2] = color.b;
    imageData.data[pixelStart + 3] = 255;
}

const floodFill = (imageData: ImageData, startX: number, startY: number, color: RGBColor) => {
    let currentPosition: [number, number],
        currentX: number,
        currentY: number,
        pixelStart: number,
        reachLeft: boolean,
        reachRight: boolean;
    const { width, height } = imageData;
    const pixelRowWidth = width * 4;

    const pixelStack: [number,number][] = [[startX, startY]];
    const startPixelIndex = ((startY * width) + startX) * 4;
    const startColor = Color(`rgb(${imageData.data[startPixelIndex]},${imageData.data[startPixelIndex + 1]}, ${imageData.data[startPixelIndex + 2]})`);
    if (!startColor) {
        return;
    }
    const startColorRGB = startColor.rgb();

    while (pixelStack.length > 0) {
        currentPosition = pixelStack.pop() || [0,0];
        currentX = currentPosition[0];
        currentY = currentPosition[1];

        pixelStart = ((currentY * width) + currentX) * 4;

        while (currentY >= 0 && PixelMatchesColor(imageData, pixelStart, startColorRGB)) {
            currentY -= 1;
            pixelStart -= pixelRowWidth;
        }

        pixelStart += pixelRowWidth;
        currentY += 1;
        reachLeft = false;
        reachRight = false;

        while (currentY < height && PixelMatchesColor(imageData, pixelStart, startColorRGB)) {
            ColorPixel(imageData, pixelStart, color);

            if (currentX > 0) {
                if (PixelMatchesColor(imageData, pixelStart - 4, startColorRGB)) {
                    if (!reachLeft) {
                        pixelStack.push([currentX - 1, currentY]);
                        reachLeft = true;
                    }
                } else if (reachLeft) {
                    reachLeft = false;
                }
            }

            if (currentX < pixelRowWidth) {
                if (PixelMatchesColor(imageData, pixelStart + 4, startColorRGB)) {
                    if (!reachRight) {
                        pixelStack.push([currentX + 1, currentY]);
                        reachRight = true;
                    }
                } else if (reachRight) {
                    reachRight = false;
                }
            }
            currentY += 1;

            pixelStart += pixelRowWidth;
        }
    }
}