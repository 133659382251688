import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

const millisecondsInMinute = 1000 * 60;
const millisecondsInHour = millisecondsInMinute * 60;
const millisecondsInDay = millisecondsInHour * 24;
const millisecondsInYear = millisecondsInDay * 365;

const getTimeObjectFromTotal = (t: number) => {
  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var hours = Math.floor((t / millisecondsInHour) % 24);
  var days = Math.floor((t / millisecondsInDay) % 365);
  var years = Math.floor(t / millisecondsInYear);

  return {
    'years': years,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
}

interface Props {
  date: number
  stop?: boolean
}

const Countdown: React.FC<Props> = ({ date, stop }) => {
  const [years, setYears] = useState('');
  const [days, setDays] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [interval, setInterval] = useState(0);
  const intervalRef = useRef(interval);
  intervalRef.current = interval;

  const update = useCallback(() => {
    const absoluteTimeRemaining = Math.abs(timeRemaining);
    const time = getTimeObjectFromTotal(absoluteTimeRemaining);
    let years = '', days = '', hours = '', minutes = '', seconds = '';
    if (absoluteTimeRemaining > millisecondsInYear) {
      years = time.years + ':';
    }
    if (absoluteTimeRemaining > millisecondsInDay) {
      days = time.days + ':';
    }
    if (absoluteTimeRemaining > millisecondsInHour) {
      hours = ('0' + time.hours).slice(-2) + ':';
    }
    if (absoluteTimeRemaining > millisecondsInMinute) {
      minutes = ('0' + time.minutes).slice(-2) + ':';
    }
    seconds = ('0' + time.seconds).slice(-2);
    setTimeRemaining(timeRemaining - 1000);
    setYears(years);
    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  }, [timeRemaining]);

  useEffect(() => {
    setTimeRemaining(date - +(new Date()));
    setInterval(window.setInterval(update, 1000));
    return (() => {
      window.clearInterval(intervalRef.current);
    })
  }, [date, update]);

  useEffect(() => {
    window.clearInterval(intervalRef.current);
  }, [stop]);

  return (
    <div>
        <span>{(timeRemaining >= 0) ? '' : '-'}</span>
        <span>{years}</span>
        <span>{days}</span>
        <span>{hours}</span>
        <span>{minutes}</span>
        <span>{seconds}</span>
      </div>
  );
}

export default Countdown;