import { Feature, GeoJsonProperties, Geometry, FeatureCollection } from 'geojson';
import { CityNode } from 'src/projects/Running/NodesProgress/NodesProgress';
import { ActivityStreamsData } from './Strava';

interface Path {
    color: string;
    coordinates: any[][];
    activityId: number;
}

export const GetGeoJsonFeatureForPath = (path: Path): Feature<Geometry, GeoJsonProperties> => {
    return {
        type: 'Feature',
        properties: {
            'color': path.color,
            'activityId': path.activityId,
        },
        geometry: {
            type: 'LineString',
            coordinates: path.coordinates,
        }
    };
};

export const GetGeoJSONFromSingleStravaStreamData = (stravaJSONStreams: ActivityStreamsData) => {
    return {
        type: 'FeatureCollection',
        features: [ GetGeoJSONLineStringForArrayOfLongitudeLatitude(
            stravaJSONStreams.latlng.map((d) => {
                return [
                    d[1],
                    d[0]
                ];
            }))
        ]
    }
};

export const GetGeoJSONLineStringForArrayOfLongitudeLatitude = (coordinates: [number, number][]) => {
    const properties: { [key: string]: any } = {};
    const geometry: GeoJSON.LineString = {
        type: 'LineString',
        coordinates,
    };
    return {
        type: 'Feature',
        id: 0,
        properties,
        geometry,
    }
}

export const CreateGeoJsonForFoundUnfoundNodes = (foundNodes: CityNode[], unfoundNodes: CityNode[]): FeatureCollection => {
    return {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    color: [84, 39, 143],
                },
                geometry: {
                    type: 'MultiPoint',
                    coordinates: foundNodes.map(n => [n.coordinates[1], n.coordinates[0]]),
                }
            },
            {
                type: 'Feature',
                properties: {
                    color: [224, 130, 20],
                },
                geometry: {
                    type: 'MultiPoint',
                    coordinates: unfoundNodes.map(n => [n.coordinates[1], n.coordinates[0]]),
                }
            }
        ]
    }
}