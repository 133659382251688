import * as d3 from "d3";
import { d3Category10 } from "../Utils/Color";

export {};

export interface GraphMargins {
    top:    number;
    right:  number;
    bottom: number;
    left:   number;
}

export interface PlotSeries {
    name: string;
    data: Array<number|Date>[];
}

export const D3_CATEGORY_10 = d3.scaleOrdinal(d3Category10);

export const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});