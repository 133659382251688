import { faEye, faEyeSlash, faLowVision } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ToggleSwitchProps {
    visible: boolean;
    disabled: boolean;
    onClick: () => void;
}

export const VisibleToggleSwitch = ({
    visible,
    onClick,
    disabled
}: ToggleSwitchProps) => {
    return (
        <FontAwesomeIcon
            icon={disabled ? faLowVision : (visible ? faEye : faEyeSlash)}
            style={{ marginRight: '4px', cursor: 'pointer' }}
            onClick={() => {
                if (!disabled) {
                    onClick();
                }
            }}
        />
    );
}