import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { STRAVA_OATUTH_URL } from './Common';

const SignIn: React.FC = () => {
    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <h3>Oh No!</h3>
                    <p>The page couldn't determine your Strava Athelete Id.</p>
                    <p>Click <a href={STRAVA_OATUTH_URL}>here</a> to re-authorize Strava with the site.</p>
                </Col>
            </Row>
        </Container>
    );
};

export default SignIn;