import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import ControlLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import '../../lib/bootstrap/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import * as mapboxgl from 'mapbox-gl';
import { LngLat } from 'mapbox-gl';
import { MAPBOX_ACCESS_TOKEN } from '../Running/Common';
import { GetMapboxStyleUrl, MapboxStyle } from 'src/lib/Utils/Maps';

(mapboxgl as any).accessToken = MAPBOX_ACCESS_TOKEN;
var PANORAMA_URL_BASE = 'https://www.udeuschle.de/panoramas/panqueryfull.aspx';

interface LngLatBasic {
  lng: number,
  lat: number,
};

const MAPBOX_STYLE_URL = GetMapboxStyleUrl(MapboxStyle.SKIING);
const VIEW_ID = 'view';

export default class Panorama extends React.Component {
  private mapRef = React.createRef<HTMLDivElement>();
  private map: mapboxgl.Map|null = null;
  private marker: mapboxgl.Marker|null = null;

  public state = {
    leftAngle: 175,
    rightAngle: 185,
    cameraAltitude: 10,
    findSummit: false,
    latitude: 46.835341,
    longitude: -121.733045,
    manualLocation: true,
    maxDistance: 300,
    resolution: 40,
    autoTilt: true,
  }

  componentDidMount() {
    const { longitude, latitude } = this.state;
    const self = this;
    if (this.mapRef.current) {
      this.map = new mapboxgl.Map({
        container: this.mapRef.current,
        style: MAPBOX_STYLE_URL,
        zoom: 2,
        center: [longitude, latitude],
      });
    } else {
      console.warn("Couldn't initialize map due to null ref!");
      return;
    }

    this.map.addControl(new mapboxgl.NavigationControl());
    const geolocateControl = new mapboxgl.GeolocateControl({
      positionOptions: { enableHighAccuracy: true },
      trackUserLocation: true,
      showUserLocation: false,
    });
    geolocateControl.on('geolocate', this.handleGeoLocate);
    geolocateControl.on('click', this.handleGeoLocateControlClick);
    this.map.addControl(geolocateControl);

    this.map.on('style.load', () => { self.handleMapLoad() });
    this.map.on('click', (e) => { self.handleMapClick(e.lngLat) })

    this.marker = new mapboxgl.Marker()
      .setLngLat([0, 0])
      .addTo(this.map);
  }

  componentDidUpdate() {
    this.refreshData();
  }

  private handleMapLoad() {
    this.map?.setZoom(7);
    if (this.map?.getSource(VIEW_ID) === undefined) {
      this.map?.addSource(VIEW_ID, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {
            'color': 'red',
            'id': 0,
          },
          geometry: {
            coordinates: [],
            type: 'Polygon',
          },
        },
      });
    }
    if (this.map?.getLayer(VIEW_ID) === undefined) {
      this.map?.addLayer({
        id: VIEW_ID,
        type: 'fill',
        source: VIEW_ID,
        paint: {
          'fill-color': [ 'get', 'color' ],
          'fill-opacity': 0.5,
        },
      });
    }
    this.center();
  }

  private handleMapClick(lnglat: LngLat) {
    if (this.state.manualLocation) {
      this.setState({
        latitude: lnglat.lat,
        longitude: lnglat.lng,
      });

      this.center();
    }
  }

  private handleGeoLocateControlClick() {
    this.setState({ manualLocation: !this.state.manualLocation });
  }

  private handleGeoLocate(data?: GeolocationPosition|Object) {
    if (data && data instanceof GeolocationPosition) {
      const { latitude, longitude } = data.coords;
      this.setState({
        latitude,
        longitude,
      });
      this.center();
    }
  }

  private refreshData() {
    const viewSource = this.map?.getSource(VIEW_ID);
    if (viewSource) {
      (viewSource as mapboxgl.GeoJSONSource).setData(this.currentViewData());
    }
  }

  private center() {
    this.marker?.setLngLat([this.state.longitude, this.state.latitude]);
    this.map?.setCenter([this.state.longitude, this.state.latitude]);
    this.refreshData();
  }

  private currentViewData(): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            'color': 'red',
            'id': 0,
          },
          geometry: {
            coordinates: [
              getCircleSegmentPoints(
                {
                  lat: this.state.latitude,
                  lng: this.state.longitude,
                },
                this.state.maxDistance,
                this.state.leftAngle,
                this.state.rightAngle
              ),
            ],
            type: 'Polygon',
          },
        }
      ]
    }
  }

  private handleLeftAngleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ leftAngle: parseInt(e.currentTarget.value) });
  }

  private handleRightAngleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rightAngle: parseInt(e.currentTarget.value) });
  }

  private handleCameraHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ cameraAltitude: e.currentTarget.value });
  }

  private handleFindSummitChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ findSummit: e.currentTarget.checked });
  }

  private handleTiltChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ autoTilt: e.currentTarget.checked });
  }

  private handleGo = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const properties = [
      ['lon', this.state.longitude],
      ['lat', this.state.latitude],
      ['altcam', this.state.cameraAltitude],
      ['hialt', this.state.findSummit],
      ['resolution', this.state.resolution],
      ['leftbound', this.state.leftAngle],
      ['rightbound', this.state.rightAngle],
      ['split', 30],
      ['splitnr', 1],
      ['tilt', this.state.autoTilt ? 'auto' : -2.5],
      ['elexagg', 1],
      ['range', this.state.maxDistance],
      ['colorcoding', true],
      ['colorcodinglimit', 300],
      ['language', 'en'],
      ['screenwidth', document.body.offsetWidth],
      ['screenheight', document.body.offsetHeight],
    ].map(p => getPropertyString(p[0], p[1]));

    const queryPrameters: { [key: string]: string } = {
      mode: 'newstandard',
      data: properties.join('$$$'),
    };
    const queryString = Object.keys(queryPrameters).map(key => `${key}=${queryPrameters[key]}`).join('&');

    window.open(`${PANORAMA_URL_BASE}?${queryString}`, '_blank');
  }

  private createSummaryItem(name: string, value: string|number|boolean) {
    return [
      <dt key={`${name}-dt`} className="col-5">{name}</dt>,
      <dd key={`${name}-dd`} className="col-7">{value.toString()}</dd>
    ];
  }

  public render() {
    const {
      leftAngle,
      rightAngle,
      cameraAltitude,
      findSummit,
      latitude,
      longitude,
      maxDistance,
      resolution,
      autoTilt,
    } = this.state;

    return (
      <Container>
        <Row>
          <Col sm={12}>
            <h2>A Nicer Panorama UI (hopefully)</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div style={{ height: 0, paddingBottom: '100%' }} ref={this.mapRef}></div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h3>Other options:</h3>
            <Form>
              <FormGroup>
                <ControlLabel>Left Angle: {leftAngle}</ControlLabel>
                <FormControl
                  type="range"
                  min={0}
                  max={360}
                  value={leftAngle}
                  onChange={this.handleLeftAngleChange}
                />
                <ControlLabel>Right Angle: {rightAngle}</ControlLabel>
                <FormControl
                  type="range"
                  className="form-control-range"
                  min={0}
                  max={360}
                  value={rightAngle}
                  onChange={this.handleRightAngleChange}
                />
                <ControlLabel>Camera Altitude (m)</ControlLabel>
                <FormControl
                  type="number"
                  className="from-control"
                  min={10}
                  value={cameraAltitude}
                  onChange={this.handleCameraHeightChange}
                />
              </FormGroup>
              <FormGroup>
                <div className="form-check">
                  <input
                    checked={findSummit}
                    type="checkbox"
                    className="form-check-input"
                    onChange={this.handleFindSummitChange}
                  />
                  <label className="form-check-label">Automatically find "summit"</label>
                </div>                
              </FormGroup>
              <FormGroup>
                <div className="form-check">
                  <input
                    checked={autoTilt}
                    type="checkbox"
                    className="form-check-input"
                    onChange={this.handleTiltChange}
                  />
                  <label className="form-check-label">Automatic "tilt"</label>
                </div>                
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h3>Summary:</h3>
            <dl className="row" id="summary">
              {this.createSummaryItem('lon', longitude)}
              {this.createSummaryItem('lat', latitude)}
              {this.createSummaryItem('altcam', cameraAltitude)}
              {this.createSummaryItem('resolution', resolution)}
              {this.createSummaryItem('leftbound', leftAngle)}
              {this.createSummaryItem('rightbound', rightAngle)}
              {this.createSummaryItem('range', maxDistance)}
              {this.createSummaryItem('High Ground', findSummit)}
              {this.createSummaryItem('Tilt', autoTilt ? 'auto' : -2.5 )}
            </dl>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form>
              <FormGroup>
                <Button
                  bsPrefix="btn-lg btn-block btn-outline-success"
                  type="button"
                  className="btn"
                  children="GO"
                  onClick={this.handleGo}
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const getCircleSegmentPoints = (center: LngLatBasic, radius: number, angle1: number, angle2: number) => {
  const steps = 60;
  const coordinates: number[][] = [];

  if (angle1 !== angle2) {
    coordinates.push([center.lng, center.lat]);
  }

  const arcStartDegree: number = angle1;
  const arcEndDegree: number = (angle1 < angle2) ? angle2 : angle2 + 360;
  const angleDifference: number = Math.abs(arcStartDegree - arcEndDegree);
  const increment: number = (angleDifference !== 0)
    ? angleDifference / steps
    : 360 / steps;
  coordinates.push(destination(center, radius, arcStartDegree));
  for (var i = 0; i < 64; i++) {
    const currentAngle = arcStartDegree + (i * increment);
    coordinates.push(destination(center, radius, currentAngle));
  }
  coordinates.push(destination(center, radius, arcEndDegree));

  if (angle1 !== angle2) {
    coordinates.push([center.lng, center.lat]);
  }
  return coordinates;
}

const EARTH_RADIUS_MILES = 6371008.8;
const EARTH_RADIUS_KILOMETERS = EARTH_RADIUS_MILES / 1000;

// https://github.com/Turfjs/turf/blob/master/packages/turf-destination/index.ts
const destination = (origin: LngLatBasic, distance: number, bearing: number) => {
  const longitude = degreesToRadians(origin.lng);
  const latitude = degreesToRadians(origin.lat);
  const bearingRad = degreesToRadians(bearing);
  const radians = distance / EARTH_RADIUS_KILOMETERS;

  // Main
  const latitude2 = Math.asin(Math.sin(latitude) * Math.cos(radians) +
      Math.cos(latitude) * Math.sin(radians) * Math.cos(bearingRad));
  const longitude2 = longitude + Math.atan2(Math.sin(bearingRad) * Math.sin(radians) * Math.cos(latitude),
      Math.cos(radians) - Math.sin(latitude) * Math.sin(latitude2));
  const lng = radiansToDegrees(longitude2);
  const lat = radiansToDegrees(latitude2);

  return [lng, lat];
}

const degreesToRadians = (degrees: number) => {
  const radians = degrees % 360;
  return radians * Math.PI / 180;
}

const radiansToDegrees = (radians: number) => {
  const degrees = radians % (2 * Math.PI);
  return degrees * 180 / Math.PI;
}

const getPropertyString = (name: any, value: any) => {
  return `${name}:${value}`;
}