import { ModalBody } from "react-bootstrap";
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { Modal } from "react-bootstrap";
import { GetMapboxStyleUrl, MapboxStyle } from "src/lib/Utils/Maps";
import { MAPBOX_ACCESS_TOKEN } from "../Common";
import DeckGL, { GeoJsonLayer } from "deck.gl";
import { StaticMap } from "react-map-gl";
import { FeatureCollection, MultiPoint } from 'geojson';

const StreetModal: React.FC<{
    showModal: boolean;
    onHide: () => void;
    name: string;
    geoJson: FeatureCollection;
}> = ({
    showModal,
    onHide,
    name,
    geoJson,
}) => {
    const coordinates = geoJson.features.reduce((a: [number,number][], f) =>
        a.concat((f.geometry as MultiPoint).coordinates as [number,number][])
    , []);
    const initialViewState = {
        longitude: coordinates.reduce((a,c) => a + c[0], 0) / coordinates.length,
        latitude: coordinates.reduce((a,c) => a + c[1], 0) / coordinates.length,
        bearing: 0,
        pitch: 0,
        zoom: 14,
    }
    const layers = [
        new GeoJsonLayer({
            id: 'streetNodes',
            data: geoJson,
            getFillColor: (a: any) => a.properties.color,
            getPointRadius: 25,
            getLineWidth: 0,
            getLineColor: [0, 0, 0, 0],
        })
    ];

    return (
        <Modal
            show={showModal}
            onHide={onHide}
            size="lg"
        >
            <ModalHeader closeButton={true}>
                <h4>
                    {name}
                </h4>
            </ModalHeader>
            <ModalBody
                style={{ minHeight: '500px' }}
            >
                <DeckGL
                    initialViewState={initialViewState}
                    controller={true}
                    layers={layers}
                >
                    <StaticMap
                        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                        mapStyle={GetMapboxStyleUrl(MapboxStyle.DARK)}
                    />
                </DeckGL>
            </ModalBody>
        </Modal>
    );
}

export default StreetModal;