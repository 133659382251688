import { MeterDistanceBetweenLatitudeLongitude, GetPlaneBufferGeometryAttributes, tile2lat, tile2long } from "src/lib/Utils/Maps";
import { Tile } from "src/types/deck.gl";
import { Vector3 } from "three/src/math/Vector3";

const VERSION = '0.0.1';

export interface ElevationDecoder {
    rScaler: number;
    gScaler: number;
    bScaler: number;
    offset: number;
}

export interface TerrainOptions {
    bounds: any;
    meshMaxError: any;
    elevationDecoder: ElevationDecoder;
    workerUrl?: any;
}

export interface LoaderOptions {
    terrain: TerrainOptions;
    image?: any;
    baseUri?: string;
    tile: Tile;
}

export const CalculateVertexNormals = (indices: Uint32Array, vertices: Float32Array, vertexHeightCorrectionFactor: number) => {
  const normals = new Float32Array(vertices.length);

  const positionA = new Vector3();
  const positionB = new Vector3();
  const positionC = new Vector3();
  const normalA = new Vector3();
  const normalB = new Vector3();
  const normalC = new Vector3();
  const ctob = new Vector3(), atob = new Vector3();

  for (let i = 0; i < indices.length; i += 3) {
    const vertexIndexA = indices[i] * 3;
    const vertexIndexB = indices[i + 1] * 3;
    const vertexIndexC = indices[i + 2] * 3;

    positionA.set(
      vertices[vertexIndexA],
      vertices[vertexIndexA + 1],
      vertices[vertexIndexA + 2] * vertexHeightCorrectionFactor
    );
    positionB.set(
      vertices[vertexIndexB],
      vertices[vertexIndexB + 1],
      vertices[vertexIndexB + 2] * vertexHeightCorrectionFactor
    );
    positionC.set(
      vertices[vertexIndexC],
      vertices[vertexIndexC + 1],
      vertices[vertexIndexC + 2] * vertexHeightCorrectionFactor
    );

    ctob.subVectors( positionC, positionB );
    atob.subVectors( positionA, positionB );
    ctob.cross( atob );

    normalA.set(normals[vertexIndexA], normals[vertexIndexA + 1], normals[vertexIndexA + 2]);
    normalB.set(normals[vertexIndexB], normals[vertexIndexB + 1], normals[vertexIndexB + 2]);
    normalC.set(normals[vertexIndexC], normals[vertexIndexC + 1], normals[vertexIndexC + 2]);

    normalA.add( ctob );
    normalB.add( ctob );
    normalC.add( ctob );

    normals[vertexIndexA] = normalA.x;
    normals[vertexIndexA + 1] = normalA.y;
    normals[vertexIndexA + 2] = normalA.z;
    normals[vertexIndexB] = normalB.x;
    normals[vertexIndexB + 1] = normalB.y;
    normals[vertexIndexB + 2] = normalB.z;
    normals[vertexIndexC] = normalC.x;
    normals[vertexIndexC + 1] = normalC.y;
    normals[vertexIndexC + 2] = normalC.z;
  }

  const vector = new Vector3();
  for (var i = 0; i < normals.length; i+= 3) {
    vector.set(normals[i], normals[i + 1], normals[i + 2]);
    vector.normalize();
    normals[i] = vector.x;
    normals[i + 1] = vector.y;
    normals[i + 2] = vector.z;
  }

  return normals;
}

export const getMeshBoundingBox = (attributes: any) => {
    if (!attributes || !attributes.POSITION) {
      return null;
    }
  
    let minX = Infinity;
    let minY = Infinity;
    let minZ = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    let maxZ = -Infinity;
  
    const positions = attributes.POSITION.value;
    const len = positions && positions.length;
  
    if (!len) {
      return null;
    }
  
    for (let i = 0; i < len; i += 3) {
      const x = positions[i];
      const y = positions[i + 1];
      const z = positions[i + 2];
  
      minX = x < minX ? x : minX;
      minY = y < minY ? y : minY;
      minZ = z < minZ ? z : minZ;
  
      maxX = x > maxX ? x : maxX;
      maxY = y > maxY ? y : maxY;
      maxZ = z > maxZ ? z : maxZ;
    }
    return [[minX, minY, minZ], [maxX, maxY, maxZ]];
}
/*
const getTerrain = (imageData: number[], tileSize: number, elevationDecoder: ElevationDecoder) => {
    const {rScaler, bScaler, gScaler, offset} = elevationDecoder;
  
    const gridSize = tileSize + 1;
    // From Martini demo
    // https://observablehq.com/@mourner/martin-real-time-rtin-terrain-mesh
    const terrain = new Float32Array(gridSize * gridSize);
    // decode terrain values
    for (let i = 0, y = 0; y < tileSize; y++) {
      for (let x = 0; x < tileSize; x++, i++) {
        const k = i * 4;
        const r = imageData[k + 0];
        const g = imageData[k + 1];
        const b = imageData[k + 2];
        terrain[i + y] = r * rScaler + g * gScaler + b * bScaler + offset;
      }
    }
    // backfill bottom border
    for (let i = gridSize * (gridSize - 1), x = 0; x < gridSize - 1; x++, i++) {
      terrain[i] = terrain[i - gridSize];
    }
    // backfill right border
    for (let i = gridSize - 1, y = 0; y < gridSize; y++, i += gridSize) {
      terrain[i] = terrain[i - 1];
    }
    return terrain;
  }
  */

const getTileMesh = (terrainImage: any, terrainOptions: TerrainOptions, vertexHeightCorrectionFactor: number) => {
    const {
        bounds,
        elevationDecoder
    } = terrainOptions;
    const data = terrainImage.data;
    const tileSize = terrainImage.width;
    const [minX, minY, maxX, maxY] = bounds || [0, 0, tileSize, tileSize];

    const width = maxX - minX;
    const height = maxY - minY;
    const {
        indices,
        vertices,
        uvs,
    } = GetPlaneBufferGeometryAttributes(
        width,
        height,
        -minX,
        -minY,
        tileSize,
        tileSize,
        true);

    const {rScaler, bScaler, gScaler, offset} = elevationDecoder;

    for (var row = tileSize - 1; row >= 0; row--) {
        const rowIndexPosition = row * tileSize;
        for (var column = 0; column < tileSize; column++) {
            const terrainPosition = ((tileSize - 1 - row) * tileSize) + column;
            
            const k = terrainPosition * 4;
            const r = data[k + 0];
            const g = data[k + 1];
            const b = data[k + 2];
            
            const terrainValue = r * rScaler + g * gScaler + b * bScaler + offset;

            const currentVertexIndex = (rowIndexPosition * 3) + (column * 3);
            vertices[currentVertexIndex + 2] = terrainValue;
        }
    }

    const calculatedNormals = CalculateVertexNormals(indices, vertices, vertexHeightCorrectionFactor);

    const attributes = {
        POSITION: {value: vertices, size: 3},
        TEXCOORD_0: {value: uvs, size: 2},
        NORMAL: {value: calculatedNormals, size: 3},
    };

    return {
        loaderData: { header: {} },
        header: {
          vertexCount: tileSize * tileSize * 3 * 2,
          boundingBox: getMeshBoundingBox(attributes)
        },
        mode: 4,
        indices: {
            value: indices,
            size: 1
        },
        attributes,
    };
}

const mapboxParseTerrain = async (arrayBuffer: any, options: LoaderOptions, context: any) => {
    options.image = options.image || {};
    options.image.type = 'data';
    
    // Latitude Longitude bounding box for the tiles
    const { x, y, z } = (options.tile as any).index;
    const northEdge = tile2lat(y, z);
    const westEdge  = tile2long(x, z);
    const southEdge = tile2lat(y + 1 , z);

    const maxDistance = MeterDistanceBetweenLatitudeLongitude({ lat: northEdge, lon: westEdge }, { lat: southEdge, lon: westEdge });
    const [, minY, , maxY] = options.terrain.bounds;
    const renderRange = maxY - minY;
    const vertexHeightCorrectionFactor = renderRange / maxDistance;
    const image = await context.parse(arrayBuffer, options, options.baseUri);
    return getTileMesh(image, options.terrain, vertexHeightCorrectionFactor);
}

export const MapboxTerrainLoader = {
    id: 'mapboxTerrain',
    name: 'MapboxTerrain',
    version: VERSION,
    extensions: ['png', 'pngraw'],
    mimeTypes: ['image/png'],
    options: {
      terrain: {
        bounds: null,
        workerUrl: `https://unpkg.com/@loaders.gl/terrain@${VERSION}/dist/terrain-loader.worker.js`,
        meshMaxError: 10,
        elevationDecoder: {
          rScaler: 1,
          gScaler: 0,
          bScaler: 0,
          offset: 0
        }
      }
    },
    parse: mapboxParseTerrain,
};