export const ParseCSVText = (text: string) => {
    const firstNewLineIndex = text.indexOf('\n');
    const lineSeparator = (firstNewLineIndex >= 0 && text[firstNewLineIndex - 1] === '\r')
        ? '\r\n'
        : '\n';
    
    const inputRows = text.split(lineSeparator);
    const heading = inputRows.shift();
    if (!heading) {
        return [];
    }

    const fields = heading.split(',');
    if (fields.length < 2) {
        console.warn(`${fields.length} fields discovered, not converting`);
        return [];
    }
    return (inputRows || []).map(row => {
        const values = row.split(',');
        return fields.reduce((accumulator: any, item: string, index: number) => {
            accumulator[item] = values[index];
            return accumulator;
        }, {});
    });
}