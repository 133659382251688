import * as React from 'react';
import * as mapboxgl from 'mapbox-gl';
import * as d3 from 'd3';
import { GetMapboxStyleUrl, MapboxStyle } from 'src/lib/Utils/Maps';

const MAPBOX_STYLE_URL = GetMapboxStyleUrl(MapboxStyle.SKIING);
const HOT_SPRING_URL = '/data/WAHotSprings.html'; // 'http://www.hotspringsenthusiast.com/Washington.asp';

const COLOR = d3.interpolateYlOrRd;

export default class HotSprings extends React.Component {
    private mapRef = React.createRef<HTMLDivElement>();
    private map: mapboxgl.Map|null = null;

    public componentDidMount = () => {
        const self = this;
        if (this.mapRef.current) {
            this.map = new mapboxgl.Map({
              container: this.mapRef.current,
              style: MAPBOX_STYLE_URL,
              zoom: 6,
              center: [-120.536914, 47.583937],
            });
        } else {
            console.warn("Couldn't initialize map due to null ref!");
            return;
        }
        
        this.map.addControl(new mapboxgl.NavigationControl());
        this.map.on('style.load', () => { self.handleMapLoad() });
    }

    private handleMapLoad = async () => {
        const data = await (await fetch(HOT_SPRING_URL)).text();
        const parser = (new DOMParser()).parseFromString(data, 'text/html')
        const firstTable = parser.getElementsByTagName('table')[0];
        if (firstTable) {
            const rows = Array.from(firstTable.getElementsByTagName('tr'));
            rows.shift();
            rows.forEach(row => {
                const data = Array.from(row.getElementsByTagName('td'));
                const link = data[3].getElementsByTagName('a')[0];
                const hotSpring = {
                    latitude: parseFloat(data[1].innerHTML),
                    longitude: -parseFloat(data[2].innerHTML),
                    fahrenheit: parseFloat(data[4].innerHTML),
                    celsius: parseFloat(data[5].innerHTML),
                    name: link?.innerHTML,
                    href: link?.getAttribute('href'),
                };
                const value = /*1 -*/ (Math.max(0, Math.min(1, (hotSpring.fahrenheit - 32) / 180)));
                const colorRGB = COLOR(value);
                const color = d3.color(colorRGB)?.hex();
                if (this.map) {
                    new mapboxgl.Marker({ color })
                        .setLngLat([hotSpring.longitude, hotSpring.latitude])
                        .setPopup(new mapboxgl.Popup().setHTML(`<b><a href=${hotSpring.href}>${hotSpring.name}</a></b><br/>Temperature: ${hotSpring.fahrenheit}°F // ${hotSpring.celsius}°C`))
                        .addTo(this.map);
                }
            });
        }
    }


    public render() {
        return (
            <div style={{ height: '100%', width: '100%' }}>
                <h2>It's getting hot in here</h2>
                <div style={{ height: '90%' }} ref={this.mapRef}/>
            </div>
        );
    }
};
