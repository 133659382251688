import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { ShrinkingSideMenu } from 'src/components/ShrinkingSideMenu';
import { GetMapboxStyleUrl, MapboxStyle } from 'src/lib/Utils/Maps';
import { CACHE_NAME, GetFromCacheJSON } from '../Running/Common';
import { CITY_MAPPING } from '../Running/HeatmapUtils';
import MonthSelector from './MonthSelector';
import PeakPopup from './PeakPopup';
import YearSelector from './YearSelector';

export interface Ascent {
    date: string;
    id: number;
};

const SeattleCenter = CITY_MAPPING[237385].center;

const PeakBagger: React.FC = () => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const map = useRef<mapboxgl.Map>();
    const cache = useRef<Cache>();
    const [mapIsReady, setMapIsReady] = useState(false);
    const [cacheIsReady, setCacheIsReady] = useState(false);

    useEffect(() => {
        if (mapContainer.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: GetMapboxStyleUrl(MapboxStyle.SKIING),
                center: [SeattleCenter.longitude, SeattleCenter.latitude],
                zoom: 10,
            });
            map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
            map.current.addControl(new mapboxgl.GeolocateControl({
                positionOptions: { enableHighAccuracy: true },
                trackUserLocation: true,
                showUserLocation: true,
            }), 'top-right');
            map.current.on('load', () => {
                setMapIsReady(true);
            });
            (async () => {
                cache.current = await window.caches.open(CACHE_NAME);
                setCacheIsReady(true);
            })();
            return () => {
                if (map.current) {
                    map.current.remove();
                    setMapIsReady(false);
                    setCacheIsReady(false);
                }
            }
        }
        return;
    }, []);

    return (
        <>
            <div
                style={{ width: '100%', height: '100%' }}
                ref={mapContainer} 
            />
            {
                mapIsReady && map.current && 
                cacheIsReady && cache.current && (
                    <PeakBaggerMap
                        map={map.current}
                        cache={cache.current}
                    />
                )
            }
        </>
    )
}

export default PeakBagger;

const LOW_PEAKS_COLOR = '#CCCCCC';
const HIGH_PEAKS_COLOR = '#00DDFF';
const LOW_PEAKS_RADIUS = 15;
const HIGH_PEAKS_RADIUS = 28;

export const PEAK_URL = 'https://s3.amazonaws.com/jrgrover.com-strava/data/ascents.geojson';

const GetPeakCircleColorInterpolation = () => [
    'interpolate',
    ['linear'],
    ['var', 'ascents'],
    0,
    ['to-color', LOW_PEAKS_COLOR],
    1000,
    ['to-color', HIGH_PEAKS_COLOR]
];

interface PeakBaggerMapProps {
    map: mapboxgl.Map;
    cache: Cache;
}

const PeakBaggerMap: React.FC<PeakBaggerMapProps> = ({
    map, cache
}) => {
    const [filteredPeaks, setFilteredPeaks] = useState<GeoJSON.FeatureCollection>();
    const [peaks, setPeaks] = useState<GeoJSON.FeatureCollection>({
        features: [],
        type: 'FeatureCollection',
    });
    const [years, setYears] = useState<number[]>([]);
    const [selectedYears, setSelectedYears] = useState<number[]>();
    const [selectedMonths, setSelectedMonths] = useState<number[]>();

    const loadData = useCallback(async () => {
        const peaksJson = await GetFromCacheJSON(cache, PEAK_URL);
        setPeaks(peaksJson);
        const years = (peaksJson as GeoJSON.FeatureCollection).features.reduce((accumulator: number[], peak) => {
            const yearsForPeak = (peak.properties?.ascents || []).map((ascent: Ascent) => {
                const { date } = ascent;
                if (date.length < 4) {
                    // console.warn(`date ${date} only has a year or similar, returning false`);
                    return null;
                }
                return parseInt(date.slice(0, 5));
            });
            return Array.from(new Set(accumulator.concat(yearsForPeak)));
        }, []).filter(year => year != null).sort().reverse();
        setYears(years);
    }, [cache]);

    useEffect(() => {
        if (!map.isStyleLoaded()) {
            return;
        }

        map.getSource('peaks') ||
            map.addSource('peaks', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                },
                cluster: true,
                clusterProperties: {
                    'ascents': ['+', ['length', ['get', 'ascents']]],
                },
                maxzoom: 24,
                clusterMaxZoom: 10,
            });

        map.getLayer('peaks') ||
            map.addLayer({
                type: 'circle',
                id: 'peaks',
                source: 'peaks',
                layout: {
                    visibility: 'visible',
                    'circle-sort-key': 1000,
                },
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-color': [
                        'let',
                        'ascents',
                        ["length", ["get", "ascents"]],
                        GetPeakCircleColorInterpolation(),
                    ],
                    'circle-opacity': 0.75,
                    'circle-radius': LOW_PEAKS_RADIUS,
                },
            });

        map.getLayer('peakAscentsType') ||
            map.addLayer({
                id: 'peakAscentsType',
                type: 'symbol',
                source: 'peaks',
                layout: {
                    'text-field': ["to-string", ["length", ["get", "ascents"]]],
                    'text-size': 12,
                    "text-allow-overlap": true,
                    "text-ignore-placement": true,
                    "icon-ignore-placement": true,
                }
            });

        map.getLayer('peakClusters') ||
            map.addLayer({
                id: 'peakClusters',
                type: 'circle',
                source: 'peaks',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['get', 'point_count'],
                        1,
                        LOW_PEAKS_RADIUS,
                        100,
                        30
                    ],
                    'circle-color': [
                        'let',
                        'ascents',
                        ['get', 'ascents'],
                        GetPeakCircleColorInterpolation(),
                    ],
                    'circle-stroke-width': 1,
                    'circle-stroke-color': '#333',
                }
            });

        map.getLayer('peakCount') ||
            map.addLayer({
                id: 'peakCount',
                type: 'symbol',
                source: 'peaks',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{ascents}\n({point_count_abbreviated})',
                    'text-size': 10
                }
            });

        loadData();
    }, [loadData, map])

    useEffect(() => {
        if (map.isStyleLoaded()) {
            const peaksSource = map.getSource('peaks') as mapboxgl.GeoJSONSource;
            // console.log(filteredPeaks, peaks);
            if (!filteredPeaks) {
                // console.log('setting normal peaks');
                peaksSource.setData(peaks);
            } else {
                console.log('setting data to filteredPeaks')
                peaksSource.setData(filteredPeaks);
            }
        }
    }, [map, peaks, filteredPeaks]);

    const handlePeakClusterClick = useCallback((ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        const firstFeature = ev.features[0];
        const clusterId = firstFeature.properties.cluster_id;
        (map.getSource('peaks') as mapboxgl.GeoJSONSource).getClusterExpansionZoom(
            clusterId,
            (err: any, zoom: any) => {
                if (err) return;
                map.easeTo({
                    center: firstFeature.geometry.coordinates,
                    zoom: zoom
                });
            }
        );
    }, [map]);

    const handlePeakClick = useCallback((ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        const firstFeature = ev.features[0];
        const { ascents, name, id, photo } = firstFeature.properties;

        const parsedAscents: Ascent[] = JSON.parse(ascents);
        const placeholder = document.createElement('div');
        ReactDOM.render(
            <PeakPopup
                id={id}
                name={name}
                photo={photo}
                ascents={parsedAscents}
            />,
            placeholder
        );

        new mapboxgl.Popup()
            .setLngLat(ev.lngLat)
            .setDOMContent(placeholder)
            .addTo(map);
    }, [map]);

    useEffect(() => {
        map.on('click', 'peakClusters', handlePeakClusterClick);
        map.on('click', 'peaks', handlePeakClick);
        return () => {
            map.off('click', 'peakClusters', handlePeakClusterClick)
            map.off('click', 'peaks', handlePeakClick);
        }
    }, [map, handlePeakClusterClick, handlePeakClick]);

    useEffect(() => {
        if (!selectedMonths && !selectedYears) {
            setFilteredPeaks(undefined);
            return;
        }
        setFilteredPeaks({
            type: 'FeatureCollection',
            features: peaks.features.map(feature => {
                return {
                    type: 'Feature',
                    id: feature.id,
                    geometry: {
                        type: 'Point',
                        coordinates: [...(feature.geometry as any).coordinates]
                    },
                    properties: {
                        name: feature.properties?.name,
                        id: feature.properties?.id,
                        ascents: (selectedMonths || selectedYears)
                            ? (feature.properties?.ascents || []).filter((ascent: Ascent) => {
                                const { date } = ascent;
                                if (date.length <= 4) {
                                    // console.warn(`date ${date} only has a year or similar, returning false`);
                                    return false;
                                }
                                const year = parseInt(date.slice(0, 5));
                                const yearMatch = (selectedYears === undefined) || selectedYears.includes(year);
                                const month = parseInt(ascent.date.slice(5,7));
                                const monthMatch = (selectedMonths === undefined) || (month <= 12 && month >= 1 && selectedMonths.includes(month));
                                return yearMatch && monthMatch;
                            })
                            : (feature.properties?.ascents || []),
                    }
                }
            }),
        });
    }, [peaks, selectedMonths, selectedYears]);

    return (
        <>
            <ShrinkingSideMenu>
                <YearSelector
                    update={(updatedYears) => { setSelectedYears(updatedYears.length > 0 ? updatedYears : undefined); }}
                    reset={() => { setSelectedYears(undefined); }}
                    years={years}
                />
                <MonthSelector
                    update={(updatedMonths) => { setSelectedMonths(updatedMonths); }}
                    reset={() => { setSelectedMonths(undefined); }}
                />
                <Container>
                    <Row>
                        <Col sm={12}>
                            <Button
                                variant="warning"
                                size="sm"
                                style={{ marginTop: '8px' }}
                                block={true}
                                onClick={async () => {
                                    await cache.delete(PEAK_URL);
                                    loadData();
                                }}
                            >
                                Clear Cache
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </ShrinkingSideMenu>
            <div
                style={{
                    position: 'absolute',
                    bottom: '15px',
                    right: '0px',
                    textAlign: 'right',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: 'rgba(225,225,225,0.85)',
                    padding: '8px',
                }}
            >
                <b>Legend</b><br/>
                <i>Ascents</i>
                <div
                    style={{
                        textAlign: 'center',
                        height: '100px',
                        background: `linear-gradient(0, ${LOW_PEAKS_COLOR} 0%, ${HIGH_PEAKS_COLOR} 100%)`,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: '2px 2px',
                        color: '#EEE',
                    }}
                >
                    <div>1000+</div>
                    <div>0</div>
                </div>
                <i>Peaks</i>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '15px'
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#FFF',
                            width: `${LOW_PEAKS_RADIUS * 2}px`,
                            height: `${LOW_PEAKS_RADIUS * 2}px`,
                            borderRadius: `${LOW_PEAKS_RADIUS}px`,
                            border: '1px solid #333',
                            textAlign: 'center',
                            fontSize: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <div>{`<= 10`}</div>
                    </div>
                    <div
                        style={{
                            backgroundColor: '#FFF',
                            width: `${HIGH_PEAKS_RADIUS * 2}px`,
                            height: `${HIGH_PEAKS_RADIUS * 2}px`,
                            borderRadius: `${HIGH_PEAKS_RADIUS}px`,
                            border: '1px solid #333',
                            textAlign: 'center',
                            fontSize: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <div>{`> 10`}</div>
                    </div>
                </div>
            </div>
        </>
    );
}