import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

interface SongInformation {
	song: string;
	color: string;
}

export const SONGS: { [key: string]: SongInformation } = {
	'🚂': {
		song: 'tmt',
		color: 'rgb(95,39,158)',
	},
	'🍑': {
		song: 'bbg',
		color: '#f4823b',
	},
	'🤬': {
		song: 'rage',
		color: 'rgb(255, 59, 59)',
	},
	'🎄': {
		song: 'xmas',
		color: 'rgb(59, 125, 59)',
	},
	'♾️': {
		song: 'alot',
		color: '#DDDDDD',
	},
	'🔊': {
		song: 'hardbass',
		color: '#000',
	}
};

const Music: React.FC = () => {
	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				margin: '0 auto',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Helmet title="Play that one song we all like!" />
			{Object.keys(SONGS).map(icon => {
				const { song, color } = SONGS[icon];
				return (
					<Link
						key={icon}
						to={`/music/${song}`}
						style={{
							backgroundColor: color,
							minHeight: '150px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							fontSize: '75px',
							flex: '1 0 auto',
						}}
					>
						{icon}
					</Link>
				);
			})}
		</div>
	);
}

export default Music;