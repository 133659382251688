import * as React from 'react';
import Viewer from './Viewer';
import { ParseCSVText } from 'src/lib/Utils/CSV';
// import { ParseCSVText } from 'src/lib/Utils/CSV';

export type Races = 
    'Berlin'|
    'Boston'|
    'Chicago'|
    'London'|
    'Tokyo'|
    'New York City';

interface RaceColors {
    fill: string;
    stroke: string;
}

export const RACE_COLORS: { [key in Races] : RaceColors } = {
    Berlin: {
        fill: '#1D1D1B',
        stroke: '#0C5FA7'
    },
    Boston: {
        fill: '#FEDD32',
        stroke: '#0B5795'
    },
    Chicago: {
        fill: '#0C55BF',
        stroke: '#DB1B39'
    },
    London: {
        fill: '#CC2165',
        stroke: '#213981' 
    },
    Tokyo: {
        fill: '#55AD9A',
        stroke: '#55AD9A'
    },
    'New York City': {
        fill: '#4288B9',
        stroke: '#FBAE42'
    }
};

const ABBOTT_RACES = Object.keys(RACE_COLORS);

interface RaceResult {
    time: number;
    year: number;
}

export interface RacerData {
    age: number;
    gender: any;
    races: {
        [key in Races]: RaceResult | null;
    };
}

const ParseCSVRow = (row: any) => {
    var races: {[key: string]: RaceResult|null} = {};
    for (var i = 0; i < ABBOTT_RACES.length; i++) {
        var result: any = null;
        const race = ABBOTT_RACES[i];
        const raceResult = row[race];
        
        if (raceResult && raceResult !== '') {
            const results = raceResult.split('-');
            result = {
            'time': parseInt(results[0]),
            'year': parseInt(results[1]),
            }
        }

        races[race] = result;
    }
    return {
        age: parseInt(row.Age),
        gender: row.Gender,
        races: races,
    };
}

interface State {
    data: RacerData[];
    minYear: number|null;
    maxYear: number|null;
    minTime: number|null;
    maxTime: number|null;
}

export default class AbbottStars extends React.Component {
    public state: State = {
        data: [],
        minYear: null,
        maxYear: null,
        minTime: null,
        maxTime: null,
    };

    public componentDidMount = () => {
        this.fetchData();
    };

    private fetchData = async () => {
        const text = await (await fetch('/data/ABBOTT.csv')).text();
        const parsed = ParseCSVText(text);
        
        const data = parsed.map(ParseCSVRow);
        const test = data.reduce(
            (a, datum) => {
                Object.keys(datum.races).forEach(race => {
                    if (datum.races[race]) {
                        const raceResult = datum.races[race];
                        if (raceResult?.year) {
                            const year = raceResult.year;
                            if (year > a.maxYear) {
                                a.maxYear = year;
                            }
                            if (year < a.minYear) {
                                a.minYear = year;
                            }
                        }
                        if (raceResult?.time) {
                            const time = raceResult.time;
                            if (time > a.maxTime) {
                                a.maxTime = time;
                            }
                            if (time < a.minTime) {
                                a.minTime = time;
                            }
                        }
                    }
                })
                return a;
            }, 
            {
                minYear: 9999,
                maxYear: 0,
                minTime: 99999999,
                maxTime: 0,
            }
        );
        
        this.setState({
            data,
            minYear: test.minYear - 1,
            maxYear: test.maxYear + 1,
            minTime: test.minTime - 60,
            maxTime: test.maxTime + 60,
        });
    }
    
    public render() {
        const {
            data,
            minYear,
            maxYear,
            minTime,
            maxTime,
        } = this.state;

        const views = [1,2,3,4,5,6].map(i => {
            const finishers = data.filter(datum => {
                return Object.keys(datum.races)
                    .filter(race => datum.races[(race as Races)] !== null)
                    .length === i;
            });
            return (
               <Viewer
                key={i}
                stars={i}
                data={finishers}
                domains={{
                    minYear: minYear || 0,
                    maxYear: maxYear || 0,
                    minTime: minTime || 0,
                    maxTime: maxTime || 0
                }}
            /> 
            );
        });

        return (
            <div style={{ font: '10px sans-serif', height: '100%', margin: 0, padding: 0 }}>
                <div
                    style={{ height: '5%' }}
                >
                    <h2 style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        margin: '0px auto',
                        font: '1.5em sans-serif'
                    }}>
                        Abbott Marathon Majors Finishers (by # of stars)
                    </h2>
                </div>
                <div
                    style={{
                        margin: '0 auto',
                        height: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-evenly',
                    }}
                >
                    {views}
                </div>
            </div>            
        );
    }
};
