import { Modal, ModalBody, Table } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { STRAVA_URL, MAPBOX_ACCESS_TOKEN_URL, COMMON_COLOR_INTERPOLATION_BLACK_WHITE } from './Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/free-solid-svg-icons';
import { secondsToString } from 'src/lib/Utils/Strings';
import * as d3 from 'd3';
import { ActivityData } from './types';
import { COMMON_COLOR_INTERPOLATION } from './Common';
import { GetMapboxTileUrl, MapboxStyle } from 'src/lib/Utils/Maps';
import { useRef, useState } from 'react';

const strokeWidth = 2;
const strokeColor = 'fc4c02';
const strokeOpacity = 1;
const fillColor = '000';
const fillOpacity = 0;
const OverlayPathPrefix = `path-${strokeWidth}+${strokeColor}-${strokeOpacity}+${fillColor}-${fillOpacity}`;

const ActivityModal: React.FC<{
    showModal: boolean;
    onHide: () => void;
    activity?: ActivityData;
    athleteId: number;
}> = ({
    showModal,
    onHide,
    activity,
    athleteId,
}) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const yScaleRef = useRef(d3.scaleLinear());
    const xScaleRef = useRef(d3.scaleLinear());
    const [yScaleRangeMax, setYScaleRangeMax] = useState(1);
    const [xScaleRangeMax, setXScaleRangeMax] = useState(1);
    const splits = activity?.splits || [];
    const paceExtent = d3.extent(splits.map(s => s.pace || (s.time / s.distance)));

    yScaleRef.current.domain([
        (paceExtent[0] || 60) - 60,
        (paceExtent[1] || 600) + 120
    ]).rangeRound([0, yScaleRangeMax]);
    xScaleRef.current.domain([0, Math.ceil(splits.length)])
        .rangeRound([0, xScaleRangeMax]);
    const modalBarWidth = xScaleRef.current(1) - xScaleRef.current(0);

    return (
        <Modal
            show={showModal}
            onHide={onHide}
            size={splits.length > 15 ? 'xl' : splits.length > 10 ? 'lg' : undefined}
            onEntered={() => {
                setXScaleRangeMax(svgRef.current?.clientWidth || 0)
                setYScaleRangeMax(svgRef.current?.clientHeight || 0);
            }}
        >
            <ModalHeader closeButton={true}>
                <h4>
                    <a
                        href={`${STRAVA_URL}${activity?.id}`}
                        style={{ marginRight: '8px' }}
                    >
                        {activity?.name}
                    </a>
                    <small
                        style={{ marginRight: '8px' }}
                    >
                        {new Date(activity?.date || 0).toDateString()}
                    </small>
                    <a
                        className="pull-right"
                        target="_blank"
                        rel="noreferrer"
                        href={`/routeVisualizer/#${activity?.id}-${athleteId}`}
                    >
                        <FontAwesomeIcon icon={faMap}/>
                    </a>
                </h4>
            </ModalHeader>
            <ModalBody>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <div style={{ flex: '1 auto', paddingRight: '1em' }}>
                        <Table
                            striped={true}
                            hover={true}
                            bordered={true}
                        >
                            <tbody>
                                <tr>
                                    <td>Distance</td>
                                    <td>{activity?.distance.toFixed(2)} mi</td>
                                </tr>
                                <tr>
                                    <td>Duration</td>
                                    <td>{secondsToString(activity?.duration || 0)}</td>
                                </tr>
                                <tr>
                                    <td>Average Pace</td>
                                    <td>{secondsToString(Math.floor(activity?.pace || 0))}</td>
                                </tr>
                                <tr>
                                    <td>Average Speed</td>
                                    <td>{activity?.speed.toFixed(2)} mph</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div style={{ flex: '2 auto', marginBottom: '1rem' }}>
                        <svg
                            width={'100%'}
                            height={'100%'}
                            ref={svgRef}
                        >
                            <g>
                                {
                                    (activity?.splits || []).map((split, i) => {
                                        const correctedSplit = split?.pace || (split.time / split.distance);
                                        const splitRatio = ((correctedSplit) - (paceExtent[0] || 0)) / ((paceExtent[1] || 0) - (paceExtent[0] || 0));
                                        const yTransform = yScaleRef.current.range()[1]-yScaleRef.current(correctedSplit)
                                        return (
                                            <g
                                                key={`${activity?.id}-${i}`}
                                                transform={`translate(${xScaleRef.current(i)},${yTransform})`}
                                            >
                                                <rect
                                                    width={xScaleRef.current(i+1) - xScaleRef.current(i)}
                                                    height={Math.max(yScaleRef.current(correctedSplit), 20)}
                                                    fill={COMMON_COLOR_INTERPOLATION(splitRatio)}
                                                />
                                                <text
                                                    fontSize="9px"
                                                    y={9}
                                                    x={modalBarWidth / 2}
                                                    textAnchor="middle"
                                                    fill={COMMON_COLOR_INTERPOLATION_BLACK_WHITE(splitRatio)}
                                                >
                                                    {secondsToString(Math.round(correctedSplit))}
                                                </text>
                                            </g>
                                        );
                                    })
                                }
                            </g>
                        </svg>
                    </div>
                </div>
                <img
                    style={{ width: '100%' }}
                    src={`${GetMapboxTileUrl(MapboxStyle.DARK)}/static/${OverlayPathPrefix}(${encodeURIComponent(activity?.polyline || '')})/auto/512x512?${MAPBOX_ACCESS_TOKEN_URL}`}
                    alt="map"
                />
            </ModalBody>
        </Modal>
    );
}

export default ActivityModal;