import * as React from 'react';

const IMAGE_COUNT = 4;

export default class PiScreensaver extends React.Component {
    public state = {
        currentImage: 0,
    }

    componentDidMount() {
        const self = this;
        setInterval(function(){
            self.setState({ currentImage: (self.state.currentImage + 1) % IMAGE_COUNT });
        }, 10000);
    }

    createImageTag = (_: any, number: number) => (
        <img
            key={number}
            style={{
                opacity: number === this.state.currentImage ? 1 : 0,
                width: '100%',
                height: '100%',
                margin: '0 auto',
                position: 'absolute',
                top: 0,
                left: 0,
                transition: 'opacity 1.5s',
            }}
            src={`/img/pi/image${number}.gif`}
            alt={number.toString()}
        />
    );

    public render() {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                {Array(IMAGE_COUNT).fill(null).map(this.createImageTag)}
            </div>
        );
    }
};
