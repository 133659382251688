import { useCallback, useEffect, useState } from 'react';
import './style.css';
import 'react-tabs/style/react-tabs.css';
import 'react-datetime/css/react-datetime.css';
import { RawCSVTransaction, Transaction } from './types';
import { ParseCSVText } from '../../lib/Utils/CSV';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import TransactionTable from './TransactionTable';
import BalanceTable from './BalanceTable';
import Charts, { AccountsCategories } from './Charts';
import Datetime from 'react-datetime';
import StackedBarChart from '../../lib/d3/StackedBarChart';
import { Button, Form, Modal, ModalBody } from 'react-bootstrap';
import { formatter } from '../../lib/d3/common';
import SavingsChart from './SavingsChart';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ShrinkingSideMenu } from 'src/components/ShrinkingSideMenu';

const ConvertRawTransaction = (rawTransaction: RawCSVTransaction): Transaction|null => {
    if (rawTransaction.Account === undefined) {
        return null;
    }
    const dateParts = rawTransaction.Date.split('-');
    const transactionDate = new Date();
    transactionDate.setFullYear(parseInt(dateParts[0]));
    transactionDate.setMonth(parseInt(dateParts[1]) - 1);
    transactionDate.setDate(parseInt(dateParts[2]));
    transactionDate.setHours(0);
    transactionDate.setMinutes(0);
    transactionDate.setMilliseconds(0);
    return {
        account: rawTransaction.Account,
        category: rawTransaction.Category,
        date: transactionDate,
        description: rawTransaction.Description,
        originalDescription: rawTransaction['Original Description'],
        value: parseFloat(rawTransaction.Value)
    }
}

const firstDayOfMonth = (input: Date) => {
    const firstDay = new Date(input);
    firstDay.setDate(1);
    firstDay.setHours(0);
    firstDay.setMinutes(0);
    firstDay.setSeconds(0);
    firstDay.setMilliseconds(0);
    return firstDay;
}

interface ModalInformation {
    category: string;
    total: number;
    date: Date;
    transactions: Transaction[];
}

const App: React.FC = () => {
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);
    const [accounts, setAccounts] = useState<Set<string>>();
    const [accountsCategories, setAccountsCategories] = useState<AccountsCategories>();
    const [balances, setBalances] = useState<Map<Date, Map<string, number>>>(new Map());
    const [expensesMap, setExpensesMap] = useState<Map<Date,Map<string,number>>>(new Map());
    const [filteredAccounts, setFilteredAccounts] = useState<Set<string>>(new Set());
    const [filteredBalances, setFilteredBalances] = useState<Map<Date, Map<string, number>>>();
    const [rangeStart, setRangeStart] = useState(new Date());
    const [rangeEnd, setRangeEnd] = useState(new Date());
    const [finalTransactionDate, setFinalTransactionDate] = useState(new Date());
    const [initialTransactionDate, setInitialTransactionDate] = useState(new Date());
    const [modalInformation, setModalInformation] = useState<ModalInformation|null>(null);

    const load = useCallback(async () => {
        const transactionsCsv = await (await fetch('./data/updated.csv')).text();
        const parsedCsvTransactions = ParseCSVText(transactionsCsv);
        const convertedTransactions = parsedCsvTransactions.map(parsedCsvTransaction => {
            return ConvertRawTransaction(parsedCsvTransaction);
        }).filter(a => a !== null) as Transaction[];
        setTransactions(convertedTransactions);
        const accountsSet: Set<string> = new Set();
        const balanceMap: Map<Date, Map<string, number>> = new Map();
        let currentMap = new Map();
        let currentDate = convertedTransactions[0].date;
        balanceMap.set(currentDate, currentMap);
        for (var i = 0; i < convertedTransactions.length; i++) {
            const { account, value, date } = convertedTransactions[i];
            if (!accountsSet.has(account)) {
                accountsSet.add(account);
            }
            if (date.getTime() !== currentDate.getTime()) {
                currentDate = date;
                currentMap = new Map(currentMap);
                balanceMap.set(currentDate, currentMap);
            }

            if (!currentMap.has(account)) {
                currentMap.set(account, 0);
            }
            const previousValue = currentMap.get(account);
            if (previousValue !== undefined) {
                const currentValue = previousValue + value;
                currentMap.set(account, currentValue);
            }
        }
        setAccounts(accountsSet);
        setFilteredAccounts(new Set(accountsSet));
        setBalances(balanceMap);
        const lastTransactionDate = convertedTransactions[convertedTransactions.length - 1].date;
        const threeYearsBehindLastTransactionDate = new Date(lastTransactionDate);
        threeYearsBehindLastTransactionDate.setFullYear(threeYearsBehindLastTransactionDate.getFullYear() - 3);
        threeYearsBehindLastTransactionDate.setDate(1);
        setFinalTransactionDate(lastTransactionDate);
        setInitialTransactionDate(convertedTransactions[0].date);
        setRangeEnd(lastTransactionDate);
        setRangeStart(threeYearsBehindLastTransactionDate);

        setAccountsCategories(await (await fetch('./data/accounts.json')).json())
    }, []);

    useEffect(() => {
        if (filteredTransactions.length === 0) return;
        let expenses: Map<Date, Map<string, number>> = new Map();
        let currentMonthDate = firstDayOfMonth(filteredTransactions[0].date);

        for (var i = 0; i < filteredTransactions.length; i++) {
            const { value, date, category } = filteredTransactions[i];
            if (firstDayOfMonth(date).getTime() !== currentMonthDate.getTime()) {
                currentMonthDate = firstDayOfMonth(date);
            }
            if (!expenses.has(currentMonthDate)) {
                expenses.set(currentMonthDate, new Map());
            }
            const yearMonthMap = expenses.get(currentMonthDate);
            yearMonthMap?.set(category, (yearMonthMap.get(category) || 0) + value);
        }
        const all_categories = [...expenses.values()].reduce((accumulator: string[], entry) => {
            const categories: string[] = Array.from(entry.keys());
            for (var i = 0; i < categories.length; i++) {
                const category = categories[i];
                if (accumulator.indexOf(category) < 0) {
                    accumulator.push(category);
                }
            }
            return accumulator;
        }, []);
        for (const entry of expenses.values()) {
            for (var j = 0; j < all_categories.length; j++) {
                const category = all_categories[j];
                if (!entry.has(category)) {
                    entry.set(category, 0);
                }
            }
        }
        setExpensesMap(expenses);
    }, [filteredTransactions]);

    useEffect(() => {
        setFilteredTransactions(transactions.filter(transaction => {
            return transaction.date >= rangeStart &&
                transaction.date <= rangeEnd &&
                filteredAccounts.has(transaction.account);
        }));
        const currentFilteredBalances = new Map(
            [...balances]
            .filter(([k, v]) => {
                return k >= rangeStart &&
                    k <= rangeEnd;
            })
            .map(([k, v]) : [Date, Map<string, number>]  => {
                const filteredBalancesForDate = new Map(
                    [...v]
                        .filter(([account, _]) => filteredAccounts.has(account))
                );
                const result: [Date, Map<string,number>] = [k, filteredBalancesForDate];
                return result;
            })
        );
        setFilteredBalances(currentFilteredBalances);
    }, [transactions, balances, rangeStart, rangeEnd, filteredAccounts]);

    useEffect(() => {
        load();
    }, [load]);

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <ShrinkingSideMenu
                menuItems={[]}
            >
                <div
                    style={{ margin: '0 5px' }}
                >
                    <h3 style={{ color: '#EEE' }}>Date Range</h3>
                    <div style={{ display: 'inline' }}>
                        <Datetime
                            dateFormat="YYYY-MM"
                            timeFormat={false}
                            onChange={(updatedYearMonth) => {
                                if (typeof updatedYearMonth !== 'string') {
                                    const start = updatedYearMonth.toDate();
                                    
                                    start.setDate(1);
                                    start.setHours(0);
                                    start.setMinutes(0);
                                    start.setSeconds(0);
                                    start.setMilliseconds(0);
                                    setRangeStart(start);
                                }
                            }}
                            value={rangeStart}
                            isValidDate={(currentDate: moment.Moment, selectedDate: moment.Moment) => currentDate.isBetween(initialTransactionDate, finalTransactionDate, undefined, "[]")}
                            inputProps={{ style: { maxWidth: '50%', display: 'inline' }}}
                        />
                        <Datetime
                            dateFormat="YYYY-MM"
                            timeFormat={false}
                            onChange={(updatedYearMonth) => {
                                if (typeof updatedYearMonth !== 'string') {
                                    const end = updatedYearMonth.toDate();
                                    end.setMonth(end.getMonth() + 1);
                                    end.setDate(0);
                                    end.setHours(0);
                                    end.setMinutes(0);
                                    end.setSeconds(0);
                                    end.setMilliseconds(0);
                                    setRangeEnd(end);
                                }
                            }}
                            value={rangeEnd}
                            isValidDate={(currentDate: moment.Moment, selectedDate: moment.Moment) => currentDate.isBetween(initialTransactionDate, finalTransactionDate, undefined, "[]")}
                            inputProps={{ style: { maxWidth: '50%', display: 'inline' }}}
                        />
                    </div>
                    <h3 style={{ color: '#EEE' }}>
                        Accounts
                        <span style={{ float: 'right' }}>
                            <Button
                                size="sm"
                                style={{ marginRight: '5px' }}
                                variant='outline-primary'
                                onClick={() => {
                                    setFilteredAccounts(new Set(accounts));
                                }}
                            >
                                Select All
                            </Button>
                            <Button
                                size="sm"
                                variant='outline-primary'
                                onClick={() => {
                                    setFilteredAccounts(new Set());
                                }}
                            >
                                Clear
                            </Button>
                        </span>
                    </h3>
                    <div style={{ color: '#EEE', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '5px' }}>
                        {accounts && Array.from(accounts).map(account => {
                            return (<Form.Switch
                                key={`${account}-switch`}
                                id={`${account}-switch`}
                                label={account}
                                checked={filteredAccounts.has(account)}
                                onChange={() => {
                                    const updatedFilteredAccounts = new Set([...accounts].filter(a => filteredAccounts.has(a) || a === account));
                                    if (filteredAccounts.has(account)) {
                                        updatedFilteredAccounts.delete(account);
                                    } else {
                                        updatedFilteredAccounts.add(account);
                                    }
                                    setFilteredAccounts(updatedFilteredAccounts);
                                }}
                            />);
                        })}
                    </div>
                </div>
            </ShrinkingSideMenu>
            <Tabs style={{ width: '100%', flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
                <TabList style={{ flex: '0 0 auto', marginLeft: '60px' }}>
                    <Tab>Transactions Table</Tab>
                    <Tab>Balances Table</Tab>
                    <Tab>Running Balance Charts</Tab>
                    <Tab>Monthly Net Bar Chart</Tab>
                    <Tab>Savings Rate Graph</Tab>
                </TabList>
                <TabPanel
                    style={{ margin: '0 10px', flex: '1 0 auto' }}
                >
                    <TransactionTable transactions={filteredTransactions} />
                </TabPanel>
                <TabPanel
                    style={{ margin: '0 10px', flex: '1 0 auto' }}
                >
                    {filteredBalances && (
                        <BalanceTable
                            accounts={filteredAccounts}
                            balances={filteredBalances}
                        />
                    )}
                </TabPanel>
                <TabPanel
                    style={{ flex: '1 0 auto' }}
                >
                    {filteredBalances && accountsCategories && (
                        <Charts
                            balances={filteredBalances}
                            accountsCategories={accountsCategories}
                        />
                    )}
                </TabPanel>
                <TabPanel
                    style={{ flex: '1 0 auto' }}
                >
                    <StackedBarChart
                        data={expensesMap}
                        stackClicked={(date: Date, category: string, total: number) => {
                            setModalInformation({
                                category,
                                date,
                                total,
                                transactions: filteredTransactions.filter((t) => {
                                    return t.category === category &&
                                        t.date.getFullYear() === date.getFullYear() &&
                                        t.date.getMonth() === date.getMonth()
                                }),
                            });
                        }}
                    />
                </TabPanel>
                <TabPanel
                    style={{ flex: '1 0 auto' }}
                >
                    <SavingsChart
                        data={expensesMap}
                    />
                </TabPanel>
            </Tabs>
            <Modal
                show={modalInformation !== null}
                onHide={() => { setModalInformation(null); }}
                size="xl"
            >
                <ModalHeader closeButton={true}>
                    <div style={{ display: 'flex', width: '100%', fontWeight: 'bold' }}>
                        <div>{modalInformation?.category}</div>
                        <div style={{ flexGrow: 1, textAlign: 'center' }}>
                            {`${modalInformation?.date.getFullYear()}-${(modalInformation?.date.getMonth() || 0) < 10 ? '0' : ''}${modalInformation?.date.getMonth()}`}
                        </div>
                        <div>
                            {formatter.format(modalInformation?.total || 0)}
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <TransactionTable
                        transactions={modalInformation?.transactions || []}
                    />
                </ModalBody>
            </Modal>
        </div>
    );
}

export default App;