export function debounce(fn: any, delay: number) {
    let timer: any = undefined;
    return function(this: any, ...args: any[]) {
        const context = this;
        clearTimeout(timer);
        timer = setTimeout(
            function() {
                fn.apply(context, args);
            },
            delay
        );
    };
}