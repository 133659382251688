import { useCallback, useRef, useState } from 'react';
import './style.css';
import { Helmet } from 'react-helmet';

const YTMND: React.FC = () => {
	const TEXTS = 27;
	const FONTSIZE = 120;
	const IMG = '27.webp';
	const VIDEOSRC = "/img/YTMND/27.mp4"
	const videoRef = useRef<HTMLVideoElement>(null);
	const [showText, setShowText] = useState(false);

	const handleClick = useCallback(() => {
		if (!videoRef.current) return;

		videoRef.current.currentTime = 0;
		videoRef.current.play();
		setShowText(true);
		setTimeout(() => {
			setShowText(false);
		}, 2200);
	}, []);

	const text: JSX.Element[] = [];
	for (var i = 0; i < TEXTS; i++) {
		const animationPercent = (TEXTS - i) / TEXTS;
		text.push((
		<div
			key={i}
			style={{
				color: i === 0 ? 'inherit' : '#CCC',
				zIndex: TEXTS - i,
				position: 'inherit',
				fontSize:  animationPercent * FONTSIZE + 'pt',
				width: '100%',
				animation: showText ? `opacity 1000ms linear ${animationPercent * 400}ms 1 normal forwards` : 'none',
				opacity: 0,
			}}
		>
			27 !!!
		</div>
		));
	}

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				margin: '0 auto',
				overflow: 'hidden',
				backgroundImage: `url("img/YTMND/${IMG}")`,
				animation: 'x 5000ms linear alternate infinite, y 2000ms linear alternate infinite',
				backgroundSize: '50%',
			}}
		>
			<Helmet
				title='27 !!!'
			/>
			<video
				src={VIDEOSRC}
				ref={videoRef}
				preload=""
				style={{
					margin: '0 auto',
  					height: 'auto',
					width: '100%',
  					display: 'block',
					opacity: 0,
					animation: showText ? 'opacity 2000ms linear 0s 1 normal forwards' : 'none',
					position: 'relative',
					top: '50%',
					transform: 'translateY(-50%)',
					pointerEvents: 'none',
				}}
				playsInline
			>
				<source src={VIDEOSRC} type="video/mp4" />
				<a href={VIDEOSRC}>
					Click Me
				</a>
			</video>
			<div
				style={{
					position: 'absolute',
					top: '60%',
					width: '100%',
					textAlign: 'center',
					pointerEvents: 'none',
				}}
			>
				{text}
			</div>
			<div
				style={{
					width: '100%',
					height: '100%',
					position: 'absolute',
					top: 0,
					left: 0,
				}}
				onClick={handleClick}
			/>
		</div>
	)
}

export default YTMND;