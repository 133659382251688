import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';

interface YearSelectorProps {
    update: (months: number[]) => void;
    reset: () => void;
    years: number[];
}

const YearSelector: React.FC<YearSelectorProps> = ({ update, reset, years }) => {
    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <h3
                        style={{
                            textAlign: 'center',
                            color: '#CCC',
                        }}
                    >
                        Year Selector
                    </h3>
                    <Form>
                        <Form.Group>
                            <Form.Label></Form.Label>
                            <Form.Control
                                custom={true}
                                as="select"
                                multiple={true}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    const { selectedOptions } = event.currentTarget;
                                    const values = Array.from(selectedOptions).map(option => {
                                        return parseInt(option.value)
                                    });
                                    update(values);
                                }}
                                value={undefined}
                            >
                                {years.map(year => {
                                    return (
                                        <option
                                            key={year}
                                            value={year}
                                        >
                                            {year}
                                        </option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default YearSelector;