import * as React from 'react';
import { Row, Col, Jumbotron, Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import FormLabel from 'react-bootstrap/FormLabel';

const coindeskApiUrl = "https://api.coindesk.com/v1/bpi/";
const coindeskCurrentPrice = "currentprice.json"
const coindeskHistoricalPrice = "historical/close.json"

export default class BitcoinLossTracker extends React.Component {
    public state = {
        money: 15,
        date: '2014-06-05',
        loss: '$0.00',
    }

    handleMoneyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const money = parseInt(event.currentTarget.value);
        if (isNaN(money)) { return }
        this.setState({ money });
    }

    handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = event.currentTarget.value;
        this.setState({ date });
    }

    handleClick = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        const historicalQuery = `?start=${this.state.date}&end=${this.state.date}`;
        const historicalUrl = `${coindeskApiUrl}${coindeskHistoricalPrice}${historicalQuery}`
        const historicalPrice = await (await fetch(historicalUrl)).json();
        const bitcoinValueAtSellDate = parseInt(historicalPrice.bpi[this.state.date].toString().replace(",",""))
        const bitcoinAmountAtSellDate = this.state.money / bitcoinValueAtSellDate;

        const currentPrice = await (await fetch(`${coindeskApiUrl}${coindeskCurrentPrice}`)).json();
        const bitcoinValueAtCurrentDate = parseInt(currentPrice.bpi.USD.rate.toString().replace(",",""))
        const valueAtCurrentDate = (bitcoinAmountAtSellDate * bitcoinValueAtCurrentDate) - this.state.money;
        this.setState({
            loss: `$${valueAtCurrentDate.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`
        });
    }
    
    public render() {
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Jumbotron
                            style={{ backgroundColor: '#f0ad4e'}}
                        >
                            <h1
                                className="display-1"
                                style={{
                                    color: '#FFF',
                                    textAlign: 'center'
                                }}
                            >
                                Remember that one time you sold your bitcoin?
                            </h1>
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="offset-md-3">
                        <Form
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <FormGroup className="row">
                                <FormLabel
                                    bsPrefix="col-md-4 col-form-label col-form-label-lg"
                                >
                                    You sold (in USD):
                                </FormLabel>
                                <Col md={8} className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">$</span>
                                    </div>
                                    <FormControl type="number" value={this.state.money} onChange={this.handleMoneyChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <FormLabel bsPrefix="col-md-4 col-form-label col-form-label-lg">
                                    You sold on:
                                </FormLabel>
                                <Col md={8}>
                                    <FormControl type="date" value={this.state.date} onChange={this.handleDateChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col md={12}>
                                    <Button
                                        size="lg"
                                        block={true}
                                        variant="warning"
                                        onClick={this.handleClick}
                                    >Calculate :(</Button>
                                </Col>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="offset-md-3">
                        <Row>
                            <Col md={6}>
                                <h2>You lost:</h2>
                            </Col>
                            <Col md={6} style={{ textAlign: 'right' }}>
                                <h2
                                    style={{ color: '#d9534f' }}
                                >
                                    {this.state.loss}
                                </h2>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
};
