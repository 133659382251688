import { faSortNumericUp, faSortNumericDown, faDotCircle, faCheckCircle, faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useCallback } from "react";
import { Table } from "react-bootstrap";
import { CreateGeoJsonForFoundUnfoundNodes } from "src/lib/Utils/GeoJSON";
import ActivityModal from "../ActivityModal";
import { ToISOStringLocal } from "../Common";
import StreetModal from "../MapboxUtils/StreetModal";
import { ActivityData } from "../types";
import { CityWay, Progress } from "./NodesProgress";
import WaysTooltip from "./WaysTooltip";
import { FeatureCollection } from 'geojson';

type RunSortType = 'Date' | 'Name' | 'Completed' | 'Progressed' | 'Nodes';

const RunTable: React.FC<{
    progress: Progress[];
    athleteId: number;
    activities: ActivityData[];
    cityWays: {[name: string]: CityWay};
}> = ({
    progress,
    athleteId,
    activities,
    cityWays,
}) => {
    const [reverse, setReverse] = useState(true);
    const [sort, setSort] = useState<RunSortType>('Date');
    const [showModal, setShowModal] = useState(false);
    const [modalActivity, setModalActivity] = useState<number>();
    const [showStreetModal, setShowStreetModal] = useState(false);
    const [modalStreet, setModalStreet] = useState<string>();
    const [modalGeoJson, setModalGeoJson] = useState<FeatureCollection>();
    
    const setSortAndReverse = useCallback((updatedSort: RunSortType) => {
        setSort(updatedSort);
        if (updatedSort !== sort) {
            setReverse(false);
        } else {
            setReverse(!reverse);
        }
    }, [sort, reverse]);

    const sortedRuns = [...progress].sort((progressA, progressB) => {
        const propertiesMap: {[key in RunSortType]: (a: Progress) => string|number|Date} = {
            'Date': (progress: Progress) => progress.date,
            'Name': (progress: Progress) => progress.name,
            'Completed': (progress: Progress) => progress.ways.completed,
            'Progressed': (progress: Progress) => progress.ways.progressed,
            'Nodes': (progress: Progress) => progress.nodes.completed,
        }
        let propA = propertiesMap[sort](progressA);
        let propB = propertiesMap[sort](progressB);
        
        const result = reverse
            ? propA < propB
            : propA > propB;
        return result ? 1 : -1;
    })

    return (
        <Table
            striped={true}
            hover={true}
            size="sm"
            style={{ fontFamily: 'monospace' }}
        >
            <thead style={{ position: 'sticky', top: 0 }}>
                <tr
                    style={{
                        display: 'sticky',
                        top: 0,
                        backgroundColor: '#FFF',
                        color: '#999',
                    }}
                >
                    <th
                        onClick={() => setSortAndReverse('Date')}
                        style={{
                            color: sort === 'Date' ? '#000' : 'inherit'
                        }}
                    >Date{<FontAwesomeIcon
                        icon={reverse ? faSortNumericUp : faSortNumericDown}
                        style={{ marginRight: '2px' }}
                    />}</th>
                    <th
                        onClick={() => setSortAndReverse('Name')}
                        style={{
                            color: sort === 'Name' ? '#000' : 'inherit'
                        }}
                    >
                        Name{<FontAwesomeIcon
                            icon={reverse ? faSortNumericUp : faSortNumericDown}
                            style={{ marginRight: '2px' }}
                        />}
                    </th>
                    <th
                        onClick={() => setSortAndReverse('Nodes')}
                        style={{
                            textAlign: 'right',
                            color: sort === 'Nodes' ? '#000' : 'inherit'
                        }}
                    >
                        {<FontAwesomeIcon
                            icon={reverse ? faSortNumericUp : faSortNumericDown}
                            style={{ marginRight: '2px' }}
                        />}<FontAwesomeIcon icon={faDotCircle} title="Nodes" />
                    </th>
                    <th
                        onClick={() => setSortAndReverse('Completed')}
                        style={{
                            textAlign: 'right',
                            color: sort === 'Completed' ? '#000' : 'inherit'
                        }}
                    >
                        {<FontAwesomeIcon
                            icon={reverse ? faSortNumericUp : faSortNumericDown}
                            style={{ marginRight: '2px' }}
                        />}<FontAwesomeIcon icon={faCheckCircle} title="Completed" />
                    </th>
                    <th
                        onClick={() => setSortAndReverse('Progressed')}
                        style={{
                            textAlign: 'right',
                            color: sort === 'Progressed' ? '#000' : 'inherit'
                        }}
                    >
                        {<FontAwesomeIcon
                            icon={reverse ? faSortNumericUp : faSortNumericDown}
                            style={{ marginRight: '2px' }}
                        />}<FontAwesomeIcon icon={faTasks} title="Progressed" />
                    </th>
                </tr>
            </thead>
            <tbody>
                {sortedRuns.map(run => <tr
                    key={run.id}
                >
                    <td>
                        {ToISOStringLocal(run.date)}
                    </td>
                    <td style={{ 
                        textOverflow: 'ellipsis',
                        maxWidth: '400px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}><span onClick={() => {
                        setShowModal(true);
                        setModalActivity(run.id);
                    }} style={{ cursor: 'pointer' }}>{run.name}</span></td>
                    <td style={{ textAlign: 'right' }}>
                        {run.nodes.completed}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                        <WaysTooltip
                            title={"Completed Streets"}
                            runId={run.id.toString()}
                            ways={run.ways.completedList}
                            wayClicked={(way: string) => {
                                const nodes = cityWays[way].nodeInformation || [];
                                const foundNodes = nodes.filter(n => n.activityId !== undefined);
                                const unfoundNodes = nodes.filter(n => n.activityId === undefined);
                                setShowStreetModal(true);
                                setModalStreet(way);
                                setModalGeoJson(CreateGeoJsonForFoundUnfoundNodes(foundNodes, unfoundNodes));
                            }}
                        >
                            <span>{run.ways.completedList.length}</span>
                        </WaysTooltip>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                        <WaysTooltip
                            title={"Progressed Streets"}
                            runId={run.id.toString()}
                            ways={run.ways.progressedList}
                            wayClicked={(way: string) => {
                                const nodes = cityWays[way].nodeInformation || [];
                                const foundNodes = nodes.filter(n => n.activityId !== undefined);
                                const unfoundNodes = nodes.filter(n => n.activityId === undefined);
                                setShowStreetModal(true);
                                setModalStreet(way);
                                setModalGeoJson(CreateGeoJsonForFoundUnfoundNodes(foundNodes, unfoundNodes));
                            }}
                        >
                            <span>{run.ways.progressedList.length}</span>
                        </WaysTooltip>
                    </td>
                </tr>)}
            </tbody>
            {showModal && modalActivity && <ActivityModal
                onHide={() => setShowModal(false)}
                showModal={showModal}
                athleteId={athleteId}
                activity={activities.find(activity => activity.id === modalActivity)}
            />}
            {showStreetModal && modalStreet && modalGeoJson && <StreetModal
                onHide={() => setShowStreetModal(false)}
                showModal={showStreetModal}
                name={modalStreet}
                geoJson={modalGeoJson}
            />}
        </Table>
    );
}

export default RunTable;