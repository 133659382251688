export const INFLATION_MAP: { [key: string] : number } = {
"2020" : 9.37,
"2019" : 9.01,
"2018" : 9.11,
"2017" : 8.97,
"2016" : 8.65,
"2015" : 8.43,
"2014" : 8.17,
"2013" : 8.13,
"2012" : 7.96,
"2011" : 7.93,
"2010" : 7.89,
"2009" : 7.50,
"2008" : 7.18,
"2007" : 6.88,
"2006" : 6.55,
"2005" : 6.41,
"2004" : 6.21,
"2003" : 6.03,
"2002" : 5.81,
"2001" : 5.66,
"2000" : 5.39,
"1999" : 5.08,
"1998" : 4.69,
"1997" : 4.59,
"1996" : 4.42,
"1995" : 4.35,
"1994" : 4.18,
"1993" : 4.14,
"1992" : 4.15,
"1991" : 4.21,
"1990" : 4.23,
"1989" : 3.97,
"1988" : 4.11,
"1987" : 3.91,
"1986" : 3.71,
"1985" : 3.55,
"1984" : 3.36,
"1983" : 3.15,
"1982" : 2.94,
"1981" : 2.78,
"1980" : 2.69,
"1979" : 2.51,
"1978" : 2.34,
"1977" : 2.23,
"1976" : 2.13,
"1975" : 2.05,
"1974" : 1.87,
"1973" : 1.77,
"1972" : 1.70,
"1971" : 1.65,
"1970" : 1.55,
"1969" : 1.42,
"1968" : 1.31,
"1967" : 1.20,
"1966" : 1.09,
"1965" : 1.01,
"1964" : 0.93,
"1963" : 0.85,
"1962" : 0.70,
"1961" : 0.69,
"1959" : 0.51,
"1956" : 0.50,
"1954" : 0.45,
"1953" : 0.60,
"1951" : 0.53,
"1949" : 0.46,
"1948" : 0.40,
"1945" : 0.35,
"1944" : 0.32,
"1943" : 0.29,
"1942" : 0.27,
"1941" : 0.25,
"1940" : 0.24,
"1939" : 0.23,
"1936" : 0.25,
"1935" : 0.24,
"1934" : 0.23,
"1929" : 0.35,
"1924" : 0.25,
"1910" : 0.07
};
