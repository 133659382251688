import { faBrush, faEraser, faEyeDropper, faPaperPlane, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as d3 from 'd3';
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import BrushSizePicker from './BrushSizePicker';
import { Draw, GetMouseEventPositionForHTMLElementRef, Position, Props, StaticCanvasProps } from './Canvas';
import HuePicker from './HuePicker';
import SaturationPicker from './SaturationPicker';

const CanvasWidth = 600;
const CanvasHeight = CanvasWidth;
const BrushSize = 50;

export const hslFor = (hue: number, saturation: number, brightness: number) => {
    return `hsl(${hue}, ${saturation}%, ${brightness}%)`;
}

const SVGCanvas: React.FC<Props> = ({ onSend }) => {
    const canvasRef = useRef<SVGSVGElement>(null);
    const [drawing, setDrawing] = useState(false);
    const [position, setPosition] = useState<Position | undefined>(undefined);
    const [draws, setDraws] = useState<Draw[]>([]);
    const [currentDraw, setCurrentDraw] = useState<[Position,Position][]>([]);
    const [hue, setHue] = useState(0);
    const [saturation, setSaturation] = useState(100);
    const [brightness, setBrightness] = useState(50);
    const [lineWidth, setLineWidth] = useState<number>(5);
    const [eraser, setEraser] = useState(false);
    const [eyeDropper, setEyeDropper] = useState(false);
    const [hoverColor, setHoverColor] = useState(hslFor(0, 100, 50));
    const [brush, setBrush] = useState(false);
    const [hover, setHover] = useState(false);

    const undo = () => {
        setDraws(draws.slice(0, draws.length - 1));
    }

    const startDrawing = (event: MouseEvent|TouchEvent) => {
        const position = GetMouseEventPositionForHTMLElementRef(event, canvasRef.current);
        if (position && !eyeDropper) {
            setPosition(position);
            setDrawing(true);
        }
    };

    const draw = (event: MouseEvent|TouchEvent) => {
        const currentPosition = GetMouseEventPositionForHTMLElementRef(event, canvasRef.current);
        if (drawing && !eyeDropper) {    
            if (position && currentPosition) {
                setCurrentDraw(currentDraw.concat([[position, currentPosition]]));
            }
        }
        if (currentPosition) {
            setPosition(currentPosition);
        }        
    };

    const stopDraw = () => {
        setDrawing(false);
        setPosition(undefined);
        setHover(false);
        if (drawing) {
            const updatedDraws = draws.concat({
                color: eraser ? 'rgb(255,255,255)' : hslFor(hue, saturation, brightness),
                lineWidth: brush ? BrushSize : lineWidth,
                path: currentDraw,
            });
            setDraws(updatedDraws);
            setCurrentDraw([]);
        }
        if (eyeDropper) {
            setEyeDropper(false);
            const { h: hue, s: saturation, l: brightness } = d3.hsl(hoverColor);
            setHue(hue);
            setSaturation(saturation * 100);
            setBrightness(brightness * 100);
        }
    };

    const setHoverColorOnMouseOver = (event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
        const strokeColor = event.currentTarget.getAttribute('stroke');
        setHoverColor(strokeColor || '#000');
    }

    const mouseOverCanvas = (event: MouseEvent|TouchEvent) => {
        setHover(true);
    }

    const stopDrawCallback = useCallback(stopDraw, [eyeDropper, hoverColor, drawing, eraser, brush, currentDraw, lineWidth, draws, hue, saturation, brightness]);
    const drawCallback = useCallback(draw, [eyeDropper, drawing, position, currentDraw]);
    const startDrawingCallback = useCallback(startDrawing, [eyeDropper]);
    const mouseOverCallback = useCallback(mouseOverCanvas, []);

    useEffect(() => {
        const { current: canvas } = canvasRef;
        if (!canvas) {
            return;
        }
        canvas.addEventListener('mouseover', mouseOverCallback);
        canvas.addEventListener('mousedown', startDrawingCallback);
        canvas.addEventListener('mousemove', drawCallback);
        canvas.addEventListener('mouseup', stopDrawCallback);
        canvas.addEventListener('mouseleave', stopDrawCallback);
        canvas.addEventListener('touchstart', startDrawingCallback);
        canvas.addEventListener('touchmove', drawCallback);
        canvas.addEventListener('touchend', stopDrawCallback);
        return () => {
            canvas.removeEventListener('mouseover', mouseOverCallback);
            canvas.removeEventListener('mousedown', startDrawingCallback);
            canvas.removeEventListener('mousemove', drawCallback);
            canvas.removeEventListener('mouseup', stopDrawCallback);
            canvas.removeEventListener('mouseleave', stopDrawCallback);
            canvas.removeEventListener('touchstart', startDrawingCallback);
            canvas.removeEventListener('touchmove', drawCallback);
            canvas.removeEventListener('touchend', stopDrawCallback);
        };
    }, [
        startDrawingCallback,
        drawCallback,
        stopDrawCallback,
        mouseOverCallback,
    ]);

    return (
        <div style={{ textAlign: 'center' }}>
            <Button
                variant="success"
                style={{ width: `${CanvasWidth - 50}px` }}
                onClick={() => {
                    onSend(draws);
                }}
            >
                Send
                <FontAwesomeIcon
                    icon={faPaperPlane}
                    style={{
                        marginLeft: '4px'
                    }}
                />
            </Button>
            <div style={{
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}>
                <div style={{
                    marginTop: '5px',
                    marginRight: '3px',
                }}>
                    <ButtonGroup vertical={true}>
                        <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={undo}
                        >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>
                        <Button
                            size="sm"
                            variant="outline-primary"
                            active={eyeDropper}
                            color={eyeDropper ? hoverColor : 'inherit'}
                            onClick={() => {
                                setEyeDropper(!eyeDropper);
                            }}
                        >
                            <FontAwesomeIcon icon={faEyeDropper} />
                        </Button>
                        <Button
                            size="sm"
                            variant="outline-primary"
                            active={brush}
                            onClick={() => {
                                setBrush(!brush);
                            }}
                        >
                            <FontAwesomeIcon icon={faBrush} />
                        </Button>
                        <Button
                            size="sm"
                            variant="outline-primary"
                            active={eraser}
                            onClick={() => {
                                setEraser(!eraser);
                            }}
                        >
                            <FontAwesomeIcon icon={faEraser} />
                        </Button>
                    </ButtonGroup>
                </div>
                <svg
                    ref={canvasRef}
                    style={{
                        marginTop: '5px',
                        border: 'solid 1px #CCC',
                        width: `${CanvasWidth}px`,
                        height: `${CanvasHeight}px`,
                        cursor: eyeDropper ? 'crosshair' : 'auto',
                    }}
                    width={CanvasWidth * window.devicePixelRatio}
                    height={CanvasHeight * window.devicePixelRatio}
                >
                    {drawsToSVGElements(draws, setHoverColorOnMouseOver)}
                    {drawToSVGElement(
                        {
                            color: eraser ? 'rgb(255,255,255)' : hslFor(hue, saturation, brightness),
                            lineWidth: brush ? BrushSize : lineWidth,
                            path: currentDraw,
                        },
                        'drawcurrent',
                        setHoverColorOnMouseOver
                    )}
                    <circle
                        display={(hover && !eyeDropper) ? undefined : 'none'}
                        r={(brush ? BrushSize : lineWidth) / 2}
                        cx={`${position?.x}px`}
                        cy={`${position?.y}px`}
                        fill={eraser ? 'rgb(255,255,255)' : hslFor(hue, saturation, brightness)}
                    />
                </svg>
                <div
                    style={{
                        marginTop: '5px',
                        marginLeft: '3px',
                    }}
                >
                    <HuePicker
                        onPick={(hue: number) => {
                            setHue(hue);
                            setBrightness(50);
                        }}
                        saturation={saturation}
                    />
                    <SaturationPicker
                        hue={hue}
                        onPick={(saturation: number) => {
                            setSaturation(saturation);
                            setBrightness(50);
                        }}
                    />
                    <BrushSizePicker
                        onPick={(size: number) => {
                            setLineWidth(size);
                        }}
                        color={hslFor(hue, saturation, brightness)}
                    />
                    <div style={{ marginTop: '10px'}}>
                        <div
                            style={{
                                borderRadius: '5px',
                                height: '30px',
                                backgroundColor: 'hsl(0, 0%, 0%)',
                            }}
                            onClick={() => {
                                setBrightness(0);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SVGCanvas;

export const StaticSVGCanvas: React.FC<StaticCanvasProps> = ({ draws }) => {
    return (
        <div>
            <div
                style={{
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                <svg
                    style={{
                        marginTop: '5px',
                        border: 'solid 1px #CCC',
                        width: `${CanvasWidth}px`,
                        height: `${CanvasHeight}px`,
                    }}
                    width={CanvasWidth * window.devicePixelRatio}
                    height={CanvasHeight * window.devicePixelRatio}
                >
                    {drawsToSVGElements(draws)}
                </svg>
            </div>
        </div>
    )
}

const drawsToSVGElements = (draws?: Draw[], onMouseOver?: MouseEventHandler<SVGPathElement>) => {
    return (draws || []).map((draw, index) => {
        return drawToSVGElement(draw, `draw${index}`, onMouseOver);
    })
}

const drawToSVGElement = (draw: Draw, key: string, onMouseOver?: MouseEventHandler<SVGPathElement>) => {
    return (
        <path
            key={key}
            strokeWidth={`${draw.lineWidth}px`}
            fill="none"
            stroke={draw.color}
            strokeLinejoin="round"
            strokeLinecap="round"
            d={drawPathToSVGD(draw.path)}
            onMouseOver={onMouseOver}
        />
    );
}

const drawPathToSVGD = (positions: [Position, Position][]) => {
    const pathPositions = positions.map(position => {
        const endingPosition = position[1];
        return `${endingPosition.x} ${endingPosition.y}`;
    });
    const firstPosition = positions.length > 0 ? positions[0][0] : { x: 0, y: 0 };
    return `M${firstPosition.x} ${firstPosition.y} L${pathPositions.join(' L')}`;
}