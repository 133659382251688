import { PathLayer } from '@deck.gl/layers';

export default class TerrainPathLayer extends PathLayer<any> {
    shouldUpdateState({props, oldProps, context, changeFlags}: any) {
        const superShouldUpdateState = super.shouldUpdateState({props, oldProps, context, changeFlags});
        if (
            (props.calculateHeight !== oldProps.calculateHeight) ||
            (props.zoom !== oldProps.zoom) ||
            superShouldUpdateState
        ) {
            // console.log('zoom change or height on (or normal shouldUpdateState)', props.zoom, oldProps.zoom, superShouldUpdateState, '\nflags', changeFlags);
            return true;
        }
        return false;
    }
}

(TerrainPathLayer as any).layerName = 'TerrainPathLayer';