import DeckGl, { BitmapLayer, Layer, MapView, TileLayer } from "deck.gl";
import { useEffect, useState } from "react";
import { GeolocateControl, NavigationControl, _MapContext } from "react-map-gl";
import { GetMapboxTileUrl, getURLFromTemplate, MapboxStyle } from "src/lib/Utils/Maps";
import { Tile } from "src/types/deck.gl";
import { load } from '@loaders.gl/core';
import { MAPBOX_ACCESS_TOKEN } from "../Running/Common";
import { ProjectionUtils } from "../SlopeAngle/SlopeAngleLayer/Shaders/fragment.glsl";
import { TERRAIN_TILE_URL } from "../SlopeAngle/SlopeAngleLayer/Tests/SlopeAngleThree";
import { CollapsingSideMenu } from "src/components/CollapsingSideMenu";
import { Container, Row, Col } from "react-bootstrap";
import { Range } from "react-range";
import { LayerToggle } from "../Running/MapboxUtils/LayerToggle";
import { faMap } from "@fortawesome/free-solid-svg-icons";

const RenderColoredElevatonTileLayer = (props: any) => {
    const {
        bbox: {west, south, east, north},
    } = props.tile;
    const [terrain, image] = props.data;

    return new ElevationColorsLayer(props, {
        data: null,
        image,
        terrain,
        bounds: [west, south, east, north],
    }) as any;
}

const GetMapboxTileUrlWithAccessToken = (mapboxStyle: MapboxStyle) =>
    `${GetMapboxTileUrl(mapboxStyle)}/tiles/512/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`

const ElevationHighlight: React.FC = () => {
    const [layers, setLayers] = useState<Layer<any,any>[]>([]);
    const [groundTexture, setGroundTexture] = useState(MapboxStyle.MONOCHROME);
    const [minimumHighlight, setMinimumHighlight] = useState(1000);
    const [maximumHighlight, setMaximumHighlight] = useState(8900);

    useEffect(() => {
        setLayers([
            new TileLayer({
                id: 'ElevationHighlight',
                data: '',
                maxZoom: 15,
                visible: true,
                renderSubLayers: RenderColoredElevatonTileLayer,
                getTileData: (tile: Tile) => {
                    const { z, x, y } = (tile as any).index;
                    const terrain = TERRAIN_TILE_URL(z, x, y);
                    const textureUrl = getURLFromTemplate(
                        GetMapboxTileUrlWithAccessToken(groundTexture),
                        (tile as any).index
                    );

                    return Promise.all([
                        load(terrain, []).catch(_ => null),
                        textureUrl ? load(textureUrl, []).catch(_ => null) : Promise.resolve(null)
                    ]);
                },
                updateTriggers: { getTileData: [groundTexture] },
                maximumHighlight,
                minimumHighlight,
            } as any),
        ]);
    }, [groundTexture, minimumHighlight, maximumHighlight]);

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
            <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                <DeckGl
                    controller={{
                        inertia: true,
                        touchRotate: true,
                    }}
                    ContextProvider={_MapContext.Provider as any}
                    initialViewState={{
                        bearing: 0,
                        pitch: 0,
                        latitude: 0,
                        longitude: 0,
                        zoom: 0,
                        maxZoom: 15,
                    }}
                    layers={layers}
                    views={[new MapView({repeat: true})]}
                >
                    <div style={{position: 'absolute', right: 0, margin: '10px 10px 0px 0px' }}>
                        <div>
                            <NavigationControl style={{ position: 'inherit' }} />
                            <div style={{ marginTop: '10px' }}>
                                <GeolocateControl
                                    style={{ position: 'inherit' }}
                                    positionOptions={{ enableHighAccuracy: true }}
                                    trackUserLocation={true}
                                    showUserLocation={true}
                                />
                            </div>
                        </div>
                        <div className="mapboxgl-ctrl mapboxgl-ctrl-group" style={{ marginTop: '8px' }}>
                            <LayerToggle
                                onClick={() => {
                                    setGroundTexture(groundTexture === MapboxStyle.SATELLITE ? MapboxStyle.MONOCHROME : MapboxStyle.SATELLITE)
                                }}
                                icon={faMap}
                            />
                        </div>
                    </div>
                </DeckGl>
            </div>
            <CollapsingSideMenu
                isOpen={true}
            >
                <Container style={{ color: '#CCC' }}>
                    <Row>
                        <Col sm={12}>
                            <h4>Elevation Highlight: <small>{minimumHighlight}m - {maximumHighlight}m</small></h4>
                            <div style={{ width: '100%' }}>
                                <Range
                                    step={25}
                                    min={0}
                                    max={8900}
                                    values={[minimumHighlight, maximumHighlight]}
                                    onChange={(values: number[]) => {
                                        setMinimumHighlight(values[0]);
                                        setMaximumHighlight(values[1]);
                                    }}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            {...props}
                                            style={{
                                            ...props.style,
                                            height: '6px',
                                            width: '100%',
                                            marginTop: '3px',
                                            backgroundColor: '#ccc',
                                            borderRadius: '4px',
                                            }}
                                        >
                                            {children}
                                        </div>
                                        )}
                                    renderThumb={({ props }) => (
                                        <div
                                            {...props}
                                            style={{
                                                ...props.style,
                                                height: '12px',
                                                width: '12px',
                                                borderRadius: '4px',
                                                backgroundColor: '#999',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </CollapsingSideMenu>
        </div>
    );
}

export default ElevationHighlight;

class ElevationColorsLayer extends BitmapLayer<any> {
    constructor(props: any, moreProps: any) {
        super({...props, ...moreProps});
    }

    getShaders() {
        return Object.assign({}, super.getShaders(), { fs: ElevationColorsFragmentShader });
    }

    draw(opts: any) {
        const { uniforms, moduleParameters } = opts;
        const {
            terrain,
            minimumHighlight,
            maximumHighlight
        } = (this as any).props;
        super.draw({
            moduleParameters,
            uniforms: {
                ...uniforms,
                terrain,
                minimumHighlight,
                maximumHighlight,
            },
        });
    }
}

(ElevationColorsLayer as any).defaultProps = {
    terrain: { type: 'image', value: null, async: true },
};
(ElevationColorsLayer as any).layerName = 'ElevationColorsLayer';

const ElevationColorsFragmentShader = `
#define SHADER_NAME bitmap-layer-fragment-shader
#ifdef GL_ES
precision highp float;
#endif
uniform sampler2D bitmapTexture;
uniform sampler2D terrain;
uniform float coordinateConversion;
varying vec2 vTexCoord;
varying vec2 vTexPos;
uniform vec4 bounds;
uniform float minimumHighlight;
uniform float maximumHighlight;

${ProjectionUtils}

vec2 getUV(vec2 pos) {
    return vec2(
      (pos.x - bounds[0]) / (bounds[2] - bounds[0]),
      (pos.y - bounds[3]) / (bounds[1] - bounds[3])
    );
}

float unpackElevation(vec3 e) {
    return (e.r * 6553.6) +
        (e.g * 25.6) +
        (e.b * 0.1) -
        10000.0;
}

void main(void) {
    vec4 color = vec4(0.0, 0.0, 1.0, 1.0);
    vec4 maxColor = vec4(1.0, 1.0, 1.0, 1.0);

    vec2 uv = vTexCoord;
    if (coordinateConversion < -0.5) {
        vec2 lnglat = mercator_to_lnglat(vTexPos);
        uv = getUV(lnglat);
    } else if (coordinateConversion > 0.5) {
        vec2 commonPos = lnglat_to_mercator(vTexPos);
        uv = getUV(commonPos);
    }
    vec4 bitmapColor = texture2D(bitmapTexture, uv);
    vec4 terrainColor = texture2D(terrain, uv);
    float elevation = unpackElevation(terrainColor.rgb * 256.0);

    float highlightRange = maximumHighlight - minimumHighlight;
    vec4 elevationColor = mix(color, maxColor, (elevation - minimumHighlight) / highlightRange);
    float elevationMix = min(
        smoothstep(minimumHighlight - 100.0, minimumHighlight, elevation) * 0.5,
        smoothstep(maximumHighlight + 100.0, maximumHighlight, elevation) * 0.5
    );
    bitmapColor = mix(bitmapColor, elevationColor, elevationMix);

    gl_FragColor = bitmapColor;
    geometry.uv = uv;
}
`;