import * as React from 'react';
import './fullScreenMenu.css';

interface Props {
    isOpen: boolean;
    onMenuToggle: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    backgroundColor?: string;
    noOpenButton?: boolean;
}

export default class FullScreenMenu extends React.Component<Props> {
    public render() {
        const menuStyle: React.CSSProperties = this.props.isOpen 
        ? {
            width: '100%',
            height: '100%',
            left: 0,
            paddingTop: '20px',
            overflowY: 'auto',
        }
        : {};

        return (
            <div
                style={{
                    zIndex: 100,
                    width: 0,
                    height: 0,
                    position: 'fixed',
                    top: 0,
                    left: '-2000px',
                    backgroundColor: this.props.backgroundColor || 'rgba(14, 14, 14, .9)',
                    transition: '0.3s',
                    ...menuStyle
                }}
            >
                <div
                    onClick={this.props.onMenuToggle}
                    id="menuButton"
                    className={this.props.isOpen ? 'open' : ''}
                    style={{
                        display: (!this.props.noOpenButton || this.props.isOpen) ? undefined : 'none'}}
                >
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                </div>
                {this.props.children}
            </div>
        );
    }
}