import { createShadowPlane, MeterDistanceBetweenLatitudeLongitude, GetMapboxTileUrl, MakePlaneBufferGeometry, MapboxStyle, tile2lat, tile2long } from "src/lib/Utils/Maps";
import { SlopeAngleFragmentShader } from "src/lib/Utils/Shaders/Fragment";
import { SlopeAngleVertexShader } from "src/lib/Utils/Shaders/Vertex";
import * as THREE from "three";
import { OrbitControls } from "../../../RouteVisualizer/OrbitControls";
import { MAPBOX_ACCESS_TOKEN } from "../../../Running/Common";

const DEFAULT_RENDER_DIMENSION = 1000;
const DEFAULT_ZOOM = 15;
const TILE_HEIGHTMAP_DIMENSION = 256;
const MATERIAL_DIMENSION = 512;

const TEMP_TILE_X = 5359;
const TEMP_TILE_Y = 11426;
const MAX_VERTEX_HEIGHT = 6263.47;
const MIN_VERTEX_HEIGHT = -418;
export const TERRAIN_TILE_URL = (z: number|string, x: number|string, y: number|string) => {
    return `https://api.mapbox.com/v4/mapbox.terrain-rgb/${z}/${x}/${y}.pngraw?access_token=${MAPBOX_ACCESS_TOKEN}`;
}
const TEXTURE_TILE_URL = (styleUrl: string, dimension: number, z: number, x: number, y: number) => {
    return `${styleUrl}/tiles/${dimension}/${z}/${x}/${y}/?access_token=${MAPBOX_ACCESS_TOKEN}`;
}
const DEFAULT_BACKGROUND_COLOR = 0xB3B3B3;

export class SlopeAngleThree {
    private imageLoader = new THREE.ImageLoader();
    private canvasBuffer = document.createElement('canvas');
    private canvasBufferContext = this.canvasBuffer.getContext('2d');
    private planeCanvas = document.createElement('canvas');
    private planeCanvasContext = this.planeCanvas.getContext('2d');
    private planeTexture = new THREE.Texture(this.planeCanvas);
    private planeGeometry = new THREE.BufferGeometry();
    private renderDimension = DEFAULT_RENDER_DIMENSION;
    private zoom = DEFAULT_ZOOM;
    private parentElement: HTMLElement|null = null;
    private renderer = new THREE.WebGLRenderer({
        antialias: true,
    });
    private camera = new THREE.PerspectiveCamera(45, 1, 1, 10000);
    private scene = new THREE.Scene();
    private backgroundColor = new THREE.Color(DEFAULT_BACKGROUND_COLOR);
    private controls = new OrbitControls(this.camera, this.renderer.domElement);
    private tileInformation = {
        heightSum: 0,
    };

    private bufferMaterial = new THREE.ShaderMaterial({
        uniforms: {
            masterTexture: {
                value: this.planeTexture,
            },
            slopeColorTexture: {
                value: new THREE.TextureLoader().load('./img/textures/slopeColor.png'),
            },
        },
        vertexShader: SlopeAngleVertexShader,
        fragmentShader: SlopeAngleFragmentShader,
        transparent: true,
    })

    constructor() {
        this.imageLoader.crossOrigin = '';
        this.camera.position.y = 900;
        this.camera.position.z = 0;
        this.camera.lookAt(new THREE.Vector3(0,0,0));
        this.scene.background = this.backgroundColor;

        this.controls.maxPolarAngle = Math.PI/2;
        this.controls.rotateSpeed = 1.0;
        this.controls.zoomSpeed = 1.2;
        this.controls.panSpeed = 0.8;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.15;

        window.addEventListener('resize', this.resize, false);
    }

    private animate = () => {
        this.controls.update();
        requestAnimationFrame(this.animate);
        this.renderer.render(this.scene, this.camera);
    }

    public deconstruct = () => {
        window.removeEventListener('resize', this.resize);
    }

    public resetScene = () => {
        this.scene.children.forEach(v => this.scene.remove(v));
    }

    public setData = () => {
        this.planeCanvas.width = MATERIAL_DIMENSION;
        this.planeCanvas.height = MATERIAL_DIMENSION;
    }

    public createScene = async () => {
        MakePlaneBufferGeometry(
            DEFAULT_RENDER_DIMENSION,
            DEFAULT_RENDER_DIMENSION,
            TILE_HEIGHTMAP_DIMENSION,
            TILE_HEIGHTMAP_DIMENSION,
            this.planeGeometry
        );
        const plane = new THREE.Mesh(this.planeGeometry, this.bufferMaterial);
        plane.rotation.x = -Math.PI / 2;
        this.scene.add(plane);

        this.imageLoader.load(
            TEXTURE_TILE_URL(GetMapboxTileUrl(MapboxStyle.TOPOGRAPHIC), MATERIAL_DIMENSION, this.zoom, TEMP_TILE_X, TEMP_TILE_Y),
            this.processTexture,
            undefined,
            undefined
        );
        this.imageLoader.load(
            TERRAIN_TILE_URL(this.zoom, TEMP_TILE_X, TEMP_TILE_Y),
            this.processHeightmap,
            undefined,
            undefined,
        )
    }

    private processTexture = (image: HTMLImageElement) => {
        if (this.planeCanvasContext) {
            this.planeCanvasContext.drawImage(image, 0, 0);
            this.planeTexture.needsUpdate = true;
        }
    }

    private processHeightmap = (image: HTMLImageElement) => {
        this.canvasBuffer.width = image.width;
        this.canvasBuffer.height = image.height;
        this.canvasBufferContext?.drawImage(image,0,0);
        const pix = this.canvasBufferContext?.getImageData(0, 0, image.width, image.height).data || [];

        const wrapOffset = 0;
        
        // Latitude Longitude bounding box for the tiles
        const northEdge = tile2lat(TEMP_TILE_Y, this.zoom)
        const westEdge  = tile2long(TEMP_TILE_X, this.zoom)
        const southEdge = tile2lat(TEMP_TILE_Y + 1 , this.zoom)

        // Get the latlong points for the furthest distance we'll cover
        const lat1 = northEdge;
        const lon1 = westEdge;
        const lat2 = southEdge;
        const lon2 = westEdge;

        const maxDistance = MeterDistanceBetweenLatitudeLongitude({ lat: lat1, lon: lon1 }, {lat: lat2, lon: lon2 }) / 1000;
        const renderScaleInMeters = 1 / maxDistance;
        const minRenderHeight = MIN_VERTEX_HEIGHT * renderScaleInMeters;
        let index = 0;
        let minVerticesHeight = MAX_VERTEX_HEIGHT;
        for (var pos = 0; pos < pix.length; pos +=4) {
            const r = pix[pos];
            const g = pix[pos + 1];
            const b = pix[pos + 2];
            const mapzen = -10000 + ((r * 256 * 256 + g * 256 + b) * 0.1)// TANGRAM, duh: (r * 256 + g + b / 256) - 32768
      
            var zBufferIndex = (index * 3) + 2
            var scaledHeight = mapzen * renderScaleInMeters
            if (scaledHeight < minRenderHeight){
                scaledHeight = minRenderHeight
            }
            if (scaledHeight < minVerticesHeight) {
                minVerticesHeight = scaledHeight;
            }
            (this.planeGeometry.attributes.position.array[zBufferIndex] as any) = scaledHeight;
            this.tileInformation.heightSum += this.planeGeometry.attributes.position.array[zBufferIndex];
      
            index += 1;
            if ((index % TILE_HEIGHTMAP_DIMENSION) === 0) {
              index += wrapOffset
            }
        }

        this.bufferMaterial.needsUpdate = true;
        this.bufferMaterial.uniforms.vCorrection = { value: minVerticesHeight };
        this.bufferMaterial.uniforms.renderVerticalScale = { value: renderScaleInMeters };
        this.bufferMaterial.uniforms.contourIncrement = { value: 50.0 };
        this.bufferMaterial.uniforms.contourIncrementHalf = { value: 50.0 / 2.0 };
        this.bufferMaterial.uniforms.contourIncrementSmall = { value: 10.0 };
        this.bufferMaterial.uniforms.contourIncrementSmallHalf = { value: 10.0 / 2.0 };
        this.bufferMaterial.uniforms.aspectColors = { value: [
            new THREE.Color(0x008837),
            new THREE.Color(0x9A98F5),
            new THREE.Color(0xF56F67),
            new THREE.Color(0xF5DE73),
        ] };
        // this.bufferMaterial.uniforms.widthMultiplier = { value: 0.2 * Math.pow(2., 20. - this.zoom) }
        // this.bufferMaterial.uniforms.contourColor;

        (this.planeGeometry.attributes.position as any).needsUpdate = true;
        // (this.planeGeometry.attributes.normals as any).needsUpdate = true;
        this.planeGeometry.computeVertexNormals();

        // console.log('three index:', this.planeGeometry.index?.array, '\nthree position', this.planeGeometry.attributes.position.array, '\nthree normals:', this.planeGeometry.attributes.normal.array);
        // console.log(THREE.BufferGeometry);
    }

    public initialize = (parentElementId: string) => {
        let parentElementMaybe = document.getElementById(parentElementId);
        if (!parentElementMaybe) {
            parentElementMaybe = document.createElement('div');
            parentElementMaybe.style.width = '100%';
            parentElementMaybe.style.height = '100%';
            parentElementMaybe.style.overflowY = 'hidden';
            parentElementMaybe.id = parentElementId;
            document.body.appendChild(parentElementMaybe);
        }
        this.parentElement = parentElementMaybe;
        this.resize();
        this.parentElement.appendChild(this.renderer.domElement);
        // TEMP
        this.setData();
        this.scene.add(createShadowPlane(0, 0, this.renderDimension, this.backgroundColor));
        this.createScene();
        this.animate();
    }

    private resize = () => {
        if (this.parentElement) {
            this.camera.aspect = this.parentElement.offsetWidth / this.parentElement.offsetHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.setSize(this.parentElement.offsetWidth, this.parentElement.offsetHeight);
        }        
    }
}