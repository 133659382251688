import React, { useCallback, useEffect, useState } from 'react';
import { Button, FormControl, FormGroup, InputGroup, Table } from 'react-bootstrap';
import Peer from 'peerjs';
import LogViewer, { Log, Severity } from 'src/projects/Drawgression/LogViewer';
import { GetTimestamp } from 'src/projects/Drawgression/Common';

const Client: React.FC = () => {
    const [logs, setLogs] = useState<Log[]>([]);
    const [peer] = useState(new Peer());
    const [dataConnection, setDataConnection] = useState<Peer.DataConnection>();
    const [inputId, setInputId] = useState<string>("");
    const [message, setMessage] = useState<any>("");

    const log = (message: string, severity?: Severity) => {
        setLogs((previousLogs) => {
            return previousLogs.concat({ message, time: GetTimestamp(), severity });
        });
    }

    const handleData = useCallback((data: any) => {
        log(`handling data: ${data}`);
        setMessage(data);
    }, []);

    const handlePeerConnection = useCallback((dc: Peer.DataConnection) => {
        log('handling peer connection');
        // console.log('data connection:', dc);
        setDataConnection(dc);
    }, []);

    useEffect(() => {
        peer.on('connection', handlePeerConnection);
        peer.on('close', () => {
            log('got a close', 'Warning');
        });
        peer.on('disconnected', () => {
            log('got a disconnection', 'Error');
        });
        peer.on('error', (err: any) => {
            log(`got an error: ${err}`, 'Error');
        });
        peer.on('open', (id: string) => {
            log(`got an open: ${id}`);
        })
        return () => {
            peer.disconnect();
        }
    }, [peer, handlePeerConnection]);

    useEffect(() => {
        if (!dataConnection) {
            return;
        }
        dataConnection.on('data', handleData);
        return () => {
            dataConnection.off('data', handleData);
        }
    }, [dataConnection, handleData]);

    const data = [
        { name: 'id', value: peer.id },
        { name: 'connections', value: Object.keys(peer.connections) },
        { name: 'input id to connect to:', value: (
            <FormGroup>
                <InputGroup>
                    <FormControl
                        as="input"
                        type="text"
                        value={inputId}
                        onChange={(e) => {
                            setInputId(e.currentTarget.value);
                        }}
                    />
                    <InputGroup.Append>
                        <Button
                            onClick={() => {
                                if (inputId) {
                                    setDataConnection(peer.connect(inputId, {}));
                                }                                
                            }}
                        >
                            Connect
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </FormGroup>
        ) },
        { name: 'send data:', value: (
            <input
                type="textarea"
                onChange={(e) => {
                    if (dataConnection) {
                        log(`trying to send ${e.currentTarget.value} to ${dataConnection.dataChannel.readyState}`)
                        dataConnection.send(e.currentTarget.value);
                    }
                }}
            />
        )},
        {
            name: 'Data recieved', value: (
                <pre>{JSON.stringify(message)}</pre>
            )
        }
    ]

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '75%',
                    overflowY: 'scroll',
                }}
            >
                <Table
                    striped={true}
                    size="sm"
                >
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(d => {
                            return (
                                <tr
                                    key={d.name}
                                >
                                    <td>{d.name}</td>
                                    <td>{d.value}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            <div
                style={{
                    width: '100%',
                    height: '25%',
                    overflowY: 'scroll',
                    backgroundColor: '#333',
                }}
            >
                <LogViewer
                    logs={logs}
                />
            </div>
        </div>
    );
}

export default Client;
