import * as React from 'react';
import { Container, Row, Col, ButtonGroup, Button, Form, ToggleButton, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faFire } from '@fortawesome/free-solid-svg-icons';
import { API_ROOT, GetActivityUrl, GetMultipleFromCacheJSON, GetFromCacheJSON, CACHE_NAME } from '../Running/Common';
import { ActivityStreamsData } from 'src/lib/Utils/Strava';
import SkiingHeatmap from './SkiingHeatmap';
import SkiingTopologicalMap from './SkiingTopologicalMap';
import { ShrinkingSideMenu } from 'src/components/ShrinkingSideMenu';
import { useRef, useState } from 'react';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SignIn from '../Running/SignIn';

const LOCATIONS_LIST = '/data/skiing/list.json';
const RESORTS_LIST = '/data/skiing/resorts.json';

const GetUserUrl = (userId: number, refresh?: boolean) => `${API_ROOT}/skiing/${userId}${refresh ? '?refresh=true' : ''}`;

export interface ResortBounds {
    north: number;
    east: number;
    south: number;
    west: number;
}

export interface UserData {
    area: number,
    name: string,
    date: string,
    id: number,
}

export interface SkiActivityData {
    id: number,
    data: ActivityStreamsData,
}

export interface ResortData {
    Region: number;
    bounds: ResortBounds;
    geo_lat: number;
    geo_lng: number;
    id: number;
    name: string;
    official_website: string;
}

interface UserDataMap {
    [locationKey: number]: UserData[];
}

const SkiingVisualizer: React.FC = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const [view, setView] = useState('Heatmap');
    const [locationSelected, setLocationSelected] = useState(-1);
    const [userDataMap, setUserDataMap] = useState<UserDataMap>({});
    const [locationData, setLocationData] = useState<SkiActivityData[]>([]);
    const [locations, setLocations] = useState<{[locationKey: number]: string}>({});
    const [resorts, setResorts] = useState<ResortData[]>([]);
    const [excludedIds, setExcludedIds] = useState<number[]>([]);
    const [athleteId] = useState(() => {
        const queryAthleteId = query.get('athlete');
        if (queryAthleteId) {
            localStorage.setItem('athleteId', queryAthleteId);
        }
        const localStorageAthleteId = localStorage.getItem('athleteId');
        if (!localStorageAthleteId) {
            return null;
        }
        return parseInt(localStorageAthleteId);
    });
    const CacheStorage = useRef<Cache>();

    const loadLocationData = useCallback(async (selectedLocation: number, currentUserData: UserDataMap) => {
        if (!athleteId) {
            toast.error('No athelete Id found!');
            return;
        }
        const updatedUserLocationActivityData = currentUserData[selectedLocation];
        var t = toast.loading(`Loading activities for ${locations[selectedLocation]}`);
        if (!CacheStorage.current) {
            toast.error('No cache found!', { id: t });
            return;
        }

        const activityStreams = await GetMultipleFromCacheJSON(
            CacheStorage.current,
            updatedUserLocationActivityData.map(activityData =>
                GetActivityUrl(athleteId, activityData.id))
        );
        const updatedLocationData = updatedUserLocationActivityData.map((data, i) => {
            return {
                id: data.id,
                data: activityStreams[i],
            };
        });
        toast.success('Loaded activities!', { id: t });
        setLocationSelected(selectedLocation);
        setLocationData(updatedLocationData);
    }, [athleteId, locations]);

    const initialize = useCallback(async (refresh: boolean = false) => {
        CacheStorage.current = await window.caches.open(CACHE_NAME);
        var t = toast.loading('Loading location data...');
        if (!CacheStorage.current) {
            toast.error('No cache found!', { id: t });
            return;
        }
        const jsonData = await GetMultipleFromCacheJSON(CacheStorage.current, [
            LOCATIONS_LIST,
            RESORTS_LIST,
        ]);
        setLocations(jsonData[0]);
        setResorts(jsonData[1]);

        toast.loading('Loading user data...', { id: t });
        if (!athleteId) {
            toast.error('No athlete Id found', { id: t });
            return;
        }

        if (!athleteId) {
            return;
        }

        const url = GetUserUrl(athleteId, refresh);

        const userData: UserData[] = await GetFromCacheJSON(CacheStorage.current, url);
        const parsedUserData = userData.reduce((acc: { [area: number]: UserData[] }, item: UserData) => {
            if (!acc[item.area]) {
                acc[item.area] = [];
            }
            acc[item.area].push(item);
            return acc;
        }, {});

        toast.success('Loaded data!', { id: t });
        setUserDataMap(parsedUserData);
    }, [athleteId]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    const handleLocationChange = useCallback(async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        if (value === '') {
            return;
        }

        const locationSelected = parseInt(value);
        loadLocationData(locationSelected, userDataMap);
    }, [userDataMap, loadLocationData]);

    const clearUserCache = useCallback(async () => {
        if (!CacheStorage.current) {
            toast.error('No cache found!');
            return;
        }
        
        if (!athleteId) {
            toast.error('No athlete Id found!');
            return;
        }
        await CacheStorage.current.delete(GetUserUrl(athleteId));
        await CacheStorage.current.delete(GetUserUrl(athleteId, true));
        await initialize(true);
    }, [athleteId, initialize]);

    const refreshResorts = useCallback(async () => {
        if (!CacheStorage.current) {
            toast.error('No cache found!');
            return;
        }
        await CacheStorage.current.delete(API_ROOT);
        await CacheStorage.current.delete(LOCATIONS_LIST);
        await CacheStorage.current.delete(RESORTS_LIST);
        await initialize();
    }, [initialize]);

    const locationIdList = athleteId
        ? Object.keys(userDataMap)
        : [];
    locationIdList.unshift('');
    const resortSelected = (locationSelected !== -1) ? resorts.find(resort => resort.id === locationSelected) : null;

    return athleteId
        ? (<>
            <ShrinkingSideMenu>
                <Container>
                    <Row>
                        <Col
                            sm={12}
                            style={{
                                color: 'hsla(0, 100%, 100%, 0.89)',
                            }}
                        >
                            <h3>Visualization Options</h3>
                            <Form>
                                <Form.Group>
                                    <Form.Label>
                                        Visualization
                                    </Form.Label>
                                    <ButtonGroup
                                        toggle={true}
                                        style={{ marginLeft: '4px' }}
                                    >
                                        <ToggleButton
                                            type="radio"
                                            value="Heatmap"
                                            variant="secondary"
                                            checked={view === 'Heatmap'}
                                            onChange={() => setView('Heatmap')}
                                        >
                                            <FontAwesomeIcon icon={faFire} /> Heatmap
                                        </ToggleButton>
                                        <ToggleButton
                                            type="radio"
                                            value="3D"
                                            variant="secondary"
                                            checked={view === '3D'}
                                            onChange={() => setView('3D')}
                                        >
                                            <FontAwesomeIcon icon={faMap} /> 3D viewer
                                        </ToggleButton>
                                    </ButtonGroup>
                                    <Form.Text
                                        style={{
                                            display: (view === '3D') ? '' : 'none',
                                        }}
                                    >
                                        Mobile tip: Three-fingers to pan!
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        Location
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        size="sm"
                                        custom={true}
                                        onChange={handleLocationChange}
                                    >
                                        {
                                            locationIdList.map(locationId => {
                                                return (
                                                    <option
                                                        key={locationId}
                                                        value={locationId}
                                                    >
                                                        {locationId === '' ? 'Choose a location...' : locations[parseInt(locationId)] }
                                                    </option>
                                                );
                                            })
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        Activities
                                    </Form.Label>
                                    {
                                        athleteId &&
                                        userDataMap &&
                                        locationSelected !== -1 &&
                                        userDataMap[locationSelected] && (
                                            <ActivitiesTable
                                                userData={userDataMap[locationSelected]}
                                                onChange={(id: number) => {
                                                    const updatedExcludedIds = [...excludedIds];
                                                    const indexOf = updatedExcludedIds.indexOf(id);
                                                    if (indexOf >= 0) {
                                                        updatedExcludedIds.splice(indexOf, 1);
                                                    } else {
                                                        updatedExcludedIds.push(id);
                                                    }
                                                    setExcludedIds(updatedExcludedIds);
                                                }}
                                                excludedIds={excludedIds}
                                            />
                                        )
                                    }
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        Cache information
                                    </Form.Label>
                                    <div>
                                        {resorts.length} Resorts cached
                                        <Button
                                            variant="warning"
                                            size="sm"
                                            style={{ marginLeft: '8px' }}
                                            onClick={refreshResorts}
                                        >
                                            Refresh Resorts
                                        </Button>
                                    </div>
                                    {athleteId &&
                                        userDataMap &&
                                        (<div>
                                            <Button
                                                variant="warning"
                                                size="sm"
                                                style={{ marginLeft: '8px' }}
                                                onClick={clearUserCache}
                                            >
                                                Clear Cache
                                            </Button>
                                        </div>)
                                    }
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </ShrinkingSideMenu>
            {
                ((locationSelected !== -1) && resortSelected && locationData)
                    ? (view === 'Heatmap')
                        ? (
                            <SkiingHeatmap
                                resort={resortSelected}
                                activities={locationData.filter(activity => excludedIds.indexOf(activity.id) === -1)}
                            />
                        )
                        : (
                            <SkiingTopologicalMap
                                resort={resortSelected}
                                activities={locationData.filter(activity => excludedIds.indexOf(activity.id) === -1)}
                            />
                        )
                    : null
            }
        </>)
        : <SignIn/>;
}

export default SkiingVisualizer;

interface ActivitiesTableProps {
    userData: UserData[];
    onChange: (id: number) => void;
    excludedIds: number[];
}

const ActivitiesTable: React.FC<ActivitiesTableProps> = ({ userData, excludedIds, onChange }) => {
    const yearGroupings = userData.reduce(
        (a: { [key: number] : UserData[]}, i) => {
        const year = (new Date(i.date)).getFullYear();
        if (!a[year]) {
            a[year] = [];
        }
        a[year].push(i);
        return a;
    }, {});

    return (
        <>
            {Object.keys(yearGroupings).map(year => {
                const yearActivitiesSorted = yearGroupings[parseInt(year)]
                    .sort((a, b) => { return new Date(a.date).getTime() - new Date(b.date).getTime()})
                return (<div key={year}>
                    <h6>{year}</h6>
                    <Table size="sm">
                        <tbody>
                            {yearActivitiesSorted.map(userActivity => {
                                return (
                                    <tr
                                        key={userActivity.id}
                                    >
                                        <td>
                                            <Form>
                                                <Form.Group
                                                    style={{ marginBottom: '0px' }}
                                                >
                                                    <Form.Check
                                                        value={userActivity.id}
                                                        type="checkbox"
                                                        checked={!(excludedIds.indexOf(userActivity.id) >= 0)}
                                                        onChange={() => {
                                                            onChange(userActivity.id);
                                                        }}
                                                        label={userActivity.name}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </td>
                                        <td
                                            style={{ textAlign: 'right' }}
                                        >
                                            {new Date(userActivity.date).toLocaleDateString()}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>)
            })}
        </>
    );
}
