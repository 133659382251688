import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export type Severity =
    'Log' |
    'Warning' |
    'Error';

const SeverityColorMap: { [key in Severity]: string } = {
    Log: 'inherit',
    Error: '#F33',
    Warning: '#FF3',
};

export interface Log {
    time: string;
    message: string;
    severity?: Severity;
}

interface Props {
    logs: Log[];
}

const LogViewer: React.FC<Props> = ({ logs }) => {
    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <pre style={{ color: '#EEE' }}>
                        {logs.map((l, i) =>
                            <span key={i} style={{ color: SeverityColorMap[l.severity || 'Log' ] }}>
                                {`${l.time}: ${l.message}\n`}
                            </span>
                        )}
                    </pre>
                </Col>
            </Row>
        </Container>
    )
}

export default LogViewer;