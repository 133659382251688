import { faFilter, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

interface DateRangeSelectorProps {
    selectDateRange: (start: Date, end: Date) => void;
    resetDateRange: () => void;
};

const MONTH_DIVISIONS = 12;
export const MONTH_DEGREE = Math.PI / 6;
export const RADIANS_TO_DEGREES = 180 / Math.PI;

export const MONTH_SHORT_NAMES = Array.from(Array(MONTH_DIVISIONS).keys()).map(i => {
    return new Date((new Date()).setMonth(i)).toLocaleString('default', { month: 'short' });
});
export const MONTH_DAYS = Array.from(Array(12).keys()).map(i => {
    return (new Date(2020, i+1, 0)).getDate();
});

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({ selectDateRange, resetDateRange }) => {
    const Width = 400;
    const HalfWidth = Width / 2;
    const Margin = 20;
    const Radius = HalfWidth - Margin;
    const [rangeMiddle, setRangeMiddle] = useState(Math.PI / 2);
    const [range, setRange] = useState(Math.PI);
    const [draggingStartEnd, setDraggingStartEnd] = useState(false);
    const [draggingMiddle, setDraggingMiddle] = useState(false);
    const [resetDisabled, setResetDisabled] = useState(true);
    const startEndRef = useRef(draggingStartEnd);
    const middleRef = useRef(draggingMiddle);
    startEndRef.current = draggingStartEnd;
    middleRef.current = draggingMiddle;

    const startDateRef = useRef(new Date(2020, 9, 1));
    const endDateRef = useRef(new Date(2020, 2, 31));

    useEffect(() => {
        const start = rangeMiddle + (range / 2);
        const end = rangeMiddle - (range / 2);
        const rangeStartMod = start % MONTH_DEGREE;
        const rangeEndMod = end % MONTH_DEGREE;
        const rangeStartFloor = (2 - Math.floor(start / MONTH_DEGREE)) % 12;
        const rangeEndFloor = (2 - Math.floor(end / MONTH_DEGREE)) % 12;
        const startMonth = rangeStartFloor < 0 ? (rangeStartFloor + 12) : rangeStartFloor;
        const endMonth = rangeEndFloor < 0 ? (rangeEndFloor + 12) : rangeEndFloor;
        const startModNormalized = (start < 0 ? (MONTH_DEGREE - Math.abs(rangeStartMod)) : rangeStartMod) / MONTH_DEGREE;
        const endModNormalized = (end < 0 ? (MONTH_DEGREE - Math.abs(rangeEndMod)) : rangeEndMod) / MONTH_DEGREE;
        const startDay = Math.ceil(MONTH_DAYS[startMonth] * (1 - startModNormalized));
        const endDay = Math.ceil(MONTH_DAYS[endMonth] * (1 - endModNormalized));

        startDateRef.current.setMonth(startMonth);
        startDateRef.current.setDate(startDay);
        endDateRef.current.setMonth(endMonth);
        endDateRef.current.setDate(endDay);
    }, [range, rangeMiddle]);

    const handleDrag = useCallback((event: React.MouseEvent<SVGElement, MouseEvent>) => {
        const target = event.currentTarget;
        const bbox = (target as SVGElement).getBoundingClientRect();
        const diffX = event.clientX - bbox.x;
        const diffY = event.clientY - bbox.y;
        const normalizedX = - (HalfWidth - ((diffX / target.clientWidth) * Width));
        const normalizedY = HalfWidth - ((diffY / target.clientHeight) * Width);
        const angle = Math.atan2(normalizedY, normalizedX);
        let updatedRange = Math.abs(rangeMiddle - angle) * 2
        if ((updatedRange / 2) > Math.PI) {
            updatedRange = ((Math.PI * 2) - (updatedRange / 2)) * 2;
        } 
        if (startEndRef.current) {
            const rangeToSet = Math.min(Math.PI * 2, updatedRange);
            setRange(rangeToSet);

        } else if (middleRef.current) {
            setRangeMiddle(angle);

        }
    }, [HalfWidth, rangeMiddle]);

    const rangeStart = rangeMiddle - (range / 2);
    const rangeEnd = rangeMiddle + (range / 2);
    const rangeStartX = HalfWidth + (Math.cos(rangeStart) * (Radius - Margin));
    const rangeStartY = HalfWidth - (Math.sin(rangeStart) * (Radius - Margin));
    const rangeEndX = HalfWidth + (Math.cos(rangeEnd) * (Radius - Margin));
    const rangeEndY = HalfWidth - (Math.sin(rangeEnd) * (Radius - Margin));
    const rangeMiddleX = HalfWidth + (Math.cos(rangeMiddle) * (Radius - Margin));
    const rangeMiddleY = HalfWidth - (Math.sin(rangeMiddle) * (Radius - Margin));

    return (
        <Container>
            <div style={{ width: '100%', height: '400px' }}>
                <h3
                    style={{
                        textAlign: 'center',
                        color: '#CCC',
                    }}
                >
                    Date Range
                </h3>
                <h4 style={{ color: '#CCC', textAlign: 'center', fontFamily: 'monospace' }}>
                    {startDateRef.current.toISOString().slice(5, 10)} - {endDateRef.current.toISOString().slice(5, 10)}
                </h4>
                <svg
                    width="100%"
                    height="350px"
                    viewBox={`0 0 ${Width} ${Width}`}
                    onMouseMove={handleDrag}
                    onMouseUp={() => {
                        setDraggingMiddle(false);
                        setDraggingStartEnd(false);
                    }}
                    onMouseLeave={() => {
                        setDraggingMiddle(false);
                        setDraggingStartEnd(false);
                    }}
                    style={{
                        // cursor: (draggingMiddle || draggingStartEnd) ? 'grabbing' : undefined
                    }}
                >
                    {MONTH_SHORT_NAMES.map((month, i) => {
                        const startingAngle = (Math.PI / 2) - (i * MONTH_DEGREE);
                        const endingAngle = startingAngle - MONTH_DEGREE;
                        const startX = Math.cos(startingAngle) * Radius;
                        const startY = Math.sin(startingAngle) * Radius;
                        const endX = Math.cos(endingAngle) * Radius;
                        const endY = Math.sin(endingAngle) * Radius;

                        return (
                            <g
                                key={i}
                            >
                                <path
                                    stroke="black"
                                    fill={`rgba(25, 128, 25, ${(i+1) / 12})`}
                                    d={`M ${HalfWidth} ${HalfWidth} l ${startX} ${-startY} a ${Radius} ${Radius} 0 0 1 ${endX - startX} ${-(endY - startY)} Z`}
                                />
                                <text
                                    textAnchor="middle"
                                    fill="#CCC"
                                    fontSize="18px"
                                    fontWeight="bold"
                                    transform={`translate(${HalfWidth + ((startX + endX) / 2)},${HalfWidth - ((startY + endY) / 2)}) rotate(${((MONTH_DEGREE * i) + (MONTH_DEGREE / 2)) * RADIANS_TO_DEGREES})`}
                                >
                                    {month}
                                </text>
                            </g>
                        );
                    })}
                    <g>
                        <path
                            fill="rgba(255, 255, 255, 0.8)"
                            d={`M ${HalfWidth} ${HalfWidth} L ${rangeStartX} ${rangeStartY} A ${Radius - Margin} ${Radius - Margin} 0 0 0 ${rangeMiddleX} ${rangeMiddleY}`}
                        />
                        <path
                            fill="rgba(255, 255, 255, 0.8)"
                            d={`M ${rangeMiddleX} ${rangeMiddleY} A ${Radius - Margin} ${Radius - Margin} 0 0 0 ${rangeEndX} ${rangeEndY} L ${HalfWidth} ${HalfWidth}`}
                        />
                        <circle
                            style={{ cursor: 'ew-resize' }}
                            r={10}
                            cx={rangeStartX}
                            cy={rangeStartY}
                            fill="#CCC"
                            onMouseDown={() => setDraggingStartEnd(true) }
                            onMouseUp={() => setDraggingStartEnd(false) }
                        >
                            ...
                        </circle>
                        <circle
                            style={{ cursor: 'ew-resize' }}
                            r={10}
                            cx={rangeEndX}
                            cy={rangeEndY}
                            fill="#CCC"
                            onMouseDown={() => setDraggingStartEnd(true) }
                            onMouseUp={() => setDraggingStartEnd(false) }
                        >
                            ...
                        </circle>
                        <circle
                            style={{ cursor: 'grab' }}
                            r={10}
                            cx={rangeMiddleX}
                            cy={rangeMiddleY}
                            fill="#CCC"
                            onMouseDown={() => setDraggingMiddle(true) }
                            onMouseUp={() => setDraggingMiddle(false) }
                        >
                            ...
                        </circle>
                    </g>
                </svg>
            </div>
            <Row>
                <Col sm={12}>
                    <Button
                        disabled={resetDisabled}
                        onClick={() => {
                            setResetDisabled(true);
                            resetDateRange();
                        }}
                        style={{ float: 'left' }}
                    >
                        <FontAwesomeIcon icon={faUndo} />
                        {` Reset`}
                    </Button>
                    <Button
                        onClick={() => {
                            setResetDisabled(false);
                            selectDateRange(startDateRef.current, endDateRef.current);
                        }}
                        style={{ float: 'right' }}
                    >
                        <FontAwesomeIcon icon={faFilter}/>
                        {` Filter`}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default DateRangeSelector;