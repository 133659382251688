import * as d3 from "d3";
import { TERRAIN_TILE_URL } from "src/projects/SlopeAngle/SlopeAngleLayer/Tests/SlopeAngleThree";
import { lat2tile, long2tile, MAPBOX_ELEVATION_DECODER, tile2lat, tile2long } from "./Maps";

const loadImage = (url: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = '';
    img.addEventListener('load', () => resolve(img));
    img.addEventListener('error', reject);
    img.src = url;
});

const ZXYToTileId = (z: number, x: number, y: number) => {
    return `${z}-${x}-${y}`;
}

interface TerrainTileInformation {
    northEdge: number;
    westEdge: number;
    latitudeIndexPerOffset: number;
    longitudeIndexPerOffset: number;
    data: Uint8ClampedArray;
}

export default class MapboxTerrainCache {
    private terrainCache: { [tile: string]: TerrainTileInformation } = {};
    private promises: { [tile: string]: Promise<any> } = {};

    handleTileLoaded = (image: HTMLImageElement, z: number, x: number, y: number) => {
        const tile = ZXYToTileId(z, x, y);
        const canvasBuffer = document.createElement('canvas');
        const context = canvasBuffer.getContext('2d');
        canvasBuffer.width = image.width;
        canvasBuffer.height = image.height;
        context?.drawImage(image, 0, 0);
        const data = context?.getImageData(0, 0, image.width, image.height).data;
        if (data) {
            const dataHeight = 256;
            const dataWidth = dataHeight;
            const northEdge = tile2lat(y, z);
            const eastEdge = tile2long(x + 1, z);
            const southEdge = tile2lat(y + 1, z);
            const westEdge = tile2long(x, z);

            const longDiff = eastEdge - westEdge
            const latDiff = northEdge - southEdge

            const latitudeIndexPerOffset = dataHeight / latDiff;
            const longitudeIndexPerOffset = dataWidth / longDiff;

            this.terrainCache[tile] = {
                data,
                northEdge,
                westEdge,
                latitudeIndexPerOffset,
                longitudeIndexPerOffset,
            };
        }
    }

    loadTile = (x: number, y: number, z: number) => {
        const tile = ZXYToTileId(z, x, y);
        if (this.promises[tile] === undefined) {
            this.promises[tile] = loadImage(TERRAIN_TILE_URL(z, x, y))
                .then((img: HTMLImageElement) => {
                    this.handleTileLoaded(img, z, x, y);
                })
                .catch(err => console.error(err));
        }
    }

    loadTilesForLatitudeLongitudePathAtZoom = (latitudeLongitudePath: [number, number][], zoom: number) => {
        const north = d3.max(latitudeLongitudePath, (l: number[]) => { return l[1] }) || 0;
        const south =  d3.min(latitudeLongitudePath, (l: number[]) => { return l[1] }) || 0;
        const west = d3.min(latitudeLongitudePath, (l: number[]) => { return l[0] }) || 0;
        const east =  d3.max(latitudeLongitudePath, (l: number[]) => { return l[0] }) || 0;

        const top     = lat2tile(north, zoom);
        const left    = long2tile(west, zoom);
        const bottom  = lat2tile(south, zoom);
        const right   = long2tile(east, zoom);

        const width   = Math.abs(left - right) + 1;
        const height  = Math.abs(top - bottom) + 1;

        for (var x = 0; x < width; x++) {
            for (var y = 0; y < height; y++) {
                const realX = x + left;
                const realY = y + top;
                this.loadTile(realX, realY, zoom);
            }
        }

        return Promise.all(Object.values(this.promises));
    }

    getAltitudeForZoomLatitudeLongitude = (zoom: number, latitude: number, longitude: number) => {
        const tileY = lat2tile(latitude, zoom);
        const tileX = long2tile(longitude, zoom);
        const tileId = ZXYToTileId(zoom, tileX, tileY);
        const tileInformation = this.terrainCache[tileId];
        if (tileInformation === undefined) {
            return 0;
        }
        const {
            northEdge,
            westEdge,
            data,
            latitudeIndexPerOffset,
            longitudeIndexPerOffset,
        } = tileInformation;
        const dataWidth = 256;

        const longOffset = Math.floor(
            Math.abs(
                westEdge - longitude
            ) * longitudeIndexPerOffset
        )
        const latOffset = Math.floor(
            Math.abs(
                northEdge - latitude
            ) * latitudeIndexPerOffset
        )
        const index = (
            (
                (latOffset * dataWidth) + longOffset
            ) * 4
        );

        const altitude = (data[index] * MAPBOX_ELEVATION_DECODER.rScaler +
            data[index + 1] * MAPBOX_ELEVATION_DECODER.gScaler +
            data[index + 2] * MAPBOX_ELEVATION_DECODER.bScaler) + MAPBOX_ELEVATION_DECODER.offset + 50;
        
        if (isNaN(altitude)) {
            return 0;
        }
        return altitude;
    }
}