import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { VariantForState, Player, IsConnected } from './Common';

interface Props {
    players: { [key: string]: Player}
    onKick?: (id: string) => void;
}

const PlayerStatus: React.FC<Props> = ({ players, onKick }) => {
    return (
        <Row>
            <Col sm={12}>
                <div style={{ width: '100%', display: 'flex' }}>
                    <h5>Players: 
                    {
                        Object.keys(players).map(playerId => {
                            const player = players[playerId];
                            const kickIcon = onKick && !IsConnected(player.state) && (
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{ marginLeft: '3px' }}
                                    inverse={true}
                                    onClick={() => {
                                        onKick(playerId);
                                    }}
                                />
                            );
                            return (
                                <Badge
                                    variant={VariantForState(player.state)}
                                    pill={true}
                                    key={playerId}
                                    style={{
                                        margin: '5px 5px',
                                    }}
                                >
                                    {player.name}
                                    {kickIcon}
                                </Badge>
                            );
                        })
                    }
                    </h5>
                </div>
            </Col>
        </Row>
    )
}

export default PlayerStatus;