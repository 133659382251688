import SimplyGraph from "../../lib/d3/SimplyGraph";

interface SavingsChartProps {
	data: Map<Date, Map<string,number>>;
}

const SavingsChart: React.FC<SavingsChartProps> = ({ data }) => {
	const dataPoints: [Date,number,number][] = Array.from(data.entries()).map(([date, entries]) => {
		const income = entries.get('Income');
		const expendituresFiltered = Array.from(entries.entries())
			.filter(([category,]) => category !== 'Income' &&
				category !== 'Investments' &&
				category !== 'Transfer' &&
				category !== 'Taxes');
		const expenditure = expendituresFiltered
			.reduce((accumulator, [,value]) => {
				return accumulator + value;
			}, 0);
		const savings = ((income || 0) + expenditure);
		const savingsRate = (income ? (savings / income) : 0) * 100;
		return [date, savingsRate, expenditure];
	});
	const average = dataPoints.reduce((a, [,value]) => a + value, 0) / dataPoints.length;
	return (
		<div style={{ width: '100%', height: '100%'}}>
			<SimplyGraph
				graphLines={[{
					dataPoints,
					name: 'Savings Rate',
				},{
					dataPoints: [
						[dataPoints[0][0],average],
						[dataPoints[dataPoints.length - 1][0],average]
					],
					name: 'Average'
				}]}
				yAccessor="1"
				xAccessor="0"
				yAxisFormat={(a: any) => `${a}%`}
				margins={{ top: 50, left: 80, bottom: 40, right: 100 }}
			/>
		</div>
	)
}

export default SavingsChart;