import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap';
import { TopologicalMap } from './TopologicalMap';
import { Object3D, PerspectiveCamera } from 'three';
import { RouteProps } from 'react-router-dom';
import { GetFromCacheJSON, GetActivityUrl } from '../Running/Common';
import { GetGeoJSONFromSingleStravaStreamData } from 'src/lib/Utils/GeoJSON';
import { CACHE_NAME } from '../Running/Common';
import RouteDetails from '../Running/MapboxUtils/RouteDetails';
import { GetDataFromFile, ParseDataByExtension } from 'src/lib/Utils/Maps';
import { getFileExtension } from 'src/lib/Utils/Strings';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ShrinkingSideMenu } from 'src/components/ShrinkingSideMenu';
import toast from 'react-hot-toast';

const RouteVisualizer: React.FC<RouteProps> = ({ location }) => {
    const [menuIsOpen, setMenuIsOpen] = useState(true);
    const [data, setData] = useState<any>(undefined);
    // const [fileName, setFileName] = useState<string>();
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const topologicalMap = useRef<TopologicalMap>();

    const handleFileUploadClick = useCallback(() => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    }, []);

    const handleFileUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        var t = toast.loading('Detecting uploaded file type...')
        setMenuIsOpen(false);

        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            let data = await GetDataFromFile(file);

            if (data) {
                topologicalMap.current?.resetScene();
                topologicalMap.current?.setData(data);
                topologicalMap.current?.createScene();
                // setFileName(file.name);
                setData(data);
                toast.success('File uploaded!', { id: t });
            } else {
                setMenuIsOpen(true);
                toast.error('Error uploading file! File extenson not recognized?', { id: t });
            }
        } else {
            setMenuIsOpen(true);
            toast.error('Error uploading file! No file found?', { id: t });
        }
    }, []);

    const loadDataForUrl = useCallback(async (url: string) => {
        var t = toast.loading('Loading data');
        const file = await (await fetch(url)).text();
        const extension = getFileExtension(url);
        const data = ParseDataByExtension(file, extension);

        if (data) {
            topologicalMap.current?.resetScene();
            topologicalMap.current?.setData(data);
            topologicalMap.current?.createScene();
            setMenuIsOpen(false);
            setData(data);
            toast.success('Data loaded!', { id: t });
        } else {
            setMenuIsOpen(true);
            toast.error(`Error loading activity from url ${url}`, { id: t });
        }
    }, []);

    const loadDataForActivityIdAndAthleteId = useCallback(async (activityId: string, athleteId: string) => {
        var t = toast.loading(`Loading activity data for ${activityId} (athleteId: ${athleteId})`);
        const CacheStorage = await window.caches.open(CACHE_NAME);
        const jsonData = await GetFromCacheJSON(CacheStorage, GetActivityUrl(parseInt(athleteId), parseInt(activityId)));
        const data = GetGeoJSONFromSingleStravaStreamData(jsonData);

        if (data) {
            topologicalMap.current?.resetScene();
            topologicalMap.current?.setData(data);
            topologicalMap.current?.createScene();
            setMenuIsOpen(false);
            setData(data);
            toast.success('Activity loaded!', { id: t });
        } else {
            setMenuIsOpen(true);
            toast.error(`Error loading activity ${activityId} for athlete ${athleteId}`, { id: t });
        }
    }, []);

    const animateTopologicalScene = useCallback(() => {
        topologicalMap.current?.controls?.update();
        /*if (this.topologicalMap.animateLines !== undefined && this.topologicalMap.animateLines !== false)
        {
            this.topologicalMap.animatedLines.forEach(line => {
                topologicalScene.animateRunLine(line, topologicalScene.minVerticesHeight, topologicalScene.planeGeometryWithShader.attributes.position.array)
            })
        }*/
        requestAnimationFrame(animateTopologicalScene);
        topologicalMap.current?.renderer?.render((topologicalMap.current.scene as Object3D), topologicalMap.current.camera as PerspectiveCamera);
        /*
        if (this.topologicalMap.statsVisible) {
            this.topologicalMap.stats?.update();
        }
        */
    }, []);

    useEffect(() => {
        topologicalMap.current = new TopologicalMap({
            'snapLinesToGeometry': true,
            'loading': null,
            'container': document.getElementById('renderCanvas')
        });
        topologicalMap.current.scaleZoom = true;
        animateTopologicalScene();
        if (location?.hash) {
            const hashLocation = location.hash.substring(1);
            if (hashLocation.startsWith('http')) {
                loadDataForUrl(hashLocation);
            } else {
                const hashParts = hashLocation.split('-');
                const activityId = hashParts[0];
                const athleteId = hashParts[1];
                setMenuIsOpen(false);
                loadDataForActivityIdAndAthleteId(activityId, athleteId);
            }
        }
    }, [animateTopologicalScene, loadDataForActivityIdAndAthleteId, loadDataForUrl, location?.hash]);

    return (
        <>
            <ShrinkingSideMenu
                forceMenuOpen={menuIsOpen}
            >
                <Container>
                    <Row>
                        <Col sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <button
                            type="button"
                            className="btn btn-sm btn-success"
                            onClick={handleFileUploadClick}>
                            <FontAwesomeIcon icon={faUpload} />
                            {' Upload A GPX / GeoJSON File'}
                        </button>
                        <input
                            type="file"
                            onChange={handleFileUpload}
                            ref={hiddenFileInput}
                            style={{ display: 'none' }}
                        />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            {
                                data && (
                                    <RouteDetails
                                        route={[{
                                            geometry: data.features[0].geometry,
                                            legs: [],
                                            weight_name: '',
                                            weight: 0,
                                            duration: 0,
                                            distance: 0,
                                        }]}
                                    />
                                )
                            }
                        </Col>
                    </Row>
                </Container>
            </ShrinkingSideMenu>
            <div id="renderCanvas" style={{ width: '100%', height: '100%', overflowY: 'hidden' }}></div>
        </>
    );
}

export default RouteVisualizer;
