import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import { GetMapboxStyleUrl, MapboxStyle } from 'src/lib/Utils/Maps';
import { CACHE_NAME } from "../Common";
import { HeatmapV3 } from "../HeatmapV3";
import { DataViewPropsBase } from "../types";

export const Heatmap: React.FC<DataViewPropsBase> = (props) => {
    return (
        <HeatmapWrapper
            Heatmap={HeatmapV3}
            {...props}
        />
    );
}

interface HeatmapWrapperProps extends DataViewPropsBase {
    Heatmap: React.FC<any>
    style?: MapboxStyle;
}

interface SkiingWrapperProps {
    style?: MapboxStyle;
    Heatmap: React.FC<any>;
}

export const HeatmapWrapper: React.FC<HeatmapWrapperProps | SkiingWrapperProps> = (props) => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const map = useRef<mapboxgl.Map>();
    const cache = useRef<Cache>();
    const [mapIsReady, setMapIsReady] = useState(false);
    const [cacheIsReady, setCacheIsReady] = useState(false);

    useEffect(() => {
        if (mapContainer.current && !map.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: GetMapboxStyleUrl(props.style || MapboxStyle.DARK),
                center: [0, 0],
                zoom: 3,
            });
            map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
            map.current.addControl(new mapboxgl.GeolocateControl({
                positionOptions: { enableHighAccuracy: true },
                trackUserLocation: true,
                showUserLocation: true,
            }), 'bottom-right');
            map.current.on('load', () => {
                setMapIsReady(true);
            });
            (async () => {
                cache.current = await window.caches.open(CACHE_NAME);
                setCacheIsReady(true);
            })();
            return () => {
                if (map.current) {
                    map.current.remove();
                    setMapIsReady(false);
                    setCacheIsReady(false);
                }
            }
        }
        return;
    }, [props.style]);

    const { Heatmap } = props;

    return (
        <>
            <div
                style={{ width: '100%', height: '100%' }}
                ref={mapContainer} 
            />
            {
                mapIsReady && map.current && 
                cacheIsReady && cache.current && (
                    <Heatmap
                        {...props}
                        map={map.current}
                        cache={cache.current}
                    />
                )
            }
        </>
    );
}