import React from 'react';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from '../lib/Utils/MediaQuery';

interface CollapsingSideMenuProps {
    isOpen: boolean;
    onClose?: () => void;
}

export const CollapsingSideMenu: React.FC<CollapsingSideMenuProps> = ({
    isOpen,
    onClose,
    children
}) => {
    const isMobile = useMediaQuery('(max-width: 500px)');

    return (
        <div
            style={{
                height: '100%',
                transition: 'all 200ms linear',
                position: 'absolute',
                backgroundColor: '#333',
                overflow: isOpen ? 'scroll': 'hidden',
                maxWidth: isMobile ? '100%' : '500px',
                minWidth: isMobile ? '100%' : '414px',
                top: 0,
                left: isOpen ? 0 : -1000
            }}
        >
            {children}
            {onClose && (
            <FontAwesomeIcon
                icon={faCaretSquareLeft}
                style={{
                    position: 'absolute',
                    right: 15,
                    top: 10,
                    cursor: 'pointer',
                }}
                inverse={true}
                onClick={onClose}
            />)}
        </div>
    );
}