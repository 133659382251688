import { BitmapLayer } from '@deck.gl/layers';
import { RGBColorToArray } from 'src/lib/Utils/Maps';
import { AngleToHue, CommonUniforms, MixLayers, ProjectionUtils, SlopeToHue, TrigonometryDefines } from './SlopeAngleLayer/Shaders/fragment.glsl';

// From deck.gl's BitmapLayer fragment shader
// https://github.com/visgl/deck.gl/blob/8.4-release/modules/layers/src/bitmap-layer/bitmap-layer-fragment.js
const packUVsIntoRGB = `
vec3 packUVsIntoRGB(vec2 uv) {
  // Extract the top 8 bits. We want values to be truncated down so we can add a fraction
  vec2 uv8bit = floor(uv * 256.);
  // Calculate the normalized remainders of u and v parts that do not fit into 8 bits
  // Scale and clamp to 0-1 range
  vec2 uvFraction = fract(uv * 256.);
  vec2 uvFraction4bit = floor(uvFraction * 16.);
  // Remainder can be encoded in blue channel, encode as 4 bits for pixel coordinates
  float fractions = uvFraction4bit.x + uvFraction4bit.y * 16.;
  return vec3(uv8bit, fractions) / 255.;
}
`;

const fs=`
#define SHADER_NAME bitmap-layer-fragment-shader
#ifdef GL_ES
precision highp float;
#endif
uniform sampler2D bitmapTexture;
uniform sampler2D normalBitmapTexture;
varying vec2 vTexCoord;
varying vec2 vTexPos;
uniform float desaturate;
uniform vec4 transparentColor;
uniform vec3 tintColor;
uniform float opacity;
uniform float coordinateConversion;
uniform vec4 bounds;

${CommonUniforms}

${ProjectionUtils}

// apply desaturation
vec3 color_desaturate(vec3 color) {
  float luminance = (color.r + color.g + color.b) * 0.333333333;
  return mix(color, vec3(luminance), desaturate);
}
// apply tint
vec3 color_tint(vec3 color) {
  return color * tintColor;
}
// blend with background color
vec4 apply_opacity(vec3 color, float alpha) {
  return mix(transparentColor, vec4(color, 1.0), alpha);
}
vec2 getUV(vec2 pos) {
  return vec2(
    (pos.x - bounds[0]) / (bounds[2] - bounds[0]),
    (pos.y - bounds[3]) / (bounds[1] - bounds[3])
  );
}
${packUVsIntoRGB}

${TrigonometryDefines}

${AngleToHue}

${SlopeToHue}

float CorrectRGNormal(float normal) {
    return (normal * -2.0) + 1.0;
}

float CorrectZNormal(float normal) {
    float scale = 4.0;
    float span = 1.0;
    float shift = scale - span;
    return ((normal * scale) - shift) / span;
}

void main(void) {
  vec2 uv = vTexCoord;
  if (coordinateConversion < -0.5) {
    vec2 lnglat = mercator_to_lnglat(vTexPos);
    uv = getUV(lnglat);
  } else if (coordinateConversion > 0.5) {
    vec2 commonPos = lnglat_to_mercator(vTexPos);
    uv = getUV(commonPos);
  }
  vec4 bitmapColor = vec4(1.0, 1.0, 1.0, 0.0); // texture2D(bitmapTexture, uv);
  vec3 normalData = texture2D(normalBitmapTexture, uv).rgb * 2.0 - 1.0;
  normalData = normalize(vec3(normalData.x, normalData.y, normalData.z * 0.5));

  vec3 normal = vec3(-normalData.r, -normalData.g, normalData.b);
  ${MixLayers('bitmapColor')}

  gl_FragColor = apply_opacity(color_tint(color_desaturate(bitmapColor.rgb)), bitmapColor.a * opacity);
  geometry.uv = uv;
  DECKGL_FILTER_COLOR(gl_FragColor, geometry);
  if (picking_uActive) {
    // Since instance information is not used, we can use picking color for pixel index
    gl_FragColor.rgb = packUVsIntoRGB(uv);
  }
}
`;

export default class SlopeAngleTileLayer extends BitmapLayer<any> {
    constructor(props: any, moreProps: any) {
        super({...props, ...moreProps});
    }

    getShaders() {
        return Object.assign({}, super.getShaders(), { fs });
    }

    draw(opts: any) {
        const { uniforms, moduleParameters } = opts;
        const {
            slopeMaskOpacity,
            slopeAspectOpacity,
            slopeAngleOpacity,
            aspectColors,
            slopeCutoffRange,
            slopeMaskEnabled,
            slopeAspectEnabled,
            slopeAngleEnabled,
            normal: normalBitmapTexture,
        } = (this as any).props;
        const colors = [
            RGBColorToArray(aspectColors['south']),
            RGBColorToArray(aspectColors['west']),
            RGBColorToArray(aspectColors['north']),
            RGBColorToArray(aspectColors['east'])
        ];
        
        super.draw({
          moduleParameters,
          uniforms: {
              ...uniforms,
              normalBitmapTexture,
              slopeMaskOpacity,
              slopeAspectOpacity,
              slopeAngleOpacity,
              aspectColors: Array.prototype.concat([], ...colors),
              slopeCutoffMin: (slopeCutoffRange[0] * (Math.PI / 180)),
              slopeCutoffMax: (slopeCutoffRange[1] * (Math.PI / 180)),
              slopeMaskEnabled,
              slopeAspectEnabled,
              slopeAngleEnabled,
          }
      });
    }
}

(SlopeAngleTileLayer as any).defaultProps = {
    normal: { type: 'image', value: null, async: true },
};
(SlopeAngleTileLayer as any).layerName = 'SlopeAngleTileLayer';
