import * as React from 'react';
import * as d3 from 'd3';

export default class Ella extends React.Component {
  private cursorSymbol = d3.symbol().size(300);
  private cursorRef = React.createRef<SVGPathElement>();
  private nodes: any[] = [];
  private graphRef = React.createRef<SVGSVGElement>();
  private graph: d3.Selection<any, {}, null, undefined>|null = null;
  private nodeSelection: d3.Selection<any, {}, any, {}>|null = null;
  private simulation = d3.forceSimulation()
    .force("x", d3.forceX())
    .force("y", d3.forceY())
    //.force("gravity", d3.forceManyBody().strength(30))
    .force("charge", d3.forceManyBody().strength(-30))
    .nodes(this.nodes)
    .alphaTarget(1)
    .stop()
  private color = () => '#'+Math.floor(Math.random()*16777215).toString(16);
  private currentColor = 'none';
  private mouse = [0,0];
  private still = 1;
  private scale = 1;
  private timestamp = Date.now();

  public state = {
    shape: 0,
  }

  public componentDidMount() {
    const body = document.body;
    const width = body.offsetWidth;
    const height = body.offsetHeight;
    this.graph = d3.select(this.graphRef.current);
    this.nodeSelection = this.graph.selectAll('.node');
    this.simulation
      .force("center", d3.forceCenter(width / 2, height / 2))
      .on('tick', this.updateNodes)
      .restart();
    this.mouse = [width / 2, height / 2];
    setInterval(this.checkStill, 5);
    setInterval(this.removeNodes, 1000);
    body.addEventListener('keypress', this.handleKeyPress);
    this.update();
  }

  private updateNodes = () => {
    this.nodeSelection?.attr("transform",
      (d: any) => `translate(${d.x},${d.y})scale(${d.scale})`
    )
  }

  public componentWillUnmount() {
    document.body.removeEventListener('keypress', this.handleKeyPress);
  }

  private update = () => {
    this.nodeSelection = this.nodeSelection?.data(this.nodes) || null;
    
    this.nodeSelection?.exit()
      .transition()
      .duration(750)
      .attr("scale", 0)
      .attr("font-size", 0)
      .style("fill-opacity", 0)
      .remove();
    
    const appended = this.nodeSelection?.enter().append((datum: any) =>
        document.createElementNS(d3.namespaces['svg'], (datum.text) ? 'text': 'path')
      )
      .attr('fill', (d: any) => d.color)
      .attr('class', 'node')

    appended?.filter((d: any) => d.text !== undefined)
      .text((d: any) => d.text || '')
      //.attr('font-size', `${50 + this.state.scale/10}px`)
      .attr('font-family', 'serif')
      .style('text-anchor', 'middle')
      .style('alignment-baseline', 'middle')

    appended?.filter((d: any) => !d.text)
      .attr('d', () => this.cursorSymbol(this.state.shape))
      //.attr('scale', () => this.state.scale)

    this.nodeSelection = appended?.merge(this.nodeSelection as any) || null;    

    this.simulation.nodes(this.nodes);
    this.simulation.alpha(1).restart();
  }

  public componentDidUpdate() {
    const el = d3.select(this.cursorRef.current);

    el.transition()
      .duration(750)
      .attr("d", () => this.cursorSymbol(this.state.shape))
      .style('fill', 'transparent')
      .attr('r', 30);
  }

  private checkStill = () => {
    if(!this.still && Date.now() - this.timestamp > 70){
      this.still = 1;
      this.scale = 1;
      this.dropBall();
    }
    this.update();
  }

  private removeNodes = () => {
    this.nodes.pop();
    this.update();
  }

  private dropLetter = (code: number) => {
    this.nodes.push({
      x: this.mouse[0],
      y: this.mouse[1],
      color: this.color(),
      text: String.fromCharCode(code),
      scale: this.scale,
    });
    this.update();
  }

  private dropBall = () => {
    this.nodes.push({
      x: this.mouse[0],
      y: this.mouse[1],
      color: this.color(),
      scale: this.scale,
    });
    this.update();
  }

  private handleMouseMove = (event: React.MouseEvent<SVGSVGElement>) => {
    const { timestamp, mouse, still } = this;
    const cursor = d3.select(this.cursorRef.current);
    const currentMouse = [event.clientX, event.clientY];
    const delta = {
      t: Date.now() - timestamp,
      x: (currentMouse[0] - mouse[0]),
      y: currentMouse[1] - mouse[1]
    };
    const speedX = Math.round(delta.x / delta.t * 1000);
    const speedY = Math.round(delta.y / delta.t * 1000);
    const updatedTimestamp = delta.t + timestamp;
    this.scale = (mouse[0] > -1) ?
      Math.sqrt(
        Math.pow(delta.x, 2) + Math.pow(delta.y, 2)
        ) / 10 :
      1;
    this.still = 0;

    if(Math.abs(speedX) > 125 || Math.abs(speedY) > 125){
      this.dropBall();
    } else {
      if (!still) {
        this.still = 1;
        this.scale = 0;
      }
    }
    cursor.attr('transform', `translate(${mouse[0]}, ${mouse[1]})scale(${this.scale})`)
      .style('fill', this.currentColor)

    this.mouse = currentMouse;
    this.timestamp = updatedTimestamp;
    this.update();
  }

  private handleKeyPress = (event: KeyboardEvent) => {
    if(event.keyCode === 32) {
      this.nodes.length = 0;
      this.setState({ shape: (this.state.shape) ? 0 : 3 });
      this.update();
    } else {
      this.dropLetter(event.keyCode);
    }
  }

  public render() {
    const {
      shape,
    } = this.state;

    return (
      <svg
        width='100%'
        height='100%'
        style={{ position: 'fixed' }}
        onMouseMove={this.handleMouseMove}
        ref={this.graphRef}
      >
        <path
          ref={this.cursorRef}
          className="cursor"
          style={{
            stroke: '#F00',
            pointerEvents: 'none'
          }}
          d={this.cursorSymbol(shape) || undefined}
        />
      </svg>
    );
  }
}