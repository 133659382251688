import React, { CSSProperties, ReactElement, useState } from 'react';
import { useMediaQuery } from '../lib/Utils/MediaQuery';

export interface MenuItem {
    icon: ReactElement;
    title: ReactElement;
    key: string;
    color: string;
    onClick: () => void;
}

interface ShrinkingSideMenuProps {
    menuItems?: MenuItem[];
    forceMenuOpen?: boolean;
}

export const ShrinkingSideMenu: React.FC<ShrinkingSideMenuProps> = ({
    menuItems = [],
    children,
    forceMenuOpen,
}) => {
    const [isOpen, setIsOpen] = useState(forceMenuOpen);
    const isMobile = useMediaQuery('(max-width: 500px)');
    const spanOpenProperties: CSSProperties = isOpen
    ? {
        width: '0%',
        left: '50%',
    } : {};
    const menuButtonProperties: CSSProperties = {
        width: '40px',
        height: '40px',
        position: 'fixed',
        left: 0,
        top: 0,
        margin: '10px 0px 0px 10px',
        cursor: 'pointer',
        zIndex: 10000,
    }
    const spanProperties: CSSProperties = {
        display: 'block',
        position: 'absolute',
        height: '5px',
        width: '100%',
        background: isOpen ? '#fbfbfb' : '#040404',
        borderRadius: '9px',
        opacity: 1,
        left: 0,
        transition: '.25s ease-in-out',
    };
    return (
        <div
            style={{
                zIndex: 100,
                minWidth: isOpen
                    ? isMobile ? '100%' : '380px'
                    : '0px',
                height: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                backgroundColor: isOpen ? 'rgba(51, 51, 51, .85)' : 'rgba(0, 0, 0, 0)',
                transition: '0.3s',
                overflowY: 'auto',
            }}
        >
            <div
                onClick={() => { setIsOpen(!isOpen) }}
                className={isOpen ? 'open' : ''}
                style={menuButtonProperties}
            >
                <span style={{
                    ...spanProperties,
                    top: isOpen ? '18px' : '0px',
                    ...spanOpenProperties,
                }}/>
                <span style={{
                    ...spanProperties,
                    top: '10px',
                    transform: isOpen ? 'rotate(45deg)' : 'rotate(0deg)',
                }}/>
                <span style={{
                    ...spanProperties,
                    top: '10px',
                    transform: isOpen ? 'rotate(-45deg)' : 'rotate(0deg)',
                }}/>
                <span style={{
                    ...spanProperties,
                    top: '20px',
                    ...spanOpenProperties,
                }}/>
            </div>
            <div
                style={{
                    marginTop: '50px',
                    left: isOpen ? 'inherit' : '-75px',
                    position: isOpen ? 'initial' : 'fixed',
                }}
            >
                {menuItems.map(menuItem => {
                    return (
                        <div
                            key={menuItem.key}
                            style={{
                                display: 'flex',
                                backgroundColor: menuItem.color,
                                height: '50px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setIsOpen(false);
                                menuItem.onClick();
                            }}
                        >
                            <div
                                style={{
                                    margin: '5px',
                                    minWidth: '50px',
                                    textAlign: 'center',
                                }}
                            >
                                {menuItem.icon}
                            </div>
                            {isOpen && (
                                <div
                                    style={{
                                        fontSize: '35px',
                                        marginRight: '5px',
                                    }}
                                >
                                    {menuItem.title}
                                </div>
                            )}
                        </div>
                    );
                })}
                {isOpen && children}
            </div>
        </div>
    );
}
