import { faClipboard, faExclamationTriangle, faTerminal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Peer from 'peerjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Button, Col, Container, Row } from 'react-bootstrap';
import FullScreenMenu from 'src/components/fullScreenMenu';
import { Draw } from './Canvas';
import ChainViewer from './ChainViewer';
import { APIBase, DataMessage, DescriptionMessage, DrawgressionData, GetTimestamp, ImageMessage, IsConnected, IsConnecting, Message, MessageData, MessageType, OrderMessage, Player, PlayersMessage, VariantForState } from './Common';
import Game from './Game';
import LogViewer, { Log, Severity } from './LogViewer';
import PlayerStatus from './PlayerStatus';

interface Props {
    room: string;
    username: string;
}

const Client: React.FC<Props> = ({ room, username }) => {
    const [logsOpen, setLogsOpen] = useState(false);
    const [logs, setLogs] = useState<Log[]>([]);
    const [actionId, setActionId] = useState<string>();
    const [state, setState] = useState<RTCIceConnectionState>('new');
    const [image, setImage] = useState<Draw[]>();
    const [description, setDescription] = useState<string>();
    const [waiting, setWaiting] = useState(true);
    const [gameData, setGameData] = useState<{[key: string]: DrawgressionData}>();
    const [order, setOrder] = useState<string[]>([]);
    const [players, setPlayers] = useState<{[key: string]: Player}>({});
    const [peer] = useState(new Peer());
    const [dataConnection, setDataConnection] = useState<Peer.DataConnection>();
    const [bail, setBail] = useState(false);

    const log = (message: string, severity?: Severity) => {
        setLogs((previousLogs) => {
            return previousLogs.concat({ message, time: GetTimestamp(), severity });
        });
    }

    const handleMessage = useCallback((message: Message) => {
        const { type, data } = message;
        log(`message: ${JSON.stringify(message, null, 2)}`);
        switch (type) {
            case MessageType.Start:
                setDescription(undefined);
                setGameData(undefined);
                let startMessage = (data as MessageData);
                setImage(undefined);
                setActionId(startMessage.id);
                setWaiting(false);
                break;
            case MessageType.Image:
                setDescription(undefined);
                let imageMessage = (data as ImageMessage);
                setImage(imageMessage.draws);
                setActionId(imageMessage.id);
                setWaiting(false);
                break;
            case MessageType.Description:
                setImage(undefined);
                var descriptionMessage = (data as DescriptionMessage);
                setDescription(descriptionMessage.description);
                setActionId(descriptionMessage.id);
                setWaiting(false);
                break;
            case MessageType.Data:
                setGameData((data as DataMessage).data);
                setDescription(undefined);
                setImage(undefined);
                setActionId(undefined);
                setWaiting(false);
                break;
            case MessageType.Order:
                setOrder((data as OrderMessage).order);
                break;
            case MessageType.Players:
                setPlayers((data as PlayersMessage).players);
                break;
        }
    }, []);

    useEffect(() => {
        peer.on('connection', (dc: Peer.DataConnection) => {
            log('Handling peer connection, why does this happen on the client?', 'Warning');
        });
        peer.on('close', () => {
            log('got a close', 'Warning');
            setState('closed');
        });
        peer.on('disconnected', () => {
            log('got a disconnection', 'Error');
            setState('disconnected');
        });
        peer.on('error', (err: any) => {
            log(`${err}`, 'Error');
        });
        peer.on('open', async (id: string) => {
            log(`peer open: ${id}, getting room id for: ${room}`);
            const { id: roomId } = await (await fetch(`${APIBase}/room/${room}`)).json() as { id: string };
            log(`got room id: ${roomId}`);
            if (!roomId) {
                log(`room id was null or undefined`, 'Error');
                return;
            }
            setDataConnection(peer.connect(roomId, {}));
        });

        return () => {
            peer.disconnect();
        }
    }, [peer, room]);

    useEffect(() => {
        if (!dataConnection) {
            return;
        }
        const handleData = (data: any) => {
            // log(`[${dataConnection.peer}] on data: ${data}`);
            handleMessage(JSON.parse(data));
        }
        dataConnection.on('open', () => {
            log('got data channel open, this likely should not happen on the client?', 'Warning');
            dataConnection.on('data', handleData);
            const nameMessage: Message = {
                type: MessageType.Name,
                data: username,
            }
            dataConnection.send(JSON.stringify(nameMessage));
            setState(dataConnection.peerConnection.iceConnectionState);
        });
        dataConnection.on('close', () => {
            log('got data channel close')
            dataConnection.off('data', handleData);
            setState('closed');
        });
        dataConnection.on('error', (err: any) => {
            log(`dataConnection error: ${JSON.stringify(err)}`);
        });
        return () => {
            dataConnection.off('data', handleData);
            setState(dataConnection.peerConnection.iceConnectionState);
        }
    }, [dataConnection, handleMessage, username])

    const gameOver = bail || (!image && !description && !waiting && gameData);
    return (
        <div style={{ height: '100%', width: '100%', overflow: gameOver ? 'scroll' : 'hidden'}}>
            <FullScreenMenu
                isOpen={logsOpen}
                onMenuToggle={() => {
                    setLogsOpen(false);
                }}
                noOpenButton={true}
            >
                <LogViewer logs={logs}/>
            </FullScreenMenu>
            <FontAwesomeIcon
                style={{ position: 'absolute', top: 10, right: 10 }}
                icon={faTerminal}
                onClick={() => {
                    setLogsOpen(true);
                }}
            />
            <FontAwesomeIcon
                style={{ position: 'absolute', top: 10, left: 10 }}
                icon={faExclamationTriangle}
                onClick={() => {
                    setBail(!bail);
                }}
            />
            <Container>
                <Row>
                    <Col sm={12}>
                        <h3>
                            Drawgression
                            <Button
                                style={{
                                    margin: '0px 4px'
                                }}
                                size="sm"
                                variant="info"
                                onClick={() => {
                                    navigator.clipboard.writeText(`${window.location}`);
                                }}
                            >
                                <FontAwesomeIcon icon={faClipboard} style={{ marginRight: '4px' }} />
                                #{room}
                            </Button>
                            {
                                !IsConnected(state) && (
                                    <Badge
                                        pill={true}
                                        variant={VariantForState(state || 'new')}
                                    >
                                        {
                                            IsConnecting(state)
                                                ? 'Connecting'
                                                : 'Disconnected :('
                                        }
                                    </Badge>
                                )
                            }
                        </h3>
                    </Col>
                </Row>
                <PlayerStatus
                    players={players}
                />
                {
                    gameOver && gameData && (
                        <ChainViewer
                            gameState={{
                                data: gameData,
                                players,
                                order
                            }}
                        />
                    )
                }
                {
                    !gameOver && actionId && (
                        <Game
                            sendDescription={(description: string) => {
                                setWaiting(true);
                                dataConnection?.send(JSON.stringify({
                                    data: {
                                        id: actionId,
                                        description,
                                    },
                                    type: MessageType.Description,
                                }));
                            }}
                            sendImage={(draws: Draw[]) => {
                                setWaiting(true);
                                dataConnection?.send(JSON.stringify({
                                    data: {
                                        id: actionId,
                                        draws,
                                    },
                                    type: MessageType.Image,
                                }));
                            }}
                            waiting={waiting}
                            image={image}
                            description={description}
                        />
                    )
                }
            </Container>
        </div>
    );
}

export default Client;