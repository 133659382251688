import './main.css';
import { RouteProps } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';

const GITHUB_MARKDOWN_RAW_URL = 'https://api.github.com/markdown/raw';

const getMarkdownFileAndConvertToGithubMarkdownHTML = async (file: string) => {
    const sourceFile = `/data/notes/${file}`;
    const fileContents = await (await fetch(sourceFile)).text();
    const result = await (await fetch(GITHUB_MARKDOWN_RAW_URL, { method: 'POST', body: fileContents })).text();
    return result;
}

const GithubMarkdownNotes: React.FC<RouteProps> = ({ location }) => {
    const [markdown, setMarkdown] = useState<string>();
    const loadData = useCallback(async () => {
        const file = location?.hash ? location.hash.slice(1) : 'scratchMarkdown.txt';
        var t = toast.loading(`Loading markdown notes for ${file}`);
        const markdownHTML = await getMarkdownFileAndConvertToGithubMarkdownHTML(file);
        toast.success('Markdown loaded!', { id: t });
        setMarkdown(markdownHTML);
    }, [location?.hash]);

    useEffect(() => { loadData(); }, [loadData]);
    return (
        <div style={{
            margin: '10px auto',
            maxWidth: '980px',
        }}>
            <div className="markdown-body">
                {
                    markdown && (
                        <div dangerouslySetInnerHTML={{ __html: markdown }}/>
                    )
                }
            </div>
        </div>
    );
}

export default GithubMarkdownNotes;
