import * as React from 'react';
import { DataViewPropsBase, ActivityData } from './types';
import ActivityModal from './ActivityModal';
import * as d3 from 'd3';

interface Props extends DataViewPropsBase {
}

interface State {
    width: number;
    height: number;
    showModal: boolean;
    focusActivity?: ActivityData;
    focusDisplay?: string;
    focusTranslate?: string;
    scaleFactor: number;
}

export default class Paths extends React.Component<Props> {
    private static MARGINS = { top: 65, right: 30, bottom: 45, left: 60 };
    private graphParent: React.RefObject<SVGSVGElement> = React.createRef();

    public state: State = {
        width: 0,
        height: 0,
        showModal: false,
        focusDisplay: 'none',
        scaleFactor: 1,
    }

    public componentDidMount() {
        window.addEventListener('resize', this.resize);
        this.resize();
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    private resize = () => {
        const width = this.graphParent.current?.clientWidth || 0;
        const height = this.graphParent.current?.clientHeight || 0;
        const xScaleFactor = width / this.props.statistics.maxDiffX;
        const yScaleFactor = height / this.props.statistics.maxDiffY;

        const scaleFactor = (this.props.statistics.maxDiffX > this.props.statistics.maxDiffY)
            ? xScaleFactor
            : yScaleFactor;
        
        this.setState({
            width,
            height,
            scaleFactor,
        });
    }

    public render() {
        const {
            width,
            height,
            showModal,
            focusActivity,
            focusDisplay,
            focusTranslate,
            scaleFactor,
        } = this.state;
        const { activities, athleteId } = this.props;

        return (
            <>
                <svg
                    width="100%"
                    height="100%"
                    style={{ position: 'absolute' }}
                    ref={this.graphParent}
                >
                    <g
                        transform={`translate(${width / 2},${height / 2})`}
                    >
                        {
                            activities.map(activity => {
                                // console.log(activity.fixedArray[activity.fixedArray.length - 1]);
                                const firstPoint = activity.fixedArray[0];
                                const lastPoint = activity.fixedArray[activity.fixedArray.length - 1];

                                return (
                                    <g
                                        key={activity.id}
                                        transform={`translate(-${firstPoint[0] * scaleFactor},-${firstPoint[1] * scaleFactor})`}
                                    >
                                        <path
                                            fill="none"
                                            stroke="#333"
                                            d={
                                                `M ${activity.fixedArray.map(a => `${a[0] * scaleFactor}, ${a[1] * scaleFactor}`).join(' L ')}`
                                            }
                                            onMouseOver={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                                                this.setState({
                                                    focusActivity: activity,
                                                    focusDisplay: undefined,
                                                    focusTranslate: `translate(${event.clientX},${event.clientY})`,
                                                });
                                                d3.select(event.target as SVGPathElement).transition().duration(100).attr('stroke', '#E33').attr('stroke-width', 3);
                                            }}
                                            onMouseOut={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                                                this.setState({
                                                    focusDisplay: 'none',
                                                })
                                                d3.select(event.target as SVGPathElement).transition().duration(100).attr('stroke', '#333').attr('stroke-width', 1);
                                            }}
                                            onClick={(event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                                                this.setState({
                                                    focusActivity: activity,
                                                    showModal: true,
                                                })
                                            }}
                                        />
                                        <circle
                                            r={5}
                                            style={{ stroke: '#333' }}
                                            strokeWidth={0.75}
                                            cx={lastPoint[0] * scaleFactor}
                                            cy={lastPoint[1] * scaleFactor}
                                        />
                                    </g>
                                );
                            })
                        }
                    </g>
                    <g
                        display={focusDisplay}
                        transform={focusTranslate}
                    >
                        <text
                            dx={15}
                            dy="-.7em"
                        >
                            {focusActivity?.name}
                        </text>
                        <text
                            dx={15}
                            dy=".3em"
                        >
                            {new Date(focusActivity?.date || 0).toDateString()}
                        </text>
                    </g>
                    <text
                        textAnchor="middle"
                        fontSize="30px"
                        x={width / 2}
                        y={10 + (Paths.MARGINS.top / 2)}
                    >
                        Paths
                    </text>
                </svg>
                <ActivityModal
                    activity={focusActivity}
                    showModal={showModal}
                    onHide={() => this.setState({ showModal: false })}
                    athleteId={athleteId}
                />
            </>
        );
    }
}