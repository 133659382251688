import { Table } from "react-bootstrap";
import { Transaction } from "./types";
import { CSSProperties } from "react";
import { formatter } from "../../lib/d3/common";

interface TransactionTableProps {
    transactions: Transaction[];
}

const headerStyle: CSSProperties = {
    position: 'sticky',
    top: '0px',
    backgroundColor: '#FFF'
}

const columns: Map<string,[string,((i: any) => string)|undefined,string|undefined]> = new Map([
    ['Date', ['date', (d: Date) => {
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const date = d.getDate();
        return `${year}-${month < 10 ? '0' : ''}${month}-${date < 10 ? '0' : ''}${date}`
    },undefined]],
    ['Value', ['value', (i: number) => formatter.format(i), undefined]],
    ['Account', ['account', undefined, undefined]],
    ['Category', ['category', undefined, undefined]],
    ['Description', ['description', undefined, 'originalDescription']]
]) || new Map();

const TransactionTable: React.FC<TransactionTableProps> = ({ transactions }) => {
    return (
        <Table
            striped
            bordered
            hover
            size="sm"
            style={{ fontFamily: 'monospace', textAlign: 'right', fontSize: '10px' }}
        >
            <thead>
                <tr>
                    {Array.from(columns.keys(), header => {
                        return (<th style={headerStyle} key={header}>{header}</th>);
                    })}
                </tr>
            </thead>
            <tbody>
                {transactions.map((transaction, index) =>
                    <TransactionRow
                        key={index}
                        id={index}
                        transaction={transaction}
                    />
                )}
            </tbody>
        </Table>
    );
}

export default TransactionTable;

interface TransactionRowProps {
    transaction: Transaction;
    id: number;
}

const TransactionRow: React.FC<TransactionRowProps> = ({ transaction, id }) => {
    return (
        <tr
            onClick={() => { console.log(transaction) }}
        >
            {Array.from(columns.entries(), ([key, [property, format, title]]) =>
                <TransactionRowData
                    key={`${id}-${key}`}
                    value={transaction[property]}
                    format={format}
                    title={title ? transaction[title] : undefined}
                />
            )}
        </tr>
    )
}

interface TransactionRowDataProps {
    format?: (i: any) => string;
    value: any;
    title?: any;
}

const TransactionRowData: React.FC<TransactionRowDataProps> = ({ format, value, title }) => {
    const valueString = format
        ? format(value)
        : value;
    return (<td><span title={title}>{valueString}</span></td>);
}