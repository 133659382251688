import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Bracket, BracketRunNodes, MeterDistanceBetweenLatitudeLongitude, FindRelevantNodesForLatitudeLongitudePoint } from 'src/lib/Utils/Maps';
import { ActivityStreamsData } from 'src/lib/Utils/Strava';
import { CACHE_NAME, GetActivityUrl, GetAthleteUrl, GetFromCacheJSON } from 'src/projects/Running/Common';
import { RawActivityData } from 'src/projects/Running/types';
import { PEAK_URL } from '..';
import { PeakbaggerPeakLinkForPeakId } from '../PeakPopup';

interface Peak {
    name: string;
    location: [number, number];
    id: string;
}

const MyPeaksWrapper: React.FC = () => {
    const cache = useRef<Cache>();
    const [cacheIsReady, setCacheIsReady] = useState(false);

    useEffect(() => {
        (async () => {
            cache.current = await window.caches.open(CACHE_NAME);
            setCacheIsReady(true);
        })();
        return;
    }, []);

    return (
        <>
            {
                cacheIsReady && cache.current && (
                    <MyPeaks
                        cache={cache.current}
                    />
                )
            }
        </>
    )
}

interface MyPeaksProps {
    cache: Cache;
}

const MyPeaks: React.FC<MyPeaksProps> = ({ cache }) => {
    const [peakCount, setPeakCount] = useState<number>();
    const [peaks, setPeaks] = useState<Peak[]>();
    const [athlete, setAthlete] = useState<string>();
    const [activitiesCount, setActivitiesCount] = useState<number>();
    const [activitiesPointsCount, setActivitiesPointsCount] = useState<number>();
    const [foundPeaks, setFoundPeaks] = useState<Peak[]>();
    const [bracketedNodes, setBracketedNodes] = useState<Bracket>();

    const loadPeaks = useCallback(async() => {
        const peaksJson = await GetFromCacheJSON(cache, PEAK_URL);
        const peaks = (peaksJson as GeoJSON.FeatureCollection).features.reduce(
            (accumulator: Peak[], feature) => {
                if (
                    feature.id &&
                    feature.properties?.name &&
                    (feature.geometry as any).coordinates
                ) {
                    return accumulator.concat([{
                        name: feature.properties.name,
                        location: [...((feature.geometry as any).coordinates as [number, number])],
                        id: feature.id.toString(),
                    }]);
                } else {
                    return accumulator;
                }
            }, []);
        setPeakCount(peaks.length);
        setPeaks(peaks);
    }, [cache]);

    const loadHikes = useCallback(async () => {
        console.log('loadHikes');
        if (!athlete) {
            console.log('no athlete');
            return;
        }
        const athleteId = parseInt(athlete);
        const url = `${GetAthleteUrl(athleteId, true)}`;
        const rawData: RawActivityData[] = await GetFromCacheJSON(cache, url);
        const activities = rawData.filter(activity => {
            return activity.type === 'Hike' || activity.type === 'Run';
        });
        setActivitiesCount(activities.length);
        const activityIds = activities.map(activity => activity.id);
        const points: number[][] = [];
        const BATCH_SIZE = 10;
        for (var i = 0; i < activityIds.length; i += BATCH_SIZE) {
            const batch = activityIds.slice(i, i + BATCH_SIZE);
            const promises = batch.map(activity => {
                return GetFromCacheJSON(cache, GetActivityUrl(athleteId, activity, true), 1000);
            });
            await Promise.all(promises);
            for (var j = 0; j < batch.length; j++) {
                const activity = batch[j];
                try {
                    const activityStreamsData: ActivityStreamsData = await promises[j];
                    if (activityStreamsData.latlng && activityStreamsData.latlng.length !== 0) {
                        const longitudeLatitudeStream = activityStreamsData.latlng.map(latitudeLongitude => [latitudeLongitude[1], latitudeLongitude[0]]);
                        Array.prototype.push.apply(points, longitudeLatitudeStream);
                    }
                } catch (ex: any) {
                    console.warn(`Caught an exception getting activity ${activity}, skipping (${ex})`);
                }
            }
        }
        setActivitiesPointsCount(points.length);
        setBracketedNodes(BracketRunNodes(points));
    }, [cache, athlete]);

    useEffect(() => {
        loadHikes();
    }, [athlete, loadHikes]);

    useEffect(() => {
        if (!peaks || !bracketedNodes) {
            console.log('no peaks or no nodes, waiting...')
            return;
        }
        console.log('calculating found peaks');
        const foundPeaks = peaks.filter(peak => {
            const nodeCoordinates = peak.location;
            const relevantNodes = FindRelevantNodesForLatitudeLongitudePoint(nodeCoordinates[1], nodeCoordinates[0], bracketedNodes);
            const peakFound = relevantNodes.find(node => {
                return MeterDistanceBetweenLatitudeLongitude({ lat: node[1], lon: node[0] }, {lat: nodeCoordinates[1], lon: nodeCoordinates[0] }) < 50
            });
            return peakFound;
        });
        setFoundPeaks(foundPeaks);
        console.log('found peaks set');
        return;
    }, [peaks, bracketedNodes]);

    useEffect(() => {
        console.log('useEffect');
        loadPeaks();
        const localStorageAthleteId = localStorage.getItem('athleteId');
        if (!localStorageAthleteId) {
            return;
        }
        setAthlete(localStorageAthleteId);
    }, [loadPeaks]);

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    {athlete && athlete !== '' && (
                        <>
                        <h3>Got athlete Id: </h3>
                            <Form.Control
                                custom={true}
                                as="select"
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    const { value } = event.currentTarget;
                                    setActivitiesCount(undefined);
                                    setActivitiesPointsCount(undefined);
                                    setBracketedNodes(undefined);
                                    setFoundPeaks(undefined);
                                    setAthlete(value);
                                }}
                                value={undefined}
                            >
                                {[3130286, 34963533, 12951819].map(athleteId => {
                                    return (
                                        <option
                                            key={athleteId}
                                            value={athleteId}
                                        >
                                            {athleteId}
                                        </option>
                                    )
                                })}
                            </Form.Control>
                        </>
                    )}
                    {athlete === '' && (
                        <h3>Couldn't detect athlete id</h3>
                    )}
                    {peakCount ? (
                        <h4>{peakCount} Peaks</h4>
                    ) : (
                        <h6>Loading Peaks...</h6>
                    )}
                    {activitiesCount ? (
                        <h4>{activitiesCount} activities
                            <Button
                                style={{ marginLeft: '8px' }}
                                size="sm"
                                onClick={async () => {
                                    if (athlete) {
                                        await cache.delete(GetAthleteUrl(parseInt(athlete), true));
                                        setActivitiesCount(undefined);
                                        setActivitiesPointsCount(undefined);
                                        setBracketedNodes(undefined);
                                        setFoundPeaks(undefined);
                                        loadHikes();
                                    }
                                }}
                            >
                                Clear Cache
                            </Button>
                        </h4>
                    ) : (
                        <h6>Loading activities...</h6>
                    )}
                    {activitiesPointsCount ? (
                        <h4>{activitiesPointsCount} activity points</h4>
                    ) : (
                        <h6>Loading activity points...</h6>
                    )}
                    {foundPeaks ? (
                        <>
                            <h4>{foundPeaks.length} close peaks</h4>
                            <ul>
                                {foundPeaks.map(peak => {
                                    return (
                                        <li
                                            key={peak.id}
                                        >
                                            <a
                                                href={PeakbaggerPeakLinkForPeakId(parseInt(peak.id))}
                                            >
                                                {peak.name}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </>
                    ) : (
                        <h6>
                            Calculating found peaks
                        </h6>
                    )
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default MyPeaksWrapper;