import { ScaleOrdinal } from "d3";

// https://observablehq.com/@d3/color-legend
interface SwatchesProps {
	colors: ScaleOrdinal<string, string, string>;
    columns: string;
	filteredCategories: string[];
	swatchClicked: (value: string) => void;
	domain: string[];
	selectedSwatch?: string;
	marginLeft?: number;
	circular?: boolean;
}

export const Swatches: React.FC<SwatchesProps> = ({
	colors,
    columns,
	filteredCategories,
	swatchClicked,
	selectedSwatch,
	domain,
	marginLeft = 0,
	circular = false,
}) => {
	const swatchSize = 15;
	const swatchWidth = swatchSize;
	const swatchHeight = swatchSize;
	const format = (x: any) => x;

    return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				marginLeft: `${marginLeft}px`,
				minHeight: '33px',
				font: '10px sans-serif',
			}}
		>
			<div
				style={{
					width: '100%',
					columns: columns,
				}}
			>
				{domain.map((value: any) => <Swatch
					key={value}
					value={format(value)}
					width={swatchWidth}
					height={swatchHeight}
					color={colors(value)}
					filtered={filteredCategories.indexOf(value) !== -1}
					swatchClicked={swatchClicked}
					opacity={(selectedSwatch === undefined || selectedSwatch === value) ? 1 : .25}
					circular={circular}
				/>)}
			</div>
    	</div>
	);
}

interface SwatchProps {
	value: any;
	width: number;
	height: number;
	color: string;
	filtered: boolean;
	swatchClicked: (value: string) => void;
	opacity: number;
	circular: boolean;
}

const Swatch: React.FC<SwatchProps> = ({
	value,
	width,
	height,
	color,
	filtered,
	swatchClicked,
	opacity,
	circular,
}) => {
	return (
		<div
			style={{
				breakInside: 'avoid',
				display: 'flex',
				alignItems: 'center',
				paddingBottom: '1px',
				opacity
			}}
			onClick={() => {
				swatchClicked(value)
			}}
		>
			<div
				style={{
					width: `${width}px`,
					height: `${height}px`,
					margin: '0 0.5em 0 0',
					background: filtered ? 'none': color,
					border: filtered ? `dashed ${color}` : 'none',
					borderRadius: circular ? `${width / 2}px` : '0px',
				}}
			/>
			<div
				title={value}
				style={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					maxWidth: `calc(100% - ${width}px - 0.5em)`,
				}}
			>
				{value}
			</div>
		</div>
	)
}