import * as React from 'react';
import FullScreenMenu from 'src/components/fullScreenMenu';
import { Row, Col, ToggleButtonGroup, ToggleButton, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faTh, faSquare } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import FullScreenLoading from 'src/components/loading';
import MultiHistogram from './MultiHistogram';
import * as d3 from 'd3';
import { ParseCSVText } from 'src/lib/Utils/CSV';
import { histogram } from 'd3';
import Histogram from './Histogram';
import Overall from './Overall';
import { BRACKET_NAMES, RaceResult, Bracket, TIME_HISTOGRAM, TIME_EXTENT, BRACKET_SIZE_SECONDS, Gender, OverallResult, AGE_EXTENT, RaceYear, GetBracketForAge } from './common';

const S3_RACES_URL = 'https://s3-us-west-2.amazonaws.com/jrgrover.com?prefix=data/marathonStatistics&delimiter=.csv';

interface State {
    menuIsOpen: boolean;
    visualization: string;
    loading: boolean;
    loadingMessage: string;
    races: { [key: string]: any };
    race: string;
    year: string;
    width: number;
    height: number;
    data: any[];
    brackets: any;
    bracket: string;
}

export default class MarathonStatistics extends React.Component {
    private graphParent: React.RefObject<HTMLDivElement> = React.createRef();
    private COLUMNS = 3;

    public static margins = {
        top: 65,
        right: 40,
        bottom: 45,
        left: 60,
    };

    public state: State = {
        menuIsOpen: false,
        visualization: "histograms",
        loading: false,
        loadingMessage: '',
        races: {},
        race: '',
        year: '',
        width: 0,
        height: 0,
        data: [],
        brackets: {},
        bracket: BRACKET_NAMES[0],
    }

    public componentDidMount = () => {
        this.getRaces();
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    private getRaces = async () => {
        this.setState({ loading: true, loadingMessage: 'Loading Race Data...' })
        const raceData = await (await fetch(S3_RACES_URL)).text();
        this.setState({ loadingMessage: 'Parsing Race Data...' });
        const parser = (new DOMParser()).parseFromString(raceData, 'text/html');
        const csvs = parser.querySelectorAll('CommonPrefixes Prefix');
        const races = Array.from(csvs).reduce(
            (acc: { [key: string]: { year: string, path: string }[] }, item) => {
            const pathParts = item.innerHTML.split('/');
            const [,,,race,yearFile] = pathParts;
            const year = yearFile.split('.')[0];
            const relativePath = [race,yearFile].join('/');
            if (!acc[race]) {
                acc[race] = [];
            }
            acc[race].push({ year: year, path: relativePath });
            return acc;
        }, {});
        this.setState({
            loading: false,
            races,
            // race: Object.keys(races).sort()[0],
            menuIsOpen: true,
        });
    }

    private handleMenuToggle = () => {
        this.setState({ menuIsOpen: !this.state.menuIsOpen });
    }

    private handleVisualizationChange = (visualization: string) => {
        this.setState({ visualization });
    }

    private handleRaceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const race = event.currentTarget.value;
        const year = this.state.races[race][0];
        this.setState({
            race,
            year: year || ''
        });
        if (year) {
            this.getRaceData(year.path);
        }
        
    }

    private handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            year: event.currentTarget.value,
            loading: true,
            loadingMessage: `Loading race data for the ${event.currentTarget.value} ${this.state.race} race`,
        });
        this.getRaceData(event.currentTarget.value);
    }

    private handleBracketChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ bracket: event.currentTarget.value });
    }

    private getRaceData = async (file: string) => {
        const raceData = await (await fetch(`/data/marathonStatistics/data/${file}`)).text();
        const parsedData = ParseCSVText(raceData);
        const data: RaceResult[] = parsedData.map(d => {
            return {
                Age: parseInt(d.Age),
                Gender: d.Gender,
                Time: parseInt(d.Time),
            }
        });

        const brackets = BRACKET_NAMES.reduce((arr: {[key: string]: Bracket}, name) => {
            arr[name] = { values: [] };
            return arr
        }, {});
        data.reduce((brackets, d) => {
            const bracket = GetBracketForAge(d.Age);
            brackets[bracket].values.push(d);
            return brackets;
        }, brackets);
        brackets["All"] = { values: data };
        Object.keys(brackets).forEach(bracket => {
            brackets[bracket].bins = TIME_HISTOGRAM(brackets[bracket].values);
        });

        let timeThreshold = Math.floor(TIME_EXTENT[0] / BRACKET_SIZE_SECONDS) * BRACKET_SIZE_SECONDS;
        const thresholds = [timeThreshold];
        for (; timeThreshold <= TIME_EXTENT[1]; timeThreshold += BRACKET_SIZE_SECONDS) {
            thresholds.push(timeThreshold + BRACKET_SIZE_SECONDS);
        }
        const overallHistogram = histogram<RaceResult, number>()
            .value((d) => d.Time)
            .thresholds(thresholds)
            .domain(TIME_EXTENT)

        const m = data.filter(d => d.Gender === Gender.M);
        const f = data.filter(d => d.Gender === Gender.F);
        const overallData: OverallResult[] = d3.range(AGE_EXTENT[0], AGE_EXTENT[1] + 1).reduce((acc: OverallResult[], age) => {
            acc.push({
                age,
                gender: Gender.M,
                bins: overallHistogram(m.filter(d => d.Age === age)).filter(b => b.length !== 0),
            });
            acc.push({
                age,
                gender: Gender.F,
                bins: overallHistogram(f.filter(d => d.Age === age)).filter(b => b.length !== 0),
            });
            return acc;
        }, [])


        this.setState({
            brackets,
            data: overallData,
            loading: false,
        });
    }

    private handleResize = () => {
        const width = this.graphParent.current?.offsetWidth || 0;
        const height = this.graphParent.current?.offsetHeight || 0;

        this.setState({ width, height });
    }
    
    public render() {
        const {
            visualization,
            races,
            race,
            year,
            width,
            height,
            data,
            brackets,
            bracket,
        } = this.state;
        const years: RaceYear[] = races[race] || [];

        const chartInnerWidth = width - MarathonStatistics.margins.left - MarathonStatistics.margins.right;
        const innerAreaMinusMargins = chartInnerWidth - ((this.COLUMNS - 1) * (MarathonStatistics.margins.left / 2));
        const chartWidth = innerAreaMinusMargins / this.COLUMNS;
        const chartHeight = chartWidth / 1.618;
        const histogramsHeight = (Math.ceil(BRACKET_NAMES.length / 3) * (chartHeight + MarathonStatistics.margins.top)) + MarathonStatistics.margins.top + MarathonStatistics.margins.bottom;
        const svgHeight = (visualization === 'histograms') ? histogramsHeight : height;

        return (
            <>
                <FullScreenMenu
                    isOpen={this.state.menuIsOpen}
                    onMenuToggle={this.handleMenuToggle}
                >
                    <Container style={{ color: 'hsla(0, 100%, 100%, 0.89)' }}>
                        <Row>
                            <Col sm={12}>
                                <h1>Options</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <h2>Visualization:</h2>
                                <ToggleButtonGroup
                                    type="radio"
                                    name="buttons"
                                    className="btn-group-toggle"
                                    value={visualization}
                                    onChange={this.handleVisualizationChange}
                                >
                                    <ToggleButton className="btn-secondary" type="radio" value="histograms" name="buttons">
                                        <FontAwesomeIcon icon={faTh} /> All Histograms 
                                    </ToggleButton>
                                    <ToggleButton className="btn-secondary" type="radio" value="bracket" name="buttons">
                                        <FontAwesomeIcon icon={faChartBar} /> Single Brackets
                                    </ToggleButton>
                                    <ToggleButton className="btn-secondary" type="radio" value="overall" name="buttons">
                                        <FontAwesomeIcon icon={faSquare} /> Overall Breakdown
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Race:</Form.Label>
                                        <Form.Control
                                            custom={true}
                                            as="select"
                                            onChange={this.handleRaceChange}
                                            value={race}
                                        >
                                            <option value={""}>- Select A Race -</option>
                                            {
                                                Object.keys(races).sort().map(race => (
                                                        <option key={race} value={race}>
                                                            {race}
                                                        </option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col sm={4}>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Year:</Form.Label>
                                        <Form.Control
                                            as="select"
                                            custom={true}
                                            onChange={this.handleYearChange}
                                            value={year}
                                            disabled={years.length === 0}
                                        >
                                            {
                                                years.map(year => (
                                                    <option key={year.year} value={year.path}>
                                                        {year.year}
                                                    </option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col sm={4}>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Age Bracket:</Form.Label>
                                        <Form.Control
                                            as="select"
                                            custom={true}
                                            onChange={this.handleBracketChange}
                                            value={bracket}
                                            disabled={visualization !== 'bracket'}
                                        >
                                            {
                                                BRACKET_NAMES.map(bracket => (
                                                    <option key={bracket} value={bracket}>
                                                        {bracket}
                                                    </option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </FullScreenMenu>
                <FullScreenLoading
                    isOpen={this.state.loading}
                    message={this.state.loadingMessage}
                    percentProgress={40}
                />
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    ref={this.graphParent}
                >
                    <svg
                        style={{ backgroundColor: '#f0f0f0' }}
                        width={width}
                        height={svgHeight}
                    >
                        <text
                            textAnchor="middle"
                            style={{ fontSize: '30px' }}
                            x={width / 2}
                            y={5 + (MarathonStatistics.margins.top / 2)}
                        >
                            Marathon Pace Results By Age Groups
                        </text>
                        <MultiHistogram
                            chartWidth={chartWidth}
                            chartHeight={chartHeight}
                            visibility={visualization === 'histograms' ? undefined : 'hidden'}
                            data={brackets}
                        />
                        <Histogram
                            index={0}
                            visibility={visualization === 'bracket' ? undefined : 'hidden' }
                            width={chartInnerWidth}
                            height={svgHeight - MarathonStatistics.margins.top - MarathonStatistics.margins.bottom}
                            data={brackets[bracket]?.bins}
                            title={bracket}
                        />
                        <Overall
                            visibility={visualization === 'overall' ? undefined : 'hidden' }
                            width={chartInnerWidth}
                            height={svgHeight - MarathonStatistics.margins.top - MarathonStatistics.margins.bottom}
                            data={data || []}
                            title={years.find(y => y.path === year)?.year || ''}
                        />
                    </svg>
                </div>
            </>
        );
    }
};
