import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import { Link } from 'react-router-dom';
import { Category, PROJECTS_MANIFEST, Experiment } from './projects/Manifest';
import { useEffect } from 'react';

const Home: React.FC = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#f2f2f2';
    return () => {
      document.body.style.backgroundColor = 'inherit';
    }
  });

  return (
    <Container style={{
      fontFamily: 'Georgia, Times, "Times New Roman", serif',
    }}>
      <Row>
        <Col sm={12}>
          <h1
            className="display-2"
            style={{ paddingTop: '20px' }}
          >
            John Grover's <small
              style={{ fontVariant: 'small-caps' }}
            >
              <a href="/resume.html">
                Résumé
              </a>
            </small>
          </h1>
          <h2
            className="display-4"
          >
            Client-side Projects
          </h2>
        </Col>
      </Row>
      {
        <div>
          <CategoryContainer category={PROJECTS_MANIFEST.Complete} />
          <CategoryContainer category={PROJECTS_MANIFEST.Development} />
          <CategoryContainer category={PROJECTS_MANIFEST.Broken} />
          <CategoryContainer category={PROJECTS_MANIFEST.Other} />
        </div>
      }
    </Container>
  );
}

export default Home;

interface CategoryContainerProps {
  category: Category;
}

const CategoryContainer: React.FC<CategoryContainerProps> = ({ category: { title, subtext, experiments } }) => {
  return (
    <Row>
      <TitleWithSubtext title={title} subtext={subtext} />
      {experiments.map(experiment => {
        return experiment.title !== ''
          ? (<Item experiment={experiment} key={experiment.title} />)
          : null;
      })}
    </Row>
  );
}

interface TitleWithSubtextProps {
  title: string;
  subtext?: string;
}

const TitleWithSubtext: React.FC<TitleWithSubtextProps> = ({ title, subtext }) => {
  return (
    <Col sm={12}>
      <h3
        style={{ paddingTop: '10px', fontWeight: 'bold' }}
      >
        {title}
        {
          (subtext) ? (
            <small
              className="text-muted"
            >
              {` ${subtext}`}
            </small>
          ) :
          null
        }
      </h3>
    </Col>
  );
}

interface ItemProps {
  experiment: Experiment;
}

const Item: React.FC<ItemProps> = ({ experiment: { thumbnail, title, href, component } }) => {
  const thumbnailUrl: string|undefined = (thumbnail && thumbnail !== "") ? `/img/projects/thumbnails/${thumbnail}` : thumbnail;
  return (
    <Col
      sm={4}
      style={{
        height: '250px',
        padding: '10px 10px 15px 10px'
      }}
      key={href}
    >
      <div
        style={{
          backgroundImage: `url(${thumbnailUrl})`,
          height: '100%',
          width: '100%',
          position: 'initial',
          backgroundSize: 'cover',
        }}
        className="thumbnail"
      >
        <div style={{
          paddingLeft: '10px',
          backgroundColor: 'rgba(204,204,204,.89)',
          fontSize: '24px',
          width: '100%',
        }}>
          {
            (!href.startsWith("https://") && component)
              ? (<Link to={href}>{title}</Link>)
              : <a href={`.${href}`}>{title}</a>
          }
        </div>
      </div>
    </Col>
  );
}
