import * as React from 'react';
import DeckGl from 'deck.gl';
import SlopeAngleSimpleMeshLayer from './SlopeAngleLayer/SlopeAngleSimpleMeshLayer';
import { COORDINATE_SYSTEM } from "@deck.gl/core";
import { GetPlaneBufferGeometryAttributes } from 'src/lib/Utils/Maps';
import { CalculateVertexNormals, getMeshBoundingBox } from './MapboxTerrainLoader';
import { AspectColors } from '.';

const DUMMY_DATA = [0];

const viewport = {
    zoom: 14,
    latitude: 0,
    longitude: 0,
    maxPitch: 85,
    pitch: 60,
    bearing: 25,
};

const TILE_SIZE = 256;

/*
const getVertexHeightForDampenedCosWave = (row: number, column: number) => {
    const distance = Math.sqrt(Math.pow(row, 2) + Math.pow(column, 2));
    const amplitude = 50;
    const frequency = 12;
    const d = 1.5;
    const dampExponent = (d / 100) * (-distance);
    const damp = Math.pow(Math.E, dampExponent);
    return damp * (amplitude * (Math.cos(distance / frequency)) + amplitude);
}
*/

const getTestMesh = () => {
    const width = 0.015625;
    const height = 0.015625;
    const minX = -TILE_SIZE + (width / 2);
    const minY = -TILE_SIZE + (height / 2);
    const vertexHeightCorrectionFactor = 0.000012790351819935067;

    const {
        indices,
        vertices,
        uvs,
    } = GetPlaneBufferGeometryAttributes(width, height, minX, minY,
        TILE_SIZE,
        TILE_SIZE,
        true
    );
    
    const vertexWidth = (width / vertexHeightCorrectionFactor) / TILE_SIZE;
    const STARTING_ANGLE = 30;
    const MAX_ANGLE_STEEP = 60;
    const MAX_ANGLE_SHALLOW = 30;
    const ENDING_ANGLE = 0;
    const RADIANS = Math.PI / 180;

    const STEEP_AVERAGE = (MAX_ANGLE_STEEP + STARTING_ANGLE) / 2;
    const SHALLOW_AVERAGE = (MAX_ANGLE_SHALLOW + ENDING_ANGLE) / 2;
    const steepAngleAdjacent = (Math.tan(SHALLOW_AVERAGE * RADIANS) * (TILE_SIZE - 4)) / (Math.tan(STEEP_AVERAGE * RADIANS) + Math.tan(SHALLOW_AVERAGE * RADIANS));
    const steepAngleAdjacentFloor = Math.floor(steepAngleAdjacent);
    const steepAngleDelta = (MAX_ANGLE_STEEP - STARTING_ANGLE) / steepAngleAdjacentFloor;
    const shallowAngleDelta = MAX_ANGLE_SHALLOW / (TILE_SIZE - 4 - steepAngleAdjacentFloor);

    let secondHeight = 0;
    let secondDegree = STARTING_ANGLE;
    let secondAnglePositivity = 1;
    for (var row = 2; row < TILE_SIZE - 2; row++) {
        const rowIndexPosition = row * TILE_SIZE;

        const secondHeightDelta = Math.tan(secondDegree * RADIANS) * vertexWidth * secondAnglePositivity;
        secondHeight += secondHeightDelta;

        let height = 0;
        let degree = STARTING_ANGLE;
        let anglePositivity = 1;

        for (var column = 2; column < TILE_SIZE - 2; column++) {
            // const rowCorrected = row - (TILE_SIZE / 2);
            // const columnCorrected = column - (TILE_SIZE / 2);
            // const terrainValue = getVertexHeightForDampenedCosWave(rowCorrected, columnCorrected);
            const heightDelta = Math.tan(degree * RADIANS) * vertexWidth * anglePositivity;
            height += heightDelta;

            const terrainValueCalculated = (height + secondHeight) / 2; 
            const terrainValue = Math.max(terrainValueCalculated, 0);

            const currentVertexIndex = (rowIndexPosition * 3) + (column * 3);
            vertices[currentVertexIndex + 2] = terrainValue;

            if (degree >= MAX_ANGLE_STEEP) {
                degree = MAX_ANGLE_SHALLOW;
                anglePositivity = -1;
            }
            degree += (anglePositivity * (anglePositivity >= 1 ? steepAngleDelta : shallowAngleDelta));
        }

        if (secondDegree >= MAX_ANGLE_STEEP) {
            secondDegree = MAX_ANGLE_SHALLOW;
            secondAnglePositivity = -1;
        }
        secondDegree += (secondAnglePositivity * (secondAnglePositivity >= 1 ? steepAngleDelta : shallowAngleDelta));
    }

    const calculatedNormals = CalculateVertexNormals(indices, vertices, vertexHeightCorrectionFactor);

    const attributes = {
        POSITION: {value: vertices, size: 3},
        TEXCOORD_0: {value: uvs, size: 2},
        NORMAL: {value: calculatedNormals, size: 3},
    };

    return {
        loaderData: { header: {} },
        header: {
          vertexCount: TILE_SIZE * TILE_SIZE * 3 * 2,
          boundingBox: getMeshBoundingBox(attributes)
        },
        mode: 4,
        indices: {
            value: indices,
            size: 1
        },
        attributes,
    };
}

interface DeckGlPreviewProps {
    wireframe: boolean;
    aspectColors: AspectColors;
    slopeCutoffRange: [number,number];
    slopeAspectOpacity: number;
    slopeMaskOpacity: number;
    terrainOpacity: number;
    slopeAngleOpacity: number;
}

const DeckGlPreview: React.FC<DeckGlPreviewProps> = ({
    aspectColors,
    wireframe,
    slopeCutoffRange,
    slopeAspectOpacity,
    slopeMaskOpacity,
    terrainOpacity,
    slopeAngleOpacity,
}) => {
    const mesh = getTestMesh();
    return (
        <DeckGl
            style={{ position: 'relative' }}
            controller={{
                inertia: true,
                touchRotate: true,
            }}
            initialViewState={viewport}
            layers={[(new (SlopeAngleSimpleMeshLayer as any)({
                wireframe,
                mesh,
                aspectColors: aspectColors,
                data: DUMMY_DATA,
                coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
                getPosition: (_: any) => [0, 0, 0],
                slopeCutoffRange,
                slopeAspectOpacity,
                slopeMaskOpacity,
                terrainOpacity,
                slopeAngleOpacity,
            }))
            ]}
        />
    );
}

export default DeckGlPreview;