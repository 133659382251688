import * as React from 'react';
import * as d3 from 'd3';
import MarathonStatistics from '.';
import { secondsToString, secondsToStringD3 } from 'src/lib/Utils/Strings';
import { Bin } from 'd3';
import { RaceResult, TIME_EXTENT, COLOR, GetPaceForBracketGenderRace, Gender } from './common';

interface Props {
    index: number;
    visibility?: string;
    width: number;
    height: number;
    data: Bin<RaceResult, number>[];
    title: string;
}

export default class Histogram extends React.Component<Props> {
    private xAxisElement: React.RefObject<SVGGElement> = React.createRef();
    private yAxisElement: React.RefObject<SVGGElement> = React.createRef();
    private x = d3.scaleLinear();
    private y = d3.scaleLinear();
    private xAxis = d3.axisBottom(this.x).tickFormat(secondsToStringD3);
    private yAxis = d3.axisLeft(this.y).tickPadding(6);

    public render() {
        const max = d3.max(this.props.data || [], function(d: any) { return d.length });
        this.x.range([0, this.props.width])
            .domain(TIME_EXTENT);
        this.y.range([this.props.height, 0])
            .domain([0, max]);
        this.xAxis.scale(this.x);
        this.yAxis.scale(this.y);
        d3.select(this.xAxisElement.current).call(this.xAxis as any);
        d3.select(this.yAxisElement.current).call(this.yAxis as any);

        const parentTranslateX = MarathonStatistics.margins.left + ((this.props.index % 3) * (this.props.width + (MarathonStatistics.margins.left / 2)));
        const parentTranslateY = MarathonStatistics.margins.top + (Math.floor(this.props.index / 3) * (this.props.height + MarathonStatistics.margins.top));
        const bostonX = GetPaceForBracketGenderRace(this.props.title, Gender.M, 'Boston');
        const nycX = GetPaceForBracketGenderRace(this.props.title, Gender.M, 'New York');
        return (
            <g
                visibility={this.props.visibility}
                transform={`translate(${parentTranslateX}, ${parentTranslateY})`}
            >
                <g
                    ref={this.xAxisElement}
                    transform={`translate(0,${this.y(0)})`}
                >

                </g>
                <g ref={this.yAxisElement}>

                </g>
                <text
                    textAnchor="middle"
                    fontSize="20px"
                    x={this.props.width / 2}
                    y={-5}
                >
                    {this.props.title}
                </text>
                {
                    (this.props.data || []).map((datum: any, index: number) => {
                        return (
                            <rect
                                key={index}
                                fill={COLOR(this.props.title)}
                                transform={`translate(${this.x(datum.x0)},${this.y(datum.length)})`}
                                width={this.x(datum.x1) - this.x(datum.x0)}
                                height={this.props.height - this.y(datum.length)}
                            >
                                <title>
                                    {`${secondsToString(datum.x0)}-${secondsToString(datum.x1)}\nRunners: ${datum.length}`}
                                </title>

                            </rect>
                        )
                    })
                }
                <line
                    strokeWidth="1"
                    stroke="#5dd"
                    y1={0}
                    y2={this.props.height}
                    x1={this.x(bostonX)}
                    x2={this.x(bostonX)}
                />
                <line
                    strokeWidth="1"
                    stroke="#126"
                    y1={0}
                    y2={this.props.height}
                    x1={this.x(nycX)}
                    x2={this.x(nycX)}
                />
            </g>
        )
    }
}
