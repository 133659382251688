import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef } from "react";
import { Overlay, Popover, PopoverTitle, PopoverContent, ListGroup, ListGroupItem } from "react-bootstrap";
import { STRAVA_URL } from "../Common";
import { CityNode } from "./NodesProgress";

export interface RunInformation {
	name: string;
	id: number;
	nodes: CityNode[];
}

const RunsTooltip: React.FC<{
	way: string;
	runs: RunInformation[];
	completedBy?: number;
}> = ({
	children,
	way,
	runs,
	completedBy,
}) => {
	const [show, setShow] = useState(false);
    const target = useRef(null);
    const sortedRuns = [...runs].sort((a: RunInformation, b: RunInformation) => a.id < b.id ? 1 : -1);

    return (<>
        <div ref={target} onClick={() => setShow(!show)}>
            {children}
        </div>
        <Overlay
            target={target.current}
            show={show}
            placement="right"
            rootClose={true}
            rootCloseEvent="click"
            onHide={() => setShow(false)}
        >
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
                <Popover
                    {...props}
                    id={way}
                    arrowProps={arrowProps}
                    placement={placement}
                    popper={popper}
                >
                    <PopoverTitle as="h3">
                        {way} Progress
                    </PopoverTitle>
                    <PopoverContent>
                        <ListGroup variant="flush">
                            {sortedRuns.map(run => <ListGroupItem key={run.id}>
                                <a
                                    href={`${STRAVA_URL}${run.id}`}
                                >
                                    {run.name} ({run.nodes.length})
									{completedBy && run.id === completedBy && (
										<FontAwesomeIcon
											icon={faCheckCircle}
											style={{ marginLeft: '4px' }}
										/>
									)}
                                </a>
                            </ListGroupItem>)}
                        </ListGroup>
                    </PopoverContent>
                </Popover>
            )}
        </Overlay>
    </>);
}

export default RunsTooltip;
