import * as d3 from 'd3';
import { histogram } from 'd3';

export const BRACKET_NAMES = ["All", "18-34", "35-39", "40-44", "45-49", "50-54", "55-59", "60-64", "65-69", "70-74", "75-79", "80+"];
export const COLOR = d3.scaleOrdinal(["#1f77b4","#aec7e8","#ff7f0e","#ffbb78","#2ca02c","#98df8a","#d62728","#ff9896","#9467bd","#c5b0d5","#8c564b","#c49c94","#e377c2","#f7b6d2","#7f7f7f","#c7c7c7","#bcbd22","#dbdb8d","#17becf","#9edae5"])
    .domain(BRACKET_NAMES);

interface Standards {
  M: { [key: string]: string },
  F: { [key: string]: string }
}
export const QUALIFYING_STANDARDS: { [key: string]: Standards } = {
    "Boston": {
        "M": {
        "18-34":"3:05",
        "35-39":"3:10",
        "40-44":"3:15",
        "45-49":"3:25",
        "50-54":"3:30",
        "55-59":"3:40",
        "60-64":"3:55",
        "65-69":"4:10",
        "70-74":"4:25",
        "75-79":"4:40",
        "80+":"4:55"
        },
        "F": {
        "18-34":"3:35",
        "35-39":"3:40",
        "40-44":"3:45",
        "45-49":"3:55",
        "50-54":"4:00",
        "55-59":"4:10",
        "60-64":"4:25",
        "65-69":"4:40",
        "70-74":"4:55",
        "75-79":"5:10",
        "80+":"5:25"
        }
    },
    "New York": {
        "M": {
        "18-34": "2:53",
        "35-39": "2:55",
        "40-44": "2:58",
        "45-49": "3:05",
        "50-54": "3:14",
        "55-59": "3:23",
        "60-64": "3:34",
        "65-69": "3:45",
        "70-74": "4:10",
        "75-79": "4:30",
        "80+": "4:55"
        },
        "F": {
        "18-34": "3:13",
        "35-39": "3:15",
        "40-44": "3:26",
        "45-49": "3:38",
        "50-54": "3:51",
        "55-59": "4:10",
        "60-64": "4:27",
        "65-69": "4:50",
        "70-74": "5:30",
        "75-79": "6:00",
        "80+": "6:35"
        }
    }
    }

export const GetPaceForBracketGenderRace = (bracket: string, gender: Gender, race: string) => {
  const paceTime = QUALIFYING_STANDARDS[race][gender][bracket] || "0:00"
  const splitPaceTime = paceTime.split(":")
  return (parseInt(splitPaceTime[0]) * 60 * 60) + (parseInt(splitPaceTime[1]) * 60)
}

export const GetBracketForAge = (age: number) => {
  if (age < 35) {
    return "18-34";
  } else if (age < 40) {
    return "35-39"
  } else if (age < 45) {
    return "40-44"
  } else if (age < 50) {
    return "45-49"
  } else if (age < 55) {
    return "50-54"
  } else if (age < 60) {
    return "55-59"
  } else if (age < 65) {
    return "60-64"
  } else if (age < 70) {
    return "65-69"
  } else if (age < 75) {
    return "70-74"
  } else if (age < 80) {
    return "75-79"
  } else {
    return "80+"
  }
}

export const SECONDS_PER_BRACKET = 13*26.2;
export const TIME_EXTENT: [number, number] = [2 * 60 * 60, 7 * 60 * 60];
export const TIME_THRESHOLDS = Math.floor((TIME_EXTENT[1] - TIME_EXTENT[0]) / SECONDS_PER_BRACKET);

export const TIME_HISTOGRAM = histogram<RaceResult, number>()
    .value((d: any) => d.Time)
    .thresholds(TIME_THRESHOLDS)
    .domain(TIME_EXTENT);

export const AGE_EXTENT = [16, 85];
export const BRACKET_SIZE_SECONDS = 60 * 5;

export enum Gender {
    M = 'M',
    F = 'F',
}

export interface RaceResult {
    Age: number;
    Gender: Gender;
    Time: number;
}

export interface Bracket {
    bins?: d3.Bin<RaceResult, number>[];
    values: RaceResult[];
}

export interface OverallResult {
    age: number;
    gender: Gender;
    bins: d3.Bin<RaceResult, number>[];
}

export interface RaceYear {
    year: string,
    path: string,
}