import * as React from 'react';
import * as d3 from 'd3';
import { Row, Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';

interface PartyData {
    year: number;
    control: VALID_PARTIES;
}

interface ElectionResult {
    Democratic: string;
    Republican: string;
    year: number;
}

enum VALID_PARTIES {
    democratic = 'democratic',
    republican = 'republican',
}

interface STATE_RESULT {
    Name: string;
    Data: {
        year: number;
        Democratic: number;
        Republican: number;
    }[];
}

const MARGINS = {top: 65, right: 10, bottom: 50, left: 50};
const YEAR_HEIGHT = 60;
const REPUBLICAN_COLOR = '#C55';
const DEMOCRATIC_COLOR = '#55C';

export default class ElectionMargins extends React.Component {
    private graphParent: React.RefObject<HTMLDivElement>;
    private xAxisElement: React.RefObject<SVGGElement>;
    private yAxisElement: React.RefObject<SVGGElement>;

    private x = d3.scaleLinear()
        .domain([-100, 100]);
    private y = d3.scaleLinear();

    private xAxis = d3.axisTop(this.x)
        .tickFormat(d => { return `${Math.abs(parseInt(d.toString()) || 0)} %`; });
    private yAxis = d3.axisLeft(this.y)
        .tickFormat(d3.format('d'));

    public xPlot = (d: any, i: number) => {
        const left = d.Democratic
        const right = d.Republican
        let margin = Math.abs(left - right)
        
        if (left > right)
        {
            margin = -1 * margin
        }

        return this.x(margin); 
    }

    private yPlot = (d: any, i: number) => {
        return this.y(d.year);
    };

    private line = d3.line()
        .curve(d3.curveCatmullRom)
        .x(this.xPlot)
        .y(this.yPlot);
    
    public state = {
        width: 0,
        height: 0,
        graphWidth: 0,
        graphHeight: 0,
        elections: [],
        stateResults: [],
        dataHeight: 0,
    };

    public constructor(props: any) {
        super(props);
        this.graphParent = React.createRef();
        this.xAxisElement = React.createRef();
        this.yAxisElement = React.createRef();
    }

    public componentDidMount() {
        this.fetchPartyData();
        window.addEventListener('resize', this.handleResize);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    private handleResize = () => {
        const width = this.graphParent.current?.offsetWidth || 0;
        const height = this.graphParent.current?.offsetHeight || 0;
        const graphWidth = width - MARGINS.left - MARGINS.right;
        const graphHeight = height - MARGINS.top - MARGINS.bottom;

        this.x.range([0, graphWidth]);

        this.xAxis.scale(this.x);
        this.yAxis.scale(this.y);

        this.setState({ width, height, graphWidth, graphHeight });
        d3.select(this.xAxisElement.current).call(this.xAxis as any);
        d3.select(this.yAxisElement.current).call(this.yAxis as any);
    }

    
    async fetchPartyData() {
        var results: { [key: string] : ElectionResult[]}= await (await fetch('/data/electionMargins/results.json')).json() || [];
        const parsedResults = Object.keys(results).map(function(k){
            return {
              "Name" : k,
              "Data" : results[k].map(function(d){ return {
                "year": d.year,
                "Democratic": parseFloat(d.Democratic),
                "Republican": parseFloat(d.Republican)
              }})
            }
        });
        
        const yMin = d3.min(
            parsedResults,
            function(d) {
                return d3.min(
                    d.Data,
                    function(y){
                        return y.year
                    });
            }
        );
        const yMax = d3.min(
            parsedResults,
            function(d) { 
                return d3.max(
                    d.Data,
                    function(y){
                        return y.year
                    });
            }
        );

        const dataHeight = d3.max(parsedResults, function(d){ return d.Data.length}) || 0;

        this.y.domain([
            ((yMin || 0) - 2),
            ((yMax || 0)  + 2)
        ]);
        this.y.range([0, dataHeight * YEAR_HEIGHT]);

        const years: number[] = Array.prototype.concat.apply([], parsedResults.map(function(d){ return d.Data.map(function(y){ return y.year})}));
        this.yAxis.tickValues([...Array.from(new Set(years))]);

        const elections: PartyData[] = await (await (await fetch('/data/electionMargins/party.json')).json());
        this.setState({
            dataHeight,
            elections,
            stateResults: parsedResults,
        });
        this.handleResize();
    }

    public handleLineMouseLeave = (event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
        d3.selectAll(".state").transition().attr("stroke-width", 2.5).attr("opacity", 1).style("stroke", "#EEE")
        d3.select(event.currentTarget.nextElementSibling).style('display', 'none')
    }
    
    public render() {
        const {
            graphWidth,
            width,
            dataHeight,
            elections,
            stateResults,
        } = this.state;
        const chartHeight = dataHeight * YEAR_HEIGHT;
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <div
                            style={{ width: '100%' }}
                            ref={this.graphParent}
                        >
                            <svg
                                width={width}
                                height={chartHeight + MARGINS.top + MARGINS.bottom}
                            >
                                <g
                                    transform={`translate(${MARGINS.left}, ${MARGINS.top})`}
                                >
                                    <rect
                                        width={graphWidth / 2}
                                        height={chartHeight}
                                        transform={`translate(${graphWidth / 2}, 0)`}
                                        style={{
                                            fill: REPUBLICAN_COLOR,
                                            opacity: .75,
                                        }}
                                    />
                                    <rect
                                        width={graphWidth / 2}
                                        height={chartHeight}
                                        transform="translate(0,0)"
                                        style={{
                                            fill: DEMOCRATIC_COLOR,
                                            opacity: .75
                                        }}
                                    />
                                    <g
                                        className="x axis"
                                        transform="translate(0,0)"
                                        ref={this.xAxisElement}
                                    />
                                    <g
                                        className="y axis"
                                        ref={this.yAxisElement}
                                    />
                                    {
                                        elections.map((d: PartyData, i: any) => {
                                            return (
                                                <rect
                                                    key={i}
                                                    width={graphWidth}
                                                    height={YEAR_HEIGHT / 2}
                                                    fill={d.control === VALID_PARTIES.democratic ? DEMOCRATIC_COLOR : REPUBLICAN_COLOR}
                                                    opacity={.55}
                                                    transform={`translate(0,${this.y(d.year) - (YEAR_HEIGHT / 4)})`}
                                                />
                                            );
                                        })
                                    }
                                    {
                                        stateResults.map((d: STATE_RESULT) => {
                                            const dataParty = (d.Data[d.Data.length - 1].Democratic > d.Data[d.Data.length - 1].Republican)
                                                ? VALID_PARTIES.democratic : VALID_PARTIES.republican
                                            const handleLineMouseOver = (event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
                                                d3.selectAll(".state").transition().attr("opacity", .25);
                                                d3.select(event.currentTarget)
                                                    .transition()
                                                    .attr("stroke-width", 5)
                                                    .attr("opacity", 1)
                                                    .style("stroke", (dataParty === VALID_PARTIES.democratic)
                                                            ? DEMOCRATIC_COLOR
                                                            : REPUBLICAN_COLOR)

                                                const clientPoint = d3.pointer(event, event.currentTarget);
                                                d3.select(event.currentTarget.nextElementSibling)
                                                    .style("display", null)
                                                    .attr("x", clientPoint[0])
                                                    .attr("y", clientPoint[1])
                                            }
                                            return (
                                                <g
                                                    key={d.Name}
                                                    className="people"
                                                >
                                                    <path
                                                        style={{
                                                            stroke: '#EEE',
                                                            fill: 'none',
                                                        }}
                                                        className="state"
                                                        strokeWidth={2.5}
                                                        onMouseOver={handleLineMouseOver}
                                                        onMouseLeave={this.handleLineMouseLeave}
                                                        d={this.line(d.Data as any) || undefined}
                                                    />
                                                    <text
                                                        dy="1em"
                                                        dx="1em"
                                                        fontSize="18px"
                                                        fontWeight="bold"
                                                        style={{ display: "none" }}
                                                    >
                                                        {d.Name}
                                                    </text>
                                                </g>
                                            )
                                        })
                                    }
                                </g>
                                <text
                                    textAnchor="middle"
                                    style={{
                                        fontSize: "2.2em"
                                    }}
                                    x={width / 2}
                                    y={MARGINS.top / 2}
                                >
                                    Margin of Victory (1900 - Present)
                                </text>
                            </svg>
                        </div>
                    </Col>
                </Row>
            </Container>);
    }
}