import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";

interface ParkingAvailability {
	[key: string]: {
		available_spaces: number;
		occupied_spaces: number;
		total_spaces: number;
	}
}

interface ParkingData {
	data: {
		parkingAvailability: ParkingAvailability;
	};
}

const CrystalParking: React.FC = () => {
	const [parkingAvailability, setParkingAvailability] = useState<ParkingAvailability>();
	const loadData = useCallback(async () => {
		var uuid = (Math.random() * Number.MAX_SAFE_INTEGER).toString(36);
		var query = 'query ParkingAvailability($id: ID\u0021, $startDay: Int\u0021, $endDay: Int\u0021, $year: Int\u0021) {parkingAvailability(id: $id, startDay: $startDay, endDay: $endDay, year: $year)}';
		var body = JSON.stringify({
			query,
			operationName: 'ParkingAvailability',
			variables: {
				id: "bJsl",
				startDay: 1,
				endDay: 31,
				year: 2022,
			},
		});

		var t = toast.loading('Loading parking data');
		var results: ParkingData = await (await fetch(`https://platform.honkmobile.com/graphql?honkGUID=${uuid}`, {
			body,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			method: 'POST',
			mode: 'cors',
		})).json();
		toast.success('Loaded parking data', { id: t });
		setParkingAvailability(results.data.parkingAvailability);
	}, []);

	useEffect(() => {
		loadData();
	}, [loadData]);

	return (
		<>
			<h2>
				Why you gotta be so rude?
				<Button
					onClick={() => {
						loadData();
					}}
					size="sm"
					style={{ marginLeft: '4px' }}
				>
					<FontAwesomeIcon icon={faSyncAlt}/>
				</Button>
			</h2>
			<table>
				<thead>
					<tr>
						<th>
							Date
						</th>
						<th>
							Available
						</th>
						<th>
							Occupied
						</th>
						<th>
							"Total"
						</th>
					</tr>
				</thead>
				<tbody>
					{
						parkingAvailability &&
						Object.keys(parkingAvailability).map(date => {
							var availability = parkingAvailability[date];
							var dateString = (new Date(date)).toLocaleDateString();
							return (
								<tr key={date}>
									<td>
										{dateString}
									</td>
									<td>
										{availability.available_spaces}
									</td>
									<td>
										{availability.occupied_spaces}
									</td>
									<td>
										{availability.total_spaces}
									</td>
								</tr>
							);
						})
					}
				</tbody>
			</table>
		</>
	);
}

export default CrystalParking;