import { useState, useRef } from "react";
import { Overlay, Popover, PopoverTitle, PopoverContent, ListGroup, ListGroupItem } from "react-bootstrap";

const WaysTooltip: React.FC<{
    runId: string;
    ways: string[];
    title: string;
    wayClicked: (way: string) => void;
}> = ({
    children,
    ways,
    runId,
    title,
    wayClicked,
}) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const sortedWays = [...ways].sort();

    return (<>
        <div ref={target} onClick={() => setShow(!show)}>
            {children}
        </div>
        <Overlay
            target={target.current}
            show={show}
            placement="left"
            rootClose={true}
            rootCloseEvent="click"
            onHide={() => setShow(false)}
        >
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
                <Popover
                    {...props}
                    id={runId}
                    arrowProps={arrowProps}
                    placement={placement}
                    popper={popper}
                >
                    <PopoverTitle as="h3">
                        {title}
                    </PopoverTitle>
                    <PopoverContent>
                        <ListGroup variant="flush">
                            {sortedWays.map(way => <ListGroupItem key={way}>
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => { wayClicked(way) }}
                                >
                                    {way}
                                </span>
                            </ListGroupItem>)}
                        </ListGroup>
                    </PopoverContent>
                </Popover>
            )}
        </Overlay>
    </>);
}

export default WaysTooltip;