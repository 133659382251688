import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { MONTH_DEGREE, MONTH_SHORT_NAMES, RADIANS_TO_DEGREES } from './DateRangeSelector';

interface MonthSelectorProps {
    update: (months: number[]) => void;
    reset: () => void;
}

const MonthSelector: React.FC<MonthSelectorProps> = ({ update, reset }) => {
    const Width = 400;
    const HalfWidth = Width / 2;
    const Margin = 20;
    const Radius = HalfWidth - Margin;

    const [selectedMonths, setSelectedMonths] = useState([...MONTH_SHORT_NAMES]);

    return (
        <Container>
            <div style={{ width: '100%', height: '400px' }}>
                <h3
                    style={{
                        textAlign: 'center',
                        color: '#CCC',
                    }}
                >
                    Month Selector
                </h3>
                <svg
                    width="100%"
                    height="350px"
                    viewBox={`0 0 ${Width} ${Width}`}
                >
                    {MONTH_SHORT_NAMES.map((month, i) => {
                        const startingAngle = (Math.PI / 2) - (i * MONTH_DEGREE);
                        const endingAngle = startingAngle - MONTH_DEGREE;
                        const startX = Math.cos(startingAngle) * Radius;
                        const startY = Math.sin(startingAngle) * Radius;
                        const endX = Math.cos(endingAngle) * Radius;
                        const endY = Math.sin(endingAngle) * Radius;

                        const monthSelected = selectedMonths.includes(month);

                        return (
                            <g
                                key={i}
                            >
                                <path
                                    stroke="black"
                                    fill={`rgba(25, 128, 25, ${monthSelected ? '1' : '0.5'})`}
                                    d={`M ${HalfWidth} ${HalfWidth} l ${startX} ${-startY} a ${Radius} ${Radius} 0 0 1 ${endX - startX} ${-(endY - startY)} Z`}
                                    onClick={() => {
                                        const updatedSelectedMonths = [...selectedMonths];
                                        if (monthSelected) {
                                            updatedSelectedMonths.splice(updatedSelectedMonths.indexOf(month), 1);
                                        } else {
                                            updatedSelectedMonths.push(month);
                                        }
                                        setSelectedMonths(updatedSelectedMonths);
                                        update(updatedSelectedMonths.map(month => {
                                            return MONTH_SHORT_NAMES.indexOf(month) + 1;
                                        }));
                                    }}
                                />
                                <text
                                    textAnchor="middle"
                                    fill="#CCC"
                                    fontSize="18px"
                                    fontWeight="bold"
                                    transform={`translate(${HalfWidth + ((startX + endX) / 2)},${HalfWidth - ((startY + endY) / 2)}) rotate(${((MONTH_DEGREE * i) + (MONTH_DEGREE / 2)) * RADIANS_TO_DEGREES})`}
                                >
                                    {month}
                                </text>
                            </g>
                        );
                    })
                    }
                </svg>
            </div>
            <Row>
                <Col sm={12}>
                    <Button
                        onClick={() => {
                            reset();
                            setSelectedMonths([...MONTH_SHORT_NAMES])
                        }}
                        style={{ float: 'left' }}
                    >
                        <FontAwesomeIcon icon={faCheckSquare} />
                        {` Select All`}
                    </Button>
                    <Button
                        onClick={() => {
                            setSelectedMonths([]);
                            update([]);
                        }}
                        style={{ float: 'right' }}
                    >
                        <FontAwesomeIcon icon={faSquare}/>
                        {` Select None`}
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}

export default MonthSelector;