import { SimpleMeshLayer } from "@deck.gl/mesh-layers";
import { RGBColorToArray } from "src/lib/Utils/Maps";
import vs from "./Shaders/vertex.glsl";
import fs from "./Shaders/fragment.glsl";
import { SimpleMeshLayerProps } from "src/types/deck.gl";

export interface SlopeAngleSimpleMeshProps extends Partial<SimpleMeshLayerProps> {
    
}

export default class SlopeAngleSimpleMeshLayer extends SimpleMeshLayer<any> {
    constructor(parentProps: any, props: SlopeAngleSimpleMeshProps) {
        super({ ...parentProps, ...props});
    }
    getShaders() {
        return Object.assign({}, super.getShaders(), {
            fs,
            vs,
        });
    }

    draw({ uniforms }: any) {
        const {
            aspectColors,
            contourIncrement,
            contourIncrementSmall,
            slopeCutoffRange,
            slopeMaskEnabled,
            slopeAspectEnabled,
            slopeAngleEnabled,
            terrainOpacity,
            slopeMaskOpacity,
            slopeAspectOpacity,
            slopeAngleOpacity,
        } = (this as any).props;
        const colors = [
          RGBColorToArray(aspectColors['south']),
          RGBColorToArray(aspectColors['west']),
          RGBColorToArray(aspectColors['north']),
          RGBColorToArray(aspectColors['east'])
        ];

        super.draw({
            uniforms: {
                ...uniforms,
                ...{
                    contourIncrement,
                    contourIncrementHalf: contourIncrement / 2,
                    contourIncrementSmall,
                    contourIncrementSmallHalf: contourIncrementSmall / 2,
                    aspectColors: Array.prototype.concat([], ...colors),
                    slopeCutoffMin: (slopeCutoffRange[0] * (Math.PI / 180)),
                    slopeCutoffMax: (slopeCutoffRange[1] * (Math.PI / 180)),
                    slopeMaskEnabled,
                    slopeAspectEnabled,
                    slopeAngleEnabled,
                    terrainOpacity,
                    slopeMaskOpacity,
                    slopeAspectOpacity,
                    slopeAngleOpacity,
                },
            }
        })
    }
}

(SlopeAngleSimpleMeshLayer as any).defaultProps = {
    slopeCutoffRange: [30,60],
    slopeMaskEnabled: true,
    slopeAspectEnabled: true,
    slopeAngleEnabled: true,
    contourIncrement: 50,
    contourIncrementSmall: 10,
};
(SlopeAngleSimpleMeshLayer as any).layerName = 'SlopeAngle';