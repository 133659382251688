import DewPoint from './DewPoint';
import Panorama from './Panorama';
import Ella from './Ella';
import Timers from './Timers';
import CovidTrajectory from './CovidTrajectory';
import PiScreensaver from './PiScreensaver';
import BitcoinLossTracker from './BitcoinLossTracker';
import TrafficCameras from './TrafficCameras';
import RantTracker from './RantTracker';
import RouteVisualizer from './RouteVisualizer';
import GithubMarkdownNotes from './GithubMarkdownNotes';
import ElectionMargins from './ElectionMargins';
import AbbottStars from './AbbottStars';
import HotSprings from './HotSprings';
import HighestGrossingFilms from './HighestGrossingFilms';
import HeartRate from './HeartRate';
import MarathonStatistics from './MarathonStatistics';
import Running from './Running';
import SkiingVisualizer from './SkiingVisualizer';
import SecretHitler from './SecretHitler';
import SlopeAngle from './SlopeAngle';
import MountainOut from './MountainOut';
import ElevationHighlight from './ElevationHighlight';

export interface Experiment {
  title: string,
  href: string,
  thumbnail?: string,
  component?: any,
};

export interface Category {
  title: string,
  subtext?: string,
  experiments: Experiment[],
};

export interface Projects {
  Complete: Category,
  Development: Category,
  Broken: Category,
  Other: Category,
}

export const PROJECTS_MANIFEST: Projects = {
    Complete: {
      title: 'Feature Complete',
      subtext: '(ish)',
      experiments: [
        {
          href: '/dewpoint/',
          title: 'Dew Point Graph',
          thumbnail: 'dewpoint.png',
          component: DewPoint,
        },
        {
          href: '/slopeAngle/',
          title: 'Slope Angle Visualizer',
          thumbnail: 'SlopeAngle.png',
          component: SlopeAngle,
        },
        {
          href: '/isthemountainout',
          title: 'Is the Mountain out?',
          thumbnail: 'isthemountainout.png',
          component: MountainOut,
        },
        {
          href: '/routeVisualizer',
          title: 'GPX / GeoJSON Route Visualizer',
          thumbnail: 'routeVisualizer.png',
          component: RouteVisualizer,
        },
        {
          href: '/running/',
          title: 'Running Statistics',
          thumbnail: 'running.png',
          component: Running,
        },
        {
          href: '/panorama/',
          title: 'Panorama UI',
          thumbnail: 'panorama.png',
          component: Panorama,
        },
        {
          href: '/covid-trajectory/',
          title: 'US Historical Covid Trajectory',
          thumbnail: 'covid-trajectory.png',
          component: CovidTrajectory
        },
        {
          href: '/ElevationHighlight',
          title: 'Elevation Highlighting',
          thumbnail: 'ElevationHighlight.png',
          component: ElevationHighlight,
        },
        {
          href: '/bitcoin/',
          title: 'Bitcoin Loss Tracker',
          thumbnail: 'bitcoin.png',
          component: BitcoinLossTracker,
        },
        {
          href: '/ella/',
          title: 'Ella\'s Collored Dots',
          thumbnail: 'ella.png',
          component: Ella,
        },
        {
          href: '/timers/',
          title: 'Basic Countdown Timers',
          thumbnail: 'timers.png',
          component: Timers,
        },
        {
          href: '/pi/',
          title: 'Raspberry Pi Screensaver',
          thumbnail: 'pi.png',
          component: PiScreensaver,
        },
        {
          href: '/otc/',
          title: 'Oregon Traffic Cameras',
          thumbnail: 'otc.png',
          component: TrafficCameras,
        },
        {
          href: '/rantTracker/',
          title: 'Rant Tracker',
          thumbnail: 'rantTracker.png',
          component: RantTracker,
        },
        {
          href: '/githubMarkdownNotes/',
          title: 'Github Markdown Notes',
          thumbnail: 'githubMarkdownNotes.png',
          component: GithubMarkdownNotes,
        },
        {
          href: '/heartRate/',
          title: 'Monthly Heart Rate',
          thumbnail: 'heartRate.png',
          component: HeartRate,
        },
        {
          href: '/highestGrossingFilms/',
          title: 'Highest Grossing Films',
          thumbnail: 'highestGrossingFilms.png',
          component: HighestGrossingFilms,
        },
        {
          href: '/marathonStatistics',
          title: 'Marathon Age Result Breakdowns',
          thumbnail: 'marathonAge.png',
          component: MarathonStatistics,
        },
        {
          href: '/usGeneralElectionStateMargins/',
          title: 'US Election State Margins',
          thumbnail: 'usGeneralElectionStateMargins.png',
          component: ElectionMargins,
        },
        {
          href: '/abbottStars/',
          title: 'Abbott Marathon Stars',
          thumbnail: 'abbottStars.png',
          component: AbbottStars,
        },
        {
          href: '/WAHotSprings',
          title: 'Washington Hot Springs',
          thumbnail: 'WAHotSprings.png',
          component: HotSprings,
        },
        {
          href: '/Skiing',
          title: 'Skiing Visualizer',
          thumbnail: 'Skiing.png',
          component: SkiingVisualizer,
        }
      ]
    },
    Development: {
      title: 'In Development',
      experiments: [
        {
          href: '/SecretHitler',
          title: 'Secret Hitler a la Jackbox',
          thumbnail: 'SecretHitler.png',
          component: SecretHitler,
        },
      ],
    },
    Broken: {
      title: 'In Progress',
      experiments: [],
    },
    Other: {
      title: 'Other Links',
      experiments: [
        {
          href: "https://www.adsbexchange.com/api/feeders/tar1090/?feed=BvTmWp3ZLe%2FS1z2iEDCvLPg6phIl5IOU%2BXTLozte1YjyVvYJUYSaApAYm5NxQ6xt",
          title: "ADS-B Exchange Feeder Page",
          thumbnail: "meme.jpg",
        }
      ],
    }
  };