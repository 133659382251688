import { ActivityData, DataViewPropsBase } from "./types";
import { secondsToString, secondsToStringD3 } from "src/lib/Utils/Strings";
import * as d3 from "d3";
import { D3_CATEGORY_10 } from "src/lib/d3/common";
import ActivityModal from "./ActivityModal";
import { useState } from "react";
import Popover from "react-bootstrap/esm/Popover";
import SimplyPlot from "src/lib/d3/SimplyPlot";

const DurationDistance: React.FC<DataViewPropsBase> = ({ activities, athleteId }) => {
    const [showModal, setShowModal] = useState(false);
    const [focusActivity, setFocusActivity] = useState<ActivityData>();

    const yearsSeries = activities.reduce(
        (
            accumulator,
            activity
        ): Map<string,[number,number,any]> => {
            const year = activity.date.getUTCFullYear().toString();
            if (!accumulator.has(year)) {
                accumulator.set(year, []);
            }
            accumulator.get(year)?.push([activity.distance, activity.duration, activity]);
            return accumulator;
        }, new Map());

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <SimplyPlot
                margins={{ top: 45, right: 30, bottom: 30, left: 60 }}
                titleText="Run Distance vs. Duration"
                xScaleOverride={d3.scaleLinear()}
                series={[...yearsSeries.entries()].map(([name,data]) => {
                    return {
                        name,
                        data
                    };
                })}
                lines={[
                    {
                        data: [[0,0],[200,200 * 396.2]],
                        color: '#CCF',
                        label: 'BQ Pace',
                    }
                    
                ]}
                yAxisFormat={secondsToStringD3}
                xAxisFormat={(a: any) => `${(a as number).toFixed(2)} mi`}
                colors={D3_CATEGORY_10}
                yTicks={(domain) => {
                    const [ymin,ymax] = domain;
                    const domainSize = ymax - ymin;
                    const tickSize = domainSize >= (21600)
                        ? 3600
                        : domainSize > (10800)
                            ? 1800
                            : domainSize > (5400)
                                ? 900
                                : domainSize > (1800)
                                    ? 300
                                    : 60;
                    const tickDistance = ymin % tickSize;
                    const min = ymin + (tickSize - tickDistance);
                    const range = d3.range(min, ymax, tickSize);
                    return range;
                }}
                onClick={(d: any) => {
                    setFocusActivity(d[2]);
                    setShowModal(true);
                }}
                hoverText={(d: any) => {
                    const activityData: ActivityData = d[2];
                    return (<>
                        <Popover.Title as="h3">{activityData.name}</Popover.Title>
                        <Popover.Content>
                            {new Date(activityData.date).toDateString()}
                            <br/>
                            {`${secondsToString(Math.floor(activityData.pace))} / mi`}
                        </Popover.Content>
                    </>);
                }}
            />
            <ActivityModal
                activity={focusActivity}
                showModal={showModal}
                onHide={() => {
                    setShowModal(false);
                }}
                athleteId={athleteId}
            />
        </div>
    );
}

export default DurationDistance;